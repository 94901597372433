import { useEffect, useState } from 'react'
import { Typography, Grid } from '@mui/material'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'

// Custom components
import ProfileAvatar from '../../../components/ProfileAvatar'

// APIs
import { useApi } from '../../../apis/privateApiCall'

// Utils
import ReplaceHashtags from '../../../utils/ReplaceHashTags'


// Methods
// method to use custom formatting for duration text
const formatDistance = (token, count, options) => {
  options = options || {}
  const result = formatDistanceLocale[token].replace('{{count}}', count)
  if (options.addSuffix) {
    if (options.comparison > 0) {
      return 'in ' + result
    } else {
      return result + ' ago'
    }
  }
  return result
}

// custom local values
const formatDistanceLocale = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
}


// Components
const Comment = ({data}) => {
  // Vars
  const theme = useTheme()
  const backgroundColor = theme.palette.gray.light
  const navigate = useNavigate()
  let cursor = "pointer"

  
  // Methods

  // go to user profile
  let handleProfileClick = () => {
    navigate('/profile/' + data?.userId)
  }

  // make comment unclickable if anonymous
  if (data?.anonymous === true) {
    cursor = "arrow"
    handleProfileClick = null
  }

  // get the duration between date and now
  const formatDuration = (dateObj) => {
    const text = formatDistanceToNowStrict(new Date(dateObj), {
      addSuffix: false,
      locale: { formatDistance }
    })
    return text
  }

  return (
    <Grid container sx={{ mb:2 }}>

      { /* avatar */}
      <Grid item xs={1} >
        <Grid container alignItems='center' justifyContent='space-evenly'>
          <Grid item >
            <ProfileAvatar
              onClick={handleProfileClick}
              sx={{ width:28, height:28, fontSize:13, cursor:cursor }}
              alt={data?.author?.username}
              src={ data?.author?.profileImage } 
            />
            <Typography variant='small' align='center'>
      
              { formatDuration(data.createdAt) }

            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* comment text */}

      <Grid item xs={11} sx={{ backgroundColor: backgroundColor, padding: '8px' }}>
        <Grid container spacing={0}>
          <Grid item xs={12} >
            <Typography variant='h6' >
              { data.author.username }
            </Typography>
          </Grid>
          <Grid item xs={12}  >
            <Typography variant='h6' sx={{ fontWeight:550 }}>
            
              { data?.htmlDescription && ReplaceHashtags(data?.htmlDescription) }              

            </Typography>
          </Grid>
        </Grid>

      </Grid>
    </Grid>    
  )
}


// Main component
export default function Comments(props) {
  // Vars
  const begId = props.begId

  // APIs
  const getComments = useApi().execute

  // State
  const [data, setData] = useState()

  // Effect to get Beg details
  useEffect(() => {
    const fetchData = async (begId) => {
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/begs/${begId}/comments`
      }
      // call the api and show an alert
      try { 
        setData(await getComments({}, options))
      }
      catch(error) {
      } finally {
        props?.doneLoading()
      }
    }

    fetchData(begId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  

  return (
    <Grid container>
      { data?.results && data.results.map((item, index) => {
          return ( 
            <Comment key={index} data={item} /> 
          )
        })
      }      
    </Grid>
  )
}