import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Divider, FormHelperText, Grid, TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { format, addDays } from 'date-fns'
import { useTheme } from '@emotion/react'

// Page components
import ContinueButton from '../components/ContinueButton'
import Disclaimer from '../components/Disclaimer'

// Icons
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

// Context
import { BegContext } from '../CreateBeg'

// Utils
import currFormat from '../../../utils/currFormat'

// CSS
import '../../../css/CreateBeg.css'


// Main component
export default function StepOne() {
  // Defaults and limits
  const MIN_GOAL        = process.env.REACT_APP_MIN_GOAL_AMOUNT
  const MAX_GOAL        = process.env.REACT_APP_MAX_GOAL_AMOUNT
  const MAX_GOAL_DAYS   = process.env.REACT_APP_MAX_GOAL_DAYS
  const displayMinGoal  = `$${currFormat(process.env.REACT_APP_MIN_GOAL_AMOUNT, 0)}`
  const displayMaxGoal  = `$${currFormat(process.env.REACT_APP_MAX_GOAL_AMOUNT, 0)}`
  const dateFormat      = 'MM/dd/yyyy'
  const MAX_DATE        = addDays(new Date(), MAX_GOAL_DAYS)
  const MAX_DATE_STRING = format(MAX_DATE, dateFormat)
  const TODAY           = addDays(new Date(), 0)
  const TODAY_STRING    = format(TODAY, dateFormat)
  
  // Vars
  const theme           = useTheme()
  const color           = theme.palette.primary.main
  const gray            = theme.palette.gray.main

  // Use context
  const { 
    inputs,
    setInputs,
    goNextPage,
  } = useContext(BegContext)
  
  // Validation
  const { 
    handleSubmit,
    register,
    setFocus,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'submit',
    defaultValues: {
      goalAmount: inputs.goalAmount || 50,
      title: inputs.title || '',
      goalDate: inputs.goalDate
    }
  })


  // Methods

  // datepicker function to handle typed date by setting form value
  const handleDateChange = (newDate) => {
    try {
      const newGoalDate = format(newDate, dateFormat)  
      setValue("goalDate", newGoalDate)
    } catch(err) {}
  }

  // datepicker function to handle date selected via mouse by updating inputs state
  const handleDateAccept = (newDate) => {
    const newGoalDate = format(newDate, dateFormat)
    setInputs(prev => ({...prev, goalDate: newGoalDate}))      
  }

  // update the inputs and go to the next step
  const onSubmit = (data) => {
    setInputs(prev => ({...prev, ...data}))
    goNextPage()
  }


  // Effects

  // set input focus on amount input
  useEffect(() => {
    setFocus("goalAmount")
  }, [setFocus])
  

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{ pb: 4 }}
    >

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h2">Enter Raise amount</Typography>
        <Grid container 
          spacing={1}
          sx={{ 
            padding: 2,
            paddingLeft: 3,
            paddingRight: 3,
            fontWeight: 700,
            fontSize: 2,
            color: {gray}
          }}
        >
          <Grid item xs={1}>
            <Typography variant="h7">$</Typography>
          </Grid>

          <Grid item xs={11}>
            <input
              name="goalAmount"
              type="text"
              className='amount-input'
              {...register('goalAmount', { 
                required: "A goal amount is required",
                min: { value: MIN_GOAL, message: `Minimum goal is ${displayMinGoal}` },
                max: { value: MAX_GOAL, message: `Maximum goal is ${displayMaxGoal}` },
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Numbers only'
                }
              })}
              style={{
                fontWeight: 700,
                fontSize: 26,
                color: {gray},
                textAlign: 'right',
                display: 'inline-block',
                width: '100%',
                border: 'none'
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{ m:-2, mt: -2, mb: 1 }} />

        <FormHelperText error={!!errors.goalAmount} sx={{ mb: 1 }}>
          { errors.goalAmount ? errors.goalAmount.message : `Minimum goal is ${displayMinGoal}`}
        </FormHelperText>

        <TextField
          name="title"
          label="Title of Raise"
          margin="normal"
          required
          autoComplete="begTitle"
          fullWidth
          error={!!errors.title || null}
          helperText={errors?.title ? errors.title.message : null}
          inputProps={{ 
            maxLength:48,
            pattern:/^[A-Za-z0-9 ]+$/ 
          }}
          {...register('title', {
              required: "Title is required",
              maxLength: { value: 48, message: 'Title must be 48 characters or less' },
              pattern: {
                value : /^[A-Za-z0-9 ]+$/,
                message: 'Title must be alpha numeric characters only'
              }
            }
          )}
        />

        <div className='end-date-flex-container'>
          <div className='end-date-flex-item end-date-item-text'>
            <Typography variant='bodytext'>Set End Date</Typography>
          </div>
          <div className='end-date-flex-item end-date-item-icon'>
            {/* <InfoOutlinedIcon /> */}
          </div>
          <div className='end-date-flex-item end-date-item-date'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                name="goalDate"
                minDate={TODAY}
                MAX_DATE={MAX_DATE}
                allowSameDateSelection
                inputFormat={dateFormat}
                onChange={handleDateChange}
                onAccept={handleDateAccept}
                value={inputs?.goalDate}
                renderInput={(params) =>
                  <TextField {...params}
                    helperText={errors.goalDate?.message || <br />}
                    sx={{ svg: {color} }}
                    fullWidth
                    error={!!errors.goalDate}
                    {...register('goalDate', {
                      required: "End date is required",
                      max: { 
                        value: MAX_DATE_STRING,
                        message: `The maximum duration is ${MAX_GOAL_DAYS} days`
                      },
                      min: { 
                        value: TODAY_STRING,
                        message: `Date cannot be before today`
                      }
                    })}
                  />}
              />
            </LocalizationProvider>
          </div>
        </div>
        <ContinueButton />
      </form>
      <Disclaimer />
    </Container>
  )}