import { useContext } from 'react'

// Components
import Menu from './components/Menu'

// Context
import { AuthContext } from '../../context/Auth'


// Main component
export default function AccountMenu() {
  // Context
  const { isLoading } = useContext(AuthContext)

  return (
    <>
      { !isLoading && <Menu /> }
    </>
  )
}