import React, { useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";

// Custom components
import { useLogin } from "../../apis/login";
import WaitOverlay from "../WaitOverlay";
import ErrorDialog from "../ErrorDialog";
import DialogHeader from "../DialogHeader";

// Utils
import upperFirst from "../../utils/upperFirst";

// Context
import { AuthContext } from "../../context/Auth";
import FirebaseSocialAuth from "../FirebaseSocialAuth";

// Main component
export default function SignInForm({ closeFunction, setDialog }) {
  // Vars
  const navigate = useNavigate();
  const location = useLocation();
  const errorRoutes = [{ path: "/403" }, { path: "/404" }, { path: "/410" }];

  // Context
  const { setLoggedIn, setAccessToken, setNavProfileImage, subTitle } =
    useContext(AuthContext);

  // Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Login api
  const { execute, isLoading } = useLogin();

  // State
  const [hasError, setHasError] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState({});
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  // Methods

  // input changes
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // toggle password display
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // show the forgot password dialog
  const handleForgotPassword = () => {
    setDialog("forgotPassword");
  };

  // move user to new page after login
  const redirectAfterLogin = () => {
    // check for error pages
    if (matchRoutes(errorRoutes, location)) navigate("/myprofile");

    // look for additional pages to move user from after login
    const locationArray = location.pathname.split("/");
    const pageName = locationArray[1];

    switch (pageName) {
      case "resetpassword":
      case "resetPassword":
        navigate("/myprofile");
        break;
      default:
    }
  };

  // Submit function to call login api
  const onSubmit = async (data) => {
    try {
      data.role = "customer";
      const result = await execute(data);

      // if api returns an accessToken, save the user information to local storage
      if (result?.accessToken) {
        localStorage.setItem("userId", result._id);
        localStorage.setItem("rights", result.rights);
        localStorage.setItem("role", result.role);
        localStorage.setItem("username", result.username);
        localStorage.setItem("firstName", result.firstName);
        localStorage.setItem("lastName", result.lastName);
        localStorage.setItem("email", result.email);
        localStorage.setItem("profileImage", result.profileImage);
        localStorage.setItem("totalRaised", result.totalRaised);
        localStorage.setItem("totalGoals", result.totalGoals);
        localStorage.setItem("chipins", result.chipins);
        localStorage.setItem("donations", result.donations);
        localStorage.setItem("comments", result.comments);
        localStorage.setItem("following", result.following);
        localStorage.setItem("followers", result.followers);
        localStorage.setItem("karma", result?.karma || 0);

        localStorage.setItem("karmaTitle", result?.karmaLevel.title);
        localStorage.setItem("karmaLevel", result?.karmaLevel.level);
        localStorage.setItem("karmaIcon", result?.karmaLevel.iconPath);

        // save access token in memory
        setAccessToken(result.accessToken);

        // set the new login state and set profile avatar
        setLoggedIn(true);
        setNavProfileImage(localStorage.getItem("profileImage"));
        closeFunction(true);

        // move the user to a new page after login if needed
        redirectAfterLogin();
      }
    } catch (error) {
      console.log(error);

      let message =
        "The server encountered an error sending your request. Please try again later.";
      if (error?.data?.message) message = upperFirst(error?.data?.message);
      setHasError(message);
      setErrorCode(error?.status || 500);
    }
  };

  return (
    <>
      {hasError && (
        <ErrorDialog
          message={hasError}
          setError={setHasError}
          errorCode={errorCode}
          title={"Sign in error"}
        />
      )}
      {isLoading && <WaitOverlay />}

      <DialogHeader>Sign In</DialogHeader>

      {/* dialog subtitle for additional instructions */}

      {subTitle}

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          error={!!errors?.username}
          helperText={errors?.username ? errors.username.message : null}
          onChange={handleChange("username")}
          {...register("username", {
            required: "Username is required",
          })}
        />

        <FormControl required fullWidth variant="outlined" sx={{ mt: 3 }}>
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            label="Password"
            sx={{ mb: 1 }}
            name="password"
            autoComplete="password"
            error={!!errors?.password}
            type={values.showPassword ? "text" : "password"}
            onChange={handleChange("password")}
            {...register("password", {
              required: "Please enter your password",
            })}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  color="primary"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText error={true}>
            {errors?.password ? errors.password.message : null}
          </FormHelperText>
          <Link
            sx={{ cursor: "pointer", fontSize: "small" }}
            variant="button"
            align="right"
            onClick={handleForgotPassword}
          >
            Forgot password?
          </Link>
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 4, mb: 4, fontWeight: 700 }}
        >
          Sign In
        </Button>

        <FirebaseSocialAuth
          setAccessToken={setAccessToken}
          setLoggedIn={setLoggedIn}
          setNavProfileImage={setNavProfileImage}
          closeFunction={closeFunction}
          redirectAfterLogin={redirectAfterLogin}
          upperFirst={upperFirst}
          setHasError={setHasError}
          setErrorCode={setErrorCode}
        />
      </form>
    </>
  );
}
