import { Paper, Box, Typography, Divider } from '@mui/material'

// Custom components
import '../../../components/styled-ui/StyledLink'
import StyledLink from '../../../components/styled-ui/StyledLink'


// Main component
export default function Tips() {
  // Vars
  const tipVariant = "subtitle2"


  return (
  <Paper elevation={4}>
    <Box sx={{ padding: '1rem' }}>
      
      <Typography variant="h6" component="div" sx={{ mb:2 }}>
        Tips
      </Typography>

      <Typography component="div" variant={tipVariant} mb={2}>
        You must be {process.env.REACT_APP_MIN_AGE}+ to raise funds on Begerz. If you are under {process.env.REACT_APP_MIN_AGE} years old, ask your parent or guardian to create a profile and help you raise money.
      </Typography>
      <Divider />

      <Typography component="div" variant={tipVariant} mb={2} mt={2}>
        Get your Raise to stand out buy being creative. The more entertaining and compelling your video story, the better odds you will have to exceed your raise amount.
      </Typography>
      <Divider />

      <Typography component="div" variant={tipVariant} mb={2} mt={2}>
        Reach beyond friends and family, share throughout your social media community.
      </Typography>
      <Divider />

      <Typography component="div" variant={tipVariant} mb={2} mt={2}>
        Give your Raise a strong name. The community can search for your Raise by what you name it. Add appropriate hash tags so those in the Begerz Community who don’t know you can find you by why you’re raising money.
      </Typography>
      <Divider />

      <Typography component="div" variant={tipVariant} mb={2} mt={2}>
        Make sure your Raise amount correlates to what you are raising money for.
      </Typography>
      <Divider />

      <Box mt={2} mb={2}>
        <StyledLink to="/help" >Learn more in Raise Tutorials</StyledLink>
      </Box>

    </Box>
  </Paper>
  )
}