import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider
} from '@mui/material';

export default function ErrorDialog({ message, setError, errorCode, title }) {
  const handleClose = () => {
    setError(false)
  }
  
  const ServerErrorMessage = () => {
    return (
      <>
        The server encountered an error. <br />
        Please wait and try again later.
      </>
    )
  }

  const UserErrorMessage = () => {
    return (
      <>
        {message}
      </>
    )
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle 
        color='gray'
        id="alert-dialog-title"
        sx={{ fontWeight: 700 }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          color='gray'
        >
          { errorCode && (errorCode !== 500 ? <UserErrorMessage /> : <ServerErrorMessage /> )}
        </DialogContentText>
      </DialogContent>
      <Divider></Divider>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}