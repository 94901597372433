import { Grid, Paper, Typography } from '@mui/material'

// Page components
import SideNote from './SideNote'

// Utils
import formatDate from '../../../utils/formatDate'


// Main component
export default function ActivityLog(props) {
  // Vars
  const data = props.data
  
  return (
    <>
      <Grid container spacing={1}>

        { data?.results && data?.results.map((item, index) => {
          return ( 
            <Grid key={index} item xs={12}>
              <Paper elevation={1} sx={{ p:1 }}>
                <Typography component="div" variant="subtitle3" fontWeight="550">
                  { item.description }
                </Typography>
                <Typography component="div" variant="subtitle3" fontWeight="600">
                  { item.note }
                </Typography>
                <Typography component="div" variant="small">
                  { formatDate(item.createdAt, 'MMM dd, u ') + " at " + formatDate(item.createdAt, 'h:mm a') }
                </Typography>
              </Paper>
            </Grid>
          )
        })}

      </Grid>
      <SideNote refresh={props.refresh} />
    </>
  )
}
