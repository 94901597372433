import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button'
import { Container, Typography } from '@mui/material'
import largeCheck from '../../assets/largeCheck.svg'

// Get context
import { AuthContext } from '../../context/Auth'
import DialogHeader from '../DialogHeader'

export default function ForgotPasswordForm({ setDialog }) {
  const navigate = useNavigate()

  // Auth context
  const { handleCloseSignin } = useContext(AuthContext)

  // Methods
  const handleSignInClick = () => {
    setDialog('signIn')
  }


  return (
    <>
      <DialogHeader sx={{ textAlign: 'center' }} >
        You're all set!
        <Typography variant="body2">
          We've sent you an email to reset your <br />password if your email is registered in our system.
        </Typography>
      </DialogHeader>

      <Container align="center">
        <img src={largeCheck} alt="Checked" />
      </Container>

      <Button
        fullWidth
        variant="outlined"
        sx={{mt: 4, mb: 2, fontWeight: 700}}
        onClick={handleSignInClick}
      >
        Return to Sign In
      </Button>

      <Button
        fullWidth
        variant="outlined"
        sx={{mt: 2, mb: 2, fontWeight: 700}}
        onClick = {() => {
          handleCloseSignin()
          navigate('/home')
        }}
      >
        Go to Home Page
      </Button>
    </>
  )
}