import MissingPhoto from '../../../assets/placeholder.png'


// Main component 
export default function PreviewImage(props) {
  // add missing image on error
  const addDefaultSrc = (event) => {
    event.target.removeAttribute('onError')
    event.target.src = MissingPhoto
  }

  return (
    <img 
      alt="preview"
      width="100%"
      style={{ cursor:"pointer" }}
      {...props}
      src={props?.src || MissingPhoto}
      onError={addDefaultSrc}
    />
  )
}