import * as axios from 'axios'


// Axios wtrapper function
export const callApi = async(data, options) => {
  // Vars
  const defaultTimeout = 4500
  const controller = new AbortController()
  let output = {}
  
  // Add data to the call
  if (data && !options.data) {
    options.data = data
  }

  // create axios instance
  const instance = axios.create({
    timeout: defaultTimeout,
    signal: controller.signal
  })
  
  // call the api
  await instance(options).then((response) => {
    output = response?.data || {}
  })
  .catch((error) => {
    controller.abort()
    throw error
  })

  return {
    output,
    controller
  }
}