import { useContext, useEffect } from 'react'
import { useApi } from '../apis/refresh'

// Get context
import { AuthContext } from '../context/Auth'


// Main component
export default function PersistentLogin() {
  // Vars
  const {
    setIsLoading,
    setLoggedIn,
    setAccessToken
  } = useContext(AuthContext)
  
  // Refresh API
  const { execute, controller } = useApi()


  // Effect if access token has changed
  useEffect(() => {
    // call refresh api to get a new access token
    const getAccessToken = async () => {
      try {
        const result = await execute({})
        setAccessToken(result?.accessToken)
        setLoggedIn(true)
      } 
      catch(error) {
        setLoggedIn(false)
      } 
      finally {
        setIsLoading(false)
      }
    }

    // use isLoading to time signin dialog
    setIsLoading(true)
    getAccessToken()

    // clean up
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return <></>
}