import React, { useContext, useState, useEffect } from "react";
import {  useParams, useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Divider } from "@mui/material";

// Page components
import Donations from "./components/Donations";
import Comments from "./components/Comments";
import BegSideBar from "./components/BegSideBar";
import Reactions from "./components/Reactions";
import SignInHeader from "./components/SignInHeader";
import FlagContent from "../../components/flag-content";

// Custom components
import Player from "../../components/player/Player";
import ProfileAvatar from "../../components/ProfileAvatar";
import BegPagination from "../../components/raises/BegPagination";
import WaitOverlay from "../../components/WaitOverlay";
import ErrorDialog from "../../components/ErrorDialog";
import HelmetMetaData from '../../components/HelmetMetaData';
import { isIOS } from "react-device-detect";


// Context
import { AuthContext } from "../../context/Auth";

// Apis
import { useApi } from "../../apis/privateApiCall";
import { callApi } from "../../apis/callApi";
import { useApi as Refresh } from "../../apis/refresh";

// Utils
import ReplaceHashtags from "../../utils/ReplaceHashTags";

// Load CSS
import "../../css/Player.css";
import SectionHeader from "../../components/styled-ui/SectionHeader";
import AccessPageLower from "../../components/AccessPageLower";
import { Helmet } from "react-helmet-async";

// Main component
export default function BegDetails() {
  // Vars
  const { begId } = useParams();
  const navigate = useNavigate();
  const currentUrl = window.location.href;

  // Authorization context
  const { signInOpen, handleSignIn, setSubTitle, accessToken } =
    useContext(AuthContext);

    const [query] = useSearchParams() 
    if (isIOS && query.get('type') ==='share') {
    window.location.replace(process.env.REACT_APP_App_Store_Link)
    
  }


  // APIs
  const execApi = useApi().execute;
  const { execute: executeRefresh } = Refresh();

  // State

  // manages data loading
  const [canLoad, setCanLoad] = useState({});

  // manages retry after logging in for private begs
  const [shouldRetry, setShouldRetry] = useState(false);

  const [data, setData] = useState();
  const [related, setRelated] = useState();
  const [waitOpen, setWaitOpen] = useState(true);
  const [error, setError] = useState({
    message: "",
    errorCode: "",
    title: "",
  });

  

  // Methods

  // handle load order of data sections
  const handleLoaded = (index) => {
    switch (index) {
      case "main":
        setCanLoad((prev) => ({ ...prev, related: true }));
        break;
      case "related":
        setCanLoad((prev) => ({ ...prev, comments: true }));
        break;
      case "comments":
        setCanLoad((prev) => ({ ...prev, donations: true }));
        break;
      default:
        setWaitOpen(false);
    }
  };

  // go to user profile
  const handleProfileClick = () => {
    navigate("/profile/" + data.userId);
  };

  // log the beg's view
  const logView = async (begId) => {
    const options = {
      method: "post",
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/views`,
    };
    execApi({ begId: begId }, options);
  };

  // fetch the requested beg
  const fetchBeg = async (begId, token) => {
    try {
      const options = {
        method: "get",
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/begs/${begId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      return await callApi(data, options);
    } catch (error) {
      throw error;
    }
  };

  // init all data for the page
  const initData = async (begId, token) => {
    try {
      // fetch the beg, start login process if not authorized
      const details = await fetchBeg(begId, token);
      setData(details.output);

      // set the first loaded data element
      handleLoaded("main");

      // log view
      try {
        await logView(begId);
      } catch (error) { }
    } catch (error) {
      throw error;
    }
  };

  // get pagination results from related begs component
  const relatedPagination = (data) => {
    setRelated(data);
  };

  // handle 403 error
  const handleSecurity = async () => {
    // try to get a refresh token and keep moving
    try {
      const refresh = await executeRefresh({});
      await initData(begId, refresh?.accessToken);
    } catch (error) {
      if (error.response.status !== 400) navigate("/403");
      setShouldRetry(true);
      setSubTitle(<SignInHeader />);
      handleSignIn();
    }
  };

  // -----------------------------------------

  // Effects

  // retry data load after new login
  useEffect(() => {
    // fetch the beg, redirect if not authorized
    const retryInit = async () => {
      try {
        await initData(begId, accessToken);
      } catch (error) {
        navigate("/403");
      } finally {
        setSubTitle("");
      }
    };
    // look for a new accesstoken - this is probably wrong
    if (!signInOpen && accessToken && shouldRetry) retryInit();

    // look for failed login
    if (!signInOpen && !accessToken && shouldRetry) {
      setSubTitle("");
      navigate("/403");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInOpen, accessToken]);

  // initial try to load data
  useEffect(() => {
    const firstInitData = async (begId) => {
      try {
        await initData(begId);
      } catch (error) {
        switch (error.response.status) {
          case 404:
            navigate("/404");
            break;
          case 403:
            handleSecurity();
            break;
          default:
            setError((prev) => ({
              ...prev,
              message: error.message,
              errorCode: "000",
              title: "Error getting beg",
            }));
        }
      }
    };

    // reset the load order to refresh the page
    setCanLoad({});
    firstInitData(begId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [begId]);

  return (
    <Container component="main" maxWidth="md" sx={{ marginTop: 0 }}>
      {/* Meta tags for preview in share link */}
      <HelmetMetaData
        quote={data?.title}
        image={data?.videos[0]?.thumbLink}
        title={data?.title}
        description={"Begerz Customer Portal"}
      />
      <Helmet>
        <meta property="og:url" content={currentUrl} />
        <meta property="og:title" content={data?.title} />
        <meta property="og:description" content="Begerz Customer Portal" />
        <meta property="og:image" content={data?.videos[0]?.thumbLink} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@begerz" />
        <meta name="twitter:title" content={data?.title} />
        <meta name="twitter:description" content="Begerz Customer Portal " />
        <meta name="twitter:image" content={data?.videos[0]?.thumbLink} />
      </Helmet>

      {waitOpen && <WaitOverlay />}
      {error?.message && <ErrorDialog setError={setError} {...error} />}

      <Grid container spacing={2} mb={4}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{
              color: "white",
              textAlign: "left",
              mb: 3,
              fontFamily: "Hey Comic",
              fontSize: "64px",
            }}
          >
            {data?.title}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8.5}>
          <Grid row="true">
            <Grid item xs={12}>
              <Paper
                elevation={0}
                sx={{
                  maxHeight: "330px",
                  height: "auto",
                  backgroundColor: "#000000",
                }}
              >
                {data?._id && (
                  <Player showNav controls playsinline streams={data?.videos} />
                )}
              </Paper>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {/* Reactions */}
            <Grid container spacing={1} sx={{ mt: 2 }}>
              {data?._id && <Reactions data={data} />}

              {/* Right side created by */}
              <Grid item xs={6} sm={5} md={3} lg={3}>
                {/* profile section*/}
                <Grid
                  container
                  spacing={0}
                  mt={-1}
                  alignItems="top"
                  justifyContent="space-between"
                >
                  <Grid item xs={3}>
                    {data?.author && (
                      <ProfileAvatar
                        onClick={handleProfileClick}
                        sx={{
                          cursor: "pointer",
                          mt: 1,
                          width: 28,
                          height: 28,
                          fontSize: 14,
                        }}
                        alt={data?.author?.username || "unknown"}
                        src={data?.author?.profileImage}
                      />
                    )}
                  </Grid>

                  <Grid item xs={8}>
                    <Typography noWrap variant="caption" mt={1}>
                      {data?.author?.username || "anonymous"} <br /> created
                      this raise
                    </Typography>
                  </Grid>

                  {/* karma flower */}
                  <Grid item xs={1} mt={-0.25} justifyContent="flex-end">
                    {data?.author && (
                      <img
                        height="auto"
                        width="48px"
                        alt="karma-flower"
                        src={data?.author?.karmaLevel?.iconPath}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* success blog */}

              {data?.success?._id && (
                <>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item>
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          fontSize={16}
                          mt={5}
                        >
                          {data?.success?.createdAt &&
                            format(
                              new Date(data.success.createdAt),
                              "LLL do, yyyy"
                            )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight={400}
                      fontSize={16}
                      mt={0}
                    >
                      {data?.success?.htmlDescription &&
                        ReplaceHashtags(data.success.htmlDescription)}
                    </Typography>
                  </Grid>
                </>
              )}

              {/* beg blog */}

              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <SectionHeader mt={4} sx={{ display: "block" }}>
                      {data?.createdAt &&
                        format(new Date(data.createdAt), "LLL do, yyyy")}
                    </SectionHeader>
                  </Grid>

                  {/* flag beg */}
                  <Grid item>
                    <FlagContent itemId={begId} begId={begId} itemType="beg" />
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
              </Grid>

              {/* blog entries */}
              <Grid item xs={12}>
                <Typography variant="h6" fontWeight={400} fontSize={16} mt={0}>
                  {data?.htmlDescription &&
                    ReplaceHashtags(data?.htmlDescription)}
                </Typography>
              </Grid>

              <Grid item xs={12} mt={2}>
                <SectionHeader>Comments</SectionHeader>
                <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

                {canLoad?.comments && (
                  <Comments
                    doneLoading={() => {
                      handleLoaded("comments");
                    }}
                    begId={begId}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* donations */}

        <Grid item xs={12} sm={3.5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {data?._id && <BegSideBar data={data} />}
            </Grid>
            <Grid item xs={12}>
              {canLoad?.donations && (
                <Donations
                  doneLoading={() => {
                    handleLoaded("donations");
                  }}
                  begId={begId}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {/* related begs */}
          <SectionHeader mt={4} sx={{ display: "block" }}>
            Related Raises ({related?.records || 0})
          </SectionHeader>

          <Grid container spacing={2} mt={2} mb={8} pl={2} pr={2}>
            {canLoad?.related && data?.author._id && (
              <BegPagination
                doneLoading={handleLoaded}
                type={"related"}
                url={`/api/v1/begs/users/${data.author._id}?status=active&publishType=private&sort=createdAt`}
                title={false}
                pagination={relatedPagination}
                pageSize={12}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <AccessPageLower />
    </Container>
  );
}
