import { Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Icons
import EditIcon from '@mui/icons-material/Edit'
import MoreIcon from    '../../assets/icons/more.svg'


// Main component
export default function Title(props) {
  // Vars
  const theme = useTheme()
  const begLink = props.begLink
  const data = props.data

  return (
    <Grid item xs={12}>

      <Grid container mt={-.5}>

        {/* title */}
        <Grid item xs={10}>
          <Link to={ begLink } style={{ textDecoration:'none' }}>
            <Typography variant="h6" noWrap
              sx={{ mb:0, minHeight:'22px' }}>
              { `${data?.title}` || 'Untitled' }
            </Typography>
          </Link>
        </Grid>

        {/* edit beg link */}
        <Grid item xs={2}>
          { (data?.editLink && 
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item>
                <Link to={ data?.editLink }>
                  <EditIcon fontSize='smaller' sx={{ color:theme.palette.gray.main }} />
                </Link>
              </Grid>
              <Grid item>
                <Link to={ begLink }>
                  <img alt="more" style={{ cursor:'pointer' }} src={MoreIcon} />
                </Link>
              </Grid>
            </Grid> ) || (
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Link to={ begLink }>
                  <img alt="more" style={{ cursor:'pointer' }} src={MoreIcon} />
                </Link>
              </Grid>
            </Grid>
            )
          }
        </Grid>
      </Grid>
    </Grid>
  )
}