import { useState, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Container from "@mui/material/Container";
import { format, isBefore } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  FormHelperText,
} from "@mui/material";
import { useTheme } from "@emotion/react";

// Custom components
import ErrorDialog from "../../components/ErrorDialog";
import WaitOverlay from "../../components/WaitOverlay";
import PasswordDetails from "../../components/PasswordDetails";

// Page components
import ContinueLinks from "./components/ContinueLinks";
import SignUpEmailSent from "./dialogs/SignUpEmailSent";
import NeedGuardian from "./dialogs/NeedGuardian";

// Apis
import { useAccounts } from "../../apis/accounts";
import { getMaxBirthdate } from "./utils/utils";
import { AuthContext } from "../../context/Auth";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import FirebaseSocialAuth from "../../components/FirebaseSocialAuth";

// utils
import upperFirst from "../../utils/upperFirst";

// Main component
export default function SignUp() {
  // State
  const [hasError, setHasError] = useState(false);
  const [errorCode, setErrorCode] = useState({});
  const [waitOpen, setWaitOpen] = useState(false);
  const [ageDialogOpen, setAgeDialogOpen] = useState(false);
  const [signedUp, setSignedUp] = useState(false);
  const birthdate = useState()[0];
  const [values, setValues] = useState({ showPassword: false });

  // Vars
  const MIN_AGE = process.env.REACT_APP_MIN_AGE;
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const dateFormat = "MM/dd/yyyy";
  const { execute } = useAccounts();
  const errorRoutes = [{ path: "/403" }, { path: "/404" }, { path: "/410" }];

  // context
  const {
    setLoggedIn,
    setAccessToken,
    setNavProfileImage,
    handleCloseSignin: closeFunction,
  } = useContext(AuthContext);
  // apis
  const location = useLocation();
  const navigate = useNavigate();
  // form validation react-hook-form
  const {
    setValue,
    setFocus,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
      username: "",
    },
  });

  // Effects

  //focus birthdate field
  useEffect(() => {
    setFocus("birthdate");
  }, [setFocus]);

  // Methods

  // handle data input changes
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // datepicker function to handle typed date by setting form value
  const handleDateChange = (newDate) => {
    try {
      setValue("birthdate", format(newDate, dateFormat));
    } catch (err) {}
  };

  // datepicker function to handle date selected via mouse by updating inputs state
  const handleDateAccept = (newDate) => {
    newDate = format(newDate, dateFormat);
    setValues((prev) => ({ ...prev, birthdate: newDate }));
  };

  // toggle password visibility
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const redirectAfterLogin = () => {
    // check for error pages
    if (matchRoutes(errorRoutes, location)) navigate("/myprofile");

    // look for additional pages to move user from after login
    const locationArray = location.pathname.split("/");
    const pageName = locationArray[1];

    switch (pageName) {
      case "resetpassword":
      case "resetPassword":
        navigate("/myprofile");
        break;
      default:
    }
  };

  // Form submission
  const onSubmit = async (data) => {
    setWaitOpen(true);
    data.role = "customer";
    data.status = "active";
    data.rights = ["full"];
    data.birthdate = format(new Date(data.birthdate), "yyyy-MM-dd");
    try {
      const result = await execute(data);
      console.log({ result });
      setWaitOpen(false);
      setSignedUp(true);

      console.log(result);
      if (result?.accessToken) {
        localStorage.setItem("userId", result?.data?.data._id);
        localStorage.setItem("rights", result?.data?.data.rights);
        localStorage.setItem("role", result?.data?.data?.role);
        localStorage.setItem("username", result?.data?.data?.username);
        localStorage.setItem("firstName", result?.data?.data?.firstName);
        localStorage.setItem("lastName", result?.data?.data?.lastName);
        localStorage.setItem("email", result?.data?.data?.email);
        localStorage.setItem("profileImage", result?.data?.data?.profileImage);
        localStorage.setItem("totalRaised", result?.data?.data?.totalRaised);
        localStorage.setItem("totalGoals", result?.data?.data?.totalGoals);
        localStorage.setItem("chipins", result?.data?.data?.chipins);
        localStorage.setItem("donations", result?.data?.data?.donations);
        localStorage.setItem("comments", result?.data?.data?.comments);
        localStorage.setItem("following", result?.data?.data?.following);
        localStorage.setItem("followers", result?.data?.data?.followers);
        localStorage.setItem("karma", result?.data?.data?.karma || 0);

        localStorage.setItem(
          "karmaTitle",
          result?.data?.data?.karmaLevel?.title
        );
        localStorage.setItem(
          "karmaLevel",
          result?.data?.data?.karmaLevel?.level
        );
        localStorage.setItem(
          "karmaIcon",
          result?.data?.data?.karmaLevel?.iconPath
        );

        // save access token in memory
        setAccessToken(result?.data?.accessToken);

        // set the new login state and set profile avatar
        setLoggedIn(true);
        setNavProfileImage(localStorage.getItem("profileImage"));
        closeFunction(true);

        // move the user to a new page after login if needed
        redirectAfterLogin();
      }
    } catch (error) {
      setWaitOpen(false);
      setHasError(
        error?.data?.message ||
          "The server encountered an error sending your request. Please try again later."
      );
      setErrorCode(error?.status || 500);
    }
  };

  // compare dates for underage dialog
  const checkAgeDialog = () => {
    if (getValues("birthdate")) {
      const birthdate = new Date(getValues("birthdate"));
      let minDate = new Date(getMaxBirthdate());
      if (isBefore(minDate, birthdate)) {
        setAgeDialogOpen(true);
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container component="main" maxWidth="md" mt={3}>
        {waitOpen && <WaitOverlay />}
        {hasError && (
          <ErrorDialog
            message={hasError}
            setError={setHasError}
            errorCode={errorCode}
            title={"There was an error signing up"}
          />
        )}
        {signedUp && <SignUpEmailSent />}
        {ageDialogOpen && (
          <NeedGuardian setDialogOpen={setAgeDialogOpen} open={ageDialogOpen} />
        )}

        <Paper elevation={4}>
          <Box
            p={6}
            component="form"
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography variant="h7">When's your birthday?</Typography>
            <Typography variant="subtitle1" marginBottom={2}>
              Your birthday will not be shown publicly.
            </Typography>

            <DesktopDatePicker
              name="birthdate"
              label="Birthdate"
              inputProps={{
                autoComplete: "off",
                form: { autocomplete: "off" },
              }}
              inputFormat={dateFormat}
              format={dateFormat}
              value={values.birthdate}
              onChange={handleDateChange}
              onAccept={handleDateAccept}
              allowSameDateSelection
              renderInput={(params) => (
                <TextField
                  fullWidth
                  sx={{ svg: { color } }}
                  {...params}
                  error={!!errors.birthdate}
                  helperText={
                    errors?.birthdate ? errors.birthdate.message : null
                  }
                  {...register("birthdate", {
                    required: "Birthday is required",
                    max: {
                      value: getMaxBirthdate(birthdate),
                      message: `You must be at least ${MIN_AGE} years old to join Begerz`,
                    },
                  })}
                />
              )}
            />

            <Typography variant="h7" marginTop={4}>
              What's your name?
            </Typography>
            <Typography marginBottom={0} variant="subtitle1">
              You can change this later at any time.
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="First name"
                  name="firstName"
                  inputProps={{
                    autoComplete: "off",
                    form: { autocomplete: "off" },
                  }}
                  error={!!errors?.firstName}
                  helperText={
                    errors?.firstName ? errors.firstName.message : null
                  }
                  onChange={handleChange("firstName")}
                  {...register("firstName", {
                    required: "First name is required",
                    maxLength: {
                      value: 30,
                      message: "Must be 30 characters or less",
                    },
                  })}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Last name"
                  id="lastName"
                  name="lastName"
                  inputProps={{
                    autoComplete: "off",
                    form: { autocomplete: "off" },
                  }}
                  error={!!errors?.lastName}
                  helperText={errors?.lastName ? errors.lastName.message : null}
                  onChange={handleChange("lastName")}
                  {...register("lastName", {
                    required: "Last name is required",
                    maxLength: {
                      value: 30,
                      message: "Must be 30 characters or less",
                    },
                    minLength: 1,
                  })}
                />
              </Grid>
            </Grid>

            <TextField
              name="username"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                autoComplete: "off",
                form: { autocomplete: "off" },
              }}
              margin="normal"
              placeholder="Username"
              fullWidth
              required
              type="string"
              label="Username"
              error={!!errors?.username}
              helperText={errors?.username ? errors.username.message : null}
              onChange={handleChange("username")}
              {...register("username", {
                required: "Username is required",
                maxLength: {
                  value: 24,
                  message: "Must be 24 characters or less",
                },
                minLength: {
                  value: 6,
                  message: "Must be at least 6 characters",
                },
                pattern: {
                  value:
                    /^[a-zA-Z]([._-](?![._-])|[a-zA-Z0-9]){3,24}[a-zA-Z0-9]$/i,
                  message: "Valid characters: a-z, 0-9, _ - .",
                },
              })}
            />

            <Typography marginTop={3} variant="h7">
              Set Email &amp; Password
            </Typography>
            <Typography variant="subtitle1">
              Enter email you would like to use for this account.
            </Typography>

            <TextField
              name="email"
              autoComplete="Email"
              placeholder="Email Address"
              InputLabelProps={{ shrink: true }}
              margin="normal"
              fullWidth
              required
              type="email"
              label="Email Address"
              inputProps={{
                autoComplete: "off",
                form: { autocomplete: "off" },
              }}
              error={!!errors?.email}
              helperText={errors?.email ? errors.email.message : null}
              onChange={handleChange("email")}
              {...register("email", {
                required: "Email address is required",
                maxLength: {
                  value: 80,
                  message: "Must be 80 characters or less",
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
            />

            <FormControl fullWidth sx={{ mt: 2 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                name="password"
                sx={{ mb: 1 }}
                autoComplete="off"
                type={values.showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      color="primary"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                error={!!errors?.password}
                {...register("password", {
                  required: "Password is required",
                  maxLength: {
                    value: 30,
                    message: "Must be 30 characters or less",
                  },
                  pattern: {
                    value:
                      // eslint-disable-next-line
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    message: <PasswordDetails />,
                  },
                })}
              />
              <FormHelperText error={true}>
                {errors?.password ? errors.password.message : null}
              </FormHelperText>
            </FormControl>

            <Button
              onClick={() => {
                checkAgeDialog();
              }}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 4, fontWeight: 700 }}
            >
              Sign Up
            </Button>

            <FirebaseSocialAuth
              setAccessToken={setAccessToken}
              setLoggedIn={setLoggedIn}
              setNavProfileImage={setNavProfileImage}
              closeFunction={closeFunction}
              redirectAfterLogin={redirectAfterLogin}
              upperFirst={upperFirst}
              setHasError={setHasError}
              setErrorCode={setErrorCode}
            />
          </Box>
        </Paper>
        <ContinueLinks />
      </Container>
    </LocalizationProvider>
  );
}
