import React from 'react'
import { Button, Grid, Dialog, DialogContent, Divider, Typography, Box, IconButton } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import { format } from 'date-fns'

// Components
import DialogHeader from '../../../components/DialogHeader'

// Icons
import blueInfo from '../../../assets/icons/blueInfo.svg'
import fullBag from '../../../assets/icons/moneybag/full.svg'


// Main component
export default function StepOne(props) {
  // Vars
  const data = props.data
  const dateFormat = 'MM/dd/yyyy'
  const setDialog = props.setDialog
  const activeDialog = props.activeDialog
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const borderColor = theme.palette.gray.secondary
  const border = { border:'1px solid', borderColor:borderColor, borderRadius:'4px' }

  
  // set the correct money bag icon
  React.useEffect(() => {
  },[data])


  return (
    <Dialog
      maxWidth="sm"
      open={activeDialog === 1}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent sx={{ mb: 2 }}>

        <Box sx={{ textAlign:'left' }}>
          <DialogHeader sx={{ textAlign:'left', mb:3 }}>
            End &amp; Withdraw
          </DialogHeader>
        </Box>        

        <IconButton
          onClick={ () => { setDialog(2) }}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Divider sx={{ mb:2 }}></Divider>

        {/* info section */}

        <Grid container sx={{ backgroundColor:theme.palette.gray.light }} p={1} alignItems="center">
          <Grid item xs={1.5} >
            <img alt='info' height="54px" src={blueInfo}></img>
          </Grid>
          <Grid item xs={10.5}>
            <Typography
              textAlign="center"
              variant='bodyText'
              sx={{ fontWeight:550, paddingRight:1 }}
            >
              Withdrawing your funds now will end your raise &amp; no one will be able to chip-in to the raise anymore.
            </Typography>
          </Grid>
        </Grid>

        <Typography variant='h6' mt={3}>
          Current Funds
        </Typography>

        {/* amount raised section */}

        <Grid container mt={1} spacing={1} p={1} alignItems="flex-end" sx={{ border }}>
          <Grid item xs={1.5} >
            <img alt='info' height="54px" src={data?.iconPath || fullBag}></img>
          </Grid>

          <Grid item xs={5.25} p={1}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography variant='h7'>
                  ${data.amountRaised}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>
                  raised of a ${data.goalAmount} goal
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={5.25} p={1}>
            <Typography variant='subtitle2'>
              Ends {format(new Date(data.goalDate), dateFormat)} <b>| { (data.daysRemaining > 0 && data.daysRemaining) || 0 } days left</b>
            </Typography>
          </Grid>

        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt:1, mb:1, fontWeight: 700 }}
              onClick={ () => { setDialog(0) } }
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt:1, mb:1, fontWeight: 700 }}
              onClick={ () => { setDialog(2) } }
            >
              Continue
            </Button>
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  )
}