import { useState, useEffect } from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Custom components
import WaitOverlay from '../../../components/WaitOverlay'
import ErrorDialog from '../../../components/ErrorDialog'

// Page components
import ActivityLog from './ActivityLog'
import PaymethodSelect from './PaymethodSelect'
import StatusSelect from './StatusSelect'

// Apis
import { useApi } from '../../../apis/privateApiCall'

// Utils
import capFirstLetter from '../../../utils/upperFirst'
import formatDate from '../../../utils/formatDate'
import currFormat from '../../../utils/currFormat'

// Icons
import LaunchIcon from '@mui/icons-material/Launch'
import LeftArrow from '../../../assets/icons/leftArrow.svg'


// Main components
export default function Details() {
  // Params
  const { withdrawalId } = useParams()
  
  // Vars
  const { execute, controller, loading } = useApi()
  const theme = useTheme()
  const navigate = useNavigate()

  // State
  const [data, setData] = useState()
  const [logs, setLogs] = useState(false)
  const [error, setError] = useState({
    message:'',
    errorCode: '',
    title: ''    
  })


  // Methods

  // call api to get activity logs
  const getActivityLog = async () => {
    try {
      const response = await execute({}, {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/admin/withdrawal-logs/withdrawal/${withdrawalId}?sort=createdAt&page=1&limit=1000`
      })
      setLogs(response)
    } catch(error) {
      throw error
    }
  }


  // Load Effect
  useEffect(() => {
    const fetchData = async() => {
      try {
        const options =  {
          method: 'get',
          baseURL: process.env.REACT_APP_API_SERVER_URL,
          url: `/api/v1/admin/withdrawals/${withdrawalId}`
        }
        // send call to API
        const response = await execute({}, options)
        setData(response)
        await getActivityLog()
      } catch(error) {
        setError(prev => ({...prev,
          message: error.message,
          errorCode: '000',
          title: 'Error getting beg'
        }))
      }
    }
    fetchData()
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <Container component="main" maxWidth="lg">
      <Typography variant="h4" sx={{ color:'white', textAlign:'left', mb:4 }}>
        Withdrawals Details
      </Typography>

      { loading && <WaitOverlay />}
      { error?.message && <ErrorDialog setError={setError} {...error} />}

      <Paper elevation={0}>


        <Box sx={{ p:2 }} >

        <Link to="/admin/withdrawals" style={{ display:"block", textDecoration:"none", color:theme.palette.primary.main, marginBottom:"14px" }}>
          <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> Withdrawals &amp; Payments
        </Link>

          <Grid container spacing={2}>

            {/* main section */}

            <Grid item xs={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={12}>

                  {/* example display item */}

                  <Grid container>
                    <Grid item xs={12}>
                      <Paper elevation={1} sx={{ p:1 }}>
                        <Typography component="div" variant="subtitle3" mb={.5}>
                          Posted by
                          <LaunchIcon
                            sx={{ float:"right", mt:1, cursor:"pointer" }} 
                            onClick={() => navigate(`/profile/${data?.user._id}`)}
                          />
                        </Typography>
                        <Typography component="div" variant="subtitle4">
                          { data?.user?.firstName && capFirstLetter(data?.user?.firstName) } { data?.user?.lastName && capFirstLetter(data?.user?.lastName)} 
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>


                <Grid item xs={6}>
                  <Paper elevation={1} sx={{ p:1 }}>
                    <Typography component="div" variant="subtitle3" mb={.5}>
                      Published timestamp
                    </Typography>
                    <Typography component="div" variant="subtitle4">
                      { data?.beg?.createdAt && ( formatDate(data.beg.createdAt, 'MMM dd, u ') + " at " + formatDate(data.beg.createdAt, 'h:mm a') ) }
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={6}>
                  <Paper elevation={1} sx={{ p:1 }}>
                    <Typography component="div" variant="subtitle3" mb={.5}>
                      Ended timestamp
                    </Typography>
                    <Typography component="div" variant="subtitle4">
                      { data?.createdAt && ( formatDate(data.createdAt, 'MMM dd, u ') + " at " + formatDate(data.createdAt, 'h:mm a') ) }
                    </Typography>
                  </Paper>
                </Grid>



                <Grid item xs={12}>

                  {/* example display item */}
                  <Grid container>
                    <Grid item xs={12}>
                      <Paper elevation={1} sx={{ p:1 }}>
                        <Typography component="div" variant="subtitle3" mb={.5}>
                          Beg title
                          <LaunchIcon
                            sx={{ float:"right", mt:1, cursor:"pointer" }} 
                            onClick={() => navigate(`/raises/details/${data?.begId}`)}
                          />
                        </Typography>
                        <Typography noWrap component="div" variant="h6">
                          { data?.beg?.title }
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>


                <Grid item xs={12}>

                  {/* beg id */}
                  <Grid container>
                    <Grid item xs={12}>
                      <Paper elevation={1} sx={{ p:1 }}>
                        <Typography component="div" variant="subtitle3" mb={.5}>
                          Beg ID
                          <LaunchIcon
                            sx={{ float:"right", mt:1, cursor:"pointer" }} 
                            onClick={() => navigate(`/raises/details/${data?.begId}`)}
                          />
                        </Typography>
                        <Typography noWrap component="div" variant="h6">
                          { data?.beg?._id }
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>


                {/* payment method select */}

                <Grid item xs={6} mt={1}>
                  <Typography component="div" variant="subtitle3" mb={.5}>
                    Payout method requested
                  </Typography>
                  { data?._id && <PaymethodSelect refresh={getActivityLog} id={data?._id} data={data?.paymethods} selected={data?.paymethod._id} /> }
                </Grid>


                {/* status */}

                <Grid item xs={6} mt={1}>
                  <Typography component="div" variant="subtitle3" mb={.5}>
                    Withdrawal status
                  </Typography>
                  { data?._id && <StatusSelect refresh={getActivityLog} id={data?._id} selected={data?.adminStatus || 'requested' } /> }
                </Grid>


                {/* funds section */}

                <Grid item xs={12} sx={{ mt:3 }}>
                
                  <Grid container>
                    <Grid item xs={6}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Fund Allocation</Typography>
                        </Grid>

                        {/* beger */}

                        <Grid item xs={12}>
                          <Paper elevation={1} sx={{ p:1 }}>
                            <Typography variant="subtitle4">For the Beger</Typography>
                            <Grid container justifyContent="space-between">
                              <Grid item>
                                <Typography variant="subtitle3">Amount Raised</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3">${ data?.amountRaised && currFormat(data?.amountRaised || 0, 2) }</Typography>
                              </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" mt={1} pl={1}>
                              <Grid item>
                                <Typography variant="subtitle3">Donated to other beg during withdrawal</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3">${ data?.amountRaised && currFormat(data?.chipInAmount, 2) }</Typography>
                              </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" pl={1}>
                              <Grid item>
                                <Typography variant="subtitle3">Donated to charity during withdrawal</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3">${ data?.amountRaised && currFormat(data?.donationAmount || 0, 2) }</Typography>
                              </Grid>
                            </Grid>

                            {/*
                            <Grid container justifyContent="space-between" pl={1}>
                              <Grid item>
                                <Typography variant="subtitle3">Processing fees</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3">${ data?.amountRaised && currFormat(data?.processorFees || 0, 2) }</Typography>
                              </Grid>
                            </Grid>
                            */}

                            <Grid container justifyContent="space-between" pl={1}>
                              <Grid item>
                                <Typography variant="subtitle3" fontWeight={600}>Subtotal</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3" fontWeight={600}>$

                                  {/* data?.amountRaised && currFormat(data.amountRaised - ((data?.chipInAmount || 0)+ (data?.donationAmount || 0) + (data?.processorFees || 0) ), 2) */}
                                  { data?.amountRaised && currFormat(data.amountRaised - ((data?.chipInAmount || 0)+ (data?.donationAmount || 0))) }

                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" pl={1}>
                              <Grid item>
                                <Typography variant="subtitle3">Begerz fees</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3">${ data?.amountRaised && currFormat(data?.begerzFees || 0, 2) }</Typography>
                              </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" pl={1}>
                              <Grid item>
                                <Typography variant="subtitle3">Begerz donation to charity</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3">${ data?.amountRaised && currFormat(data?.begerzDonation || 0, 2) }</Typography>
                              </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" pl={0}>
                              <Grid item>
                                <Typography variant="subtitle3" fontWeight={600}>Beger Total</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3" fontWeight={600}>${ currFormat(data?.withdrawalAmount || 0, 2) }</Typography>
                              </Grid>
                            </Grid>

                          </Paper>
                        </Grid>


                        {/* charity */}

                        <Grid item xs={12}>
                          <Paper elevation={1} sx={{ p:1 }}>
                            <Typography variant="subtitle4">For Charity</Typography>
                            <Grid container justifyContent="space-between">
                              <Grid item>
                                <Typography variant="subtitle3">Donated to charity as part of withdrawal</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3">${ data?.amountRaised && currFormat(data?.donationAmount || 0, 2) }</Typography>
                              </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" pl={1}>
                              <Grid item>
                                <Typography variant="subtitle3">Begerz donation to charity</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3">${ data?.amountRaised && currFormat(data?.begerzDonation || 0, 2) }</Typography>
                              </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" pt={0} pl={0}>
                              <Grid item>
                                <Typography variant="subtitle3" fontWeight={600}>Charity Total</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3" fontWeight={600}>${ data?.amountRaised && currFormat( ((data?.donationAmount || 0) + (data?.begerzDonation || 0)), 2) }</Typography>
                              </Grid>
                            </Grid>

                          </Paper>
                        </Grid>

                        {/* begerz platform */}
                      
                        <Grid item xs={12}>
                          <Paper elevation={1} sx={{ p:1 }}>
                            <Typography variant="subtitle4">For Begerz</Typography>
                            <Grid container justifyContent="space-between" pl={1}>
                              <Grid item>
                                <Typography variant="subtitle3">Begerz fees</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3">${ data?.amountRaised && currFormat(data?.begerzFees || 0, 2) }</Typography>
                              </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" pl={1}>
                              <Grid item>
                                <Typography variant="subtitle3">Begerz donation to charity</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3">${ data?.amountRaised && currFormat(data?.begerzDonation || 0, 2) }</Typography>
                              </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" pt={0} pl={0}>
                              <Grid item>
                                <Typography variant="subtitle3" fontWeight={600}>Begerz Total</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="subtitle3" fontWeight={600}>${ data?.amountRaised && currFormat( ((data?.begerzFees || 0) + (data?.begerzDonation || 0)), 2) }</Typography>
                              </Grid>
                            </Grid>

                          </Paper>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            {/* activity section */}

            <Grid item xs={12} md={3}>

              <Typography component="div" variant="subtitle3" mb={.5}>
                Activity Log
              </Typography>

              { !!logs && <ActivityLog refresh={getActivityLog} data={logs} createdAt={data?.createdAt} withdrawalId={data?._id} /> }

            </Grid>
          </Grid>

        </Box>
      </Paper>
    </Container>
  )
}
