import Typography from '@mui/material/Typography'


// Main component
export default function Disclaimer() {
  return (
    <Typography variant='caption3'
      textAlign="left"
      sx={{ mt:6 }}
    >
      A {process.env.REACT_APP_PLATFORM_FEE + process.env.REACT_APP_PLATFORM_DONATION_FEE}% processing fee is collected by Begerz.com for using their platform.<br />
      {process.env.REACT_APP_PLATFORM_DONATION_FEE}% of the {process.env.REACT_APP_PLATFORM_FEE + process.env.REACT_APP_PLATFORM_DONATION_FEE}% transaction fee (what was collected) will be donated to a partner<br />
      charity (pre-determined by Begerz.com)
    </Typography>
  )
}