import React from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from '@emotion/react'


export default function StyledLink(props) {
  const theme = useTheme()
  const font = theme.typography.link
  const defaultStyle = {
    width:'100%',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    textAlign:'left',
    fontSize:'14px'    
  }
  
  // combine default and user styles
  const style = {...font, ...defaultStyle, ...props?.style }
  
  // add center styling to default and user styles
  const centerStyle = {...style, ...{display:'inline-block', textAlign:'center' }}

  return (
    <>
      { props.center  && <Link {...props} style={centerStyle}></Link> }
      { !props.center && <Link {...props} style={style}></Link> }
    </>
  )
}
