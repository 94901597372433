import * as React from 'react';
import Box from '@mui/material/Box';

export default function ErrorHeader() {
  return (
    <Box
      sx={{
        position: 'absolute',
        height: '1.25px',
        left: 0,
        right: 0,
        top: 80,
        zIndex: '-1',
        backgroundColor: '#D1337F'
      }}
    >
    </Box>
  )
}