import { Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

// Custom components
import ImageCarousel from '../ImageCarousel'
import NumberBox from '../NumberBox'

// Icons
import EditIcon from '@mui/icons-material/Edit'
import MoreIcon from    '../../assets/icons/more.svg'
import MoneyBag from    '../../assets/icons/moneybag/full.svg'
import VideoCamera from '../../assets/icons/videoCamera.svg'

// CSS
import '../../css/Beg.css'
import ProfileAvatar from '../ProfileAvatar'

// Utils
import currFormat from '../../utils/currFormat'


// Main component
export default function SuccessStory(props) {
  // Vars
  const navigate = useNavigate()
  let data = props.data
  const theme = useTheme()
  const begLink = data.clickLink || `/raises/details/${data.begId}`
  let items = []

  // put the success image and video into a new videos array
  items[0] = { src: data?.thumbLink || null }
  data = data.beg
  data['author'] = props?.data.user

  // format the goal and raised amounts
  const renderAmounts = (data) => {
    const raised = currFormat(data.amountRaised)
    if (data.status === 'active') {
      const goal = currFormat(data.goalAmount)
      return `$${raised} of $${goal}`
    } else return `$ ${raised}`
  }

  const handleProfileClick = (id) => {
    if (id) navigate(`/profile/${id}`)
  }  

  return (
    <>
      {/* header */}
      <Grid container columnSpacing={1} sx={{ mb:.8, pl:"0px", pr:"0px" }}>

        {/* profile */}
        <Grid item xs={2}>
          <ProfileAvatar
            src={data.author?.profileImage}
            alt={data.author?.username}
            sx={{ width:37.33, height:37.33, fontSize:18, borderRadius:"7px", cursor:"pointer" }}
            onClick={() => { handleProfileClick(data.author?._id) }}
          />
        </Grid>

        <Grid item xs={10}>
          <Grid container justifyContent="flex-end">

            {/* title and icon row */}
            <Grid item xs={12}>
              <Grid container mt={-.5}>
                {/* title */}
                <Grid item xs={10}>
                  <Link to={ begLink } style={{ textDecoration:'none' }}>
                    <Typography
                      variant="subtitle1"
                      noWrap
                      sx={{ fontWeight:600, mb:0, minHeight:'22px' }}>
                      { `${data?.title}` || 'Untitled' }
                    </Typography>
                  </Link>
                </Grid>

                {/* edit beg link */}
                <Grid item xs={2}>
                  { (data?.editLink && 
                    <Grid container justifyContent="space-around" alignItems="center">
                      <Grid item>
                        <Link to={ data?.editLink }>
                          <EditIcon fontSize='smaller' sx={{ color:theme.palette.gray.main }} />
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link to={ begLink }>
                          <img alt="more" style={{ cursor:'pointer' }} src={MoreIcon} />
                        </Link>
                      </Grid>
                    </Grid> ) || (
                    <Grid container justifyContent="flex-end" alignItems="center">
                      <Grid item>
                        <Link to={ begLink }>
                          <img alt="more" style={{ cursor:'pointer' }} src={MoreIcon} />
                        </Link>
                      </Grid>
                    </Grid>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>

            {/* username */}
            <Grid item xs={12}>
              <Typography noWrap variant='subtitle2' mt={-.5}
              >
                by { data.author.username || 'Anonymous'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    
      {/* body */}
      <Grid container className="rounded-corners-gradient-borders" mb={2} sx={{ position:'relative' }} >

        {/* image carousel */}
        <Grid row="true" item xs={12}>
          { ImageCarousel(items, begLink) }
        </Grid>
        

        {/* bottom row background */}
        <Grid container justifyContent="center" alignItems="center" className="beg-footer">

          {/* money bag */}
          <Grid item xs={1.2}>
            <img alt="money bag" height="22px" width="19px" src={MoneyBag} style={{ float:"right", marginRight:"4px" }} />
          </Grid>

          <Grid item xs={4.8} >
            <Typography variant="caption2">
              { renderAmounts(data) }
            </Typography>
          </Grid>

          <Grid item xs={3} sx={{ marginTop:"3px"}}>
            {/* Achievements(data) */}
          </Grid>

          <Grid item xs={1.8}>
            <img alt="video camera" src={VideoCamera} style={{ float:"right", paddingTop:'2px' }}/>
          </Grid>
          <Grid item xs={1.2}>
            <NumberBox variant="caption2" sx={{ textAlign:'left' }} number={ data.views || 0 } />
          </Grid>

        </Grid>


      </Grid>
    </>
  )
}