import { useState } from 'react'
import Button from '@mui/material/Button'
import { Container, Typography, Dialog, DialogContent, IconButton } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'

// Icons
import CloseIcon from '@mui/icons-material/Close'
import largeCheck from '../../../assets/largeCheck.svg'


// Main component
export default function SignUpEmailSent() {
  // Vars
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  // State
  const [dialogOpen, setDialogOpen] = useState(true)

  // Methods
  const closeDialog = () => {
    setDialogOpen(false)
  }

  
  return (
    <Dialog
      maxWidth="xs"
      open={dialogOpen}
      fullScreen={fullScreen}
      fullWidth
    >
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 24,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>

      <Typography
        component="h1"
        variant="h7"
        color="gray.main"
        align='center'
        sx={{ marginBottom: 2}}
      >
        You're all set!
      </Typography>

      <Typography
        component="h4"
        variant="subtitle1"
        color="gray.main"
        align='center'
        sx={{ marginBottom: 2}}
      >
        {"Now let's get started by creating a raise and exploring the community."}
      </Typography>

      <Container align="center">
        <img src={largeCheck} alt="Checked" />
      </Container>

      <Button
        component={Link}
        to="/home"
        fullWidth
        variant="outlined"
        sx={{mt: 4, mb: 1, fontWeight: 700}}
      >
        View Trending Raises
      </Button>
      <Button
        component={Link}
        to="/raises/create"
        fullWidth
        variant="outlined"
        sx={{mt: 2, mb: 1, fontWeight: 700}}
        onClick = {() => {
        }}
      >
        Start a Raise
      </Button>
      <Button
        component={Link}
        to="/home"
        fullWidth
        variant="outlined"
        sx={{mt: 2, mb: 4, fontWeight: 700}}
        onClick = {() => {
        }}
      >
        Share
      </Button>
      </DialogContent>
    </Dialog>
  )
}