import { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Chip, Grid } from "@mui/material"

// Custom components
import StyledLink from "../../../../components/styled-ui/StyledLink"

// Utils
import formatDate from "../../../../utils/formatDate"
import upperFirst from "../../../../utils/upperFirst"

// Apis
import { useApi as privateApiCall } from '../../../../apis/privateApiCall'

// CSS
import './grid.css'


// Main component
export default function FlagsGrid(props) {
  // Vars
  const itemId = props?.itemId
  const { execute, controller } = privateApiCall()
  const pageOptions = [10,25,50,100]

  // Grid column definitions
  const columns = [
    {
      field:"Flagged By",
      width:160,
      sortable: false,
      renderHeader: () => (<span className="columnHeader">{'Flagged By'}</span>),
      headerClassName: "columnHeader",
      valueGetter: (params) => {
        return `${upperFirst(params.row.flaggedBy.firstName)} ${upperFirst(params.row.flaggedBy.lastName)}`
      },
      renderCell: (params) => {
        const link = `/profile/${params.row.flaggerId}`
        return <StyledLink to={link}> {upperFirst(params.row.flaggedBy.firstName)} {upperFirst(params.row.flaggedBy.lastName)}</StyledLink>
      }
    },
    {
      field:"Flagged Timestamp",
      sortable: false,
      width:200,
      type: 'date',
      renderHeader: () => (<span className="columnHeader">{'Flagged Timestamp'}</span>),
      valueGetter: (params) => {
        const baseDate = params.row?.updatedAt || params.row.createdAt
        return formatDate(baseDate, 'MMM dd, u ') + " at " + formatDate(baseDate, 'h:mm a')
      }
    },
    { 
      field:"Flag Reason",
      width:130,
      sortable: false,
      headerClassName: "columnHeader",
      renderHeader: () => (<span className="columnHeader">{'Flag Reason'}</span>),
      valueGetter: (params) => {
        return (upperFirst(params.row.reason))
      } 
    },
    {
      field:"Status",
      sortable: false,
      width:110,
      renderHeader: () => (<span className="columnHeader">{'Flag Status'}</span>),
      valueGetter: (params) => {
        return upperFirst(params.row.status)
      },
      renderCell: (params) => {
        if (params.row.status === 'active') return <Chip color="info" label="Enabled" />
        return <Chip label="Disabled" color="default" variant="outlined" />
      }
    },
    {
      field:"Actions",
      sortable: false,
      width:140,
      renderHeader: () => (<span className="columnHeader">{'Actions'}</span>),
      headerClassName: "columnHeader",
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            { (params.row.status === "inactive" &&
            <Grid item>
              <StyledLink to={window.location.pathname}
                onClick={ () => {enableFlag(params.row._id)} }
              >
                {'Enable'}
              </StyledLink>
            </Grid>) 
            || 
            <Grid item>
              <StyledLink to={window.location.pathname}
                onClick={ () => {disableFlag(params.row._id)} }
              >
                {'Disable'}
              </StyledLink>
            </Grid> }
          </Grid>
        )
      }
    }
  ]

  // State
  const [pagination, setPagination] = useState({ current:1, limit:10 })
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
    

  // Methods
 
  const fetchData = async() => {
    try {
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/content-flags/details?itemId=${itemId}&sort=-createdAt&page=${pagination.current}&limit=${pagination.limit}`
      }
      // send call to API
      const response = await execute({}, options)
      setRows(response.results)
      setPagination(prev => ({...prev, ...response.pagination}))
    } catch(error) {
      throw error
    } finally {
      setLoading(false)
    }
  }


  // call api to save new note
  const createLogEntry = async(flagId,status,content) => {
    try {
      const options =  {
        method: 'post',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/admin/flag-logs`
      }

      // send call to API
      await execute({
        flagId: flagId,
        editorId: localStorage.getItem('userId'),
        action: "status",
        status: status,
        note: content
      }, options)

      // reload the activity log
      await props.refresh()
    } catch(error) {}
  }


  // enable the flag
  const enableFlag = async (flagId) => {
    try {
      await execute({ status: 'active' },
        { method: 'patch',
          baseURL: process.env.REACT_APP_API_SERVER_URL,
          url: `/api/v1/content-flags/${flagId}`
        })
      await createLogEntry(flagId,'active')
      await fetchData()
    } catch(error) {} 
  }

  // disable the flag
  const disableFlag = async (flagId) => {
    try {
      await execute({ status: 'inactive' },
        { method: 'patch',
          baseURL: process.env.REACT_APP_API_SERVER_URL,
          url: `/api/v1/content-flags/${flagId}`
        })
        await createLogEntry(flagId,'inactive')
        await fetchData()
    } catch(error) {} 
  }


  // update pagination, add 1 to a zero-based index
  const updatePagination = (newPage) => {
    newPage = parseInt(newPage) + 1
    setPagination(prev => ({ ...prev, current:newPage }))
  }

  // Load Effect
  useEffect(() => {
    setLoading(true)
    fetchData()

    // clean up
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current, pagination.limit])


  return (
    <DataGrid
      disableMultipleSelection={true}
      sx={{ border:"none", "& .MuiDataGrid-columnHeaders": { backgroundColor: "none" } }}
      autoHeight
      components={{}}
      rows={rows}
      getRowId={(row) => row._id}
      columns={columns}
      disableColumnMenu
      pagination
      pageSize={pagination?.limit}
      rowsPerPageOptions={ pageOptions }
      rowCount={ pagination?.records || 0 }
      paginationMode="server"
      getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
      onPageSizeChange={(pageSize) => ( setPagination(prev => ({ ...prev, ...{limit:pageSize, current:1} }))   )}
      onPageChange={(newPage) => ( updatePagination(newPage) )}
      onSelectionModelChange={(newSelectionModel) => { setSelectionModel(newSelectionModel) }}
      selectionModel={selectionModel}
      loading={loading}
      keepNonExistentRowsSelected
    />
  )
}