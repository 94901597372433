import { Button, Grid, Dialog, DialogContent, Typography, Box, IconButton } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// Components
import DialogHeader from '../DialogHeader'

// Icons
import CloseIcon from '@mui/icons-material/Close'
import trash from '../../assets/icons/trash.svg'


// Main component
export default function DeleteVideo(props) {
  // Vars
  const setDialog = props.setDialog
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      maxWidth="sm"
      open={props.dialogOpen}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent sx={{ mb: 2 }}>

        <Box sx={{ textAlign:'left' }}>
          <DialogHeader sx={{ textAlign:'center', mb:3 }}>
            Are you sure you want to<br />delete this video?
          </DialogHeader>
        </Box>        

        <IconButton
          onClick={ () => { setDialog(2) }}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>


        {/* info section */}

        <Grid container p={1} spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} sx={{ textAlign:"center" }} >
            <img alt='trash' height="54px" src={trash}></img>
          </Grid>
          <Grid item xs={12} sx={{  textAlign:"center" }}>
            <Typography
              variant='subtitle3'
            >
              Once you delete this video, it will be removed from this raise and stored data in relation to it. Are you sure you want to delete this video?
            </Typography>
          </Grid>
        </Grid>


        <Grid container spacing={1} mt={1}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {props.delete(props.activeVideo)}}
            >
              Yes, delete this video
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="link"
              sx={{ color:theme.palette.primary.main}}
              onClick={props.cancel}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  )
}