import { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { Button, Grid } from '@mui/material'

// Custom components
import WaitOverlay from '../../../components/WaitOverlay'
import ErrorDialog from '../../../components/ErrorDialog'


// Main component
const CheckoutForm = ({...props}) => {
  // Vars
  const stripe = useStripe()
  const elements = useElements()
  const begId = props.begId

  // State
  const [hasError, setHasError] = useState({
    status: false,
    message: '',
    code: 400
  })
  const [waitOpen, setWaitOpen] = useState(false)

  // Methods
  const resetError = () => {
    setHasError({message:'', status:false, code:400 })   
  }

  // submit the data to Stripe
  const handleSubmit = async (event) => {
    event.preventDefault()

    // Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) {
      return
    }

    setWaitOpen(true)
    const returnUrl = process.env.REACT_APP_WEB_SERVER_URL + `raises/chipin/${begId}/complete`

    // `Elements` instance that was used to create the Payment Element
    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl
      }

    })

    // This point will only be reached if there is an immediate error when confirming the payment. Show error to your customer (for example, payment details incomplete)
    if (error) {
      // ignore validation errors
      if (error.type === 'validation_error') {
        setWaitOpen(false)
        return
      }

      // set the error message to display
      setHasError( prev => ({...prev, message:error.message }) )
      setWaitOpen(false)
    } else {
      // customer will be redirected to `return_url`
      setWaitOpen(false)
    }
  }

  return (
    <>
      { waitOpen && <WaitOverlay /> }
      { hasError.message && <ErrorDialog message={hasError.message} setError={resetError} errorCode={hasError.code} title={'Error processing payment'} /> }

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          <Button 
            type="submit"
            disabled={!stripe}
            fullWidth
            variant="contained"
            sx={{ mt:4, fontWeight: 700 }}
          >
            Chip In
          </Button>
        </form>
      </Grid>
    </>
  )
}

export default CheckoutForm