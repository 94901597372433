/**
 * Auto login flow:
 * 
 * Auth creates the AuthContext
 * ProtectedRoute uses the AuthContext and sets isLoading which is set during any browser refreshes outside of dom-router
 * PersistantLogin uses AuthContext and isLoading to determine when to display the signin page
 */

import { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useLogout } from '../apis/logout'
import PersistentLogin from '../components/PersistentLogin'
import App from '../App'

// Create login context
export const AuthContext = createContext()


// Main Component
export default function Auth() {
  // State
  const [isLoading, setIsLoading] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [signInOpen, setSignInopen] = useState(false)
  const [subTitle, setSubTitle] = useState("")
  const [accessToken, setAccessToken] = useState()
  const [navProfileImage, setNavProfileImage] = useState()

  // Api 
  const execLogout = useLogout().execute
  
  
  // Methods

  // open the signin dialog
  const handleSignIn = async () => {
    setSignInopen(true)
  }

  // close signin dialog
  const handleCloseSignin = () => {
    setSignInopen(false)
  }

  // Log the user out and remove local storage
  const handleSignOut = async () => {
    try {
      await execLogout()
      localStorage.clear()
    } 
    catch(error) {} 
    finally { setLoggedIn(false) }
  }
  
  // Create the value of the context
  const authContextValue = {
    isLoading,
    setIsLoading,
    signInOpen,
    loggedIn,
    subTitle,
    setSubTitle,
    setLoggedIn,
    handleSignIn,
    handleSignOut,
    handleCloseSignin,
    accessToken,
    setAccessToken,
    setNavProfileImage,
    navProfileImage
  }

  return (
    <AuthContext.Provider value={ authContextValue }>
      <PersistentLogin />
      <App />
      <Outlet />
    </AuthContext.Provider>
  )
}