import { Link } from 'react-router-dom'
import { useTheme } from '@emotion/react'


// Main component
export default function MenuItemLink(props) {
  // Vars
  const theme = useTheme()
  const linkColor = theme.palette.gray.main

  return (
    <Link {...props} style={{ textDecoration:"none", fontSize:14, color:linkColor, display:"block", padding:"6px 14px", width:"100%"}} />        
  )
}