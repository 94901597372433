import { Typography } from '@mui/material'


// Main component
export default function SectionHeader(props) {
  return (
    <Typography noWrap variant="h8" {...props}>
      {props.children}
    </Typography>
  )
}
