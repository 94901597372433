import React from 'react'
import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// Custom components
import ThumbnailImage from '../../../components/ThumbnailImage'
import DialogHeader from '../../../components/DialogHeader'

// Components - right side
import CompletedHeader from './CompletedHeader'
import WithdrawalHeader from './WithdrawalHeader'
import ViewHeader from './ViewHeader'

// Components - left side
import ShareBeg from './ShareBeg'
import ShareStory from './ShareStory'

// Icons
import LeftArrow from '../../../assets/icons/leftArrow.svg'
import { Link } from 'react-router-dom'

// Dialogs
import StepOne from '../dialogs/StepOne'
import StepTwo from '../dialogs/StepTwo'
import StepThree from '../dialogs/StepThree'


// Main component
export default function Header(props) {
  // Vars
  const data = props.data
  const setTab = props.setTab
  const theme = useTheme()

  // State
  const [activeDialog, setActiveDialog] = React.useState()
  const [totalWithdrawal, setTotalWithdrawal] = React.useState(0)

  // Methods
  const setDialog = (dialog) => {
    setActiveDialog(dialog)
  }

  // call the initial api function to refresh the data
  const handleRefresh = () => {
    props.refresh(data._id)
  }

  const SwitchDialogs = () => {
    switch (activeDialog) {
      case 1:
        return <StepOne setDialog={setDialog} activeDialog={activeDialog} data={data} />
      case 2:
        return <StepTwo setTotalWithdrawal={setTotalWithdrawal} setDialog={setDialog} activeDialog={activeDialog} data={data} />
      case 3:
        return <StepThree totalWithdrawal={totalWithdrawal} setDialog={setDialog} activeDialog={activeDialog} data={data} />
      case 4:
        setActiveDialog(0)
        handleRefresh()
        break
      default:
        return <></>
    }
  }

  // left side
  const RenderViewBox = () => {
    switch (data.status) {
      case 'completed':
        return <CompletedHeader data={data} />
      case 'ended':
        if (data?.fundsWithdrawn) return <CompletedHeader data={data} />
        return <WithdrawalHeader data={data} setDialog={setDialog} />
      default:
        return <ViewHeader data={data} setDialog={setDialog} />
    }
  }


  // right side of header.
  const RenderShareBox = () => {
    switch (data.status) {
      case 'ended':
      case 'completed':
        return <ShareStory setTab={setTab} data={data} />
      default:
        return <ShareBeg setDialog={setDialog} setTab={setTab} data={data} refresh={handleRefresh} />
    }
  }


  return (
    <>

      { SwitchDialogs() }

      <Link to="/myprofile" style={{ display:'inline-block', textDecoration:"none", color:theme.palette.primary.main }}>
        <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> My Profile 
      </Link>

      <DialogHeader mt={2} mb={0}>{data?.title}</DialogHeader>

      <Grid container spacing={2} alignItems="center" mb={2}>

        {/* left side info grid */}
        <Grid item xs={12} sm={12} md={5}>

          {/* lefthand view / withdraw */}
          { data && RenderViewBox() }

        </Grid>

        {/* image */}

        <Grid item xs={12} sm={12} md={4.5} >
          <Grid container>
            <Grid item xs={12}>
              <ThumbnailImage
                height="180px"
                width="100%"
                src={data?.videos && data?.videos[0]?.thumbLink} 
                style={{ borderRadius:'4px', objectFit:'cover', objectPosition:'center' }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* righthand share section */}

        { data && RenderShareBox() }
            
    </Grid>
    </>
  )
}