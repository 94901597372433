import React, { useEffect, useRef, useState } from 'react'
import Typography from '@mui/material/Typography'
import { convertToRaw, EditorState, ContentState } from "draft-js"
import { Editor } from 'react-draft-wysiwyg'
import draftToHtmlPuri from "draftjs-to-html"
import htmlToDraft from 'html-to-draftjs'
import { FormHelperText, Button } from '@mui/material'

// Apis
import { useApi } from '../../../apis/privateApiCall'

// CSS
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import '../../../css/CreateBeg.css'


// Main Component
export default function StoryTab(props) {
  // Vars
  let data = props.data
  const setData = props.setData

  // APIs
  const updateBeg = useApi().execute  
  
  // Convert HTML story to draft format
  const blocksFromHtml = htmlToDraft(data.htmlDescription)
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const EditorRef = useRef()
  const minEditorLength = process.env.REACT_APP_MIN_BEG_DESCRIPTION_LENGTH
  const maxEditorLength = process.env.REACT_APP_MAX_BEG_DESCRIPTION_LENGTH
  const toolbarOptions = { options: ['inline', 'list', 'textAlign'],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline']
    }}


  // State
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))
  const [errors, setErrorState] = useState({})
    
  // Focus the editor
  useEffect(() => {
    EditorRef?.current.focus()
    setErrorState({})
  },[])
  
  // Check for editor requirement errors
  const checkEditorErrors = (contentState) => {
    setErrorState({})
    if (!contentState || !contentState.hasText() ) {
      setErrorState({
        description: {
          error: true,
          message: 'Please tell your story'
        }
      })
      return false
    }

    // Check content length
    const length = contentState?.getPlainText().length || 0
    if (length < minEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Your story should be at least ${minEditorLength} characters`
        }
      })
      return false
    }
    if (length > maxEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Your story cannot exceed ${maxEditorLength} characters`
        }
      })
      return false
    }
    return true
  }

  // ----------------------------------------------------------------

  // Editor functions
  const handleBeforeInput = () => {
    const contentState = editorState.getCurrentContent()
    const length = contentState?.getPlainText().length || 0
    if (length > maxEditorLength) return 'handled'
  }

  const handlePastedText = (pastedText) => {
    const contentState = editorState.getCurrentContent()
    const length = (contentState?.getPlainText().length || 0) + pastedText.length
    if (length > maxEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Cannot paste. Your story cannot exceed ${maxEditorLength} characters`
        }
      })
      return 'handled'
    }
  }

  const handleEditorChange = (editorState) => {
    setEditorState(editorState)
    const contentState = editorState.getCurrentContent()
    checkEditorErrors(contentState)
  }

  // Submit form
  const saveBeg = async () => {
    // check for errors in the editor
    if (errors.description) {
      return false
    }

    // Set the data to be saved
    const contentState = editorState.getCurrentContent()
    const fields = {
      textDescription: contentState?.getPlainText(),
      htmlDescription: draftToHtmlPuri( convertToRaw(editorState.getCurrentContent()) )
    }

    // call api
    const update = async (begId) => {
      const options =  {
        method: 'patch',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/begs/${begId}`
      }
    
      // call the api and show an alert
      try { 
        await updateBeg(fields, options)
        setData({...data, ...fields})
      }
      catch(error) {}
    }
    update(data._id)
  }


  return (
    <>
      <Typography variant="h6">Edit your story</Typography>
      <Typography marginBottom={2} variant="subtitle1" >
        Explain who you are and why you are creating this beg.
      </Typography>

      <Editor
        id="description"
        name="description"
        wrapperClassName="wrapper"
        editorClassName="editorEdit"
        toolbar={toolbarOptions}
        hashtag={{
          separator: ' ',
          trigger: '#'
        }}
        onEditorStateChange={handleEditorChange}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        editorState={editorState}
        editorRef={(ref) => (EditorRef.current = ref)}
      />
      <FormHelperText error={ !!errors.description }>
        {(errors.description && errors.description?.message) || 'Tell your story in 8 - 1000 characters'}
      </FormHelperText>

      <Button
        onClick={() => { saveBeg() }}
        sx={{ marginTop:4 }}
        fullWidth
        variant="contained">Save &amp; Update Story
      </Button>
    </>
  )
}