import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid'

// CSS
import '../../css/Grid.css'


// Main component
export default function CustomToolbar({ setFilterButtonEl }) {
  // Vars
  const visibleFields = ['Beg Title','User Name','End Date','Raised','Chippers','Shares','Hidden','Status','Published','Privacy','Admirable','Hilarious','Brilliant','Informative','Inspiring','Withdrawn']

  return (
    <GridToolbarContainer
      sx={{ mb:3, justifyContent: 'flex-end' }}
    >
      <GridToolbarColumnsButton 
        className='grid-button'
        variant="outlined"
        disableRipple 
      />
      <GridToolbarFilterButton
        ref={setFilterButtonEl}
        className='grid-button'
        variant="outlined"
        disableRipple 
      />
      <GridToolbarExport
        className='grid-button'
        variant="outlined"
        disableRipple
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          copyStyles: false,
          fields: visibleFields
        }}
      />
    </GridToolbarContainer>
  )
}