/**
 * Creates the payment intent
 */
import React, { useEffect, useState, useContext } from "react"
import { useTheme } from '@mui/material/styles'
import { Grid } from "@mui/material"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// Custom components
import WaitOverlay from '../../../components/WaitOverlay'
import ErrorDialog from '../../../components/ErrorDialog'

// Page components
import CheckoutForm from "./CheckoutForm"

// Context
import { ChipInContext } from '../ChipIn'

// Apis
import { useApi } from '../../../apis/paymentIntent'


// Main component
export default function StepTwo() {
  // Use context
  const { 
    totals,
    data
  } = useContext(ChipInContext)
  
  // APIs
  const {
    execute,
    controller
  } = useApi()

  // State
  const [intentId, setIntentId] = useState(null)
  const [stripePromise, setStripePromise] = useState(null)
  const [options, setOptions] = useState({})
  const [hasError, setHasError] = React.useState({
    status: false,
    message: '',
    code: 500
  })
  const [waitOpen, setWaitOpen] = React.useState(true)

  // Vars
  const theme = useTheme()
  const color = theme.palette.primary.main

  // Create the payment intent
  useEffect(() => {
    const fetchData = async (amount) => {
      // create the request object
      const payLoad = {
        amount: totals.total,
        anonymous: totals.anonymous,
        customAmount: totals.customAmount,
        begId: totals.begId,
        payeeId: data.userId,
        payorId: totals.payorId,
        transaction: "chipin",
        payorFees: 0,
      }      
      if (totals.payFees) {
        payLoad['payorFees'] = totals.fees
      }

      // Get the paymentIntent from the API server
      try {
        const paymentIntent = await execute(payLoad)
        
        setIntentId(paymentIntent._id)
          
        // Load the public Stripe key
        setStripePromise(await loadStripe(process.env.REACT_APP_STRIPE_PK))
        
        // Set the payment form options
        setOptions({
          clientSecret: paymentIntent.client_secret,
          appearance: {
            theme: 'stripe',
            labels: 'above',
            fontFamily: theme.typography.fontFamily,
            borderRadius: '4px',
            rules: {
              '.Input': {
                padding: '16.5px 14px',
                borderRadius: '4px'
              },
              '.Input::placeholder': {
                color: `${theme.palette.gray.secondary}`
              },
              '.Input:hover, .Input:focus':{
                boxShadow: 'none',
                border: `1px solid ${color}`,
                outline: `1px solid ${color}`
              },
              '.Input--invalid': {
                border: `1px solid ${theme.palette.error.main}`,
                outline: `1px solid ${theme.palette.error.main}`
              },
              '.Label': {
                fontSize: '12px',
                color: `${theme.palette.gray.secondary}`
              },
              '.Label--invalid': {
                color: `${theme.palette.error.main}`
              },
              '.Error': {
                fontSize: '12px',
                color: `${theme.palette.error.main}`
              }
            }
          } 
        })

        // Clear the wait spinner
        setWaitOpen(false)
      } catch(error) {

        setWaitOpen(false)
        setHasError({
          status: true,
          message:error.request.statusText,
          code:error.request.status
        })
      }
    }

    // Set wait spinner and get the paymentIntent
    setWaitOpen(true)
    fetchData(totals)

    // Clean up
    return controller
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[totals.amount])
  

  return (
    <Grid container spacing={2} alignItems="baseline" sx={{ mt:2 }}>
      { waitOpen && <WaitOverlay /> }
      { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error connecting to payment processor'} /> }
      { options.clientSecret && 
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm begId={totals.begId} intentId={intentId} />
        </Elements>  
      }
    </Grid>
    )
}