import { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Button, Divider, Grid, Paper } from '@mui/material'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import Pagination from '@mui/material/Pagination'

// Components
import DialogHeader from '../components/DialogHeader'
import ShareDialog from '../components/dialogs/ShareDialog'

// Utils
import currFormat from '../utils/currFormat'

// Error handling 
import WaitOverlay from '../components/WaitOverlay'
import ErrorDialog from '../components/ErrorDialog'

// APIs
import { useApi } from '../apis/privateApiCall'

// Icons
import LeftArrow from '../assets/icons/leftArrow.svg'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ShareIcon from '@mui/icons-material/Share';


// Main component
export default function Karma() {

  // Vars
  const userId = localStorage.getItem('userId')
  const theme = useTheme()
  const dateFormat = 'MM/dd/yyyy'

  // APIs
  const { execute: fetchData } = useApi()
  
  // State
  const [waitOpen, setWaitOpen] = useState(true)
  const [shareOpen, setShareOpen] = useState(false)
  const [shareData, setShareData] = useState()
  const [user, setUser] = useState(false)
  const [chipIns, setChipIns] = useState(false)
  
  const [pagination, setPagination] = useState({
    current: 1,
    pages: 0,
    limit: 10,
    records: 0,
    loading: true
  })
  
  const [hasError, setHasError] = useState({
    status: false,
    message: '',
    code: 500
  })


  // Methods

  // share beg
  const handleShare = (data) => {
    setShareData(data.beg._id)
    setShareOpen(true)
  }

  // pagination
  const handlePagination = (event, value) => {
    setPagination(prev => ({...prev, current: value}))
  }


  // Components

  // donation component
  const Donation = ({data}) => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent="space-between" alignItems="baseline">

          <Grid item xs={2} >
            <Typography variant="h6" sx={{ fontWeight:550 }}>
              { format(new Date(data.createdAt), dateFormat) }
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Typography variant="h6">
              ${ currFormat((data.settledAmount) || 0) }
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography variant="h6">
              { data?.beg?.title || 'Untitled' }
            </Typography>
          </Grid>

          <Grid item xs={2} >
            <Button
              onClick={() => { handleShare(data) }}
              variant="outlined"
              startIcon={<ShareIcon />}
              sx={{ pt:.5, pb:.5 }}
            >
              Share
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  // karma image and level
  const Karma = () => {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid item sx={{ display:'inline-block', mr:.5 }}>
          <img height="auto" width="69px" alt="karma" src={localStorage.getItem('karmaIcon')} />
        </Grid>
        <Grid item >
          <Grid container sx={{ display:'inline-block' }}>
            <Grid item xs={12} >
              <Typography sx={{ marginLeft:"4px" }} variant="subtitle3"> Begerz Karma Level </Typography> 
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ marginLeft:"4px" }} variant="bodyText"> {localStorage.getItem('karmaTitle')} </Typography> 
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  // Effects

  // get user profile on page load
  useEffect(() => {
    // get an updated user profile
    const getUser = async (userId) => {
      // set api options
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/users/${userId}`
      }
      // call the api and show an alert
      try { 
        const user = await fetchData({}, options)
        setUser(user)
      }
      catch(error) {
        setHasError({
          status: true,
          code: error.code,
          message: error.message
        })
      } finally {
        setWaitOpen(false)
      }
    }
    setWaitOpen(true)
    getUser(userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  useEffect(() => {
    // get the user's chipins
    const getChipIns = async (userId) => {
      // set api options
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/users/${userId}/chipins?page=${pagination.current}&limit=${pagination.limit}&sort=-createdAt`
      }
      // call the api and show an alert
      try { 
        const data = await fetchData({}, options)
        setChipIns(data)
        setPagination(prev => ({...prev, ...data.pagination}))
      }
      catch(error) {
        setHasError({
          status: true,
          code: error.code,
          message: error.message
        })

      } finally {
        setPagination(prev => ({...prev, loading:false}))
      }
    }

    getChipIns(userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current])



  // Main component
  return (
    <Container component="main" maxWidth="md">

      { (waitOpen || pagination.loading) && <WaitOverlay /> }
      { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving profile'} /> }

      { shareOpen && <ShareDialog shareOpen={shareOpen} setShareOpen={setShareOpen} begId={shareData} /> }

      <Paper elevation={4} sx={{ p:4 }}>

        <Link to="/myprofile" style={{ display:'inline-block', textDecoration:"none", color:theme.palette.primary.main }}>
          <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> Dashboard
        </Link>

        <DialogHeader mt={2} mb={3}>My Chip-Ins &amp; Karma Points</DialogHeader>

        {/* karma points info icon */}
        <Grid container sx={{ mb:1 }}>
          {/* 
          <Grid item>
            <Typography variant='bodytext'>Karma Points</Typography>
          </Grid>
          <Grid item>
            <Typography variant='bodytext' 
              sx={{ 
                marginLeft: '4px',
                marginRight: '2rem',
                color: '#7B7B7B'
              }}>
              <InfoOutlinedIcon  />
            </Typography>
          </Grid>
          */}
          <Grid item xs={12}>
            <Divider sx={{ mt:2, mb:2 }}></Divider>
          </Grid>
        </Grid>


        {/* points and flower */}
        <Grid container alignItems="stretch" justifyContent="space-between">

          <Grid item xs={12} sm={5.9} sx={{ border:'1px solid silver', borderRadius:'4px', p:3, mb:1 }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item sx={{ mr:2 }}>
                <Typography variant='h4' sx={{ fontWeight:700 }}>
                  
                  { !waitOpen && currFormat(user.karma) } 

                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='subtitle3'>
                  Total Karma Points
                </Typography>
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={12} sm={5.9} sx={{ border:'1px solid silver', borderRadius:'4px', p:1, mb:1 }}>
            { !waitOpen && <Karma /> }
          </Grid>
        </Grid>


        {/* chip-ins icon */}
        <Grid container sx={{ mt:3, mb:1 }}>
          <Grid item>
            <Typography variant='bodytext'>My Chip-Ins</Typography>
          </Grid>
          <Grid item>
            <Typography variant='bodytext' 
              sx={{ 
                marginLeft: '4px',
                marginRight: '2rem',
                color: '#7B7B7B'
              }}>
              <InfoOutlinedIcon  />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mt:2, mb:2 }}></Divider>
          </Grid>
        </Grid>


        {/* chip-ins list */}
        <Grid container spacing={1} justifyContent="space-between" alignItems="baseline" sx={{ mb:4 }}>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              Chip in Date
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="subtitle2">
              Amount
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle2">
              Beg Name
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              Share
            </Typography>
          </Grid>

          { !pagination.loading && chipIns?.results.map((item, index) => {
            if (item?._id) {
              return <Donation key={index} data={item} />
            } else return (null)
            })
          }

          <Grid item xs={12} sx={{ mt:2 }}>
            <Divider />
          </Grid>

          {/* pagination */}

          <Grid container sx={{ mt:4 }} justifyContent="center">
            <Grid item>
              <Pagination
                disabled={!pagination?.next?.limit > 0}
                color="primary"
                count={pagination?.pages}
                onChange={handlePagination}
              />
            </Grid>
          </Grid>

        </Grid>
    </Paper>
  </Container>
  )
}