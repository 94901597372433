import React, { useEffect, useRef, useState } from 'react'
import Placeholder from './placeholder'

// Icons
import SkipNextIcon from '@mui/icons-material/SkipNext'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

// CSS
import './Button.css'


// Main component
const Player = (props) => {
  const { IVSPlayer } = window
  const { isPlayerSupported } = IVSPlayer
  const { streams } = props
  const showNav = props?.showNav
  
  // State
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line
  const [muted, setMuted] = useState(false)
  const [streamUrl, setStreamUrl] = useState()
  const [videoIndex, setVideoIndex] = useState(0)

  // Refs
  const player = useRef(null)
  const playerBaseEl = useRef(null)
  const videoEl = useRef(null)

  // -----------------------------------------------------

  // Methods

  // next video
  const nextVideo = () => {
    if (!streams || streams.length === 0) return
    if (videoIndex < streams.length - 1 ) {
      setVideoIndex(prev => (prev + 1))
    } else {
      setStreamUrl(streams[0].videoLink)
    }
  }

  // previous video  
  const previousVideo = () => {
    if (!streams || streams.length === 0) return
    if (videoIndex > 0 && videoIndex < streams.length ) {
      setVideoIndex(prev => (prev - 1))
    } else {
      setStreamUrl(streams[0].videoLink)
    }
  }

  // -----------------------------------------------------

  // Effects

  // set the video stream any time the index is changed
  useEffect(() => {
    setStreamUrl(streams[videoIndex]?.videoLink)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[videoIndex])
  

  // set index to first video on load
  useEffect(() => {
    nextVideo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streams])


  // Handle case when autoplay with sound is blocked by browser
  useEffect(() => {
    if (!player.current) return
    setMuted(player.current.isMuted())
  }, [loading])
  

  // Player state
  useEffect(() => {
    const { ENDED, PLAYING, READY } = IVSPlayer.PlayerState
    const { ERROR } = IVSPlayer.PlayerEventType

    if (!isPlayerSupported) return

    const onStateChange = () => {
      const playerState = player.current.getState()
      setLoading(playerState !== PLAYING)
    }

    const onError = (err) => {}

    player.current = IVSPlayer.create()
    player.current.attachHTMLVideoElement(videoEl.current)
    player.current.load(streamUrl)
    player.current.play()

    // Events
    player.current.addEventListener(READY, onStateChange)
    player.current.addEventListener(PLAYING, onStateChange)
    player.current.addEventListener(ENDED, nextVideo)
    player.current.addEventListener(ERROR, onError)
    
    // Cleanup
    return () => {
      player.current.removeEventListener(READY, onStateChange)
      player.current.removeEventListener(PLAYING, onStateChange)
      player.current.removeEventListener(ENDED, nextVideo)
      player.current.removeEventListener(ERROR, onError)
      player.current.delete()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IVSPlayer, isPlayerSupported, streamUrl])

  // Check for player support
  if (!isPlayerSupported) {
    return null
  }

  // -----------------------------------------------------

  return (
    <div className="Player" ref={playerBaseEl}>
      <div className="Player-videoBox">
        <Placeholder loading={loading} />
        <div className="Player-video" style={{ borderRadius:"4px" }}>
          { !!showNav && streams.length > 1 && 
          <>
            <SkipNextIcon
              className="skipNext"
              style={{ fontSize:"44px", right:14 }}
              onClick={nextVideo}
            />

            <SkipPreviousIcon
              className="skipPrevious"
              style={{ fontSize:"44px", left:14 }}
              onClick={previousVideo}
            />
          </>
          }
          
          <video ref={videoEl} controls playsInline ></video>
        </div>
      </div>
    </div>
  )
}


export default Player