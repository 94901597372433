import { Box } from '@mui/system'
import { useTheme } from '@emotion/react'


// Main component
export default function AchievementCard(props) {
  // Vars
  const theme = useTheme()
  const color = theme.palette.primary.main
  const name = props.name
  const src = props.src
  const count = props.count
  const userReaction = props.userReaction
  
  // set the opacity to 1 for the reaction the user has selected
  let shadow = '0'
  let cursor
  if (userReaction === name.toLowerCase()) shadow = `0 0 10px ${color}`
  if (localStorage.getItem('userId')) cursor = 'pointer' || 'arrow'


  return (
    <Box 
      onClick={ props.clickFunction }
      sx={{ boxShadow:shadow, cursor:cursor, minWidth:'72px', maxHeight:'72px', border:`1px solid ${color}`, borderBottom:`2px solid ${color}`, borderRadius: '4px' }}
    >
      <img src={src} width="28px" alt={ name } style={{ display:'block', marginLeft:'auto', marginRight:'auto', marginTop:'6px' }}></img>
      <div style={{ textAlign:'center', fontSize:'10px', color:`${color}`, fontWeight:'700' }}>{ name }</div>
      <div style={{ textAlign:'center', fontSize:'12px', backgroundColor:`${color}`, color:'#ffffff', fontWeight:'400', height:'20px', lineHeight:'20px' }}>{count || 0}</div>
    </Box>
  )}
