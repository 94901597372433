import { useState, useContext } from 'react'
import { callApi } from './callApi'
import { useApi as Refresh } from './refresh'
import { AuthContext } from '../context/Auth'


// Main function
export const useRefresh = () => {
  // Get function from refresh
  const { execute:executeRefresh } = Refresh()
  let controller

  // Authorization context
  const { 
    accessToken,
    handleSignIn,
    setAccessToken
  } = useContext(AuthContext)
  

  // State
  const [isLoading, setIsLoading] = useState(false)
  
    // Set headers
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}` 
  }
  

  // Methods

  // Update access token and headers for the retry
  const updateAccessToken = (accessToken) => {
    setAccessToken(accessToken)
    headers['Authorization'] = `Bearer ${accessToken}` 
  }


  // Call refresh API to get a new access token. If call fails, force login
  const getNewAccessToken = async () => {
    try {
      const refresh = await executeRefresh({})
      updateAccessToken(refresh?.accessToken)
    } 
    catch (error) {
      handleSignIn()
      throw error
    }
  }


  // Send api request - wrapper function around callApi. Used by executeCall
  const send = async (data, options) => {
    try {
      options.headers = headers
      const api = await callApi(data, options)
      controller = api.controller
      return api.output
    } 
    catch(error) {
      throw error
    }
  }


  // Retry api call after 401 error
  const retryCall = async (data, options) => {
    try {
      await getNewAccessToken()
      return await send(data, options)
    } 
    catch (error) {
      // if a 403 error came back force the login
      if (error.response.status === 403) handleSignIn()
      throw error
    } finally {
      setIsLoading(false)
    }
  }


  // Primary API call with retry logic
  const executeCall = async (data, options) => {
    try {
      setIsLoading(true)
      const api = await send(data, options)
      options = {}
      return api
    } 
    catch (error) {
      // access token is expired, get a new token
      if (error?.response?.status === 401) {
        return await retryCall(data, options)
      } 
      else {
        throw error
      }
    } finally {
      setIsLoading(false)
    } 
  }

  return {
    isLoading,
    controller,
    executeCall
  }
}