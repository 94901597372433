import { Button, Grid, Dialog, DialogContent, Typography, Box, IconButton } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// Components
import DialogHeader from '../DialogHeader'

// Icons
import CloseIcon from '@mui/icons-material/Close'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'

// Main component
export default function VideoWorking(props) {
  // Vars
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))


  return (
    <Dialog
      maxWidth="sm"
      open={props.dialogOpen}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent sx={{ mb: 2 }}>

        <Box sx={{ textAlign:'left' }}>
          <DialogHeader sx={{ textAlign:'center', mb:3 }}>
            You're video will be ready shortly
          </DialogHeader>

        </Box>        

        <IconButton
          onClick={ () => { props.close() }}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>


        {/* info section */}

        <Grid container p={1} spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} sx={{ textAlign:"center" }} >
            <PauseCircleOutlineIcon sx={{ fontSize:94, color:theme.palette.primary.main }} />
          </Grid>
          <Grid item xs={12} sx={{  textAlign:"center" }}>
            <Typography
              variant='bodytext'
            >
              Your video has been uploaded to the server and is optimized.
              Please give a few seconds for the system to finish processing!
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={1}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {props.close()}}
            >
              Close
            </Button>
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  )
}