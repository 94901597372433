import React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Divider, Paper, SvgIcon } from '@mui/material'
import { useParams } from 'react-router-dom'
import Tab from '@mui/material/Tab'
import { TabContext, TabList, TabPanel } from '@mui/lab'

// Custom components
import ErrorDialog from '../../components/ErrorDialog'
import { useApi } from '../../apis/privateApiCall'

// Page Components
import Header from './components/Header'

// Tabs
import GeneralTab from './tabs/GeneralTab'
import StoryTab from './tabs/StoryTab'
import SuccessTab from './tabs/SuccessTab'
import InvitationsTab from './tabs/InvitationsTab'
import DonorsTab from './tabs/DonorsTab'

// Load CSS
import '../../css/Player.css'

// Tab Icons
import { ReactComponent as Donors } from  '../../assets/icons/tabs/donors.svg'
import { ReactComponent as General } from '../../assets/icons/tabs/general.svg'
import { ReactComponent as Invitations } from '../../assets/icons/tabs/invitations.svg'
import { ReactComponent as Story } from '../../assets/icons/tabs/story.svg'
import { ReactComponent as Success } from '../../assets/icons/tabs/success.svg'


// Main component
export default function EditBeg() {
  // Vars
  const { begId } = useParams()
  const { execute } = useApi()

  // State
  const [value, setValue] = React.useState('1')
  const [data, setData] = React.useState()
  const [isLoading, setIsLoading] = React.useState(true)
  const [hasError, setHasError] = React.useState({
    status: false,
    message: '',
    code: 500
  })
  
  // Methods

  // tab change
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  // call begs api
  const fetchData = async(begId) => {
    try {
      setIsLoading(false)
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/begs/${begId}`
      }
      // send call to API
      const response = await execute({}, options)
      setData(response)
    } catch(error) {
      throw error
    } finally {
      setIsLoading(false)
    }
  }


  // Effects
  React.useEffect(() => {
    fetchData(begId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[begId])


  return (
    <Container component="main" maxWidth="md">

      { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving begs'} /> }

      <Paper elevation={4} sx={{ padding:4 }}>

        <Header begId={begId} refresh={fetchData} data={data} setTab={setValue} />
        <Divider sx={{ marginBottom:2 }}></Divider>


        {/* tabs */}

        <TabContext value={value}>
          <Box sx={{ borderBottom:'1px solid secondary' }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              textColor="primary"
            >
              <Tab icon={<SvgIcon><General /></SvgIcon>} label="General" value="1" disableRipple />
              <Tab icon={<SvgIcon><Story /></SvgIcon>} label="Story" value="2" disableRipple />
              <Tab 
                label="Invitations"
                value="3"
                icon={<SvgIcon><Invitations /></SvgIcon>} 
                disableRipple
              /> 
              <Tab
                label="Success Story"
                value="4"
                disabled={data?.status !== 'completed'} 
                icon={<SvgIcon><Success /></SvgIcon>}
                disableRipple
              />
              <Tab
                icon={<SvgIcon><Donors /></SvgIcon>}
                label="Donors"
                value="5"
                disableRipple 
              />
            </TabList>
          </Box>

          <TabPanel value="1">
            { !isLoading && data && <GeneralTab data={data} setData={setData} /> }
          </TabPanel>

          <TabPanel value="2">
            { !isLoading && data && <StoryTab data={data} setData={setData} /> }
          </TabPanel>
          
          <TabPanel value="3">
            { !isLoading && data && <InvitationsTab data={data} /> }
          </TabPanel>

          <TabPanel value="4" >
            { !isLoading && data && <SuccessTab data={data} setData={setData} refresh={fetchData} /> }
          </TabPanel>
          <TabPanel value="5">
            { !isLoading && data && <DonorsTab data={data} /> }
          </TabPanel>
        </TabContext>

      </Paper>
    </Container>
  )
}