import { useEffect, useState } from 'react'
import { Typography, Grid, Button, Divider } from '@mui/material'
import { format } from 'date-fns'
import Pagination from '@mui/material/Pagination'

// APIs
import { useApi } from '../../../apis/privateApiCall'

// Icons
import MailOutlineIcon from '@mui/icons-material/MailOutline'

// Components
const Donation = ({data}) => {

  const dateFormat = 'MM/dd/yyyy'

  // convert amount in cents to dollars and cents
  const currFormat = (number) => {
    number = parseFloat(number).toFixed(0)
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const sendEmail = (donor) => {
    console.log(donor)
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={1} justifyContent="space-between" alignItems="baseline">

        <Grid item xs={2} >
          <Typography
            variant="h6"
            sx={{ fontWeight:550 }}
          >
            { format(new Date(data.createdAt), dateFormat) }
          </Typography>
        </Grid>

        <Grid item xs={2} >
          <Typography
            variant="h6"
          >
            ${ currFormat((data.amount - data.payorFees) || 0) }
          </Typography>
        </Grid>

        <Grid item xs={3} >
          <Typography
            variant="h6"
          >
            { (data.anonymous && 'Anonymous') || data?.donor.username || 'Unknown'}
          </Typography>
        </Grid>

        <Grid item xs={3} >

          <Button
            onClick={() => sendEmail(data?.donor) }
            variant="outlined"
            disabled
            startIcon={<MailOutlineIcon />}
            sx={{ padding:'0', paddingLeft:2, paddingRight:2 }}
          >
            Email
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}


// Main component
export default function DonorsTab(props) {

  // Vars
  const begId = props.data._id

  // APIs
  const getDonors = useApi().execute

  // State
  const [data, setData] = useState()
  const [pagination, setPagination ] = useState({
    current: 1,
    pages: 0,
    limit: 20,
    records: 0,
    loading: true
  })

  
  // pagination
  const handlePagination = (event, value) => {
    setPagination(prev => ({...prev, current: value}))
  }

  // Effect to get donors list
  useEffect(() => {

    const fetchData = async (begId) => {
      // set api options
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/begs/${begId}/chipins?page=${pagination.current}&limit=${pagination.limit}&sort=-createdAt`
      }
    
      // call the api and show an alert
      try { 
        const data = await getDonors({}, options)
        setData(data)
        setPagination(prev => ({...prev, ...data.pagination}))
      }
      catch(error) {        
        throw error
      } finally {
        setPagination(prev => ({...prev, loading:false}))
      }
    }

    fetchData(begId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current])


  return (
    <>
      <Typography mt={0} mb={1} variant="h6">
        Donations to your beg
      </Typography>

      <Divider sx={{ mt:1, mb:2 }} />

      <Grid container spacing={1} justifyContent="space-between" alignItems="baseline">
        <Grid item xs={2}>
          <Typography variant="caption2">
            Chip in Date
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="caption2">
            Amount
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="caption2">
            Username
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="caption2">
            Contact
          </Typography>
        </Grid>
        { data?.results && data.results.map((item, index) => {
          if (item.donor) {
            return <Donation key={index} data={item} />
          } else return (null)
          })
        }
      </Grid>

      {/* pagination */}

      <Grid container sx={{ mt:4 }} justifyContent="center">
        <Grid item>
          <Pagination
            disabled={!pagination?.next?.limit > 0}
            color="primary"
            count={pagination?.pages}
            onChange={handlePagination}
          />
        </Grid>
      </Grid>
    </>
  )
}