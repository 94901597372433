import { Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// Icons
import LaunchIcon from '@mui/icons-material/Launch'


// Main component
export default function DataDisplayField(props) {
  // Vars
  const theme = useTheme()
  const navigate = useNavigate()
  const data = props?.data
  const linkColor = theme.palette.gray.secondary

  
  return (
    <Paper elevation={1} sx={{ p:1, pl:2, pr:2 }} {...props}>

      {/* title */}
      <Typography component="div" variant="subtitle3" mb={.8} color={linkColor}>
        { data?.title }

        { data?.link &&
          <LaunchIcon
            sx={{ float:"right", mt:1, mr:1, cursor:"pointer" }} 
            onClick={() => navigate(data.link)}
          />
        }
      </Typography>


      {/* display data */}

      <Typography component="div" variant="subtitle4" color={linkColor}>
        { data?.value } 
      </Typography>
    </Paper>
  )
}