import React from 'react'
import Typography from '@mui/material/Typography'
import { Divider, Grid } from '@mui/material'

// Custom components
import ProfileAvatar from '../../../components/ProfileAvatar'
import ThumbnailImage from '../../../components/ThumbnailImage'


// Main component
export default function Header(data, images) {
  // Vars  
  let begImage = ""

  // Set the thumbnail image
  if (images?.length > 0) {
    begImage = images[0].src
  }
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ThumbnailImage
          height="140px"
          width="100%"
          src={begImage} 
          style={{ borderRadius:'4px', objectFit:'cover', objectPosition:'center' }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container
          spacing={0}
          justifyContent="space-evenly"                    
        >

        <Grid item xs={12}>
          You're giving to:
          <Typography noWrap variant='h6'>{ data?.title }</Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop:2, marginBottom:2 }}>
          <Divider></Divider>
        </Grid>

        {/* profile */}
        <Grid item xs={12} >
          <Grid container spacing={1} justifyContent="flex-start">
            <Grid item xs={2} sm={1.8} md={2.8}>
              <ProfileAvatar sx={{ mt:0, width:48, height:48, fontSize:20 }} alt={ data?.author?.username || 'unknown' } src={ data?.author?.profileImage } />
            </Grid>
            <Grid item xs={10} sm={10.2} md={9.2}>
              <Grid container alignContent="center" justifyContent="flex-start">
                <Grid item xs={12}>
                  <Typography noWrap variant='subtitle1' sx={{ mb:0 }}>Funds go directly to</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography noWrap variant='h6'>{ data?.author?.username || 'unknown' }</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  </Grid>
  )
}
