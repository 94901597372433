import * as axios from 'axios'

// Apis
import { useApi } from '../../../apis/getSignedUrl'

// Main component
export default function useProfileUtils() {
  // Vars
  const execSignedUrl = useApi().execute


  // Methods

  // Get the S3 link
  const getSecureLink = async (path) => {
    try {
      const result = await execSignedUrl(path)
      const url = result?.url
      return url
    } catch(error) {
      throw error
    }
  }

  // Convert cropped image selection to a file object
  function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let bstr = atob(arr[1]) 
    let n = bstr.length
    let u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }

  // Save the video directly to the s3 bucket
  const putFile = async (url, fileObj) => {
    await axios.put(url, fileObj, { headers: {'Content-Type': 'multipart/form-data'} }).then((response) => {
      return true
    }).catch(function(error) {
      throw error
    })
  }

  return {
    getSecureLink,
    dataURLtoFile,
    putFile
  }
}