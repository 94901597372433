import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'


export default function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{ 
          disableToolbarButton: true 
        }}
        csvOptions={{
          copyStyles: false,
          fields:['Content','Posted By','Title','Reason','Beg Title','Flag Reason','Active','Inactive','Content Status','Flag Status','Timestamp']
        }}
      />
    </GridToolbarContainer>
  )
}
