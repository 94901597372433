import { Box } from '@mui/material'

export default function PasswordDetails() {
  return (
    <Box
      full
      borderRadius={1}
      pl={4} pr={4} pt={1} pb={1.5}
      mt={1}
      sx={{ width:'100%', backgroundColor: 'primary.main', color: 'white' }}
    >
      <p>Password must be 8 or more characters and contain:</p>
      <li>One or more lowercase letters</li>
      <li>One or more uppercase letters</li>
      <li>One or more numbers</li>
      <li>One or more special characters: !@#$%^&amp;*</li>
    </Box>
  )
}
