import { Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Custom components
import SectionHeaderContainer from './styled-ui/SectionHeaderContainer'

// Icons
import Admirable from   '../assets/icons/achievements/admirable.svg'
import Brilliant from   '../assets/icons/achievements/brilliant.svg'
import Hillarious from  '../assets/icons/achievements/hillarious.svg'
import Informative from '../assets/icons/achievements/informative.svg'
import Inspiring from   '../assets/icons/achievements/inspiring.svg'


// Main component
export default function ExploreCircles(props) {
  // Vars
  const navigate = useNavigate()
  const theme = useTheme()
  const achievements = [
    { name: 'Admirable',  src: Admirable,   field: 'admirable' },
    { name: 'Hilarious',  src: Hillarious,  field: 'hilarious' },
    { name: 'Informative',src: Informative, field: 'informative' },
    { name: 'Brilliant',  src: Brilliant,   field: 'brilliant' },
    { name: 'Inspiring',  src: Inspiring,   field: 'inspiring' }
  ]

  // Methods
  const handleClick = (item) => {
    navigate('/raises/categories/' + item.name.toLowerCase())
  }

  return (
    <Grid container spacing={2} justifyContent="space-evenly" sx={{ mt:1 }} {...props}>
      <Grid item xs={12} mb={1}>
        <SectionHeaderContainer to="/recent">Explore Current Raises</SectionHeaderContainer>
      </Grid>

      { achievements.map((item, index) => {
        return (
          <Grid key={index} item >
            <div style={{ cursor:'pointer', display:'inline-block', width:'128px', height:'128px', textAlign:'center', padding:26, border:`3px solid ${theme.palette.primary.main}`, borderRadius:'50%' }}
              onClick = { () => { handleClick(item) } }
            >
              <img height="48" width="48" alt={item.name} src={item.src}></img>
              <Typography
                variant="subtitle2"
                textAlign="center"
                color="primary"
                fontWeight={700}
              >
                {item.name}
              </Typography>
            </div>

          </Grid>
        )}
      )}
    </Grid>
  )
}