import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Divider, Grid, Paper } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// Custom components
import PageTitle from '../../components/styled-ui/PageTitle'
import ExploreCircles from  '../../components/ExploreCircles'
import Adverts from         '../../components/advertisement'
import StartABeg from       '../../components/content-widgets/StartABeg'

// Images
import Image1 from './assets/about-top-right.png'
import Image2 from './assets/about-center.png'


// Main component
export default function About() {
  // Vars
  const theme = useTheme()
  const donationPercentage = process.env.REACT_APP_PLATFORM_DONATION_FEE

  return (
    <Container component="main" maxWidth="md">
      
      <PageTitle mb={6}>About Begerz</PageTitle>

      <Paper elevation={0} >

        <Box sx={{ display:'flex', flexDirection:'column', alignItems:'left', padding:3, pl:5, pr:5 }} >

          <Grid container sx={{ mb:5 }}>

            {/* top image and text*/}

            <Grid container spacing={2} direction="row" alignItems="center" justifyContent="flex-start">
              <Grid item xs={4.5} >
                <img alt="smile" src={Image1} width="100%" />
              </Grid>
              <Grid item xs>
                <Typography >
                  Begerz combines the fun of video creation with the earning potential of crowdfunding to help you get the money you need to get the stuff you want.
                  <br /><br />
                  Begerz gives {donationPercentage}% of all completed fundraises to our partner charities. Yes, Begerz is a place to raise money, more importantly Begerz is a place that provides a space for a philanthropic community.
                  <br /><br />When a video pulls at your heart strings, makes your jaw drop, or makes your belly laugh you can Chip-In to help them reach their goals.                
                </Typography>
              </Grid>
            </Grid>

            {/* middle text and image */}

            <Grid container spacing={2} sx={{ mt:4 }} direction="row" alignItems="center" justifyContent="space-between">
              <Grid item xs={12}>
                <Grid container spacing={2} direction="row" alignItems="center" justifyContent="space-between">
                  <Grid item xs >

                    <Typography variant='h6'>Ask For Support in Style</Typography>

                    {/* begerz */}

                    <Typography variant='h6' component="div" mt={2}>Begerz (Raise)</Typography>
                    <Typography >
                      Post videos to raise money for what you want - personal, fundraising, contests, competition, and more.
                    </Typography>

                    {/* chipperz */}

                    <Typography variant='h6' component="div" mt={2}>Chipperz (Give)</Typography>
                    <Typography >
                      Chip in a monetary donation to your family, friends, a charity – anyone or any cause that moves you.
                    </Typography>

                    {/* community */}

                    <Typography variant='h6' component="div" mt={2}>Community (Receive)</Typography>
                    <Typography >
                      Join the Begerz community and be a part of the fun, feeling, and good karma that comes with helping others.
                    </Typography>

                  </Grid>

                  <Grid item xs={4.5}>
                    <img alt="smile" src={Image2} width="100%" />
                  </Grid>
                </Grid>

                {/* divider under image */}

                <Grid item xs={12}>
                  <Divider sx={{ mt:3, mb:2 }} />
                  <Typography >
                    From the moment you download Begerz you are welcomed and enticed with endless content and imagination. All within a karma conscious movement. We are a community that provides everyone an opportunity to raise money for their passion project, it could be anything they want, need, a place to go and do, for individuals, groups, and charities.
                    <br /><br />
                    The possibilities are endless.
                  </Typography>
                </Grid>
              </Grid>

            </Grid>

          </Grid>


          <Adverts style={{ marginBottom:'34px'}} />

          <Divider style={{ background:theme.palette.primary.main, marginBottom:'24px' }}></Divider>

          <ExploreCircles mb={10} />
          
          <StartABeg />

        </Box>
      </Paper>
    </Container>
  )
}