import { useRefresh  } from './useRefresh'


// Main export
export const useApi = () => {
  // Exports from useRefresh
  const {
    isLoading,
    error,
    controller,
    response,
    executeCall
  } = useRefresh()

  // Defaults
  const defaults = {
    method: 'get',
    baseURL: process.env.REACT_APP_API_SERVER_URL,
    url: '/api/v1/'
  }

  // Main wrapper function to execute the call
  const execute = async (data, options) => {
    // call useRefresh to perform actual call
    try {
      const config = {...defaults, ...options}
      return await executeCall(data, config)
    } catch (error) {
      throw error
    } 
  }

  // pass through loading and errors from useRefresh
  return {
    isLoading,
    error,
    response,
    controller,
    execute
  }
}