import React from 'react'
import Button from '@mui/material/Button';

export default function ContinueButton() {
  return (
    <Button
      type="submit"
      fullWidth
      sx={{ mt: 4, mb: 1 }}
      variant="contained"
    >
      Continue
    </Button>
  )
}