import { Box } from '@mui/system'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'


// Main component
export default function BoxSignUpLink({closeFunction}) {
  // Vars
  const theme = useTheme()
  const gray = theme.palette.gray.main

  return (
    <Box 
      pt={2}
      sx={{
        fontSize: 13,
        textAlign:'center',
        backgroundColor: 'gray.light',
        height: '3rem',
      }}
    >
      {"Don't have an account? "}
      <Link to="/signup"
        style={{ color:gray, textDecoration:'none', cursor:'pointer',  fontWeight:'700' }}
      >
        {'Sign Up!'}
      </Link>
  </Box>
  )
}