import React from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { Divider, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useCreateResetLink } from '../../apis/createResetLink'
import WaitOverlay from '../WaitOverlay'
import ErrorDialog from '../ErrorDialog'
import DialogHeader from '../DialogHeader'

export default function SignInForm({ setDialog }) {
  // Handle state
  const [hasError, setHasError] = React.useState(false)
  const [errorCode, setErrorCode] = React.useState({})
  const [waitOpen, setWaitOpen] = React.useState(false)
  const [values, setValues] = React.useState({email: ''})
  
  // Handle data change
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  // Form validation react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      role: 'customer'
    }
  })

  const { execute } = useCreateResetLink()

  // API
  const onSubmit = async (data) => {
    data.role = 'customer'
    try {
      const response = await execute(data)
      // Check for an access token and set signin state
      if (response.resetToken) {
        setHasError(false)
        setWaitOpen(false)
        setDialog('emailSent')
      } else {
        setWaitOpen(false)
        setHasError('The server encountered an error sending your request. Please try again later.')
        setErrorCode(500)
      }
    } catch(error) {
      setHasError(error?.data?.message || 'The server encountered an error sending your request. Please try again later.')
      setErrorCode(error?.status || 500)
    }
  }
  
  return (
    <div>

      { hasError && <ErrorDialog message={hasError} setError={setHasError} errorCode={errorCode} title={'Password reset error'} /> }
      { waitOpen && <WaitOverlay />}

      <DialogHeader>
        Forgot Password
        <Typography variant="body2">Enter the email address you signed up with.</Typography>
      </DialogHeader>

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={!!errors?.email}
          helperText={errors?.email ? errors.email.message : null}
          onChange={handleChange('email')}
          {...register('email', { 
            required: "Email address is required",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address'
            }
          })}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{mt: 4, mb: 2, fontWeight: 700}}
        >
          Continue
        </Button>

        <Divider>or</Divider>

        <Button
          onClick={() => setDialog('signIn')}
          fullWidth
          variant="outlined"
          sx={{mt: 2, mb: 4, fontWeight: 700}}
        >
          Sign In
        </Button>
      </form>
    </div>
  )
}