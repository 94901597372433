import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Button, Divider, Grid, Paper, TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Custom Components
import ProfileAvatar from '../../components/ProfileAvatar'
import StyledLink from    '../../components/styled-ui/StyledLink'
import DialogHeader from  '../../components/DialogHeader'
import WaitOverlay from   '../../components/WaitOverlay'
import ErrorDialog from   '../../components/ErrorDialog'

// Page components
import Upload from "./components/Upload"
import Popup from "./components/Popup"
import useProfileUtils from "./components/ProfileUtils"
import Delete from "./components/Delete"

// Get context from App
import { AuthContext } from '../../context/Auth'

// Apis
import { useApi } from '../../apis/updateAccount'

// Icons
import LeftArrow from '../../assets/icons/leftArrow.svg'


// Main component
export default function Profile() {
  // Vars
  const navigate = useNavigate()
  const { setNavProfileImage, handleSignOut } = React.useContext(AuthContext)

  // State
  const [hasError, setHasError] = React.useState(false)
  const [errorCode, setErrorCode] = React.useState({})
  const [waitOpen, setWaitOpen] = React.useState(false)
  const [values, setValues] = React.useState({})
  const [open, setOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const [image, setImage] = React.useState(localStorage.getItem('profileImage'))
  const [profileImage, setProfileImage] = React.useState(localStorage.getItem('profileImage'))

  // Vars
  const theme = useTheme()
  const { execute } = useApi()
  const { getSecureLink, dataURLtoFile, putFile } = useProfileUtils()

  // Form validation react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
      mode: 'onSubmit',
      defaultValues: {
        firstName: localStorage.getItem('firstName'),
        lastName: localStorage.getItem('lastName'),
        email: localStorage.getItem('email'),
        username: localStorage.getItem('username'),
        profileImage: localStorage.getItem('profileImage')
      }
  })

  // Methods
  const handleClose = () => {
    setOpen(false);
  } 

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  }  

  const handleRemoveProfileImage = () => {
    setProfileImage()
  }

  // delete the account
  const handleDeleteAccount = () => {
    setDeleteOpen(false)
    deleteUserProfile()

  }
  
  // open delete account dialog
  const handleOpenDeleteDialog = () => {
    setDeleteOpen(true)
  }

  // upload profile image via S3 Link
  const updateProfileImage = async () => {
    const path = 'profiles/' + localStorage.getItem('userId') + '.png'
    const fileObj = dataURLtoFile(profileImage, `/${path}`)
    try {
      const url = await getSecureLink(`/${path}`)
      await putFile(url, fileObj)
      return `${process.env.REACT_APP_S3URL}${path}`
    } catch (error) {}
  }

  // update user profile data
  const updateUserProfile = async (data) => {
    try {
      data.userId = localStorage.getItem('userId')
      const newProfileData = await execute(data)

      // update the image in local storage
      setNavProfileImage(newProfileData.profileImage)
      localStorage.setItem('profileImage', newProfileData.profileImage)

    } catch(error) {
      setWaitOpen(false)
      setHasError(error?.data?.message || 'The server encountered an error sending your request. Please try again later.')
      setErrorCode(error?.status || 500)
    } finally {
      setWaitOpen(false)
    }
  }

  // update user profile data
  const deleteUserProfile = async (data) => {
    const options =  {
      method: 'delete',
      baseURL: process.env.REACT_APP_AUTH_SERVER_URL,
      url: `auth/v1/accounts/${localStorage.getItem('userId')}`
    }
    try { await execute({}, options) } 
    catch(error) {
      setHasError(error?.data?.message || 'The server encountered an error sending your request. Please try again later.')
      setErrorCode(error?.status || 500)
    } finally {
      setWaitOpen(false)
      handleSignOut()
      navigate('/')
    }
  }

  // Form submission
  const onSubmit = async (data) => {
    setWaitOpen(true)
    // Upload new profile image if changed
    if (profileImage !== localStorage.getItem('profileImage')) {
      if (profileImage) {
        data.profileImage = await updateProfileImage()
      } else {
        delete data.profileImage
      }
    }
    // Update user profile data
    await updateUserProfile(data)
  }

  
  return (
    <>
      { waitOpen && <WaitOverlay />}
      { hasError && <ErrorDialog message={hasError} setError={setHasError} errorCode={errorCode} title={'There was an error updating your profile'} /> }
      { deleteOpen && <Delete open={deleteOpen} handleClose={() => {setDeleteOpen(false)}} handleDelete={() => {handleDeleteAccount()}} /> }

      <Popup
        open={open}
        handleClose={handleClose}
        image={image}
        setProfileImage={setProfileImage}
        getCroppedFile={(image) => {
          setImage(image)
          handleClose()
        }}
      />
    
      <Container component="main" maxWidth="md">
        <Paper elevation={4}>
          <Box
            noValidate
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              padding: 4,
            }}
          >

          <Link to="/myprofile" style={{ display:'inline-block', textDecoration:"none", color:theme.palette.primary.main }}>
            <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> Profile 
          </Link>

          <DialogHeader>Edit Profile</DialogHeader>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin='normal'
                label='First name'
                name='firstName'
                value={values.firstName}
                error={!!errors?.firstName}
                helperText={errors?.firstName ? errors.firstName.message : null}
                onChange={handleChange('firstName')}
                {...register('firstName', { 
                    required: "First name is required",
                    maxLength: { value: 30, message: 'Must be 30 characters or less' }
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin='normal'
                label='Last name'
                id='lastName'
                name='lastName'
                value={values.lastName}
                error={!!errors?.lastName}
                helperText={errors?.lastName ? errors.lastName.message : null}
                onChange={handleChange('lastName')}
                {...register('lastName', { 
                    required: "Last name is required",
                    maxLength: { value: 30, message: 'Must be 30 characters or less' },
                    minLength: 1
                  })
                }
              />
            </Grid>
          </Grid>

          <Typography mt={3} variant='h6'>
            Profile Photo
          </Typography>


          {/* profile image */}

          <Grid container mt={2} direction="row" alignItems="center" justifyContent="flex-start">

            <Grid item>
              <ProfileAvatar src={profileImage} sx={{ width:64, height:64, fontSize:38 }} alt={localStorage.getItem('username')}></ProfileAvatar>
            </Grid>

            <Grid item sx={{ ml:1 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Upload
                    getUploadedFile={(image) => {
                      setOpen(true)
                      setImage(image)
                    }}
                    variant="outlined"
                    sx={{ display:'block', maxWidth:'128px' }}
                    >
                    Edit Profile
                  </Upload>
                </Grid>
                <Grid item xs={12}>
                  <StyledLink onClick={handleRemoveProfileImage} to="">Remove</StyledLink>
                </Grid>
              </Grid>
            </Grid>

          </Grid>

          <Divider sx={{ mt:4, mb:4 }} />

          <TextField
            name="username"
            id="username"
            autoComplete='UserName'
            InputLabelProps={{ shrink: true }}
            margin="normal"
            fullWidth
            required
            type="string"
            label="Username"
            value={values.username}
            error={!!errors?.username}
            helperText={errors?.username ? errors.username.message : null}
            onChange={handleChange('username')}
            {...register('username', {
              required: "Username is required",
              maxLength: { value: 24, message: 'Must be 24 characters or less' },
              minLength: { value: 5, message: 'Must be at least 5 characters' },
              pattern: {
                value: /^[a-zA-Z]([._-](?![._-])|[a-zA-Z0-9]){3,24}[a-zA-Z0-9]$/i,
                message: 'Must start with a letter. Valid characters: a-z, 0-9, _ - .'
              }
            })}
          />

          <TextField
            name="email"
            autoComplete='Email'
            InputLabelProps={{ shrink: true }}
            margin="normal"
            fullWidth
            required
            type="email"
            label="Email Address"
            value={values.email}
            error={!!errors?.email}
            helperText={errors?.email ? errors.email.message : null}
            onChange={handleChange('email')}
            {...register('email', {
              required: "Email address is required",
              maxLength: { value: 80, message: 'Must be 80 characters or less' },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address'
              }
            })}
          />

          <Divider sx={{ mt:4, mb:6 }} />

          <Grid container columnSpacing={1} alignItems="center">
            <Grid item xs={12} sm={7} mb={2}>
              <Typography variant='subtitle2'>
                Need to delete your Begerz Account?<br />No problem, all you have to do is click this button here. Although, we're sad to see you leave and hope you'll change your mind. We can't change the world without you! 
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button fullWidth variant='outlined' onClick={handleOpenDeleteDialog}>Delete Account</Button>
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt:8, mb:3 }}
          >
            Save Changes
          </Button>

        </Box>
      </Paper>
    </Container>
    </>
  )
}