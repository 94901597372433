import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Grid, Paper, TextField, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'

// Custom components
import WaitOverlay from '../../../components/WaitOverlay'
import ErrorDialog from '../../../components/ErrorDialog'

// Apis
import { useApi } from '../../../apis/privateApiCall'
import CustomAlert from '../../../components/CustomAlert'


// Main components
export default function Details() {
  // Vars
  const { execute, controller, loading } = useApi()
  
  // Form validation react-hook-form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
      mode: 'onSubmit',
      defaultValues: {
        minimumDonationAmount: 22
      }
  })

  // State
  const [error, setError] = useState({ message:'', errorCode: '', title: '' })
  const [showAlert, setShowAlert] = useState(false)
  

  // Methods
  const onSubmit = async (data) => {
    delete(data['updatedAt'])
    delete(data['_id'])
    await updateData(data)
  }

  // get settings via API and set values
  const fetchData = async() => {
    try {
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/admin/settings`
      }
      // send call to API
      const response = await execute({}, options)
      for (const key in response) {
        setValue(key, response[key])
      }

    } catch(error) {
      setError(prev => ({...prev,
        message: error.message,
        errorCode: '000',
        title: 'Error getting settings'
      }))
    }
  }


  // update settings via API and set values
  const updateData = async(data) => {
    try {
      const options =  {
        method: 'post',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/admin/settings`
      }
      // send call to API
      await execute(data, options)
      setShowAlert(true)
    } catch(error) {
      setError(prev => ({...prev,
        message: error.message,
        errorCode: '000',
        title: 'Error getting settings'
      }))
    }
  }

  const closeAlert = () => {
    setShowAlert(false)
  }

  // Load Effect
  useEffect(() => {
    fetchData()
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  
  return (
    <Container component="main" maxWidth="lg">
      <Typography variant="h4" sx={{ color:'white', textAlign:'left', mb:4 }}>
        System Settings
      </Typography>

      { loading && <WaitOverlay />}
      { error?.message && <ErrorDialog setError={setError} {...error} />}

      { showAlert && <CustomAlert severity="success" close={closeAlert} text="Settings have been saved" />}

      <Paper elevation={0}>
        <Box
          noValidate
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            p: 2,
          }}
        >

          <Grid container spacing={3} mb={3} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant='h6'>
                Settings
              </Typography>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant='contained'
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>


          {/* main settings */}

          <Grid container spacing={3}>

            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="minimumDonationAmount"
                label="Minimum donation amount"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="minimumDonationAmount"
                fullWidth
                error={!!errors.minimumDonationAmount || null}
                helperText={errors?.minimumDonationAmount ? errors.minimumDonationAmount.message : null}
                {...register('minimumDonationAmount', { 
                  required: "Required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Numbers only'
                  }
                })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="emailForFlaggedNotifications"
                label="Email for flagged notifications"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="emailForFlaggedNotifications"
                fullWidth
                error={!!errors.emailForFlaggedNotifications || null}
                helperText={errors?.emailForFlaggedNotifications ? errors.emailForFlaggedNotifications.message : null}
                {...register('emailForFlaggedNotifications', { 
                  required: "Required",
                  maxLength: { value: 480, message: 'Must be 480 characters or less' },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address'
                  }
                })}
              />
            </Grid>
                  
            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="flagCountBeforeAutoRemoval"
                label="Flag count before auto removal"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="flagCountBeforeAutoRemoval"
                fullWidth
                error={!!errors.flagCountBeforeAutoRemoval || null}
                helperText={errors?.flagCountBeforeAutoRemoval ? errors.flagCountBeforeAutoRemoval.message : null}
                {...register('flagCountBeforeAutoRemoval', { 
                  required: "Required",
                  max: { value: 10000, message: "Max 10000" },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Numbers only'
                  }
                })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="maximumBegLength"
                label="Maximum days for a beg to last"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="maximumBegLength"
                fullWidth
                error={!!errors.maximumBegLength || null}
                helperText={errors?.maximumBegLength ? errors.maximumBegLength.message : null}
                {...register('maximumBegLength', { 
                  required: "Required",
                  max: { value: 10000, message: "Max 10000" },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Numbers only'
                  }
                })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="platformFeePercent"
                label="Begerz withdrawal percentage"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="platformFeePercent"
                fullWidth
                error={!!errors.platformFeePercent || null}
                helperText={errors?.platformFeePercent ? errors.platformFeePercent.message : null}
                {...register('platformFeePercent', { 
                  required: "Required",
                  max: { value: 100, message: "Max 100" },
                  pattern: {
                    value: /^[0-9.]*$/,
                    message: 'Numbers only'
                  }
                })}
              />

            </Grid>

          </Grid>



          {/* karma weight values */}

          <Typography variant='h6' mt={4} mb={2}>
            Karama Weight Values
          </Typography>

          <Grid container spacing={3}>

            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="karmaPerChipin"
                label="Karma points per dollar per chip in (per beg)"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="karmaPerChipin"
                fullWidth
                error={!!errors.karmaPerChipin || null}
                helperText={errors?.karmaPerChipin ? errors.karmaPerChipin.message : null}
                {...register('karmaPerChipin', { 
                  required: "Required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Numbers only'
                  }
                })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="karmaPerComment"
                label="Karma points per comment posted"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="karmaPerComment"
                fullWidth
                error={!!errors.karmaPerComment || null}
                helperText={errors?.karmaPerComment ? errors.karmaPerComment.message : null}
                {...register('karmaPerComment', { 
                  required: "Required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Numbers only'
                  }
                })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="karmaPerReaction"
                label="Karma points per reaction"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="karmaPerReaction"
                fullWidth
                error={!!errors.karmaPerReaction || null}
                helperText={errors?.karmaPerReaction ? errors.karmaPerReaction.message : null}
                {...register('karmaPerReaction', { 
                  required: "Required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Numbers only'
                  }
                })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="karmaPerShare"
                label="Karma points per share (max 1x per beg)"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="karmaPerShare"
                fullWidth
                error={!!errors.karmaPerShare || null}
                helperText={errors?.karmaPerShare ? errors.karmaPerShare.message : null}
                {...register('karmaPerShare', { 
                  required: "Required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Numbers only'
                  }
                })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="karmaPerWithdrawalDollarDonated"
                label="Karma points per dollar donated to charity during withdrawal"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="karmaPerWithdrawalDollarDonated"
                fullWidth
                error={!!errors.karmaPerWithdrawalDollarDonated || null}
                helperText={errors?.karmaPerWithdrawalDollarDonated ? errors.karmaPerWithdrawalDollarDonated.message : null}
                {...register('karmaPerWithdrawalDollarDonated', { 
                  required: "Required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Numbers only'
                  }
                })}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="karmaPerWithdrawalDollarChipin"
                label="Karma points per dollar given to another beg during withdrawal"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="karmaPerWithdrawalDollarChipin"
                fullWidth
                error={!!errors.karmaPerWithdrawalDollarChipin || null}
                helperText={errors?.karmaPerWithdrawalDollarChipin ? errors.karmaPerWithdrawalDollarChipin.message : null}
                {...register('karmaPerWithdrawalDollarChipin', { 
                  required: "Required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Numbers only'
                  }
                })}
              />
            </Grid>

          </Grid>

          {/* email reminders */}

          <Typography variant='h6' mt={4} mb={2}>
            Email Reminders
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField sx={{ display:'inline-block' }}
                name="abandonedBegReminderDays"
                label="Days before email reminder for abandoned begs"
                InputLabelProps={{ shrink: true }}
                required
                autoComplete="abandonedBegReminderDays"
                fullWidth
                error={!!errors.abandonedBegReminderDays || null}
                helperText={errors?.abandonedBegReminderDays ? errors.abandonedBegReminderDays.message : null}
                {...register('abandonedBegReminderDays', { 
                  required: "Required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Numbers only'
                  }
                })}
              />
            </Grid>

          </Grid>

        </Box>
      </Paper>
    </Container>
  )
}