import { useEffect, useState } from 'react'
import { Paper, Box, Typography, Divider } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

// Components
import Donation from './Donation'

// APIs
import { useApi } from '../../../apis/privateApiCall'


// Main component
export default function Donations(props) {
  // Vars
  const begId = props.begId

  // APIs
  const getDonors = useApi().execute

  // State
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  
  
  // Effect to get Beg details
  useEffect(() => {
    const fetchData = async (begId) => {
      // set api options
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/begs/${begId}/chipins`
      }
      // call the api and show an alert
      try { 
        setData(await getDonors({}, options))
      }
      catch(error) {}
      finally {
        setLoading(false)
        props?.doneLoading()
      }
    }

    fetchData(begId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  
  return (
    <Paper elevation={4}>
      <Box sx={{ padding: '1rem' }}>
        <Typography variant="h6" sx={{ fontWeight:700, marginBottom:1 }}>Donations</Typography>

        { !loading && data?.results?.map((item, index) => {
          if (item.donor) {
            return <Donation key={index} data={item} />
          } else return (null)
          })
        }

        <Divider />
        <ArrowDropDownIcon color="primary" sx={{ display:'inline-block', width:'100%', textAlign:'center' }} />
      </Box>
    </Paper>
  )
}