import React, { useContext } from "react"
import { useNavigate } from 'react-router-dom'

// Custom components
import SignIn from '../../components/signin/SignIn'

// Context
import { AuthContext } from '../../context/Auth'


// Main Component
export default function ProtectedRoute({ component:Component, ...props }) {
  // Vars
  const rights = props?.rights
  const history = useNavigate()
  const { isLoading, loggedIn } = useContext(AuthContext)
 

  // Methods
  const closeDialog = (loginStatus) => {
    if (loginStatus !== true) history(-1)
  }

  // check the logged in user's rights against required rights
  const checkRights = (rights) => {
    const userRights = localStorage.getItem('rights')
    if (rights && !userRights.includes(rights)) {
      return false
    }
    return true
  }

  return (
    <>
      { loggedIn && checkRights(rights) && <Component {...props} />}
      { (!loggedIn && !isLoading) && <SignIn closeFunction={closeDialog}/>}
    </>
  )
}