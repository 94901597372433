import React, { useRef, useState } from "react"
import Cropper from "react-cropper"
import Skeleton from "@mui/material/Skeleton"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Box from "@mui/material/Box"
import FlipIcon from '@mui/icons-material/Flip'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import "cropperjs/dist/cropper.css"

// Main function
export default function CropperDemo({ src, setProfileImage, getCroppedFile }) {
  const cropperRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [scaleX, setScaleX] = useState(1)
  const [scaleY, setScaleY] = useState(1)

  const handleClick = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    const img = cropper.getCroppedCanvas().toDataURL()
    getCroppedFile(img)
    setProfileImage(img)
  }

  const rotate = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    cropper.rotate(90)
  }

  const flip = (type) => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    if (type === "h") {
      cropper.scaleX(scaleX === 1 ? -1 : 1)
      setScaleX(scaleX === 1 ? -1 : 1)
    } else {
      cropper.scaleY(scaleY === 1 ? -1 : 1)
      setScaleY(scaleY === 1 ? -1 : 1)
    }
  }

  return (
    <>
      {loading && ( <Skeleton variant="rectangular" width={"100%"} height={400} /> )}
      
      <Box display={"flex"} justifyContent={"flex-end"} mb={2} >
        <ButtonGroup disableElevation variant="outlined">
          <Button sx={{ fontSize:10, padding:1 }} onClick={rotate}>Rotate <RotateRightIcon style={{ marginLeft:'4px' }} /></Button>
          <Button sx={{ fontSize:10, padding:1 }} onClick={() => flip("h")}>Flip Horiz <FlipIcon style={{ marginLeft:'4px' }} fontSize="small"/></Button>
          <Button sx={{ fontSize:10, padding:1 }} onClick={() => flip("v")}>Flip Vert <FlipIcon style={{ marginLeft:'4px', transform: 'rotate(90deg)' }} fontSize="small"/></Button>
        </ButtonGroup>
      </Box>

      <Cropper
        src={src}
        style={{ height: 400, width: "100%" }}
  
        // Cropper.js options
        initialAspectRatio={4 / 3}
        guides={false}
  
        ready={() => {
          setLoading(false);
        }}
        ref={cropperRef}
      />
  
      <Button
        sx={{ float:"right", mt:"24px", ml:1, mr:1 }}
        onClick={handleClick}
        autoFocus
        fullWidth
        variant="contained"
      >
        Save Image
      </Button>
    </>
  )
}
