import MissingPhoto from '../assets/placeholder.png'


// Main component 
export default function ThumbnailImage(props) {
  // add missing image on error
  const addDefaultSrc = (event) => {
    event.target.removeAttribute('onError')
    event.target.src = MissingPhoto
  }

  return (
    <img
      {...props}
      alt="video thumbnail"
      src={props?.src || MissingPhoto}
      onError={addDefaultSrc}
    />
  )
}