import { useRefresh  } from './useRefresh'

export const useApi = () => {
  // Context
  const {
    isLoading,
    error,
    controller,
    response,
    executeCall
  } = useRefresh()

  // Defaults
  const defaults = {
    method: 'post',
    baseURL: process.env.REACT_APP_API_SERVER_URL,
    url: '/api/v1/payment-intent'
  }

  const execute = async (data, options) => {
    const config = {...defaults, ...options}
    try {
      return await executeCall(data, config)
    } catch (error) {
      throw error
    } 
  }

  return {
    isLoading,
    error,
    response,
    controller,
    execute
  }
}