import React, { useState } from 'react'
import Container from '@mui/material/Container'
import { addDays } from 'date-fns'
// import { useNavigate } from 'react-router-dom'
import { Grid, Paper } from '@mui/material'
// import { useTheme } from '@mui/material'
// import { Link } from 'react-router-dom'

// Page components
import BackLink from '../../components/styled-ui/BackLink'
import Progressbar from './components/Progressbar'
import Tips from './components/Tips'

// Wizard steps
import StepOne from './dialogs/StepOne'
import StepTwo from './dialogs/StepTwo'
import StepThree from './dialogs/StepThree'

// Context
export const BegContext = React.createContext()


// Main component
export default function NewBeg() {
  // Vars
  const MAX_GOAL_DAYS   = process.env.REACT_APP_MAX_GOAL_DAYS  
  const MAX_DATE = addDays(new Date(), MAX_GOAL_DAYS)
  // const theme = useTheme()
  // const navigate = useNavigate()

  // State
  const [page, setPage] = useState(1)
  const [fileObj, setFileObj] = useState({})
  const [inputs, setInputs] = useState({
    goalAmount: 0,
    title: '',
    goalDate: MAX_DATE,
    localFileName: '',
    fileSize: 0,
    publishType: 'private',
    description: ''
  })

  // Context
  const begContextValue = {
    inputs,
    setInputs,
    fileObj,
    setFileObj,
    goNextPage,
    goPreviousPage
  }

  // Next wizard step
  function goNextPage() {
    if (page === 4) return
    setPage(page => page + 1);
  }

  // Prior wizard step
  function goPreviousPage() {
    if (page === 1) return;
    setPage(page => page - 1);
  }

  // Set the component for the wizard step
  function setStep(page) {
    switch (page) {
      case 1:
        return <StepOne />
      case 2:
        return <StepTwo />
      case 3:
          return <StepThree />
      default:
    }
  }
  
  return (
    <Container
      component="main"
      maxWidth="md"
    > 
      
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} >
          <Paper elevation={4}>

            { page > 1 && page < 4 && <BackLink goPreviousPage={ goPreviousPage } /> }

            <Progressbar page={page} />
            
            <BegContext.Provider value={ begContextValue }>
              { setStep(page) }
            </BegContext.Provider>
          </Paper>
          
        </Grid>
        <Grid item xs={12} sm={4}>
          <Tips />
        </Grid>
      </Grid>
    </Container>
  )
}