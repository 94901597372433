import React, { useState, useContext } from 'react'
import { Button, Grid, Dialog, DialogContent, Divider, Typography, Box, Snackbar } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import DialogHeader from '../../../components/DialogHeader'

// Sharing imports
import { CopyToClipboard } from 'react-copy-to-clipboard'
import MuiAlert from '@mui/material/Alert'
import {
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton
} from 'react-share'

// APIs
import { useApi } from '../../../apis/privateApiCall'

// Icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import LargeCheck from '../../../assets/largeCheck.svg'
import Twitter from '../../../assets/icons/social/twitter.svg'
import Facebook from '../../../assets/icons/social/facebook.svg'
import Share from '../../../assets/icons/social/share.svg'
import Email from '../../../assets/icons/social/email.svg'

// Context
import { ChipInContext } from '../ChipIn'

// Components
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})


// Main component
export default function Completed() {
  // APIs
  const postShare = useApi().execute

  // Use context
  const { 
    totals,
    setDialogStep
  } = useContext(ChipInContext)

  // Vars
  const begId = totals.begId
  const theme = useTheme()
  const color = theme.palette.primary.main
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const shareLink = `${process.env.REACT_APP_WEB_SERVER_URL}raises/details/${begId}`

  
  // State
  const [clipboard, setClipboard] = useState({
    value: shareLink,
    copied: false
  })


  // Methods

  // reset clipboard state so the alert can be re-displayed
  const handleAlertClose = () => {
    setClipboard(prev => ({...prev, copied:false}))
  }

  // log the share
  const handleShareClose = async (source) => {
    // create the data object to save
    const data = {
      begId: begId,
      userId: localStorage.getItem('userId'),
      platform: source
    }
    
    // set api options
    const options =  {
      method: 'post',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: '/api/v1/shares'
    }
  
    // call the api and show an alert
    try { 
      await postShare(data, options)
    }
    catch(error) {
    } 
  } 

  return (
    <Dialog
      maxWidth="sm"
      open={true}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent sx={{ mb: 2 }}>

        <Box sx={{ textAlign:'center' }}>
          <DialogHeader sx={{ textAlign:'center', mb:3 }}>
            You chipped in, nice!
          </DialogHeader>
          <img alt="checkbox" width={85} src={LargeCheck}></img>
          <Typography sx={{ mt:3, mb:3 }}>A receipt was sent to <b>{ localStorage.getItem('email')}</b> </Typography>
        </Box>        

        <Divider sx={{ mb:4 }}></Divider>

        <Typography variant="h6" align='left' sx={{ ml:3, mt:1, mb:1 }}>
          Share the love:
        </Typography>
    
        <Grid
          container
          spacing={2}
          justifyContent="space-evenly"
          sx={{ mt:1 }}
        >
          <Grid item>
            <TwitterShareButton
              url={shareLink}
              onShareWindowClose={() => {handleShareClose('twitter')}}
            >
              <img alt="twitter" width={64} src={Twitter}></img>
            </TwitterShareButton>
          </Grid>

          <Grid item>
            <FacebookShareButton
              quote='I just chipped in to this raise on Begerz!'
              url={shareLink}
              onShareWindowClose={() => {handleShareClose('facebook')}}
            >
              <img alt="facebook" width={64} src={Facebook}></img>
            </FacebookShareButton>
          </Grid>

          <Grid item>
            <CopyToClipboard 
              text={clipboard.value}
              style={{ cursor:'pointer' }}
              onCopy={() => {
                setClipboard( prev => ({...prev, copied:true}))
                handleShareClose('link')                
              }}
            >
              <img alt="share" width={64} src={Share}></img>
            </CopyToClipboard>
          </Grid>

          <Grid item>
            <EmailShareButton
              subject='I just chipped in to this raise on Begerz!'
              body='Check it our here:'
              url={shareLink}
              beforeOnClick={() => {handleShareClose('email')}}
            >
              <img alt="email" width={64} src={Email}></img>
            </EmailShareButton>
          </Grid>
        </Grid>

        {/* share link box  */}

        <Grid container
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mt:4, mb:4 }}
        >
          <Grid item xs={11} 
            sx={{ 
              border:`1px solid ${theme.palette.gray.secondary}`,
              borderTopLeftRadius:'4px',
              borderBottomLeftRadius:'4px', 
              borderRight: "none",
              pl:1, pr:1, pt:'8px', height:'44px'
            }}
          >
            <Typography noWrap variant='subtitle1' >
            { shareLink }              
            </Typography>
          </Grid>

          <Grid item xs={1} sx={{ color:`${color}`, border:`1px solid ${color}`, borderBottomRightRadius:"4px", borderTopRightRadius:"4px", pt:'8px', textAlign:'center', height:'44px' }}>
            <CopyToClipboard
              text={clipboard.value} 
              onCopy={() => {
                setClipboard( prev => ({...prev, copied:true}))
                handleShareClose('clipboard')                
              }}
            >
              <ContentCopyIcon sx={{ cursor:'pointer', textAlign:'right' }} />
            </CopyToClipboard>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2">Share link</Typography>
          </Grid>
        </Grid>

        <Typography variant='h6' sx={{ mt:1, mb:1, textAlign:'left' }}>Finished sharing?</Typography>

        <Button
          fullWidth
          variant="contained"
          sx={{ mt:1, mb:1, fontWeight: 700 }}
          onClick={ () => {setDialogStep(4)} }
        >
          Continue
        </Button>

        <Snackbar open={clipboard.copied} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
            Share link has been copied to clipboard!
          </Alert>
        </Snackbar>        


      </DialogContent>
    </Dialog>
  )
}