import { useContext, useState } from 'react'
import  * as Axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Container from '@mui/material/Container'
import { Navigate, useNavigate } from 'react-router-dom'
import WaitOverlay from '../components/WaitOverlay'
import { useForm } from 'react-hook-form'
import { 
  Link,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography
 } from '@mui/material';

import ErrorDialog from '../components/ErrorDialog'

// Get context from App
import { AuthContext } from '../context/Auth'

export default function SignIn() {
  const navigate = useNavigate()
  
  // Handle state
  const [hasError, setHasError] = useState(false)
  const [errorCode, setErrorCode] = useState({})
  const [waitOpen, setWaitOpen] = useState(false)
  const [signInSuccess, setsignInSuccess] = useState(false)

  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false
  })
  
  // Handle data change
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  // Use context
  const { 
    setLoggedIn,
    setAccessToken,
  } = useContext(AuthContext)

  // Form validation react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  // Form submission
  const onSubmit = (data) => {
    setWaitOpen(true)
    data.role = 'customer'
    const config = {
      headers: { 'Content-Type': 'application/json' }
    }
    Axios.post('http://localhost:5000/login', data, config).then((response) => {
      // Check for an access token and set signin state
      if (response?.data?.accessToken) {
        setAccessToken(response.data.accessToken)
        setWaitOpen(false)
        setLoggedIn(true)
        setsignInSuccess(true)
      }
      console.log(response.data)
    }).catch((error) => {
      setWaitOpen(false)
      setHasError(error?.response?.data?.message || 'The server encountered an error sending your request. Please try again later.')
      setErrorCode(error?.response?.status || 500)
    })
  }

  // Password toggle
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleForgotPassword = () => {}

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        marginTop: -7
      }}
    >

      { signInSuccess && <Navigate to="/home" replace={true} />}
      { hasError && <ErrorDialog message={hasError} setError={setHasError} errorCode={errorCode} title={'Sign in error'} /> }
      { waitOpen && <WaitOverlay />}

      <Paper elevation={4}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            padding: 6
          }}
        >
        <Typography
          component="h1"
          variant="h7"
          color="gray.main"
          sx={{ mb: 3}}
        >
          Sign in to Begerz
        </Typography>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="email"
              autoFocus
              error={!!errors?.username}
              helperText={errors?.username ? errors.username.message : null}
              onChange={handleChange('username')}
              {...register('username', { 
                required: "Email address is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address'
                }
              })}
            />
            <FormControl required fullWidth variant="outlined" sx={{ mt: 3 }}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                sx={{mb:1}}
                id="password"
                name="password"
                error={!!errors?.password}
                type={values.showPassword ? 'text' : 'password'}
                onChange={handleChange('password')}
                {...register('password', { 
                  required: 'Please enter your password',
                  minLength: {
                    value: 5,
                    message: 'Password be 5 or more characters'
                  }
                })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      color="primary"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
                <FormHelperText error={true}>{errors?.password ? errors.password.message : null}</FormHelperText>
                <Link 
                  href="#"
                  align='right'
                  variant="caption"
                  onClick={handleForgotPassword}
                >
                  Forgot password?
                </Link>
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                sx={{mt: 4, mb: 4, fontWeight: 700}}
              >
                Sign In
              </Button>
          </form>
        </Box>
        <Box 
          pt={2}
          sx={{
            fontSize: 13,
            textAlign:'center',
            backgroundColor: 'gray.light',
            height: '3rem',
          }}
        >
          {"Don't have an account? "}
          <Link 
            href="#"
            underline='none'
            color="gray.main"
            sx ={{ fontWeight:'700'}}
            onClick = {() => {
              navigate('/signup')
            }}
          >
            {'Sign Up!'}
          </Link>
        </Box>
      </Paper>
    </Container>
  )
}