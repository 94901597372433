import parse from 'html-react-parser'
  

// Replace hashtags with a link
export default function ReplaceHashtags(htmlString) {

  // regex replace for hashtags
  const linkString = htmlString.replace( /(#+[a-zA-Z0-9(_)]{1,})/g, 
    function($1) {
      const encoded = encodeURIComponent($1)
      return  `<a href="/search?terms=${encoded}">${$1}</a>`
  })

  return parse(linkString)
}
