import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Divider, Grid, Paper } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// Accordion components
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

// Custom components
import SectionHeader from '../../components/styled-ui/SectionHeader'
import PageTitle from '../../components/styled-ui/PageTitle'
import StartABeg from '../../components/content-widgets/StartABeg'

// Page components
// import PreviewImage from './components/PreviewImage'
import VideoModal from './components//VideoModal'
import Tutorial from './components/Tutorial'

// Images
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import StyledLink from '../../components/styled-ui/StyledLink'


// Main component
export default function Help() {
  // Vars
  const theme = useTheme()
  const linkColor = theme.palette.primary.main
  const arrowColor = theme.palette.primary.main
  const arrow = { color:arrowColor, fontSize:"36px" }

  // Tutorial videos
  const videos = [
    { title: 'How fundraising works on Begerz',
      url: 'https://files.begerz.net/tutorials/WHAT%2BIS%2BBEGERZ%2B.mp4',
      image: 'https://files.begerz.net/tutorials/thumbnails/What+is+Begerz.png',
      body: `Wondering how Begerz helps you fundraise in the best way possible? Let us show you!`
    }, 
    { title: 'How to create',
      url: 'https://files.begerz.net/tutorials/HOW_DO_I_MAKE_AN_ASK.mp4',
      image: 'https://files.begerz.net/tutorials/thumbnails/How+to+Create+an+Ask.png',
      body: `Creating a Raise is easy. But, if you need help, we've got you covered.`
    }, 
    { title: 'How to share',
      url: 'https://files.begerz.net/tutorials/HOW%2BTO%2BSHARE.mp4',
      image: 'https://files.begerz.net/tutorials/thumbnails/How+to+Share+a+Raise.png',
      body: `You've officially created a Raise, let's share it with the world!`
    }, 
    { title: 'How to chip-in',
      url: 'https://files.begerz.net/tutorials/HOW%2BTO%2BCHIP%2BIN.mp4',
      image: 'https://files.begerz.net/tutorials/thumbnails/How+to+Chip+In.png',
      body: `We know, you want to support your fellow Begerz. Not sure how? Well, now you do.`
    }, 
    { title: 'How to get your money',
      url: 'https://files.begerz.net/tutorials/HOW%2BTO%2BWITHDRAW%2B.mp4',
      image: 'https://files.begerz.net/tutorials/thumbnails/How+to+Withdraw+Funds.png',
      body: `Your raise was a success, now get your money!`
    }
  ]

  // State
  const [videoIndex, setVideoIndex] = useState(0)
  const [videoVisible, setVideoVisible] = useState(false)

  // Methods
  const handleVideoSelect = (index) => {
    setVideoIndex(index)
    setVideoVisible(true)
  }


  return (
    <Container component="main" maxWidth="md">

      { videoVisible && <VideoModal open={videoVisible} handleClose={()=>{setVideoVisible(false)}} src={videos[videoIndex].url} title={videos[videoIndex].title} /> }

      <PageTitle mb={6}>Help</PageTitle>

      <Paper elevation={0} >
        <Box sx={{ display:'flex', flexDirection:'column', alignItems:'left', padding:3, pl:5, pr:5 }} >

          <Grid container spacing={3} sx={{ mt:0, mb:6 }}>
            { videos.map((video, index) => {
                video.handleClick = () => {handleVideoSelect(index)}
                if ((index + 1) < videos.length) video.divider = true
                return (
                  <Tutorial key={index} data={video} />
                )
              })
            }      
          </Grid>
          
          <Divider sx={{ mb:4 }} />

          <SectionHeader variant="h6" mb={2} component="div">FAQ</SectionHeader>

          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon sx={arrow} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Get started</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Using Begerz is easy and getting started is even easier. All you have to do is create an account and you're in. 
                If you need a little more help, <StyledLink to="" onClick={() => handleVideoSelect(0)}>click here</StyledLink> to watch a video with all the details
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon sx={arrow} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Manage Account</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <StyledLink to="/myprofile/edit">Edit your Profile</StyledLink> <br />
                <StyledLink to="/myprofile">Account Settings</StyledLink> <br />
                <StyledLink to="/myprofile">Deactivate your Account</StyledLink>
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon sx={arrow} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Transfer Funds</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <StyledLink to="/payments">Linked Bank Accounts</StyledLink> <br />
                <StyledLink to="/payments">Payments & Withdrawals</StyledLink>
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon sx={arrow} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Safety &amp; Security</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Here at Begerz we take your Safety & Security seriously. Want to know how? {` `}
                <StyledLink to="/privacy">Click here</StyledLink>
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon sx={arrow} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Data Protection</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Protecting your data is important to us.
              </Typography>
            </AccordionDetails>
          </Accordion>


          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon sx={arrow} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6">Contact Us</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Have any questions, comments, or concerns? We would love to hear from you! <a style={{color:linkColor}} href="mailto:support@begerz.com">Click here!</a>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <div style={{ marginBottom:"4em" }}></div>
 
          <StartABeg />

        </Box>
      </Paper>
    </Container>
  )
}