import { useRef, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Divider, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import Container from '@mui/material/Container'

// Custom components
import DialogHeader from '../../components/DialogHeader'
import ResetCompleted from './ResetCompleted'
import PasswordDetails from '../../components/PasswordDetails'
import BoxSignUpLink from '../../components/BoxSignUpLink'
import WaitOverlay from '../../components/WaitOverlay'
import ErrorDialog from '../../components/ErrorDialog'

// Context
import { AuthContext } from '../../context/Auth'

// Apis
import { useResetPassword } from '../../apis/resetPassword'


// Main component
export default function ResetPassword() {
  // Params
  const { tokenId } = useParams()
  const navigate = useNavigate()

  // Context
  const { handleSignIn } = useContext(AuthContext)

  // State
  const [resetCompleted, setResetCompleted] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorCode, setErrorCode] = useState({})
  const [waitOpen, setWaitOpen] = useState(false)
  const [values, setValues] = useState({
    password: '',
    password_repeat: '',
    showPassword: false,
    showConfirmation: false
  })
  
  // Form validation react-hook-form
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
      mode: 'onSubmit',
      defaultValues: {
        password: "",
        password_repeat: ""
      }
  })

  // Vars
  const { execute } = useResetPassword()
  const password = useRef({})
  password.current = watch("password", "");
  

  // Methods

  // handle password display
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  // toggle confirmation password display
  const handleClickShowConfirmation = () => {
    setValues({ ...values, showConfirmation: !values.showConfirmation })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleMouseDownConfirmation = (event) => {
    event.preventDefault()
  }


  // form submission
  const onSubmit = async (data) => {
    let reqData = {
      resetToken: tokenId,
      password :data.password
    }

    setWaitOpen(true)

    try {
      await execute(reqData)
      setWaitOpen(false)
      setResetCompleted(true)
    } catch(error) {
      setWaitOpen(false)
      if (error.status === 410) navigate('/410')
      setHasError(error?.statusText || 'The server encountered an error sending your request. Please try again later.')
      setErrorCode(error?.status || 500)
    }
  }


  return (
    <Container component="main" maxWidth="md">

      { resetCompleted && <ResetCompleted /> }
      { waitOpen && <WaitOverlay />}
      { hasError && <ErrorDialog message={hasError} setError={setHasError} errorCode={errorCode} title={'There was an error setting the new password'} /> }

      <Paper elevation={4}>
        <Box
          onSubmit={handleSubmit}
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            padding: 3,
          }}
        >

          <DialogHeader>Reset Password</DialogHeader>

          <FormControl
            fullWidth
            sx={{ mt: 2 }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              name="password"
              id="password"
              sx={{mb:1}}
              autoComplete='PassWord'
              type={values.showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    color="primary"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              error={!!errors?.password}
              {...register('password', { 
                required: "Password is required",
                maxLength: { value: 30, message: 'Must be 30 characters or less' },
                pattern: {
                  // eslint-disable-next-line
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                  message: <PasswordDetails />
                }
              })}
            />
            <FormHelperText error={true}>{errors?.password ? errors.password.message : null}</FormHelperText>
          </FormControl>

          <FormControl fullWidth sx={{ mt:2 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Confirmation</InputLabel>
            <OutlinedInput
              name="password_repeat"
              sx={{mb:1}}
              autoComplete='none'
              type={values.showConfirmation ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmation}
                    onMouseDown={handleMouseDownConfirmation}
                    edge="end"
                    color="primary"
                  >
                    {values.showConfirmation? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
              error={!!errors?.password_repeat}
              {...register('password_repeat', { 
                validate: value => value === password.current || "The passwords do not match"
              })}
            />
            <FormHelperText error={true}>{errors?.password_repeat ? errors.password_repeat.message : null}</FormHelperText>
          </FormControl>


          {/* continue button */}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, fontWeight: 700 }}
            onClick={handleSubmit(onSubmit)}
          >
            Continue
          </Button>

          <Divider>or</Divider>


          {/* signin button */}

          <Button
            fullWidth
            variant="outlined"
            sx={{ mt: 3, mb: 4, fontWeight: 700 }}
            onClick={() => {
              handleSignIn()}
            }
          >
            Return to Sign in Page
          </Button>
        </Box>
        <BoxSignUpLink />
      </Paper>
    </Container>
  )
}