import { Button } from '@mui/material'
import { Link } from 'react-router-dom'


// Main component
export default function MenuLink(title, location) {

  return (
    <Link style={{textDecoration: "none"}} to={location}>
      <Button
        disableRipple
        color='primary'
        sx={{ display: 'block' }}
      >
        { title }
      </Button>
    </Link>
  )
}