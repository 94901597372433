import { Button, Container, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// Cards
import startabeg from '../assets/backgrounds/startabeg.png'
import needhelp from '../assets/backgrounds/needhelp.png'

// Icons
import RightArrow from '../assets/icons/rightArrow.svg'
import MoneyBag from '../assets/icons/moneybag/half.svg'


// Main component
export default function BottomCards() {
  // Vars
  const navigate = useNavigate()

  return (
    <Container maxWidth="lg">

      <Grid container mt={2} spacing={3} justifyContent="center"  alignItems="flex-start">
      
        {/* start a beg */}

        <Grid item xs={12} md={5}>

          <Grid container spacing={0} alignItems="center" justifyContent="center"
            sx={{
              paddingTop:"30px",
              minHeight:"240px",
              maxHeight:"240px",
              borderRadius:"24px",
              backgroundImage:`url(${startabeg})`,
              backgroundSize:"100% 340px",
              backgroundPosition:"0px -60px"
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                fontWeight={600}
                textAlign="center"
                color="white"
              >
                Starting your own<br />raise is easy!
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ textAlign:"center"}} >
              <Button
                variant="outlined"
                onClick = { () => {navigate('/raises/create')} }
                sx={{ backgroundColor:"white" }}
              >
                Start a Raise
              </Button>
            </Grid>
          </Grid>
        </Grid>



        {/* need help */}

        <Grid item xs={12} md={5}>

          <Grid container alignItems="center" justifyContent="center"
            sx={{
              minHeight:"240px",
              maxHeight:"240px",
              borderRadius:"24px",
              backgroundImage:`url(${needhelp})`,
              backgroundSize:"cover",
              backgroundPosition:"0px 0px",
            }}
          >
            <Grid item xs={12} sx={{ textAlign:"center", pt:"24px"}}>
              <img alt="moneybag" src={MoneyBag} height="49px" />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h5"
                fontWeight={600}
                textAlign="center"
                color="white"
              >
                Need Some Help?
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                color="white"
                textAlign="center"
              >
                We've got you covered!<br />View tips how to create a successful beg
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ textAlign:"center", mt:1, pb:"32px" }} >
              <Button
                variant="outlined"
                onClick = { () => {navigate('/help')} }
                sx={{ backgroundColor:"white" }}
              >
                Learn more <img alt="arrow" src={RightArrow} style={{ marginLeft:"6px" }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>


      </Grid>

    </Container>
  )
}
