import React from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Typography } from '@mui/material';

// Define the wizard steps
const steps = [
  'Enter Raise',
  'Add Video',
  'Tell Your Story',
]

export default function Progressbar({ page }) {
  return (
    <Box 
      fullWidth
      sx={{ pt: 4, mb: 4 }}
    >
      <Stepper 
        activeStep={page - 1}
        alternativeLabel
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>
              <Typography variant='caption' color="primary.main">
                {label}
              </Typography>
            </StepLabel>
          </Step>
          ))
        }
      </Stepper>
    </Box>
  )
}