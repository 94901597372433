import { Button } from '@mui/material'
import React from 'react'

// Get context from App
import { AuthContext } from '../../../context/Auth'


// Main component
export default function SignInLink() {
  // Context
  const { 
    handleSignIn,
  } = React.useContext(AuthContext)

  return (
    <Button disableRipple color='primary'
      sx={{ display:'block', pl:3, pr:3}}
      onClick={handleSignIn}
    >
      Sign&nbsp;In
    </Button>    
  )
}
