import { Divider, Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Icons
import RightArrow from '../assets/icons/rightArrow.svg'

// Charity logos
import charityclub from '../assets/charities/charityclub.svg'
import charitylife from '../assets/charities/charitylife.svg'
import charitylogo from '../assets/charities/charitylogo.svg'
import helphand from '../assets/charities/helphand.svg'


// Main component
export default function BegerzGivesBack(props) {
  // Vars
  const theme = useTheme()
  const color = props?.color || theme.palette.primary.main
  const linkColor = props?.linkColor || theme.palette.primary.main

  return (
    <>
      <Grid container spacing={1} justifyContent="space-around" alignItems="center">
        <Grid item xs={12} mb={1}>
          <Typography variant='h7' mb={1}>Begerz Gives Back</Typography>
          <Typography mb={3}>Our community has fed 2,457 children and raised over $280,000 for charity!</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <img alt="charity logo" src={charityclub}></img>
        </Grid>
        <Grid item xs={6} md={3}>
          <img alt="charity logo" src={charitylife}></img>
        </Grid>
        <Grid item xs={6} md={3}>
          <img alt="charity logo" src={charitylogo}></img>
        </Grid>
        <Grid item xs={6} md={3}>
          <img alt="charity logo" src={helphand}></img>
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Divider style={{ background:color, marginBottom:'24px', marginTop:'24px' }}></Divider>
        </Grid>
        <Grid item xs={2} >
          <Link 
            to={props?.to || "/about"}
            style={{ display:'inline-block', float:'right', marginRight:"4px", textDecoration:"none", color:linkColor }}
          >
            Learn More 
            <img style={{ marginLeft:'6px', verticalAlign:'top' }} alt="right arrow" src={RightArrow} /> 
          </Link>
        </Grid>
      </Grid>
    </>
  )
}
