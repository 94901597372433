import { Button, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Cards
import Image from '../../assets/content-widgets/skateboard.png'


// Main component
export default function StartABeg() {

  // Vars
  const navigate = useNavigate()
  const theme = useTheme()
  const backgroundColor = theme.palette.blue.main

  return (
    <Grid container p={2} spacing={2} alignItems="center" sx={{ backgroundColor:backgroundColor, borderRadius:"6px"}}>
      <Grid item xs>
        <img alt="skateboard" src={Image} height="190px" width="auto" />
      </Grid>

      <Grid item xs pr={0} sx={{ textAlign:"center"}}>
        <Grid container pr={2} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div" sx={{ color:"white", fontSize:"22px"}}>
              Creating a raise is Easy and fun!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" component="div" sx={{ color:"white", fontSize:"14px"}}>
              Check out our tutorial video to help get started
            </Typography>
          </Grid>

          <Grid item xs={12} mt={5}>
            <Button variant="outlined"
              fullWidth
              sx={{ backgroundColor:"white", borderColor:"white" }}
              onClick={() => ( navigate('/raises/create') )}
            >
              Start a Raise now!
            </Button>
          </Grid>

        </Grid>

      </Grid>
    </Grid>
  )
}
