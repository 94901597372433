import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'

// Custom components
import HtmlEditor from './Editor'

// Icons
import LaunchIcon from '@mui/icons-material/Launch'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import ThumbnailImage from '../../../components/ThumbnailImage'

// Apis
import { useApi } from '../../../apis/privateApiCall'


// Main component
export default function SuccessForm(props) {
  // Vars
  let data = props.data?.success
  let successId = props.data?.success?._id
  const margin = "normal"
  const theme = useTheme()
  const navigate = useNavigate()
  const color = theme.palette.gray.light
  const itemStyle = { fontSize:"13px", fontWeight:"550" }

  // Api
  const { execute, controller,
    //  loading 
    } = useApi()

  // style for thumbnail image
  const style = { 
    objectFit:'cover',
    objectPosition:'center',
    boxShadow:`0 0 0`,
    cursor:"pointer",
    border:`1px solid ${color}`,
    borderBottom:`2px solid ${color}`,
    borderRadius: '4px',
    height:'120px',
    width:'120px'
  }
  
  // Validation
  const { 
    handleSubmit,
    register,
    // setValue,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      title: data?.title,
      goalAmount: data?.goalAmount,
      goalDate: data?.goalDate,
      status: data?.status
    }
  })


  // State
  const [inputs, setInputs] = useState({})


  // Methods

  // submit the updated success story
  const onSubmit = () => {

  }

  // update the beg status
  const handleStatusChange = (event) => {
    setInputs(prev => ({...prev, status:event.target.value}))
  }

  // fetch main data
  const fetchSuccess = async(successId) => {
    try {
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/success/${successId}`
      }
      // send call to API
      const response = await execute({}, options)
      setInputs(response)
    } catch(error) {}
  }


  // Effects

  // effect to load initial data
  useEffect(() => {

    // if there is no successId then disable the section
    //if (!successId) {}

    fetchSuccess(successId)
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[successId])



  // Effects

  // Set dates in date picker
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <Grid item xs={12} >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>

        <Grid container spacing={2} alignItems="baseline">

          {/* status */}

          <Grid item xs={12} md={4}>
            <TextField
              select
              fullWidth
              margin={margin}
              label="Is featured success story?"
              sx={{ fontSize:"13px", fontWeight:"600" }}
              name="featured"
              value={inputs?.featured || false}
              onChange={handleStatusChange}
            >
              <MenuItem sx={itemStyle} value="true">Yes</MenuItem> 
              <MenuItem sx={itemStyle} value="false">No</MenuItem> 
            </TextField>
          </Grid>


          {/* title */}

          <Grid item xs={12} md={8}>
            <TextField
              name="title"
              label="Success story title"
              required
              autoComplete="successTitle"
              fullWidth
              margin={margin}
              error={!!errors.title || null}
              helperText={errors?.title ? errors.title.message : null}
              inputProps={{ maxLength:48, pattern:/^[A-Za-z0-9 ]+$/ }}
              {...register('title', {
                required: "Title is required",
                maxLength: { value: 48, message: 'Title must be 48 characters or less' },
                pattern: {
                  value : /^[A-Za-z0-9 ]+$/,
                  message: 'Title must be alpha numeric characters only'
                }})}
            />          
          </Grid>


          {/* success story link */}

          <Grid item xs={12}>
            <TextField
              name="storyLink"
              label="Success story link"
              autoComplete="storyLink"
              fullWidth
              margin={margin}
              error={!!errors.title || null}
              helperText={errors?.title ? errors.title.message : null}
              inputProps={{ maxLength:48, pattern:/^[A-Za-z0-9 ]+$/ }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => navigate(`/raises/details/${data?._id}`)} >
                    <LaunchIcon />
                  </IconButton>
                )
              }}
              {...register('title', {
                required: "Title is required",
                maxLength: { value: 48, message: 'Title must be 48 characters or less' },
                pattern: {
                  value : /^[A-Za-z0-9 ]+$/,
                  message: 'Title must be alpha numeric characters only'
                }})}
            />          
          </Grid>


          {/* upload image */}

          <Grid item xs={12} md={4} sx={{ textAlign:"center" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                <Typography
                  variant="subtitle4"
                  fontWeight={550}
                  pl={1}
                >
                  Featured image for home page
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ThumbnailImage src={data?.thumbLink} style={style} />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant='contained'
                  sx={{ borderRadius:1 }}
                >
                  Upload Image <FileUploadIcon sx={{ ml:1 }} />
                </Button>
              </Grid>
            </Grid>
          </Grid>


          {/* story content */}

          <Grid item xs={12} md={8}>
            <Typography variant="subtitle4" fontWeight={550} pl={1}>
              Success story content
            </Typography>
            { data?._id && <HtmlEditor data={data} /> }
          </Grid>

        </Grid>
      </form>
    </Grid>      
  )
}