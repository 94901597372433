import { useState } from 'react'
import WaitOverlay from '../components/WaitOverlay'
import ErrorDialog from '../components/ErrorDialog'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Paper } from '@mui/material'

// Custom components
import BegPagination from '../components/raises/BegPagination'
import PageTitle from '../components/styled-ui/PageTitle'

// Bottom content widgets
import Adverts from '../components/advertisement'
import BottomCards from '../components/BottomCards'
import FeaturedSuccess from '../components/featured-success'
import AccessPageLower from '../components/AccessPageLower'


// Main component
export default function Home() {
  // State
  const [waitOpen, setWaitOpen] = useState(true)
  const [canLoad, setCanLoad] = useState({ admirable: true})
  const [hasError, setHasError] = useState({
    status: false,
    message: '',
    code: 500
  })
  
  // handle load order of data sections
  const handleLoaded = (index) => {
    switch (index) {
      case 'admirable':
        setCanLoad(prev => ( {...prev, hilarious:true} ))
        break
      case 'hilarious':
        setCanLoad(prev => ( {...prev, brilliant:true} ))
        break
      case 'brilliant':
        setCanLoad(prev => ( {...prev, inspiring:true} ))
        break
      case 'inspiring':
        setCanLoad(prev => ( {...prev, informative:true} ))
        break
      default:
        setWaitOpen(false)
    }
  }

  // common properties for raise queries
  const raiseProps = { doneLoading:handleLoaded, pageSize:3 }
  const admirableProps =  { ...raiseProps,  ...{ type:'admirable', url:`/api/v1/begs/categories/admirable?sort=-createdAt`, title:'Admirable', toLink:'/raises/categories/admirable' }}
  const hilariousProps =  { ...raiseProps,  ...{ type:'hilarious', url:`/api/v1/begs/categories/hilarious?sort=-createdAt`, title:'Hilarious', toLink:'/raises/categories/hilarious' }}
  const brilliantProps =  { ...raiseProps,  ...{ type:'brilliant', url:`/api/v1/begs/categories/brilliant?sort=-createdAt`, title:'Brilliant', toLink:'/raises/categories/brilliant' }}
  const informativeProps ={ ...raiseProps,  ...{ type:'informative', url:`/api/v1/begs/categories/informative?sort=-createdAt`, title:'Informative', toLink:'/raises/categories/informative'  }}
  const inspiringProps =  { ...raiseProps,  ...{ type:'inspiring', url:`/api/v1/begs/categories/inspiring?sort=-createdAt`, title:'Inspiring', toLink:'/raises/categories/inspiring'  }}


  return (
    <>
      { waitOpen && <WaitOverlay />}
      { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving raises'} /> }

      <Container component="main" maxWidth="md">

        <PageTitle>Top Categories</PageTitle>

        <Paper elevation={0} sx={{ pt:1 }}>
          <Box sx={{ display:'flex', flexDirection:'column', alignItems:'left', padding:{ xs:1, sm:3} }} >

            {/* admirable */}
            { canLoad?.admirable && <BegPagination mb={2} {...admirableProps} /> }

            {/* brilliant */}
            { canLoad?.brilliant && <BegPagination {...brilliantProps} /> }

            {/* hilarious */}
            { canLoad?.hilarious && <BegPagination {...hilariousProps} /> }

            {/* ads */}
            <Adverts style={{ marginBottom:'34px', marginTop:'34px' }} />

            {/* informative */}
            { canLoad?.informative && <BegPagination {...informativeProps} /> }

            {/* inspiring */}
            { canLoad?.inspiring && <BegPagination {...inspiringProps} /> }

            {/* ads */}
            <Adverts style={{ marginBottom:'34px', marginTop:'34px' }} />


            {/* featured success stories */}
            <FeaturedSuccess />

            {/* <SectionDivider /> */}

            <AccessPageLower />

          </Box>
        </Paper>
      </Container>

      <BottomCards />
    </>
  )
}