/*
pass the following props:
-------------------------
shareOpen - open or closed state
setShareOpen - function to set open/close dialog state
shareText - text to show in the share, i.e, 'Check out this beg on Begerz!'
shareLink - link to the beg
begId - the id of the beg
title - dialog title (Share the love)
helpText - text below the top divider line
buttonText - text to show on invite button
secondaryButtonText - text to show on close button
{ shareOpen && <ShareDialog shareOpen={shareOpen} setShareOpen={setShareOpen} begId={shareData} /> }
*/
import React, { useState } from 'react'
import { useForm, useFieldArray } from "react-hook-form"
import { Box, Button, Dialog, DialogContent, Divider, Grid, IconButton, Snackbar, TextField, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import MuiAlert from '@mui/material/Alert'

// Components
import Followers from './Followers'
import DialogHeader from '../DialogHeader'
import CloseIcon from '@mui/icons-material/Close'

// APIs
import { useApi } from '../../apis/privateApiCall'

// Components
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})


// Main component
export default function InviteDialog(props) {
  // APIs
  const { execute } = useApi()
  
  // Vars
  const userId = localStorage.getItem('userId')
  const begId = props.begId
  const theme = useTheme()
  const color = theme.palette.primary.main
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const defaultHelperText = <>We recommend inviting at least 3-5 friends.<br />People you invite will be sent a private link only they can see!</>


  // Use state

  // number of email address fields
  const [invites, setInvites ] = useState(1)
  const [selectedFollowers, setSelectedFollowers] = useState([])
  const [showAlert, setShowAlert] = useState(false)

  // loading state
  // eslint-disable-next-line
  const [followersLoading, setFollowersLoading] = useState(true)

  // Validation
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState:{errors} 
  } = useForm({
    mode: 'all',
    defaultValues: { emails: [{ email: "" }] }
  })
  const { 
    fields,
    append,
    remove
  } = useFieldArray({ control, name:"emails" })


  // Methods

  // add a new email address field
  const handleAddEmail = (event) => {
    // do not add if there are errors in existing inputs
    if (Object.keys(errors).length > 0) return false

    // check for last email to have data
    const emailInputs = getValues()
    if (emailInputs.emails[invites - 1].email.length === 0) return false

    // make sure no more than 5 emails have been included
    if (invites < 5 ) {
      append({ email:"" })
      setInvites(prev => prev + 1)
    }
  }

  // reset state so the alert can be re-displayed
  const handleAlertClose = () => {
    setShowAlert(false)
  }

  // use the api to send each invitation
  const sendInvitations = async (invites) => {
    let index = 0
    const recordCount = Object.keys(invites).length

    // set api options
    const options =  {
      method: 'post',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/invitations`
    }

      // iterate and send the invitations
    try {
      Object.keys(invites).forEach( async (key) => {

        // create data object to post
        const data = invites[key]
        const reqData = {
          userId: data.userId,
          begId: data.begId,
          email: data.email,
          status: 'pending'
        }
        // add invitee if user an existing follower
        if (data?.inviteeId) reqData['inviteeId'] = data?.inviteeId

        // call the api and show an alert
        await execute(reqData, options)
        index ++
        if (index === recordCount) {
          setShowAlert(true)
        }
      })
    } catch {} 
  }

  // submit the for and send the invitations
  const onSubmit = (inputs) => {
    // copy any selected followers
    let invites = selectedFollowers

    // iterate the email addresses and send the invitation
    inputs.emails.forEach(address => {
      if (address.email) {
        invites[address.email] = {
          userId: userId,
          email: address.email,
          begId: begId
        }
      }
    })

    // make sure there are selected invitees
    if (Object.keys(invites).length === 0) return false

    // process the invitations
    sendInvitations(invites)
  }
 

  return (
    <Dialog
      maxWidth="sm"
      open={props.shareOpen}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent sx={{ mb:2 }}>

        <Box sx={{ textAlign:'left' }}>
          <DialogHeader sx={{ textAlign:'center', mb:2 }}>
            { props?.title || 'Invite freinds to your raise'}
          </DialogHeader>
        </Box>   

        <IconButton
          onClick={ () => { props.setShareOpen(false) }}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="body2" sx={{ textAlign:"center", mb:3 }}>
          { props.defaultHelperText || defaultHelperText }
        </Typography>            


        <Divider sx={{ mb:2 }}></Divider>
    
        <Typography variant="h6" align='left' mb={1} >
          Select followers to share with
        </Typography>

        {/* followers */}

        { <Followers
            setIsLoading={setFollowersLoading}
            selectedFollowers={selectedFollowers}
            setSelectedFollowers={setSelectedFollowers}
            data={{userId:userId, begId:begId}} 
          />
        }

        <Typography variant="h6" align='left' sx={{ mb:1 }} >
          Invite users by email
        </Typography>

        {/* email invitations */}
      
        <form noValidate onSubmit={handleSubmit(onSubmit)}>

          {fields.map((item, index) => (
            <Grid key={index} container spacing={0}>
              <Grid item xs={11}>
                <TextField 
                  name={`emails.${index}.email`}
                  autoComplete={`emails.${index}.email`}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  margin="normal"
                  type="email"
                  label="Email Address"
                  error={ !!errors.emails?.[index]?.email  }
                  helperText={errors.emails?.[index]?.email ? errors.emails?.[index]?.email?.message : null}
                  {...register(`emails.${index}.email`, {
                    maxLength: { value:80, message: 'Must be 80 characters or less' },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    }
                  })}
                />
              </Grid>

              {/* remove email address entry */}

              <Grid item xs={1}>
                { index > 0 && 
                  <Button
                    variant='text'
                    sx={{ marginTop:4, padding:0 }}
                    onClick={() => {
                      remove(index)
                      setInvites(prev => prev - 1)
                    }}
                  >
                    <RemoveCircleOutlineIcon color='primary' />
                  </Button>
                }
              </Grid>
            </Grid>
          ))}

          {/* add email address entry */}

          <Button sx={{ color:color, mt:1 }}
            variant='outlined'
            onClick={handleAddEmail}
          >
            + Add Email
          </Button>

          <Divider sx={{ mt:2, mb:1 }} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ mt:1, mb:1, fontWeight: 700 }}
                onClick={ () => { props.setShareOpen(false) } }
              >
                { props.secondaryButtonText || 'Close' }
              </Button>

            </Grid>
            <Grid item xs={6}>
              <Button
                type="sumbit"
                fullWidth
                variant="contained"
                sx={{ mt:1, mb:1, fontWeight: 700 }}
              >
                { props.buttonText || 'Send Invitations' }
              </Button>
            </Grid>
          </Grid>

        </form>


        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={() => {setShowAlert(false)}}>
          <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
            { `Invitations have been sent!`}
          </Alert>
        </Snackbar>        

      </DialogContent>
    </Dialog>
  )
}