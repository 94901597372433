import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'

// Icons
import MoneyBagFull from '../../../assets/icons/moneybag/full.svg'
import { Link } from 'react-router-dom'

// Main component
export default function ViewHeader(props) {

  // Vars
  const data = props.data
  const theme = useTheme()
  const borderColor = theme.palette.gray.secondary
  const border = { border:'1px solid', borderColor:borderColor, borderRadius:'4px' }

  
  return (
    <Grid container spacing={3} alignItems="bottom">
      <Grid item xs={12}>
        <Grid container sx={{ border }} alignItems="bottom">
            <Grid item xs={7.4} sx={{ padding:1 }}>
              <img  alt="money bag" height="50px" style={{ marginRight:'6px', verticalAlign:'bottom' }} src={data?.iconPath || MoneyBagFull} /> 
              <Box sx={{ display:"inline-block"}}>
                <Typography variant="h7">
                  ${ data.amountRaised }
                </Typography>
                <Typography variant="small">
                  raised of a ${ data.goalAmount } goal
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4.6} p={1} sx={{ borderLeft:`1px solid ${borderColor}`}}>

              <Grid container justifyContent="center" spacing={0} alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography textAlign="center" variant='small1'>
                    Days Left
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography textAlign="center" sx={{ fontWeight:700 }} variant='h5'>
                    { data?.daysRemaining }
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography textAlign="center" variant='small'>
                      Ends { data?.goalDate && format(new Date(data.goalDate), 'MM/dd/yyyy') }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Grid>
      <Grid item xs={12}>
        <Link
          to={`/raises/details/${data._id}`}
          style={{ textDecoration:"none", color:theme.palette.primary.main }}            
        >
          <Button fullWidth variant='outlined'>View</Button>
        </Link>
      </Grid>
    </Grid>
  )
}
