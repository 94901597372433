import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTheme } from '@emotion/react'
import Typography from '@mui/material/Typography'
import { Button, FormControl, FormControlLabel, Grid, Paper, RadioGroup, TextField } from '@mui/material';
import { format, addDays, isBefore } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

// Components
import BegerzRadio from '../../../components/styled-ui/BegerzRadio'
import Player from '../../../components/player/Player'
import ThumbnailImage from '../../../components/ThumbnailImage'
import UploadVideo from '../../../components/UploadVideo'

// Dialogs
import DeleteVideo from '../../../components/dialogs/DeleteVideo'

// CSS
import '../../../css/CreateBeg.css'

// APIs
import { useApi } from '../../../apis/privateApiCall'

// Main component
export default function GeneralTab(props) {
  // Vars
  let data = props.data
  const setData = props.setData
  const theme = useTheme()
  const color = theme.palette.primary.main
  const inactiveColor = theme.palette.gray.light
  const dateFormat = 'MM/dd/yyyy'
  const maxGoalDays = 30
  const maxVideos = 5

  // set the opacity to 1 for the selected video
  const activeStyle = { objectFit:'cover', objectPosition:'center', boxShadow:`0 0 10px ${color}`, cursor:"pointer", border:`1px solid ${color}`, borderBottom:`2px solid ${color}`, borderRadius: '4px' }
  const inactiveStyle = { objectFit:'cover', objectPosition:'center', boxShadow:`0 0 0`, cursor:"pointer", border:`1px solid ${inactiveColor}`, borderBottom:`2px solid ${inactiveColor}`, borderRadius: '4px' }

  // APIs
  const { execute } = useApi()

  // State
  const [activeVideo, setActiveVideo] = React.useState(0)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [streams, setStreams] = React.useState([])
  const [goalDate, setGoalDate] = React.useState()
  const [minDate, setMinDate] = React.useState()
  const [maxDate, setMaxDate] = React.useState()
  const [inputs, setInputs] = useState({
    goalAmount: data.goalAmount,
    title: data.title,
    goalDate: data.goalDate,
    localFileName: '',
    fileSize: 0,
    publishType: data.publishType,
    description: data.htmlDescription
  })

  // Validation
  const { 
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      goalAmount: data.goalAmount,
      title: data.title
    }
  })

  
  // Methods

  // upload success function
  const uploadSucceeded = async (file) => {
    // create the video record
    try {
      const options =  {
        method: 'post',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/videos`
      }

      // add data for the request
      file.videoType = "beg"
      file.videoLink = process.env.REACT_APP_S3URL + `${file.uuid}.mp4`
      file.thumbLink = process.env.REACT_APP_S3URL + `thumbs/${file.uuid}-00001.png`
      file.userId = data.userId
      file.begId = data._id

      // determine if this is the primary video
      file.primary = false
      let videoCount = data?.videos.length
      if (videoCount === 0) file.primary = true
      
      // call api to remove the video    
      await callVideoApi(options, file)

      // add the data to the array
      data.videos.splice(videoCount, 1, file)

      // refresh the display
      setData(data)
      handleVideoSelect(videoCount)
    } catch (error) {}
  }

  // call api to updated beg data
  const callVideoApi = async (options, data) => {
    try { 
      await execute(data, options)
    } catch(error) {
    } finally {
      setData(prev => ({...prev, ...data}))
    }
  }

  // privacy settings change
  const handleRadioChange = (event) => {
    setInputs({...inputs, [event.target.name]: event.target.value})
  }

  // handle video selection
  const handleVideoSelect = (index) => {
    let streams = []
    streams[0] = data?.videos[index]
    setStreams(streams)
    setActiveVideo(index)
  }

  // click event for delete button
  const handleDeleteVideo = () => {
    setDialogOpen(true)
  }

  // cancel delete, close dialog
  const cancelDeleteVideo = () => {
    setDialogOpen(false)
  }

  // dialog contine, delete video
  const deleteVideo = async (index) => {
    setDialogOpen(false)
    try {
      const options =  {
        method: 'delete',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/videos/${data.videos[index]._id}`
      }
      // call api to remove the video    
      await callVideoApi(options, {})

      // remove video from state
      data.videos.splice(index, 1)

      // update the displayed videos
      if (index === (data.videos.length)) {
        setData(data)
        handleVideoSelect(index - 1)
      } 
      else {
        setData(data)
        handleVideoSelect(index)
      }
    } catch (error) {}
  }

  // make sure date is not before minimum
  const handleDateChange = (newDate) => {
    // if the newDate < minDate, use minDate
    if (isBefore(newDate, minDate)) newDate = minDate

    try {
      newDate = format(newDate, dateFormat)
      setGoalDate(newDate)
      setValue("goalDate", newDate, { shouldValidate:true,  shouldDirty:true })
    } catch(err) {}
  }

  // save the changes
  const onSubmit = (fields) => {
    // combine all of the fields
    fields = {...inputs, ...fields}

    // call api
    const update = async (begId) => {
      const options =  {
        method: 'patch',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/begs/${begId}`
      }
    
      // call the api and show an alert
      try { 
        const result = await execute(fields, options)
        setData(result)
      } catch(error) {}
    }
    update(data._id)
  }


  // Effects

  // Set dates in date picker
  useEffect(() => {
    // set initial video to watch
    if (data?.videos[0]) handleVideoSelect(0)

    const goalDate = new Date(data?.goalDate)
    const createdAt = new Date(data?.createdAt)

    console.log(createdAt)

    setMaxDate(addDays(createdAt, maxGoalDays))
    setMinDate(createdAt)

    handleDateChange(goalDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
      { dialogOpen && <DeleteVideo cancel={cancelDeleteVideo} delete={deleteVideo} activeVideo={activeVideo} dialogOpen={dialogOpen} />}

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="baseline">
          <Grid item xs={12} md={6}>
            <TextField
              name="title"
              id="title"
              label="Title of Beg"
              required
              autoComplete="begTitle"
              fullWidth
              error={!!errors.title || null}
              helperText={errors?.title ? errors.title.message : null}
              inputProps={{ 
                maxLength:48,
                pattern:/^[A-Za-z0-9 ]+$/ 
              }}
              {...register('title', {
                  required: "Title is required",
                  maxLength: { value: 48, message: 'Title must be 48 characters or less' },
                  pattern: {
                    value : /^[A-Za-z0-9 ]+$/,
                    message: 'Title must be alpha numeric characters only'
                  }
                }
              )}
            />          
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              name="goalAmount"
              id="goalAmount"
              label="Amount"
              required
              autoComplete="goalAmount"
              fullWidth
              error={!!errors.goalAmount || null}
              helperText={errors?.goalAmount ? errors.goalAmount.message : null}
              {...register('goalAmount', { 
                required: "Amount is required",
                min: { value: 50, message: "Minimum is $50" },
                max: { value: 10000, message: "Maximum is $10,000" },
                minLength: { value: 2, message: "At least 2 digits" },
                maxLength: { value: 5, message: "5 digits or less" },
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Numbers only'
                }
              })}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <div className='end-date-flex-container'>
              <div className='end-date-flex-item end-date-item-date'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Goal Date"
                    name="goalDate"
                    minDate={minDate}
                    maxDate={maxDate}
                    allowSameDateSelection
                    inputFormat={dateFormat}
                    onChange={handleDateChange}
                    value={goalDate || minDate}
                    renderInput={(params) =>
                      <TextField {...params}
                        helperText={errors.goalDate?.message || <br />}
                        sx={{ svg: {color} }}
                        fullWidth
                        error={!!errors.goalDate}
                        {...register('goalDate', {
                          required: "End date is required",
                          max: { 
                            value: maxDate,
                            message: `Max date is 30 days`
                          },
                          min: { 
                            value: minDate,
                            message: 'Cannot be before today'
                          }
                        })}
                      />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container mt={1} spacing={1} alignItems="baseline">

          {/* videos */}

          <Grid item xs={12} md={8}>
            <Grid container alignItems="center">

              <Grid item xs={12}>
                <Typography variant='h6'>Video</Typography>
                <Typography variant='subtitle1'>The cover video will display as the main preview image for your Beg. You can add up to 5 total videos or images.</Typography>
              </Grid>

              {/* active video */}
              
              <Grid item xs={12}>
                <Grid container spacing={1} justifyContent="flex-start">

                  {/* video player */}

                  <Grid item xs={12} md={6}>
                    { data?.videos && <Player controls playsinline streams={ streams } /> }
                  </Grid>

                  {/* video add delete */}

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1} sx={{ mt:.5, pt:1, pb:1, backgroundColor:theme.palette.gray.light }}>
                      <Grid item xs={12} sx={{ textAlign:"center" }}>

                        {/* add video */}

                        <UploadVideo data={data} success={uploadSucceeded} button={
                          <Button
                            component="span"
                            disabled={data?.videos.length >= maxVideos}
                            variant="outlined"
                          >
                            + Add Video
                          </Button>}
                        />

                      </Grid>
                      <Grid item xs={12} sx={{ textAlign:"center"}}>
                        <Button
                          variant="outlined"
                          onClick={() => {handleDeleteVideo(activeVideo)}}
                          disabled={data?.videos[activeVideo]?.primary || data?.videos?.length === 0}
                        > 
                          Delete Video
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid item xs={12}>
                  <Typography noWrap variant='subtitle2'>{ data?.videos[activeVideo]?.localFileName}</Typography>
                </Grid>


              </Grid>
            </Grid>
          </Grid>



          {/* privacy setings */}

          <Grid item xs={12} md={4}>
            <Typography variant='h6'>Privacy setting</Typography>
            <Paper elevation={1} sx={{ mt:1, p:2, pt:1, pb:1 }}>
              <div>
                <FormControl size="small" margin="none">
                  <RadioGroup
                    name="publishType"
                    id="publishType"
                    value={inputs.publishType || 'unlisted'}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel sx={{ color:color }}
                      value="private"
                      control={<BegerzRadio size="small" sx={{ color:color, '&.Mui-checked': {color: color} }} />}
                      label="Private" 
                    />
                    <div className="radio-button-description" style={{ fontSize:'12px', marginBottom:1 }}>Only you and people you choose can watch your video</div>

                    <FormControlLabel sx={{ color:theme.palette.primary.main }}
                      value="unlisted"
                      control={<BegerzRadio size="small" sx={{ color:color, '&.Mui-checked': {color: color} }} />}
                      label="Unlisted"
                    />
                    <div className="radio-button-description" style={{ fontSize:'12px', marginBottom:1 }}>Anyone with the video link can watch your video</div>

                    <FormControlLabel size="large" sx={{ color:theme.palette.primary.main }}
                      value="public"
                      control={<BegerzRadio size="small" sx={{ color:color, '&.Mui-checked': {color: color} }} />}
                      label="Public"
                    />
                    <div className="radio-button-description" style={{ fontSize:'12px', marginBottom:1 }}>Everyone can watch your video</div>
                  </RadioGroup>
                </FormControl>
              </div>
            </Paper>
          </Grid>

          { /* video list */}

          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="flex-start" alignItems="center">

              { data && data?.videos.map((item, index) => {
                  // set style
                  let style = inactiveStyle
                  if (index === activeVideo) {
                    style = activeStyle
                  }

                  return (
                    <Grid key={index} item xs={6} sm={4} md={2.4}>
                      <Grid container alignItems="baseline">
                        <Grid item xs={12}>
                          <ThumbnailImage
                            height="140px"
                            width="140px"
                            src={item.thumbLink} 
                            style={ style }
                            onClick = {() => {handleVideoSelect(index)}}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography noWrap variant='subtitle2'>{item?.localFileName}</Typography>
                          { (item?.primary && <Typography sx={{ fontWeight: 600}} variant='subtitle2'>Primary video</Typography>) || <br />}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })
              }

            </Grid>
          </Grid>

        </Grid>

        <Button
          type="submit"
          sx={{ marginTop:4 }}
          fullWidth
          variant="contained"
        >
          Save &amp; Update Raise
        </Button>

      </form>
    </>
  )}