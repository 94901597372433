import React, { useState, useContext, useRef } from 'react'
import { Button, Grid, Dialog, DialogContent, Divider, Typography, Box, FormHelperText } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// Custom components
import DialogHeader from '../../../components/DialogHeader'
import StyledLinkTo from '../../../components/styled-ui/StyledLink'
import ProfileAvatar from '../../../components/ProfileAvatar'
import WaitOverlay from '../../../components/WaitOverlay'

// Editor imports
import { convertToRaw, EditorState } from "draft-js"
import { Editor } from 'react-draft-wysiwyg'
import draftToHtmlPuri from "draftjs-to-html"

// APIs
import { useApi } from '../../../apis/privateApiCall'

// Icons
import LeftArrow from '../../../assets/icons/leftArrow.svg'

// Context
import { ChipInContext } from '../ChipIn'

// CSS
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import '../../../css/CreateBeg.css'


// Main component
export default function Completed() {
  // APIs
  const postComment = useApi().execute

  // Use context
  const { 
    totals,
    comment,
    setComment,
    setDialogStep
  } = useContext(ChipInContext)

  // State
  const [errors, setErrorState] = useState({status: true})
  const [editorState, setEditorState] = useState(comment && EditorState.createWithContent(comment))
  const [waitOpen, setWaitOpen] = React.useState(false)

  // Vars
  const begId = totals.begId
  const theme = useTheme()
  const navigate = useNavigate()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const editorRef = useRef()
  const minEditorLength = process.env.REACT_APP_MIN_BEG_DESCRIPTION_LENGTH
  const maxEditorLength = process.env.REACT_APP_MAX_BEG_DESCRIPTION_LENGTH
  const toolbarOptions = { options: ['inline', 'list', 'textAlign'],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline']
    }}
  

  // Methods
  const handleButtonClick = async () => {
    if (errors.status === false) {
      setWaitOpen(true)

      // get editor data
      const contentState = editorState.getCurrentContent()

      // create the data object to save
      const data = {
        begId: begId,
        anonymous: totals.anonymous,
        userId: localStorage.getItem('userId'),
        textDescription: contentState?.getPlainText(),
        htmlDescription: draftToHtmlPuri( convertToRaw(editorState.getCurrentContent()) )
      }
      
      // set api options
      const options =  {
        method: 'post',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: '/api/v1/comments'
      }
    
      // call the api, then exit
      try { 
        await postComment(data, options)
      }
      catch(error) {
      } finally {
        setWaitOpen(false)
        navigate(`/raises/details/${begId}`)
      }
    } else {
      navigate(`/raises/details/${begId}`)
    }
  }
  
  // Check for editor requirement errors
  const checkEditorErrors = (contentState) => {
    // clear existing errors
    setErrorState({status:false})

    if (!contentState || !contentState.hasText() ) {
      setErrorState(prev => ({...prev, status:true}))
      return false
    }

    // Check content length
    const length = contentState?.getPlainText().length || 0
    if (length < minEditorLength) {
      setErrorState({
        status: true,
        description: {
          error: true,
          message: `Your story should be at least ${minEditorLength} characters`
        }
      })
    }
    if (length > maxEditorLength) {
      setErrorState({
        status: true,
        description: {
          error: true,
          message: `Your story cannot exceed ${maxEditorLength} characters`
        }
      })
      return false
    }
    return true
  }

  // check for max editor size
  const handleBeforeInput = () => {
    const contentState = editorState.getCurrentContent()
    const length = contentState?.getPlainText().length || 0
    if (length > maxEditorLength) return 'handled'
  }

  // check for size and paste text
  const handlePastedText = (pastedText) => {
    const contentState = editorState.getCurrentContent()
    const length = (contentState?.getPlainText().length || 0) + pastedText.length
    if (length > maxEditorLength) {
      setErrorState({
        status: true,
        description: {
          error: true,
          message: `Cannot paste. Your comment cannot exceed ${maxEditorLength} characters`
        }
      })
      return 'handled'
    }
  }

  // update the editor content
  const handleEditorChange = (editorState) => {
    setEditorState(editorState)
    const contentState = editorState.getCurrentContent()
    checkEditorErrors(contentState)
    setComment(contentState)
  }


  return (
    <Dialog
      maxWidth="sm"
      open={true}
      fullScreen={fullScreen}
      fullWidth
    >

      { waitOpen && <WaitOverlay /> }
      { /* hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error connecting to payment processor'} /> */ }

      <DialogContent sx={{ mb: 2 }}>

        <Button variant='link'
          onClick={() => { setDialogStep(3) }}
          style={{ padding:'14px', display:'inline-block', textDecoration:"none", color:theme.palette.primary.main }}>
          <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> Back
        </Button>

        <Box sx={{ textAlign:'left' }}>
          <DialogHeader sx={{ textAlign:'left', mb:3 }}>
            Comment on this Raise
          </DialogHeader>
        </Box>        
        
        <Editor
          id="description"
          name="description"
          wrapperClassName="wrapper"
          editorClassName="editorEdit"
          toolbar={toolbarOptions}
          hashtag={{
            separator: ' ',
            trigger: '#'
          }}
          onEditorStateChange={handleEditorChange}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
          editorState={editorState}
          editorRef={(ref) => ( editorRef.current = ref )}
        />

        <FormHelperText error={ !!errors.description }>
          {(errors.description && errors.description?.message) || 'Leave a comment (8 - 1000 characters)'}
        </FormHelperText>

        <Divider sx={{ mt:3, mb:3 }}></Divider>

        <Grid container spacing={1} alignItems="center">
          
          <Grid item xs={6}>
            <Grid container spacing={1} alignItems="center">
              <Grid item >

                {
                  (totals?.anonymous === true && <ProfileAvatar sx={{ mt:0, width:48, height:48, fontSize:16 }} alt='anonymous' src={null} />) 
                  ||
                  <ProfileAvatar sx={{ mt:0, width:48, height:48, fontSize:16 }} alt={ localStorage.getItem('username') } src={ localStorage.getItem('profileImage') } />
                }

              </Grid>
              <Grid item >
                <Typography variant='subtitle2' sx={{ mb:0 }}>You are reacting as</Typography>

                {
                  (totals?.anonymous === true && <Typography component="p" variant='h6'>anonymous</Typography>) 
                  ||
                  <Typography component="p" variant='h6'>{ localStorage.getItem('username') }</Typography>
                }
                
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>

            {/*
            <Grid container spacing={1} alignItems="center">
              <Grid item >
                <img alt="karma star" width={48} height={48} src={ KarmaStar } />
              </Grid>
              <Grid item >
                <Typography variant='subtitle2' sx={{ mb:0 }}>Thanks for chipping in!</Typography>
                <Typography component="p" variant='subtitle3' sx={{ fontWeight:700 }}>+2 Karma Points!</Typography>
              </Grid>
            </Grid>
            */}
            
          </Grid>

        </Grid>


        <Button
          fullWidth
          variant="contained"
          sx={{ mt:4, mb:3, fontWeight: 700 }}
          onClick={ handleButtonClick }
        >
          Save Comment
        </Button>

        <StyledLinkTo to={`/raises/details/${begId}`} center="true">Return to the Raise</StyledLinkTo>

      </DialogContent>
    </Dialog>
  )
}