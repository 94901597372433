import { Typography } from '@mui/material'


// Main component
export default function PageTitle(props) {
  return (
    <Typography
      lineHeight="1.2"
      fontFamily="Hey Comic"
      fontSize="54px"
      textAlign="left"
      color="white"
      mb={6}
      mt={10}
      {...props}
    >
      {props.children}
    </Typography>
  )
}
