import { Typography } from '@mui/material'

const convertNumber = (number) => {
  let result = 0
  let remainder = 0
  
  // Convert from millions
  if (number >= 1000000) {
    result = Math.floor(number / 1000000)
    remainder = Math.round((number % 1000000) / 100000)
    return result + (remainder > 0 ? '.' + remainder : '') + 'm'
  }

  // Convert from thousands
  if (number >= 1000) {
    result = Math.floor(number / 1000)
    remainder = Math.round((number % 1000) / 100)
    
    if (remainder >= 10) remainder = 9
    
    return result + (remainder > 0 ? '.' + remainder : '') + 'k'
  }
  
  return number
}

export default function NumberBox({...props}) {
  let formattedNumber = 0
  props.sx = { ...props.sx }
  if (props?.number) {
    formattedNumber = convertNumber(props.number)
  }

  return (
    <Typography {...props}> { formattedNumber } </Typography>
  )
}