import { forwardRef, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Button, Dialog, DialogContent, Divider, FormControl, FormControlLabel, Grid, IconButton, RadioGroup, Snackbar } from '@mui/material'
import Typography from '@mui/material/Typography'
import MuiAlert from '@mui/material/Alert'
import { format } from 'date-fns'
import Pagination from '@mui/material/Pagination'
import useMediaQuery from '@mui/material/useMediaQuery'

// Components
import BegerzRadio from '../styled-ui/BegerzRadio'

// Dialogs
import PaymethodDialog from './PaymethodDialog'
import ConfirmationDialog from './Confirmation'

// Icons
import CloseIcon from '@mui/icons-material/Close'

// Error handling
//import WaitOverlay from '../WaitOverlay'
import ErrorDialog from '../ErrorDialog'
import DialogHeader from '../DialogHeader'

// APIs
import { useApi } from '../../apis/privateApiCall'

// Utils
import isEmpty from '../../utils/isEmpty'


// Main component
export default function AccountsDialog(props) {
  // Vars
  const theme = useTheme()
  const color = theme.palette.primary.main
  const userId = localStorage.getItem('userId')
  const dateFormat = 'MM/dd/yyyy'
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const defaultErrorMessage = 'Error updating payment method'

  // APIs
  const { execute } = useApi()
  
  // State
  
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertErrorMessage, setAlertErrorMessage] = useState(defaultErrorMessage)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [defaultMethod, setDefaultMethod] = useState({})
  const [data, setData] = useState(false)
  const [pagination, setPagination ] = useState({
    current: 1,
    pages: 0,
    limit: 10,
    records: 0,
    loading: true
  })
  const [hasError, setHasError] = useState({
    status: false,
    message: '',
    code: 500
  })
  
  
  // Methods
  const handlePagination = (event, value) => {
    setPagination(prev => ({...prev, current: value}))
  }

  // radio buttons
  const handleRadioChange = (event) => {
    setDefaultMethod(event.target.value)
  }

  // delete an account
  const handleDelete = (data) => {
    setConfirmationProps(
      prev => ({
        ...prev,
        open:true,
        title: 'Delete Account',
        subtitle: 'Are you sure you want to remove this account?',
        handleConfirm: () => deleteMethod(data._id),
        data:data
      })
    )
  }

  // edit an account
  const handleEdit = (data) => {
    setDialogProps(
      prev => ({
        ...prev,
        open:true,
        action:'edit',
        title: 'Edit Account',
        subTitle: 'Edit your accounts for receiving payments',
        closeAndSave: closeAndSave,
        data:data
      })
    )
  }
  
  // set a new default paymentmethod record
  const updateDefaultRecord = () => {
    // check for a default
    if (isEmpty(defaultMethod)) {
      setAlertErrorMessage('Please select a default account before saving')
      setShowErrorAlert(true)
      return
    }
    // update the method
    if (defaultMethod) return updateMethods(defaultMethod)
    // fall through error
    setAlertErrorMessage('Please select a default account before saving')
    setShowErrorAlert(true)
  }
  
  // patch record with default
  const updateMethods = async (id) => {
    setIsLoading(true)
    // set api options
    const options =  {
      method: 'patch',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/paymethods/${id}`
    }
    // call the api and show an alert
    try { 
      await execute({default:true}, options)
      props.closeAndSave()
    } catch(error) {
      setShowErrorAlert(true)      
    } finally {
      setIsLoading(false)
    }
  }

  // patch record with default
  const deleteMethod = async (id) => {
    setIsLoading(true)
    // set api options
    const options =  {
      method: 'delete',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/paymethods/${id}`
    }
    // call the api and show an alert
    try { 
      await execute({default:true}, options)
      await fetchMethods(userId)
      setConfirmationProps(prev => ({...prev, open:false}))
    } catch(error) {
      setShowErrorAlert(true)      
    } finally {
      setIsLoading(false)
    }
  }

  // create a new account
  const handleAddAccount = async () => {
    setDialogProps(
      prev => ({
        ...prev,
        open:true,
        action:'add',
        title: 'Add Account',
        subTitle: 'Add a new account for receiving payments',
        closeAndSave: closeAndSave,
        data:{}
      })
    )
  }


  // Alerts

  // reset clipboard state so the alert can be re-displayed
  const handleAlertClose = () => {
    setShowAlert(false)
  }

  const handleErrorAlertClose = () => {
    setShowErrorAlert(false)
    setAlertErrorMessage(defaultErrorMessage)
  }


  // get paymethods
  const fetchMethods = async (userId) => {
    // set api options
    const options =  {
      method: 'get',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/users/${userId}/paymethods`
    }
    // call the api and show an alert
    try { 
      const data = await execute({}, options)
      setData(data)
      setPagination(prev => ({...prev, ...data.pagination}))
    } catch(error) {
      throw error
    } finally {
      setPagination(prev => ({...prev, loading:false}))
      setIsLoading(false)
    }
  }


  // close the dialog and reload the data
  const closeAndSave = async () => {
    setShowAlert(true)
    setDialogProps(prev => ({...prev, open:false}))
    fetchMethods(userId)
  }

  // close the dialog and reload the data
  const cancelDialog = async () => {
    setDialogProps(prev => ({...prev, open:false}))
  }

  // State for dialog box
  const [dialogProps, setDialogProps] = useState({
    open:false,
    cancelDialog:cancelDialog,
    closeAndSave:closeAndSave,
    data:{}
  })

  const [confirmationProps, setConfirmationProps] = useState({
    open:false,
    title: 'Delete Account',
    subtitle: 'Are you sure you want to delete this account?',
    content: 'Once removed, you will no longer be able to receive payouts using this account.',
    handleClose: () => { setConfirmationProps(prev => ({...prev, open:false})) },
  })


  // Components
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  // Effects
  useEffect(() => {
    // get the default paymethod
    const fetchDefault = async (userId) => {
      // set api options
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/users/${userId}/paymethods?sort=-default&page=1&limit=1`
      }
      // call the api and show an alert
      try { 
        const data = await execute({}, options)
        if (data?.results[0]?.default === true) setDefaultMethod(data?.results[0]?._id)
      } catch(error) {
        throw error
      } finally {
        fetchMethods(userId)
      }
    }
    setIsLoading(true)
    fetchDefault(userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current])

  
  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent sx={{ mb: 2 }}>
        <Box sx={{ textAlign:'left' }}>
          <DialogHeader sx={{ textAlign:'left', mb:1 }}>
            Payment Accounts
          </DialogHeader>
        </Box>        
        <Typography variant='subtitle1' sx={{ mb:2 }}>Edit your accounts for receiving payments</Typography>

        <IconButton
          onClick={props.cancelDialog}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Divider sx={{ mb:4 }}></Divider>

        {/* isLoading && <WaitOverlay /> */}
        { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving data'} /> }


        {/* deposit dialog */}

        { dialogProps.open  && <PaymethodDialog {...dialogProps} /> }
        { confirmationProps.open  && <ConfirmationDialog {...confirmationProps} /> }

        {/* list of accounts */}

        <Grid container spacing={.5}>
          <Grid item mb={1} xs={12}>
            <Grid container >
              <Grid item xs={.5}><Typography variant='caption2'></Typography></Grid>
              <Grid item xs={2}><Typography variant='caption2'>Added</Typography></Grid>
              <Grid item xs={1}><Typography variant='caption2'>Type</Typography></Grid>
              <Grid item xs={8}><Typography variant='caption2'>Description</Typography></Grid>
            </Grid>
          </Grid>
        </Grid>

        <FormControl
          fullWidth
          size="small"
          margin="none"
          >
          <RadioGroup
            name="defaultPayment"
            id="defaultPayment"
            value={defaultMethod || ""}
            onChange={handleRadioChange}
          >

          {/* iterate the paymethods and show a radio button for each */}

          { data?.results && data?.results.map((item, i) => {
            return (
              <Grid key={i} container alignItems="flex-end" justifyContent="flex-start">
                
                {/* radio button */}
                <Grid item xs={.5}>
                  <FormControlLabel key={i} sx={{ color:theme.palette.gray.main }}
                    value={item._id}
                    control={<BegerzRadio size="small" sx={{ color:color, '&.Mui-checked': {color:color} }} />}
                  />
                </Grid>

                <Grid item xs={2} display={{ xs:"none", sm:"block"}}>
                  <Typography variant='subtitle1'>
                    { item?.createdAt && format(new Date(item.createdAt), dateFormat) }
                  </Typography>
                </Grid>

                <Grid item xs={1}>
                  <Typography variant='subtitle1'>
                    { item?.paytype?.toUpperCase() }
                  </Typography>
                </Grid>

                <Grid item xs={5}>
                  <Typography variant='subtitle1'>
                    { item?.description }
                  </Typography>
                </Grid>

                <Grid item xs={1.5}>
                  <Button
                    sx={{ p:0, pl:1, pr:1 }} 
                    variant="text"
                    onClick={() => {handleDelete(item)}}
                    >
                    Delete
                  </Button>
                </Grid>
                <Grid item xs={1.5}>
                  <Button
                    sx={{ p:0, pl:1, pr:1 }} 
                    variant="outlined"
                    onClick={() => {handleEdit(item)}}
                    >
                    Edit
                  </Button>
                </Grid>
              </Grid>
            )})
          }
          </RadioGroup>
        </FormControl>

        <Button
          onClick={handleAddAccount}
          sx={{ color:color, mt:3 }}
          variant='outlined'
        >
          + Add Account
        </Button>


        {/* pagination */}

        <Grid container sx={{ mt:0 }} justifyContent="center">
          <Grid item>
            <Pagination
              disabled={!pagination?.next?.limit > 0}
              color="primary"
              count={pagination?.pages}
              onChange={handlePagination}
            />
          </Grid>
        </Grid>

        <Divider sx={{ mt:3, mb:3 }} />

        <Grid container spacing={2} mb={3}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt:1, mb:1, fontWeight: 700 }}
              onClick={props.cancelDialog}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              fullWidth
              variant='contained'
              sx={{ mt:1, mb:1, fontWeight: 700 }}
              onClick={() => {updateDefaultRecord()}}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>

        <Typography variant='subtitle3'>
          Note: You can modify these settings at any time when you withdraw funds.
        </Typography>

        <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
            Settings have been saved!
          </Alert>
        </Snackbar>

        <Snackbar open={showErrorAlert} autoHideDuration={6000} onClose={handleErrorAlertClose}>
          <Alert onClose={handleErrorAlertClose} severity="error" sx={{ width:'100%' }}>
            { alertErrorMessage }
          </Alert>
        </Snackbar>

      </DialogContent>
    </Dialog>     
  )
}