import { useState } from 'react'

// Main function
export const useKarma = () => {
  // Vars
  const levels = [199,499,1499,2999,5999,11999,23999,47999]
  const descriptions = ['Level 0', 'Level 1','Level 2','Level 3','Level 4','Total Consciousness','Level 6','Gold','Platinum','Emerald']

  // State
  const [loading, setLoading] = useState(true)
  const [karmaLevel, setKarmaLevel] = useState(0)
  const [description, setDescription] = useState('Level 0')
  const [karmaImage, setKarmaImage] = useState()

  // Methods

  // determine the karma level and image based on karma points
  const getKarmaImage = async (points) => {
    let karmaLevel = 0
    levels.every(value => {
      if (points > value && karmaLevel < 8) {
        karmaLevel = karmaLevel + 1
      }
      setKarmaLevel(karmaLevel)
      setDescription(descriptions[karmaLevel])
      return karmaLevel
    })
    setKarmaImage(`level_${karmaLevel}.svg`)
    setLoading(false)
  }

  return {
    getKarmaImage,
    loading,
    karmaLevel,
    karmaImage,
    levels,
    description
  }
}