import { useRefresh  } from './useRefresh'

export const useApi = () => {
  // Context
  const {
    isLoading,
    error,
    controller,
    response,
    executeCall
  } = useRefresh()


  const execute = async (data, options) => {
    // Defaults
    const defaults = {
      method: 'get',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: 'api/v1/users/'
    }

    // Set the url
    if (data) {
      defaults.url = defaults.url + data
    }

    const config = {...defaults, ...options}
    try {
      return await executeCall(data, config)
    } catch (error) {
      throw error
    } 
  }

  const create = async (data, options) => {
    // Defaults
    const defaults = {
      method: 'post',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: 'api/v1/followers/'
    }

    const config = {...defaults, ...options}
    try {
      return await executeCall(data, config)
    } catch (error) {
      throw error
    } 
  }


  const remove = async (data, options) => {
    // Defaults
    const defaults = {
      method: 'delete',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: 'api/v1/followers/'
    }

    // Set the url
    /*
    if (data) {
      defaults.url = defaults.url + data
    }
    */
    const config = {...defaults, ...options}
    try {
      return await executeCall(data, config)
    } catch (error) {
      throw error
    } 
  }

  return {
    isLoading,
    error,
    response,
    controller,
    execute,
    create,
    remove
  }
}