import * as React from "react"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Divider, IconButton } from "@mui/material"

// Custom components
import DialogHeader from "../../../components/DialogHeader"

// Page components
import Cropper from "./Cropper"

// Icons
import CloseIcon from '@mui/icons-material/Close'


// Main component
export default function Popup({ open, image, handleClose, setProfileImage, getCroppedFile }) {
  // Vars
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog 
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
    >

      <DialogContent sx={{ mb: 2 }}>
        <DialogHeader
          id="alert-dialog-title"
          sx={{ textAlign:'left', mb:3 }}
        >
          Edit profile image
        </DialogHeader>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Divider sx={{ mb:3 }} />

        <Cropper
          handleClose={handleClose}
          src={image}
          setProfileImage={setProfileImage}
          getCroppedFile={getCroppedFile}
        />

      </DialogContent>
    </Dialog>
  )
}