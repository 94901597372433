import React from "react";
// import {signin } from '../firebaseSetup'
import { Button } from "@mui/material";
import { useSignin } from "../apis/firebaseSetup";
import WaitOverlay from "./WaitOverlay";
import ErrorDialog from "./ErrorDialog";

const FirebaseSocialAuth = ({
  setAccessToken,
  setLoggedIn,
  setNavProfileImage,
  closeFunction,
  redirectAfterLogin,
  upperFirst,
  setHasError,
  setErrorCode,
}) => {
  const { execute, isLoading, error: hasErrorInFirebase } = useSignin();
  const signinHandler = async (authProvider) => {
    try {
      const result = await execute(authProvider);

      if (result?.accessToken) {
        console.log(result);
        localStorage.setItem("userId", result._id);
        localStorage.setItem("rights", result.rights);
        localStorage.setItem("role", result.role);
        localStorage.setItem("username", result.username);
        localStorage.setItem("firstName", result.firstName);
        localStorage.setItem("lastName", result.lastName);
        localStorage.setItem("email", result.email);
        localStorage.setItem("profileImage", result.profileImage);
        localStorage.setItem("totalRaised", result.totalRaised);
        localStorage.setItem("totalGoals", result.totalGoals);
        localStorage.setItem("chipins", result.chipins);
        localStorage.setItem("donations", result.donations);
        localStorage.setItem("comments", result.comments);
        localStorage.setItem("following", result.following);
        localStorage.setItem("followers", result.followers);
        localStorage.setItem("karma", result?.karma || 0);
        localStorage.setItem("karmaTitle", result?.karmaLevel?.title);
        localStorage.setItem("karmaLevel", result?.karmaLevel?.level);
        localStorage.setItem("karmaIcon", result?.karmaLevel?.iconPath);

        // save access token in memory
        setAccessToken(result.accessToken);

        // set the new login state and set profile avatar
        setLoggedIn(true);
        setNavProfileImage(localStorage.getItem("profileImage"));
        closeFunction(true);

        // move the user to a new page after login if needed
        redirectAfterLogin();
      }
    } catch (error) {
      console.log(error);

      let message =
        "The server encountered an error sending your request. Please try again later.";
      if (error?.data?.message) message = upperFirst(error?.data?.message);
      setHasError(message);
      setErrorCode(error?.status || 500);
    }
  };
  return (
    <>
      {hasErrorInFirebase && (
        <ErrorDialog
          message={hasErrorInFirebase}
          setError={setHasError}
          errorCode={hasErrorInFirebase}
          title={"Sign in error"}
        />
      )}
      {isLoading && <WaitOverlay />}
      <Button
        type="button"
        onClick={signinHandler.bind(null, "google")}
        fullWidth
        variant="contained"
        sx={{ mt: 3, fontWeight: 700 }}
      >
        Sign In With Google
      </Button>
      {/* <Button
        type="button"
        onClick={signinHandler.bind(null, "facebook")}
        fullWidth
        variant="contained"
        sx={{ mt: 3, fontWeight: 700 }}
      >
        Sign In With Facebook
      </Button> */}

      <Button
        type="button"
        onClick={signinHandler.bind(null, "apple")}
        fullWidth
        variant="contained"
        sx={{ mt: 3, fontWeight: 700 }}
      >
        Sign In With Apple
      </Button>
    </>
  );
};

export default FirebaseSocialAuth;
