/*
pass the following props:
-------------------------
shareOpen - open or closed state
setShareOpen - function to set open/close dialog state
shareText - text to show in the share, i.e, 'Check out this riase on Begerz!'
shareLink - link to the raise
begId - the id of the raise
title - dialog title (Share the love)
helpText - text below the top divider line
buttonText - text to show on close button

{ shareOpen && <ShareDialog shareOpen={shareOpen} setShareOpen={setShareOpen} begId={shareData} /> }
*/

import React, { useState } from 'react';
import { Button, Grid, Dialog, DialogContent, Divider, Typography, Box, Snackbar, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DialogHeader from '../DialogHeader';
import CloseIcon from '@mui/icons-material/Close';

// Sharing imports
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MuiAlert from '@mui/material/Alert';
import {
  FacebookShareButton,
  EmailShareButton,
  TwitterShareButton
} from 'react-share';

// APIs
import { useApi } from '../../apis/privateApiCall';

// Icons
import Twitter from '../../assets/icons/social/twitter.svg';
import Facebook from '../../assets/icons/social/facebook.svg';
import Share from '../../assets/icons/social/share.svg';
import Email from '../../assets/icons/social/email.svg';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


// Components
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// Main component
export default function ShareDialog(props) {
  // Vars
  const begId = props.begId;
  const theme = useTheme();
  const baseURL = `${process.env.REACT_APP_WEB_SERVER_URL}raises/details/`;
  const color = theme.palette.primary.main;
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const shareLink = props?.shareLink || `${baseURL}${props.begId}`;
  const defaultHelperText = <>We recommend sharing with at least 3-5 friends. <br /> Sharing on a social network can raise up to 5x more!</>;

  // State
  const [clipboard, setClipboard] = useState({
    value: shareLink+'?type=share',
    copied: false
  });

  // APIs
  const postShare = useApi().execute;


  // Methods

  // reset clipboard state so the alert can be re-displayed
  const handleAlertClose = () => {
    setClipboard(prev => ({ ...prev, copied: false }));
  };

  // log the share
  const handleShareClose = async (source) => {
    // create the data object to save
    const data = {
      begId: begId,
      userId: localStorage.getItem('userId'),
      platform: source
    };

    // set api options
    const options = {
      method: 'post',
      url: '/api/v1/shares'
    };

    // call the api and show an alert
    try {
      await postShare(data, options);
    }
    catch (error) {
    } finally {
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      open={props.shareOpen}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent sx={{ mb: 2 }}>

        <Box sx={{ textAlign: 'left' }}>
          <DialogHeader sx={{ textAlign: 'center', mb: 3 }}>
            {props?.title || 'Share the love'}
          </DialogHeader>
        </Box>

        <IconButton
          onClick={() => { props.setShareOpen(false); }}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Divider sx={{ mb: 2 }}></Divider>

        <Typography variant="body2" sx={{ textAlign: "center", mb: 3 }}>
          {props.defaultHelperText || defaultHelperText}
        </Typography>

        <Grid
          container
          spacing={2}
          justifyContent="space-evenly"
          sx={{ mt: 1 }}
        >

          {/* twitter */}

          <Grid item>
            <TwitterShareButton
              url={shareLink}
              onShareWindowClose={() => { handleShareClose('twitter'); }}
            >
              <img alt="twitter" width={64} src={Twitter}></img>
            </TwitterShareButton>
          </Grid>


          {/* facebook */}

          <Grid item>
            <FacebookShareButton
              quote={props?.shareText || 'Check out this raise on Begerz!'}
              url={shareLink}
              onShareWindowClose={() => { handleShareClose('facebook'); }}
            >
              <img alt="facebook" width={64} src={Facebook}></img>
            </FacebookShareButton>
          </Grid>


          {/* clipboard */}

          <Grid item>
            <CopyToClipboard
              text={clipboard.value}
              style={{ cursor: 'pointer' }}
              onCopy={() => {
                setClipboard(prev => ({ ...prev, copied: true }));
                handleShareClose('link');
              }}
            >
              <img alt="share" width={64} src={Share}></img>
            </CopyToClipboard>
          </Grid>


          {/* email */}

          <Grid item>
            <EmailShareButton
              subject={props?.shareText || 'Check out this raise on Begerz!'}
              body='Check it our here:'
              url={shareLink}
              beforeOnClick={() => { handleShareClose('email'); }}
            >
              <img alt="email" width={64} src={Email}></img>
            </EmailShareButton>
          </Grid>
        </Grid>


        {/* share link */}

        <Grid container
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mt: 4, mb: 4 }}
        >
          <Grid item xs={11}
            sx={{
              border: `1px solid ${theme.palette.gray.secondary}`,
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              borderRight: "none",
              pl: 1, pr: 1, pt: '8px', height: '44px'
            }}
          >
            <Typography noWrap variant='subtitle1' >
              {`${baseURL}${props.begId}`}
            </Typography>
          </Grid>


          {/* clipboard */}

          <Grid item xs={1} sx={{ color: `${color}`, border: `1px solid ${color}`, borderBottomRightRadius: "4px", borderTopRightRadius: "4px", pt: '8px', textAlign: 'center', height: '44px' }}>
            <CopyToClipboard
              text={clipboard.value}
              onCopy={() => {
                setClipboard(prev => ({ ...prev, copied: true }));
                handleShareClose('clipboard');
              }}
            >
              <ContentCopyIcon sx={{ cursor: 'pointer', textAlign: 'right' }} />
            </CopyToClipboard>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle2">Share link</Typography>
          </Grid>
        </Grid>

        <Typography variant='h6' sx={{ mt: 1, mb: 1, textAlign: 'left' }}>Finished sharing?</Typography>

        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 1, mb: 1, fontWeight: 700 }}
          onClick={() => { props.setShareOpen(false); }}
        >
          {props.buttonText || 'Close'}
        </Button>

        <Snackbar open={clipboard.copied} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
            Share link has been copied to clipboard!
          </Alert>
        </Snackbar>

      </DialogContent>
    </Dialog>
  );
}