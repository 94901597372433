import { useState } from 'react'
import { callApi } from './callApi'

export const useBegDetails = () => {
  // State
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  // Vars
  let controller

  // Defaults
  const defaults = {
    headers: { 
      'Content-Type': 'application/json'
    },
    method: 'get',
    baseURL: process.env.REACT_APP_API_SERVER_URL
  }

  // Execute API
  const execute = async (data, options) => {
    // Set the url
    defaults.url = `/api/v1/begs/${data.id}`
    const config = {...defaults, ...options}
    try {
      setIsLoading(true)

      // get the data and abort controller from the axios call
      const api = await callApi(data, config)
      controller = api.controller

      // save the response in state
      setResponse(api.output)
      return api.output
    } 
    catch(error) {
      setError(error)
      throw error?.response || error
    }
    finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    error,
    response,
    controller,
    execute
  }
}