import React from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material'
import ProfileAvatar from '../ProfileAvatar'
import { useTheme } from '@mui/material/styles'

// Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

// APIs
import { useApi } from '../../apis/privateApiCall'


// Main component
export default function Followers(props) {
  // APIs
  const {
    execute
  } = useApi()
  
  // Vars
  const userId = props.data.userId
  const begId = props.data.begId
  const setSelectedFollowers = props.setSelectedFollowers
  const selectedFollowers = props.selectedFollowers
  const setIsLoading = props.setIsLoading
  const theme = useTheme()

  // followers data state
  const [checkState, setCheckState] = React.useState({})
  const [followers, setFollowers ] = React.useState([])
  const [pagination, setPagination ] = React.useState({
    current: 1,
    pages: 0,
    limit: 5,
    records: 0,
    loading: true
  })


  // Methods

  // add a selected follower to invite
  const handleCheck = (event, data) => {
    // checkbox state
    const checked = event.target.checked
    const name = event.target.name

    // set checkbox state
    let checkBoxes = []
    checkBoxes[name] = checked
    setCheckState(prev => ({...prev, ...checkBoxes}))

    // existing seleted list
    let followers = selectedFollowers

    // selected user index by email
    const id = data.user.email

    // invitee data object
    const invitee = {
      userId: data.userId,
      inviteeId: data.user._id,
      email: data.user.email,
      begId: data.begId
    }

    // add follower to list
    if (checked === true) {
      followers[id] = invitee
      setSelectedFollowers(followers)
    } 
    // remove follower
    else {
      delete followers[id]
      setSelectedFollowers(followers)
    }
  }

  // load more results
  const handleMoreClick = () => {
    const nextPage = pagination.next.page
    setPagination(prev => (
      {...prev, current:nextPage, loading:true}
    ))
  }


  // Effects

  // get paginated followers
  React.useEffect(() => {
  
    // get followers of this user
    const fetchFollowers = async (userId) => {
  
      // set api options
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/users/${userId}/followers?page=${pagination.current}&limit=${pagination.limit}`
      }
  
      // call the api and show an alert
      try {

        const data = await execute({}, options)
        setFollowers(prev => (prev.concat(data.results)) )
        setPagination(prev => ({...prev, ...data.pagination}))

      } catch(error) {
        throw error
      } finally {
        setPagination(prev => ({...prev, loading:false}))
        setIsLoading(false)
      }
    }

    fetchFollowers(userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current])


  // Components - individual follower
  const Follower = (props) => {

    const data = props.data
    const id = props.data._id

    return (
      <Grid item xs={`${parseInt(props?.xs || 12)}`} md={`${parseInt(props?.md || 12)}`}>
        <Grid container spacing={0} alignItems="center" justifyContent="flex-start" >
          <Grid item >
            <FormGroup>
              <FormControlLabel
                control={ 
                  <Checkbox
                    checked={checkState[id]}
                    name={id}
                    onChange={(event) => {handleCheck(event, data)}} 
                  />  
                }
                label={
                  <ProfileAvatar
                    src={data.user.profileImage}
                    alt={data.user.username}
                    sx={{ width:32, height:32, fontSize:16 }}
                  >
                    {data?.user.username}
                  </ProfileAvatar>
                  } 
                />
            </FormGroup>
          </Grid>
          <Grid item >
            {data?.user.username || 'unknown'}
          </Grid>
        </Grid>
      </Grid>      
    )
  }


  return (
    <Grid container mb={3} spacing={0}>

      { followers && followers.map((item, index) => {
        return <Follower {...props} selectedFollowers={selectedFollowers} setSelectedFollowers={setSelectedFollowers} key={index} data={{...item, begId:begId}} />
      })}
      
      <Grid item xs={12} sx={{ minHeight:'24px' }} >
        { (pagination?.next?.page > pagination.current)  && 
          <Link to=""
            style={{ display:'block', textDecoration:"none", textAlign:"center", color:theme.palette.primary.main }}
            onClick={handleMoreClick}
          >
            More
            <ArrowDropDownIcon color="primary" sx={{ display:'inline-block', verticalAlign:'bottom', textAlign:'right' }} />
          </Link>
        }
      </Grid>
    </Grid>
  )
}