import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useNavigate } from "react-router-dom"
import { Button } from '@mui/material'

// Images
import Lost from '../assets/404.png'

// Main component
export default function NotFound() {

  // Vars
  const navigate = useNavigate()

  return (
    <>
      <Typography variant='h3' sx={{ fontWeight:700, textAlign:'center', mt:8, mb:1 }}>
        Oops!
      </Typography>
      <Typography variant='h6' sx={{ fontWeight:700, textAlign:'center', mb:6 }}>
        You are lost
      </Typography>

      <Box sx={{ textAlign:'center', mb:7 }}>
        <img alt="404" width="286px" src={Lost}></img>
      </Box>
        
      <Button
        variant='outlined'
        style={{margin: '0 auto', display:"flex"}}
        onClick={() => {navigate(`/home`, { replace: true })}}
      >
        Go Home
      </Button>
    </>
  )
}