import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Grid, Paper } from '@mui/material'

// Custom Components
import BottomCards from '../../components/BottomCards'
import PageTitle from '../../components/styled-ui/PageTitle'
import PageInactiveSubTitle from '../../components/styled-ui/PageInactiveSubTitle'
import PageSubTitle from '../../components/styled-ui/PageSubTitle'
import AccessPageLower from '../../components/AccessPageLower'
import WaitOverlay from '../../components/WaitOverlay'
import ErrorDialog from '../../components/ErrorDialog'

// APIs
import { useApi } from '../../apis/privateApiCall'

// Page components
import Raises from './tabs/Raises'
import Successes from './tabs/Successes'
import Profiles from './tabs/Profiles'


// Main component
export default function Search() {
  // Vars
  const [searchParams] = useSearchParams()
  const terms = searchParams.get("terms")
  const defaultPagination = { current:1, pages:0, limit:12, records:0, loading:true }

  // Component styles
  const activeTab = { fontWeight:'normal', sx:{textDecoration:'underline', cursor:"pointer", fontSize:28 } }
  const inActiveTab = { fontWeight:'normal', sx:{textDecoration:'none', cursor:"pointer"} }
  
  const { execute } = useApi()
  
  // State
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState({ status:false, message:'', code: 500 })
  const [searchTerms, setSearchTerms] = useState()
  const [tab, setTab] = useState(1)

  // data state
  const [begs, setBegs] = useState([])
  const [successes, setSuccesses] = useState([])
  const [profiles, setProfiles] = useState([])
  
  // pagination state
  const [pagination, setPagination ] = useState(defaultPagination)
  const [successPagination, setSuccessPagination ] = useState(defaultPagination)
  const [profilePagination, setProfilePagination ] = useState(defaultPagination)


  // Methods

  // change tabs
  const handleTabClick =(value) => {
    if (value > 0 && value < 4) setTab(value)
  }


  // Effects

  // set searchTersm and clear existing results
  useEffect(() => {
    // keep existing terms if using pagination
    if (terms === null) return
    // clear data
    setBegs([])
    setSuccesses([])
    setProfiles([])
    // reset pagination
    setProfilePagination(defaultPagination)    
    setPagination(defaultPagination)
    setSuccessPagination(defaultPagination)    
    // set the new search terms
    setSearchTerms(terms)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[terms])


  // Methods
  
  // find raises
  const fetchRaises = async (searchTerms) => {
    try { 
      const options =  {
        method: 'post',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/begs/search?page=${pagination.current}&limit=${pagination.limit}`
      }
      const response = await execute({terms: searchTerms}, options)
      setBegs(prev => (prev.concat(response.results)) )
      setPagination(prev => ({...prev, ...response.pagination}))  
    } catch(error) { throw error } 
    finally { setPagination(prev => ({...prev, loading:false})) }
  }

  // find success stories
  const fetchSuccesses = async (searchTerms) => {
    try { 
      const options =  {
        method: 'post',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/success/search?page=${successPagination.current}&limit=${successPagination.limit}`
      }
      const response = await execute({terms: searchTerms}, options)
      setSuccesses(prev => (prev.concat(response.results)) )
      setSuccessPagination(prev => ({...prev, ...response.pagination}))  
    } catch(error) { throw error} 
    finally { setSuccessPagination(prev => ({...prev, loading:false})) }
  }


  // find user profiles
  const fetchProfiles = async (searchTerms) => {
    try { 
      const options =  {
        method: 'post',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/users/search?page=${profilePagination.current}&limit=${profilePagination.limit}`
      }
      const response = await execute({terms: searchTerms}, options)
      setProfiles(prev => (prev.concat(response.results)) )
      setProfilePagination(prev => ({...prev, ...response.pagination}))  
    } catch(error) { throw error} 
    finally { setProfilePagination(prev => ({...prev, loading:false})) }
  }


  // Search for begs by text string
  useEffect(() => {
    const fetchData = async (searchTerms) => {
      await fetchRaises(searchTerms)
      setIsLoading(false)
    }
    if (!searchTerms) return
    setIsLoading(true)
    fetchData(searchTerms)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current, searchTerms])


  // Search for success stories by text string
  useEffect(() => {
    const fetchData = async (searchTerms) => {
      await fetchSuccesses(searchTerms)
      setIsLoading(false)
    }
    if (!searchTerms) return
    setIsLoading(true)
    fetchData(searchTerms)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[successPagination.current, searchTerms])


  // Search for profiles by text string
  useEffect(() => {
    const fetchData = async (searchTerms) => {
      await fetchProfiles(searchTerms)
      setIsLoading(false)
    }
    if (!searchTerms) return
    setIsLoading(true)
    fetchData(searchTerms)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[profilePagination.current, searchTerms])


  return (
    <>
      { isLoading && pagination?.current === 1 && <WaitOverlay /> }
      { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving begs'} /> }

      <Container component="main" maxWidth="md">

        <PageTitle noWrap mb={0}> { `Search Results for... ${searchTerms}`} </PageTitle>


        {/* tab control */}

        <Grid container spacing={3} alignItems="center">
          <Grid item onClick={() => handleTabClick(1)}>
            { (tab === 1 && <PageSubTitle {...activeTab}> { `Raises (${pagination?.records || 0})` } </PageSubTitle>) || 
              <PageInactiveSubTitle {...inActiveTab}> { `Raises (${pagination?.records || 0})` } </PageInactiveSubTitle> 
            }
          </Grid>

          <Grid item onClick={() => handleTabClick(2)}>
            { (tab === 2 && <PageSubTitle {...activeTab}> { `Success Stories (${successPagination?.records || 0})` } </PageSubTitle>) ||
              <PageInactiveSubTitle {...inActiveTab}> { `Success Stories (${successPagination?.records || 0})` } </PageInactiveSubTitle>
            }
          </Grid>

          <Grid item onClick={() => handleTabClick(3)}>
            { (tab === 3 && <PageSubTitle {...activeTab}> { `Profiles (${profilePagination?.records || 0})` } </PageSubTitle>) ||
              <PageInactiveSubTitle {...inActiveTab}> { `Profiles (${profilePagination?.records || 0})` } </PageInactiveSubTitle>
            }
          </Grid>

        </Grid>


        <Paper elevation={0} sx={{ pt:1 }}>
          <Box sx={{ display:'flex', flexDirection:'column', alignItems:'left', padding:3 }} >

            {/* switch tab content */}

            { tab === 1  && <Raises    pagination={pagination} setPagination={setPagination} begs={begs}  /> }
            { tab === 2  && <Successes pagination={successPagination} setPagination={setSuccessPagination} begs={successes} /> }
            { tab === 3  && <Profiles  pagination={profilePagination} setPagination={setProfilePagination} profiles={profiles} /> }


            <AccessPageLower />

          </Box>
        </Paper>
        </Container>
      <BottomCards />
    </>
  )
}