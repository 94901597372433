import { format, subYears, isBefore } from 'date-fns'

const dateFormat = 'MM/dd/yyyy'
const minAge = process.env.REACT_APP_MIN_AGE

const getMaxBirthdate = () => {
  let newDate = subYears(new Date(), minAge)
  return format(newDate, dateFormat)
}

const checkUnderAgeLimit = (newDate) => {
  const birthdate = new Date(newDate)
  let minDate = new Date(getMaxBirthdate())
  console.log(birthdate + ' < ' + minDate)
  if (isBefore(minDate, birthdate)) {
    return true
  }
  return false
}

export {
  checkUnderAgeLimit,
  getMaxBirthdate
}