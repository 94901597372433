import Box from '@mui/material/Box'

// Images
import HeaderImage from './assets/publicHeader.png'

// Main compoment
export default function Header({mobile}) {
  return (
    <Box className="public-page-header"
      sx={{ 
        backgroundImage: `url(${HeaderImage})`,
        position: 'absolute',
        width: '100%',
        minHeight: '360px',
        left: 0,
        right: 0,
        top: 80,
        zIndex: -1,
        backgroundSize: 'cover'        
      }}
    >
    </Box>
  )
}