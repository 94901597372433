// Custom components
import StyledLink from "../../../components/styled-ui/StyledLink"

// Utils
import currFormat from '../../../utils/currFormat'
import formatDate from '../../../utils/formatDate'
import formatDuration from '../../../utils/formatDuration'
import upperFirst from '../../../utils/upperFirst'


// Main export
export const columns = [
  {
    field:"Title",
    width: 180,
    sortable: false,
    renderHeader: () => (<span className="columnHeader">{'Title'}</span>),
    valueGetter: (params) => {
      return (params.row.beg.title)
    }
  },
  {
    field:"User Name",
    width:140,
    renderHeader: () => (<span className="columnHeader">{'User Name'}</span>),
    headerClassName: "columnHeader",
    valueGetter: (params) => {
      return  `${upperFirst(params.row.user.firstName)} ${upperFirst(params.row.user.lastName)}`
    },
    renderCell: (params) => {
      return (
        <StyledLink to={`/profile/${params.row.user._id}`} > { `${upperFirst(params.row.user.firstName)} ${upperFirst(params.row.user.lastName)}` } </StyledLink>
      )
    }
  },
  { 
    field:"Beg Ended",
    width:110,
    type: 'date',
    renderHeader: () => (<span className="columnHeader">{'Beg Ended'}</span>),
    valueGetter: (params) => {
      return (formatDate(params.row.createdAt))
    } 
  },
  { 
    field:"Raised",
    width:100,
    sortable: false,
    headerClassName: "columnHeader",
    renderHeader: () => (<span className="columnHeader">{'Raised'}</span>),
    valueGetter: (params) => {
      return '$' + (currFormat(params.row.amountRaised, 2))
    } 
  },
  {
    field:"Method",
    sortable: false,
    renderHeader: () => (<span className="columnHeader">{'Method'}</span>),
    headerClassName: "columnHeader",
    valueGetter: (params) => {
      return params.row.paymethod.paytype.toUpperCase()
    }
  },
  {
    field:"Status",
    renderHeader: () => (<span className="columnHeader">{'Status'}</span>),
    valueGetter: (params) => {
      return params.row?.adminStatus || 'requested'
    }
  },
  {
    field:"Status Age",
    width:130,
    renderHeader: () => (<span className="columnHeader">{'Status Age'}</span>),
    valueGetter: (params) => {
      const baseDate = params.row?.updatedAt || params.row.createdAt
      return formatDuration(baseDate)
    }
  },
  {
    field:"Last Update",
    width:190,
    type: 'date',
    renderHeader: () => (<span className="columnHeader">{'Last Update'}</span>),
    valueGetter: (params) => {
      const baseDate = params.row?.updatedAt || params.row.createdAt
      return formatDate(baseDate, 'MMM dd, u ') + " at " + formatDate(baseDate, 'h:mm a')
    }
  },
  {
    field:"Action",
    width:70,
    sortable: false,
    renderHeader: () => (<span className="columnHeader">{'Action'}</span>),
    headerClassName: "columnHeader",
    renderCell: (params) => {
      const link = `/admin/withdrawals/details/${params.row._id}`
      return (
        <StyledLink to={link} > { 'Edit' } </StyledLink>
      )
    }
  },
]