import { useState, useEffect } from 'react'
import { Button, Grid, Paper, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import { Link, useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Custom components
import WaitOverlay      from '../../../components/WaitOverlay'
import ErrorDialog      from '../../../components/ErrorDialog'
import DataDisplayField from '../../../components/DataDisplayField'

// Page components
import ActivityLog  from './ActivityLog'
import ChipinsGrid  from './chipins-grid/index'
import CommentsGrid from './comments-grid'
import DetailsForm  from './DetailsForm'
import SuccessForm  from './SuccessForm'
import Stats        from './Stats'
import HtmlEditor   from './Editor'
import Media        from './Media'

// Apis
import { useApi } from '../../../apis/privateApiCall'

// Utils
import capFirstLetter   from '../../../utils/upperFirst'
import formatDate       from '../../../utils/formatDate'
import formatDuration   from '../../../utils/formatDuration'
import currFormat       from '../../../utils/currFormat'

// Icons
import LeftArrow  from '../../../assets/icons/leftArrow.svg'


// Main components
export default function Details() {
  // Params
  const { begId } = useParams()
  
  // Vars
  const { execute, controller, loading } = useApi()
  const theme = useTheme()
  
  // State
  const [data, setData] = useState()
  const [flags, setFlags] = useState()
  const [logs, setLogs] = useState(false)
  const [error, setError] = useState({
    message:'',
    errorCode: '',
    title: ''    
  })


  // Methods

  // format display for withdrawal status widget
  const withdrawalDisplay = (data) => {
    const baseDate = data?.updatedAt || data?.createdAt
    const status = data?.adminStatus || data?.status

    return (
      <>
        { (status && capFirstLetter(status)) || 'Not started' }
        <Typography
          variant='caption'
          mt="4px"
        >
          { (baseDate && formatDuration (baseDate) + ' ago') || '0 days ago' }
        </Typography> 
      </>
    )
  }

  // format display for flag status widget
  const flagStatusDisplay = (status, flags) => {
    let displayStatus = 'OK'
    if (status === true) displayStatus = "Flagged"
    const activeFlags = flags?.activeFlags || 0

    return (
      <>
        { displayStatus }
        <Typography
          variant='caption'
          mt="4px"
        >
          { `${activeFlags} active flags` }
        </Typography> 
      </>
    )
  }


  // call api to get activity logs
  const fetchActivityLog = async () => {
    try {
      const response = await execute({}, {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/admin/activity-logs/logtypes/beg/${begId}?sort=-createdAt`
      })
      setLogs(response)
    } catch(error) {
      throw error
    }
  }

  // call api to get flags
  const fetchContentFlags = async (item, id) => {
    try {
      const response = await execute({}, {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/content-flags?begId=${begId}`
      })
      setFlags(response?.results[0])
    } catch(error) {
      throw error
    }
  }

  // fetch main data
  const fetchBeg = async() => {
    try {
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/admin/begs/${begId}`
      }
      // send call to API
      const response = await execute({}, options)
      setData(response)
      await fetchContentFlags('beg', begId)
      await fetchActivityLog()
    } catch(error) {
      setError(prev => ({...prev,
        message: error.message,
        errorCode: '000',
        title: 'Error getting beg'
      }))
    }
  }


  // Load Effect
  useEffect(() => {
    fetchBeg()
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <Container component="main" maxWidth="lg">
      
      <Typography variant="h4" sx={{ color:'white', textAlign:'left', mb:4 }}>
        {'Raises > Raise Details'}
      </Typography>

      { loading && <WaitOverlay />}
      { error?.message && <ErrorDialog setError={setError} {...error} />}

      <Paper elevation={0}>

        <Box sx={{p:2}} >

          {/* save button */}
          <Grid container justifyContent="space-between" alignItems="center" mt={1} mb={3}>
            <Grid item>
              <Link to="/admin/begz" style={{ display:"block", textDecoration:"none", color:theme.palette.primary.main, marginBottom:"14px" }}>
                <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> Back to Raises
              </Link>
            </Grid>
            <Grid item>
              <Button variant='contained'>
                Save Changes
              </Button>
            </Grid>
          </Grid>


          <Grid container spacing={2}>

            {/* main stats and inputs */}
            <Grid item xs={12} sm={9}>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <DataDisplayField 
                    data={{ 
                      title:'Posted by',
                      link:`/profile/${data?.author._id}`,
                      value: (data?.author?.firstName && (capFirstLetter(data?.author?.firstName) + ' ' + capFirstLetter(data?.author?.lastName) ))
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <DataDisplayField 
                    data={{ 
                      title:'Published Timestamp',
                      value: (data?.createdAt && ( formatDate(data.createdAt, 'MMM dd, u ') + " at " + formatDate(data.createdAt, 'h:mm a') ))
                    }}
                  />
                </Grid>


                {/* editable form elements */}
                { data?._id && <DetailsForm data={data} /> }


                {/* bottom stats row */}
                <Grid item xs>
                  <DataDisplayField sx={{ height:'80px', p:1, pl:2, pr:2 }}
                    data={{ 
                      title:'Withdrawal status',
                      link: (data?.withdrawal?._id && `/admin/withdrawals/details/${data?.withdrawal?._id}`),
                      value: (data?._id && withdrawalDisplay(data?.withdrawal))
                    }}
                  />
                </Grid>

                <Grid item xs>
                  <DataDisplayField sx={{ height:'80px', p:1, pl:2, pr:2 }}
                    data={{ 
                      title:'Amount raised',
                      value: (data?.amountRaised && '$' + currFormat(data?.amountRaised, 2)) || "$0.00"
                    }}
                  />
                </Grid>

                <Grid item xs>
                  <DataDisplayField sx={{ height:'80px', p:1, pl:2, pr:2 }}
                    data={{ 
                      title:'Flags',
                      link: (data?.withdrawal?._id && `/admin/flagged-content/item/${data?._id}`),
                      value: (flags?._id && flagStatusDisplay(data.flagStatus, flags)) || flagStatusDisplay(false, {})
                    }}
                  />
                </Grid>


                {/* beg story */}
                <Grid item xs={12} mt={2}>
                  <Typography variant="subtitle4" fontWeight={550} pl={1}>
                    Beg story
                  </Typography>
                  { data?._id && <HtmlEditor data={data} /> }
                </Grid>


                {/* media */}
                <Grid item xs={12} sx={{ mt:3 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Media</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          { data?.videos && <Media data={data.videos} /> }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>


                {/* success story */}
                <Grid item xs={12}>
                  <Typography variant="h6" mt={2}>Success story</Typography>
                  { data?._id && <SuccessForm data={data} /> }
                </Grid>


                {/* stats section */}
                <Grid item xs={12} sx={{ mt:3 }}>
                  <Typography variant="h6">Stats</Typography>
                </Grid>

                { data?._id && <Stats data={data} /> }


                {/* Chip Ins */}
                <Grid item xs={12} sx={{ mt:3 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Chip Ins</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Paper elevation={1} sx={{ p:1, pt:0 }}>
                            { data?._id && <ChipinsGrid refresh={fetchActivityLog} begId={data._id} /> }
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>


                {/* Comments */}
                <Grid item xs={12} sx={{ mt:3 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6">Comments</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Paper elevation={1} sx={{ p:1, pt:0 }}>
                          { data?._id && <CommentsGrid refresh={fetchActivityLog} begId={data._id} /> }
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

              {/* activity section */}
              <Grid item xs={12} sm={3}>

                <Typography component="div" variant="subtitle3" mb={.5}>
                  Activity Log
                </Typography>

                { !!logs && <ActivityLog refresh={fetchActivityLog} data={logs} createdAt={data?.createdAt} begId={begId} /> }

              </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  )
}