import { useState } from 'react'
import { FormControl, MenuItem, Select } from "@mui/material"

// Apis
import { useApi } from '../../../apis/privateApiCall'

const STATUSES = ['requested','processing','completed']


// Main component
export default function StatusSelect(props) {
  // Vars
  const withdrawalId = props.id
  const refresh = props.refresh
  const { execute } = useApi()

  // State
  const [selected, setSelected] = useState(props?.selected || "")
  // const [error, setError] = useState({
  //   message:'',
  //   errorCode: '',
  //   title: ''    
  // })
  
  // Methods
  const handleChange = (event) => {
    updateWithdrawal(event.target.value)
    setSelected(event.target.value)
  }

  const updateWithdrawal = async(newStatus) => {
    try {
      const options =  {
        method: 'patch',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/admin/withdrawals/${withdrawalId}`
      }

      // send call to API
      await execute({
        editorId: localStorage.getItem('userId'),
        action: "status",
        adminStatus: newStatus 
      }, options)

      // reload the activity log
      await refresh()

    } catch(error) {
      // setError(prev => ({...prev,
      //   message: error.message,
      //   errorCode: '000',
      //   title: 'Error updating withdrawal status'
      // }))
    }
  }


  return (
    <FormControl fullWidth>
      <Select
        sx={{ fontSize:"13px", fontWeight:"600" }}
        name="statusSelect"
        value={selected}
        onChange={handleChange}
      >
        { STATUSES.map((item, index) => {
          return ( 
            <MenuItem key={index}
              sx={{ fontSize:"13px", fontWeight:"550" }}
              value={item}>{ item }
            </MenuItem> 
          ) 
        })}
      </Select>
    </FormControl>
  )
}