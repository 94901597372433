import { Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'


// Main component
export default function SectionDivider(props) {
  // Vars
  const theme = useTheme()
  const color = props?.color || theme.palette.primary.main
  
  // divider styles
  const dividerStyle = {
    background: theme.palette.primary.main,
    borderBottom:`1px solid ${color}`,
    maxHeight:"1px",
    marginBottom:"3em",
    marginTop:"3em"
  }

  // icon styles
  const iconStyle = {
    width: "34px",
    height: "34px",
    justifyContent: 'center',
    position: 'relative',
    bottom: '14px',
    color: color
  }

  return (
    <Divider style={{ ...dividerStyle, ...props?.dividerStyle}}>
      { props?.children || <ArrowDropDownIcon style={{ ...iconStyle, ...props?.iconStyle }}/> }
    </Divider>
  )
}