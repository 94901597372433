import React from 'react'


export default function MenuIcon(props) {
  return (
    <>
      <img alt="menuicon" {...props} style={{verticalAlign:"middle", height:"24px", marginRight:"8px"}} />
    </>
  )
}
