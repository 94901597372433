import Typography from '@mui/material/Typography'
import { Divider, Grid } from '@mui/material'

// Custom components
import SectionHeader from '../../../components/styled-ui/SectionHeader'

// Page components
import PreviewImage from './PreviewImage'


// Main component
export default function Tutorial({data}) {
  return (
    <>
      <Grid item>
        <Grid container spacing={2} direction="row" alignItems="flex-start" justifyContent="flex-start">
          <Grid item xs={4.5} >
            <PreviewImage src={data.image} onClick={data.handleClick} />
          </Grid>
          <Grid item xs>
            <SectionHeader>
              { data.title }
            </SectionHeader>
            <Typography mt={2} >
              { data?.body }
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      { data?.divider && <Grid item xs={12}><Divider /></Grid> }
    </>
  )
}