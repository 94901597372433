import { createTheme } from '@mui/material/styles'

// Create UI theme for entire app
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#D1337F",
    },
    secondary: {
      main: "#1B7785"
    },
    teritary: {
      main: "#D1C249"
    },
    gray: {
      main: "#3B3E44",
      secondary: "#7B7B7B",
      light: "#c5c5c5"
    },
    blue: {
      main: "#1FBAD2",
      info: "#A9E4ED"
    }
  },
  typography: {
    fontFamily: [
      'Lato'
    ].join(','),
    fontSize: 14,
    link: {
      textDecoration: 'underline',
      fontSize: 14,
      color: "#D1337F"
    },
    button: {
      textTransform: 'none',
      borderBottomLeftRadius: 24,
      fontWeight: 700
    },
    h2: {
      fontSize: 32,
      fontWeight: 700,
      color: '#3B3E44'
    },
    h7: {
      display: 'block',
      fontSize: 26,
      fontWeight: 700,
      color: '#3B3E44'
    },
    h5: {
      fontSize: 24,
      fontWeight: 550,
      color: '#3B3E44'
    },
    h6: {
      fontSize: 16,
      fontWeight: 700,
      color: '#3B3E44'
    },
    h8: {
      fontSize: 18,
      fontWeight: 700,
      color: '#3B3E44'
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 550,
      marginBottom: 5,
      color: '#3B3E44'
    },
    subtitle2: {
      fontSize: 13,
      fontWeight: 550,
      color: '#7B7B7B'
    },
    subtitle3: {
      fontSize: 13,
      fontWeight: 550,
      color: '#3B3E44'
    },
    subtitle4: {
      fontSize: 14,
      fontWeight: 600,
      color: '#3B3E44'
    },
    small: {
      display: 'block',
      fontSize: 12,
      fontWeight: 550,
      color: '#3B3E44'
    },
    small1: {
      display: 'block',
      fontSize: 11,
      fontWeight: 550,
      color: '#7B7B7B'
    },
    caption: {
      display: 'block',
      fontSize: 12,
      fontWeight: 550,
      color: '#3B3E44',
      padding :0,
      margin: 0,
      lineHeight:1.1
    },
    caption2: {
      display: 'block',
      fontSize: 12,
      fontWeight: 600,
      color: '#000000',
      padding :0,
      margin: 0,
      lineHeight:1
    },
    caption3: {
      display: 'block',
      fontSize: 12,
      fontWeight: 500,
      color: '#3B3E44',
      padding :0,
      margin: 0,
      lineHeight:1.1
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          fontSize: 16,
          fontWeight: 500,
          borderWidth: 2,
          whiteSpace: 'nowrap',
          padding: 6,
          paddingLeft: 24,
          paddingRight: 24,
          disableRipple: true,
          "&:hover": { borderWidth: 2 }
        }
      }
    }
  }
})

export {
  customTheme
}