import React from 'react'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuList from '@mui/material/MenuList'
import Stack from '@mui/material/Stack'
import { Divider } from '@mui/material'

// Page components
import MenuItemLink from './MenuItemLink'

// Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Admirable from   '../../../assets/icons/achievements/admirable.svg'
import Brilliant from   '../../../assets/icons/achievements/brilliant.svg'
import Hilarious from  '../../../assets/icons/achievements/hilarious.svg'
import Informative from '../../../assets/icons/achievements/informative.svg'
import Inspiring from   '../../../assets/icons/achievements/inspiring.svg'
import MenuIcon from './MenuIcon'


// Main component
export default function ExploreMenu (props) {
  // State
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)


  // Methods
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  }

  // menu close
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return
    setOpen(false)
  }
 
  // close the menu when an item is selected  
  const handleMenuClick = () => {
    setOpen(false)
  }


  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  
  // Effects
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) anchorRef.current.focus()
    prevOpen.current = open
  }, [open])


  return (
    <Stack direction="row" spacing={2}>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        Explore <ArrowDropDownIcon />
      </Button>

      <Popper
        sx={{ zIndex:2 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  sx={{ p:1, pb:2 }}
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onClick={handleMenuClick}
                >
                  <MenuItemLink to="/raises/categories/admirable">
                    <MenuIcon alt="Admirable" src={Admirable} />
                    Admirable Raises
                  </MenuItemLink>

                  <MenuItemLink to="/raises/categories/brilliant">
                    <MenuIcon alt="Brilliant" src={Brilliant} />
                    Brilliant Raises
                  </MenuItemLink>

                  <MenuItemLink to="/raises/categories/hilarious">
                    <MenuIcon alt="Hilarious" src={Hilarious} />
                    Hilarious Raises
                  </MenuItemLink>

                  <MenuItemLink to="/raises/categories/informative">
                    <MenuIcon alt="Informative" src={Informative} />
                    Informative Raises
                  </MenuItemLink>

                  <MenuItemLink to="/raises/categories/inspiring">
                    <MenuIcon alt="Inspiring" src={Inspiring} />
                    Inspiring Raises
                  </MenuItemLink>

                  <Divider sx={{ mt:1, mb:1 }} />

                  <MenuItemLink to="/recent">
                    Most Recent
                  </MenuItemLink>

                  <MenuItemLink to="/trending">
                    Trending
                  </MenuItemLink>

                  <MenuItemLink to="/success">
                    Success Stories
                  </MenuItemLink>

                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  )
}