import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'

// Components
import NumberBox from '../../../components/NumberBox'

// Icons
import MoneyBagFull from '../../../assets/icons/moneybag/full.svg'
import CheckIcon from '@mui/icons-material/Check'

// Main component
export default function CompletedHeader(props) {

  // Vars
  const data = props.data
  const theme = useTheme()
  const borderColor = theme.palette.gray.secondary
  const border = { border:'1px solid', borderColor:borderColor, borderRadius:'4px' }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container sx={{ border }} alignItems="center">
          <Grid item xs={7.5} p={1}>
            <img  alt="money bag" height="50px" style={{ marginRight:'6px', verticalAlign:'bottom' }} src={MoneyBagFull} />
            <Box sx={{ display:"inline-block"}}>
              <Typography variant="h7">
                ${ data.amountRaised }
              </Typography>
              <Typography variant="small">
                raised of a ${ data.goalAmount } goal
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4.5} p={1} sx={{ borderLeft:`1px solid ${borderColor}`}}>
            <Grid container justifyContent="center" spacing={1} alignItems="flex-start">
              <Grid item xs={12}>
                <Typography textAlign="center" variant='small1'>
                  Beg Complete
                </Typography>
                <Typography textAlign="center" variant='small'>
                  { data?.createdAt && format(new Date(data.createdAt), 'LLL d') }&nbsp; - &nbsp;
                  { data?.goalDate && format(new Date(data.goalDate), 'LLL d') }
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <NumberBox variant="small1" sx={{ textAlign:'center' }} number={data?.donors} />
                <Typography textAlign="center" variant='small'>donors</Typography>
              </Grid>
              <Grid item xs={6}>
                <NumberBox variant="small1" sx={{ textAlign:'center' }} number={data?.shares} />
                <Typography textAlign="center" variant='small'>shares</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container sx={{ backgroundColor:theme.palette.gray.light }} alignItems="center">
          <Grid item xs={7.5} p={1}>
            <Typography textAlign="center" sx={{ fontSize:13, fontWeight:550 }}>
              <CheckIcon sx={{ verticalAlign:'bottom', fontSize:18, marginRight:'4px' }} /> Funds Withdrawn
            </Typography>
          </Grid>
          <Grid item xs={4.5} p={1} sx={{ borderLeft:`1px solid ${borderColor}`}}>
            <Typography textAlign="center" sx={{ fontSize:11, fontWeight:550 }}>
              Withdrawl date: <br />
              { data?.fundsWithdrawn && format(new Date(data.fundsWithdrawn), 'MM/dd/yyyy') }
            </Typography>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}
