import { Divider, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// Custom components
import Adverts from './advertisement'
import BegerzGivesBack from './BegerzGivesBack'
import Features from './content-widgets/Features'
import ExploreCircles from './ExploreCircles'


// Main component
export default function AccessPageLower() {
  // Vars
  const theme = useTheme()
  
  return (
    <>
      <ExploreCircles mb={8} />

      {/* ads */}
      <Adverts style={{ marginBottom:'34px'}} />

      {/* gives back section */}
      <Typography variant='h4' fontWeight={600} mt={3} mb={3} >Why you should choose Begerz</Typography>
      <Divider style={{ background:theme.palette.blue.main, marginBottom:'24px' }}></Divider>
      <BegerzGivesBack color={theme.palette.blue.main} />

      <div style={{ marginTop:'2em' }} />

      <Features mb={4} />
    </>
  )
}
