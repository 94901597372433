import { Typography } from '@mui/material'


// Main component
export default function PageSubTitle(props) {
  return (
    <Typography
      fontFamily="Hey Comic"
      fontSize="26px"
      textAlign="left"
      color="white"
      mb={6}
      mt={0}
      {...props}
    >
      {props.children}
    </Typography>
  )
}
