import { callApi } from './callApi'


// Main export
export const useApi = () => {

  // defaults
  const defaults = {
    headers: { 'Content-Type': 'application/json' },
    method: 'post',
    withCredentials: true,
    baseURL: process.env.REACT_APP_AUTH_SERVER_URL,
    url: '/auth/v1/refresh'
  }

  // use callApi to get a new access token
  const execute = async (data, options) => {
    try {
      const config = {...defaults, ...options}
      const api = await callApi(data, config)
      return api?.output
    } 
    catch(error) {
      throw error
    }
  }

  // return the execute method
  return { execute }
}