import { 
  // useState, 
  forwardRef } from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'


export default function CustomAlert(props) {
  // State

  // Methods
  const handleAlertClose = () => {
    props.close()
  }

  // Alert component
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  return (
    <Snackbar open={true} autoHideDuration={6000} onClose={handleAlertClose}>
      <Alert onClose={handleAlertClose} severity="success" sx={{ backgroundColor:"green", color:"white", fontWeight:700, width:'100%' }}>
        Settings have been saved
      </Alert>      
    </Snackbar>
  )
}
