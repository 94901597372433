// Custom components
import StyledLink from "../../../components/styled-ui/StyledLink"

// Utils
import formatDate from "../../../utils/formatDate"
import upperFirst from "../../../utils/upperFirst"
import currFormat from "../../../utils/currFormat"

// Icons
import EditIcon from '@mui/icons-material/Edit'

// default column options
const defaultOptions = {
  flex: 1,
  minWidth: 100,
  type: 'string',
  headerClassName: 'grid-column-header',
  sortable: false,
  hideable: true,
  filterable: false,
  disableColumnMenu: true
}

// searchable column options
const searchOptions = {...defaultOptions, ...{
  minWidth: 140,
  sortable: true,
  filterable: true,
  disableColumnMenu: false
}}

// sortable column options
const sortOptions = {...searchOptions, ...{
  sortable: true,
  filterable: false
}}


// Main export
export const columns = [
  {
    ...searchOptions,
    field: 'Beg Title',
    searchField: 'title',
    minWidth: 220,
    valueGetter: (params) => params.row.title,
    renderCell: (params) => { return <StyledLink to={`/admin/begz/details/${params.row._id}`} > { `${upperFirst(params.row.title)}` } </StyledLink> }
  },

  {
    ...defaultOptions,
    field: 'User Name',
    valueGetter: (params) => `${upperFirst(params.row.author.firstName)} ${upperFirst(params.row.author.lastName)}`,
    renderCell: (params) => { return <StyledLink to={`/profile/${params.row.author._id}`} > { `${upperFirst(params.row.author.firstName)} ${upperFirst(params.row.author.lastName)}` } </StyledLink> }
  },

  {
    ...defaultOptions,
    field: 'End Date',
    searchField: 'goalDate',
    type: 'date',
    valueGetter: (params) => { return formatDate((params.row?.endedDate || params.row?.goalDate), 'MMM dd, u ')  }
  },

  {
    ...sortOptions,
    minWidth: 120,
    field: 'Raised',
    type: 'number',
    valueGetter: (params) => { return '$' + currFormat(params.row.amountRaised, 2) }
  },

  {
    ...defaultOptions,
    field: 'Chippers',
    type: 'number',
    valueGetter: (params) => { return params.row?.donors || 0 }
  },

  {
    ...defaultOptions,
    field: 'Shares',
    type: 'number',
    valueGetter: (params) => params.row?.shares || 0 
  },

  {
    ...sortOptions,
    field: 'Flagged',
    type: 'boolean',
    valueGetter: (params) => { return params.row?.flagged || false }
  },

  {
    ...searchOptions,
    field: 'Status',
    type: 'singleSelect',
    searchField: 'status',
    valueOptions: ({row }) => { return ['active','inactive','blocked','completed','draft','ended'] },
    valueGetter: (params) => { return upperFirst(params.row.status) }
  },

  {
    ...sortOptions,
    field:"Published",
    type: 'date',
    minWidth: 180,
    valueGetter: (params) => {
      const baseDate = params.row?.createdAt || params.row.createdAt
      return formatDate(baseDate, 'MMM dd, u ') + " at " + formatDate(baseDate, 'h:mm a')
    }
  },

  {
    ...searchOptions,
    field:"Privacy",
    type: 'singleSelect',
    searchField: "publishType",
    valueOptions: ({row}) => { return ['public','private','unlisted'] },
    valueGetter: (params) => { return upperFirst(params.row.publishType) }
  },

  {
    ...defaultOptions,
    field:"Admirable",
    type: 'number',
    valueGetter: (params) => { return params.row?.achievements?.admirable || 0 }
  },

  {
    ...defaultOptions,
    field:"Hilarious",
    type: 'number',
    valueGetter: (params) => { return params.row?.achievements?.hilarious || 0 }
  },

  {
      ...defaultOptions,
  field:"Brilliant",
    type: 'number',
    valueGetter: (params) => { return params.row?.achievements?.brilliant || 0 }
  },

  {
    ...defaultOptions,
    field:"Informative",
    type: 'number',
    minWidth: 120,
    valueGetter: (params) => { return params.row?.achievements?.informative || 0 }
  },

  {
    ...defaultOptions,
    field:"Inspiring",
    type: 'number',
    valueGetter: (params) => { return params.row?.achievements?.inspiring || 0 }
  },

  {
    ...defaultOptions,
    field:"Withdrawn",
    type: 'boolean',
    valueGetter: (params) => {
      if (params.row?.fundsWithdrawn) return true
      return false
    }
  },

  {
    ...defaultOptions,
    field: "Actions",
    hideable: false,
    disableColumnMenu: true,
    minWidth: 80,
    renderCell: (params) => {
      return (
        <>
          <StyledLink center="true" to={`/admin/begz/details/${params.row._id}`}><EditIcon /></StyledLink>
        </>
      )
    }
  }  
]