import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { useState } from "react";
import { callApi } from "./callApi";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_appId,
  measurementId: process.env.REACT_APP_FIREBASE_measurementId,
});

const provider = {
  google: new firebase.auth.GoogleAuthProvider(),
  facebook: new firebase.auth.FacebookAuthProvider(),
  apple: new firebase.auth.OAuthProvider("apple.com"),
};
provider.apple.addScope("email");
provider.apple.addScope("name");

export const firebaseAuth = firebase.auth();

// const defaults = {
//   headers: {
//     "Content-Type": "application/json",
//   },
//   method: "post",
//   withCredentials: true,
//   baseURL: process.env.REACT_APP_AUTH_SERVER_URL,
//   url: "auth/v1/login/socialAuth",
// };

export const useSignin = () => {
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  let controller;

  // Defaults
  const defaults = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    withCredentials: true,
    baseURL: process.env.REACT_APP_AUTH_SERVER_URL,
    url: "auth/v1/login/socialAuth",
  };

  const execute = async (authProvider, data = {}, options = {}) => {
    await firebaseAuth.signInWithPopup(provider[authProvider]);
    const idToken = await firebaseAuth.currentUser.getIdToken();
    console.log({ idToken });
    const reqData = { idToken, ...data };
    const config = { ...defaults, ...options };
    try {
      setIsLoading(true);
      // get the data and abort controller from the axios call
      const api = await callApi(reqData, config);
      controller = api.controller;

      // save the response in state
      setResponse(api.output);
      return api.output;
    } catch (error) {
      setError(error);
      throw error?.response || error;
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    error,
    response,
    controller,
    execute,
  };
};

export const signout = () => {
  firebaseAuth.signOut();
};
