import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid'


export default function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ fontSize:"12px" }}>
      <GridToolbarExport
        printOptions={{ 
          disableToolbarButton: true 
        }}
        csvOptions={{
          copyStyles: false,
          fields:['Title','Username','Beg Ended','Raised','Method','Status','Status Age','Last Update']
        }}
      />
    </GridToolbarContainer>
  )
}