/**
 * props: pagination={pagination state} props.setPagination={change pagination state}
 */

import { Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'


// Main component
export default function Pages(props) {
  // Vars
  const pagination = props.pagination
  const setPagination = props.setPagination
  const theme = useTheme()

 
  // Methods 

  // handle pagination on "more" click
  const handleMoreClick = () => {
    const value = pagination.next.page
    setPagination(prev => ({...prev, current: value}))
  }

  // component to render the more link 
  const RenderControl = () => {
    return (
      <Link to="" 
        style={{ display:'block', textDecoration:"none", textAlign:"center", color:theme.palette.primary.main }}
        onClick={handleMoreClick}
      >
        { props?.children || <> More <ArrowDropDownIcon color="primary" sx={{ display:'inline-block', verticalAlign:'bottom', textAlign:'right' }} /> </> }
      </Link>
    )
  }

  // render a divider is specified
  const RenderDivider = () => {
    if (props?.divider) return props.divider
  }


  return (
    <Grid item xs={12} sx={{ minHeight:'24px' }} >
      { (pagination?.pages > pagination?.current && <RenderControl /> ) || <RenderDivider /> }
    </Grid>
  )
}