import React from 'react'
import { Button, Grid, Dialog, DialogContent, Divider, Typography, Box, IconButton } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

// Components
import DialogHeader from '../../../components/DialogHeader'

// Icons
import blueInfo from '../../../assets/icons/blueInfo.svg'


// Main component
export default function EndRaise(props) {
  // Vars
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))


  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent sx={{ mb: 2 }}>

        <Box sx={{ textAlign:'left' }}>
          <DialogHeader sx={{ textAlign:'left', mb:3 }}>
            End Raise
          </DialogHeader>
        </Box>        

        <IconButton
          onClick={ () => { props.setOpen(false) }}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Divider sx={{ mb:2 }}></Divider>

        {/* info section */}
        <Grid container sx={{ backgroundColor:theme.palette.gray.light }} p={1} alignItems="center">
          <Grid item xs={1.5} >
            <img alt='info' height="54px" src={blueInfo}></img>
          </Grid>
          <Grid item xs={10.5}>
            <Typography
              textAlign="center"
              variant='bodyText'
              sx={{ fontWeight:550, paddingRight:1 }}
            >
              Ending your raise cannot be undone, and you will not be able to take donations anymore.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt:1, mb:1, fontWeight: 700 }}
              onClick={ () => { props.setOpen(false) } }
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt:1, mb:1, fontWeight: 700 }}
              onClick={ () => { props.endRaise() } }
            >
              Continue
            </Button>
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  )
}