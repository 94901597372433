import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// Custom components
import StyledLinkTo from '../../../components/styled-ui/StyledLink'


// Main component
export default function CoontinueLinks(props) {
  return (
    <Box
      sx={{
        marginTop: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        By continuing you agree to Begerz <StyledLinkTo to="/terms" >Terms of Use</StyledLinkTo>
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        By continuing you agree to Begerz <StyledLinkTo to="/privacy" >Privacy Policy</StyledLinkTo>
      </Typography>
    </Box>
  )
}
