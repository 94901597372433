import { Divider, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// Custom components
import ProfileAvatar from '../../../components/ProfileAvatar'

// Utils
import currFormat from '../../../utils/currFormat'
import formatDuration from '../../../utils/formatDuration'


// Main component
export default function Donation(props) {
  // Vars
  const data = props.data
  const navigate = useNavigate()
  let cursor = "pointer"


  // Methods
  
  // go to user profile
  let handleProfileClick = () => {
    navigate('/profile/' + data?.donor?._id)
  }

  // make comment unclickable if anonymous
  if (data?.anonymous === true) {
    cursor = "arrow"
    handleProfileClick = null
  }


  return (
    <Grid container spacing={1.5}>
      
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={2} mt={.5} mb={1} >
        <ProfileAvatar 
          onClick={handleProfileClick}        
          sx={{ width:32, height:32, fontSize:16, cursor:cursor }}
          alt={data.donor.username}
          src={data?.donor?.profileImage} 
        />
      </Grid>

      <Grid item xs={10} mb={1}>
        <Typography
          lineHeight="1"
          variant='subtitle3'
          sx={{ mt:.5, fontWeight:550, display:'block' }}
        >
          { (data?.anonymous && 'anonymous') || data?.donor.username || 'Unknown'}
        </Typography>

        <Typography
          variant="subtitle3"
          sx={{ fontWeight:550, mr:1, display:'inline-block' }}
        >
          ${ currFormat((data.amount - data.payorFees) || 0) }
        </Typography>
        
        <Typography
          variant="subtitle2"
          sx={{ mr:1, display:'inline-block' }}
        >
          { formatDuration(data.createdAt) || '15mins'}
        </Typography>

      </Grid>
    </Grid>
  )
}