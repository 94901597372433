import { useContext } from 'react'
import { useRefresh  } from './useRefresh'
import { AuthContext } from '../context/Auth'


export const useApi = () => {
  // Context
  const {
    isLoading,
    error,
    controller,
    response,
    executeCall
  } = useRefresh()

  // Context
  const { accessToken } = useContext(AuthContext)  

  // Defaults
  const defaults = {
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },    
    method: 'patch',
    baseURL: process.env.REACT_APP_AUTH_SERVER_URL + 'auth/v1'
  }

  const execute = async (data, options) => {
    // Set the url
    defaults.url = `/accounts/${data.userId}`
    const config = {...defaults, ...options}
    try {
      return await executeCall(data, config)
    } catch (error) {
      throw error
    } 
  }

  return {
    isLoading,
    error,
    response,
    controller,
    execute
  }
}