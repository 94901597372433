import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useNavigate } from "react-router-dom"
import { Button } from '@mui/material'

// Images
import Image from '../assets/403.png'

export default function NotAccess() {

  // Vars
  const navigate = useNavigate()

  return (
    <>
      <Typography variant='h3' sx={{ fontWeight:700, textAlign:'center', mt:8, mb:1 }}>
        No Access
      </Typography>
      <Typography variant='h6' sx={{ fontWeight:700, textAlign:'center', mb:6 }}>
        Sorry, you do not access to this page.<br />
        Please click on the Home tab to navigate back.
      </Typography>

      <Box sx={{ textAlign:'center', mb:7 }}>
        <img alt="403" width="286px" src={Image}></img>
      </Box>
        
      <Button
        variant='outlined'
        style={{margin: '0 auto', display:"flex"}}
        onClick={() => {navigate(`/home`, { replace: true })}}
      >
        Go Home
      </Button>

    </>
  )
}