/**
 * props:
 * itemId={begId} begId={begId} itemType={'beg'}
 */
import { useContext, useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'

// Icons
import OutlinedFlagRoundedIcon from '@mui/icons-material/OutlinedFlagRounded'
import FlagIcon from '@mui/icons-material/Flag'

// Apis
import { useApi } from '../../apis/privateApiCall'

// Components
import Confirmation from './dialogs/Confirmation'

// Context
import { AuthContext } from '../../context/Auth'


// Main component
export default function FlagContent(props) {
  // Vars
  const { execute, controller } = useApi()
  const theme = useTheme()
  const color = theme.palette.primary.main
  const userId = localStorage.getItem('userId')
  const { handleSignIn, loggedIn } = useContext(AuthContext)  
  let description = props?.itemType

  // change beg to raise for display
  if (props?.itemType === "beg") description = "raise"

  // State
  const [clicked, setClicked] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [flagged, setFlagged] = useState(true)
  const [reason, setReason] = useState('offensive')


  // Methods
    
  // update the status field
  const updateFlag = async () => {
    let status = 'active'
    if (flagged) status = 'inactive'
    const options =  {
      method: 'post',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/content-flags`
    }
    execute({
      status: status,
      itemType: props.itemType,
      flaggerId: userId,
      itemId: props.itemId,
      begId: props.begId,
      reason: reason
    }, options)
  }


  // toggle flag icon
  const toggleContentFlag = async () => {
    setDialogOpen(false)
    try {
      await updateFlag()
      setFlagged(!flagged)
    } catch {}
  }

  // flag content item. force login and use effect to continue if user is not logged in
  const confirmAction = async () => {
    setClicked(true)
    if (!loggedIn) return handleSignIn()
    setDialogOpen(true)
  }


  // Effects

  // if the user logs to flag content, show the dialog
  useEffect(() => {
    if (loggedIn === true && clicked === true) setDialogOpen(true)
  },[loggedIn, clicked])


  // get the inital flag state
  useEffect(() => {
    const getUserFlag = async () => {
      try {
        const options = {
          method: 'get',
          baseURL: process.env.REACT_APP_API_SERVER_URL,
          url: `/api/v1/content-flags/${props.itemType}/${props.itemId}/users/${userId}`
        }
        // update the flag
        const result = await execute({}, options)
        if (result.status === "active") {
          setFlagged(true)
          return
        }
        setFlagged(false)
      } 
      catch (error) {
        setFlagged(false)
      }
    }
    
    // return if user is not logged in
    if ( !userId ) {
      setFlagged(false)
      return
    } 
    
    getUserFlag()

    // clean up
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.itemId])


  return (
    <>
      { dialogOpen && 
        <Confirmation
          open={dialogOpen}
          handleClose={() => {setDialogOpen(false)}}
          handleConfirm={()=>{toggleContentFlag()}}
          description={description}
          setReason={setReason}
          reason={reason}
        /> 
      }

      { ( flagged && 
        <FlagIcon 
          sx={{ cursor:"pointer", color:color }} 
          onClick={toggleContentFlag} />)
        || 
        <OutlinedFlagRoundedIcon 
          sx={{ cursor:"pointer", color:color }} 
          onClick={confirmAction}
        />
      }
    </>
  )
}