import { useState, useEffect, useContext } from 'react'
import { Grid } from '@mui/material'

// Contexts
import { AuthContext } from '../../../context/Auth'

// Components
import AchievementCard from './AchievementCard'

// APIs
import { useApi } from '../../../apis/privateApiCall'

// Icons
import Admirable from '../../../assets/icons/achievements/admirable.svg'
import Brilliant from '../../../assets/icons/achievements/brilliant.svg'
import Hillarious from '../../../assets/icons/achievements/hillarious.svg'
import Informative from '../../../assets/icons/achievements/informative.svg'
import Inspiring from '../../../assets/icons/achievements/inspiring.svg'
  

// Main component
export default function Reactions(props) {
  // Vars
  const begId = props.data._id
  const userId = localStorage.getItem('userId')
  
  // Authorization context
  const { loggedIn } = useContext(AuthContext)  

  // APIs
  const execApi = useApi().execute

  // State
  const [reactions, setReactions] = useState()
  const [userReaction, setUserReaction] = useState()

  // Vars
  const achievements = [
    { name: 'Admirable', src: Admirable, field: 'admirable' },
    { name: 'Hilarious', src: Hillarious, field: 'hilarious' },
    { name: 'Informative', src: Informative, field: 'informative' },
    { name: 'Brilliant', src: Brilliant, field: 'brilliant' },
    { name: 'Inspiring', src: Inspiring, field: 'inspiring' }
  ]

  // Methods
  const fetchReactions = async (begId) => {
    const options =  {
      method: 'get',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/reactions/begs/${begId}/summary`
    }
    try { 
      const result = await execApi({}, options)
      setReactions(result.achievements)
    }
    catch(error) {}
  }

  // get reactions for this beg and user
  const fetchUserReaction = async (begId) => {
    const options =  {
      method: 'get',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/users/${userId}/reactions/begs/${begId}`
    }
    const result = await execApi({}, options)
    setUserReaction(result?.results[0]?.reactionType)
  }
  
  // refresh the beg reactions
  const refreshReactions = async () => {
    await fetchReactions(begId)
    await fetchUserReaction(begId)
  }

  // increment the reaction count
  const handleReaction = async (name) => {
    if (!loggedIn) return

    const options =  {
      method: 'post',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/reactions`
    }
    const data = {
      begId: begId,
      reactionType: name.toLowerCase(),
      userId: localStorage.getItem('userId')
    }
    await execApi(data, options)
    refreshReactions()
  }


  // Effects

  // Load any user reactions
  useEffect(() => {
    const fetchUserReaction = async (begId) => {
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/users/${userId}/reactions/begs/${begId}`
      }
      try { 
        const result = await execApi({}, options)
        setUserReaction(result?.results[0]?.reactionType)
      }
      catch(error) {
      } finally {
        setReactions(props.data.achievements)
      }
    }

    // only fetch user reactions if logged in
    if (!localStorage.getItem('userId')) {
      setReactions(props.data.achievements)
      return
    }

    fetchUserReaction(begId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[begId])


  return (
    <>
      { reactions && achievements.map((item, index) => {
        const count = reactions[item?.field] || 0
        return (
          <Grid key={index} item>
            <AchievementCard
              name={item.name}
              src={item.src}
              count={count} 
              userReaction={userReaction}
              begId={begId}
              userId={userId}
              clickFunction={ () => { handleReaction(item.name) } }
            /> 
          </Grid>
        )}
      )}    
    </>      
  )
}