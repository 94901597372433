import React, { useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Grid, Paper } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Page components
import Header from './components/Header'
import SidePanel from './components/SidePanel'
import StyledLink from '../../components/styled-ui/StyledLink'

// Error handling 
import WaitOverlay from '../../components/WaitOverlay'
import ErrorDialog from '../../components/ErrorDialog'

// Checkout steps
import StepOne from './components/StepOne'
import StepTwo from './components/StepTwo'
import Completed from './components/Completed'
import Comment from './components/Comment'

// CSS
import '../../css/CreateBeg.css'

// APIs
import { useBegDetails } from '../../apis/begDetails'
import { useApi } from '../../apis/privateApiCall'

// Icons
import LeftArrow from '../../assets/icons/leftArrow.svg'


// Create a context for handing functions to components
export const ChipInContext = React.createContext()


// Main component
export default function ChipIn() {
  // Router hook to get query params  
  const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  
  // Params
  const { begId } = useParams()

  // APIs
  const getBegDetails = useBegDetails().execute
  const getPayment = useApi().execute

  // Vars
  const navigate = useNavigate()
  const theme = useTheme()
  const query = useQuery()
  const paymentIntent = query.get('payment_intent')

  // State
  const [page, setPage] = React.useState(1)
  const [dialogStep, setDialogStep] = React.useState(3)
  const [data, setData] = React.useState({})
  const [comment, setComment] = React.useState()
  const [images, setImages] = React.useState()
  const [hasError, setHasError] = React.useState(false)
  const [waitOpen, setWaitOpen] = React.useState(true)
  const [totals, setTotals] = React.useState({
    payFees: false,
    anonymous: false,
    amount: 15,
    fees: 0,
    total: 15,
    customAmount: false,
    begId: begId,
    payorId: localStorage.getItem('userId'),
    initCalc: false
  })
  // eslint-disable-next-line no-unused-vars
  const [errorCode, setErrorCode] = React.useState({})


  // Methods

  // Next wizard step
  const goNextPage = () => {
    if (page === 4) return
    setPage(page => (page + 1))
  }

  // Prior wizard step
  const goPreviousPage = () => {
    if (page === 1) return
    setPage(page => (page - 1))
  }
  
  // Context
  const chipInContextValue = {
    totals,
    data,
    comment,
    setComment,
    setTotals,
    goNextPage,
    goPreviousPage,
    setDialogStep
  }

  // Set the component for the wizard step
  function setStep(page) {
    switch (page) {
      case 1:
        return <StepOne />
      case 2:
        return <StepTwo />
      case 3:
        return <Completed />
      case 4:
        return <Comment />
      default:
        return <StepOne />
    }
  }
  
  // set the link for the next wizard step
  const setLink = (page) => {
    switch (page) {
      case 1:
        return (
          <Link to="" onClick={() => { navigate(-1) }}
            style={{ padding:'14px', display:'inline-block', textDecoration:"none", color:theme.palette.primary.main }}>
            <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> Back
          </Link>
        )
        case 2:
          return (
            <Link to="" onClick={() => { setPage(1) }}
              style={{ padding:'14px', display:'inline-block', textDecoration:"none", color:theme.palette.primary.main }}>
              <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> Back
            </Link>
          )
          case 4:
            return (
              <Link to="" onClick={() => { setPage(3) }}
                style={{ padding:'14px', display:'inline-block', textDecoration:"none", color:theme.palette.primary.main }}>
                <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> Back
              </Link>
            )
    
        default:
          return <></>
    }
  }
  

  // Effects

  // Effect to get Beg details
  useEffect(() => {
    const fetchData = async (begId) => {
      const data = await getBegDetails({id:begId})
      let items = []
      data.videos.map((item, i) => {
        return (
          items.push({ src: item?.thumbLink})
        )
      })
      setData(data)
      setImages(items)
    }

    fetchData(begId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[begId])
  

  // Effect to recover the payment information
  useEffect(() => {
    const fetchData = async (transactionId) => {
      const url = `/api/v1/payments?transactionId=${transactionId}`
      const api = await getPayment({}, { url:url })
      const data = api.results[0]

      if (data.payorFees > 0) {
        data.payFees = true
      }

      // set the totals to the payment record
      setTotals(prev => ({
        ...prev,
        payFees: data?.payFees || false,
        anonymous: data?.anonymous || false,
        customAmount: data?.customAmount || false,
        amount: ((parseInt(data?.amount) - parseInt(data.payorFees)) ) || 5
      }))

      setWaitOpen(false)
    }

    // return if the intent is empty
    if (paymentIntent === null) {
      setWaitOpen(false)
      return
    }

    fetchData(paymentIntent)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[paymentIntent])


  return (
    <>
      { waitOpen && <WaitOverlay />}
      { hasError && <ErrorDialog message={hasError} setError={setHasError} errorCode={errorCode} title={'There was an error updating your profile'} /> }

      <Container
        component="main"
        maxWidth="md"
      > 
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <Paper elevation={4}>

              { setLink(page) }

              <Box noValidate 
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  padding: 4,
                  paddingTop: 0
                }}
              >

                { !waitOpen && data?._id && Header(data, images) }

                <Grid container columnSpacing={0} mt={2}>
                  <Grid item xs={12} md={7}>
                    <Typography variant="h2">I want to chip in:</Typography>
                  </Grid>

                  <ChipInContext.Provider value={ chipInContextValue }>

                    { !waitOpen && setStep(page) }
                    { (!waitOpen && paymentIntent) && setStep(dialogStep) }

                  </ChipInContext.Provider>

                  <Typography variant='caption3' mt={6}>
                    By continuing, you agree with {` `}
                    <StyledLink to="" 
                      style={{ fontSize:"12px" }}
                      onClick={() => { window.open('/terms')}} 
                    >
                      Begerz Terms {` `}
                    </StyledLink>
                      &amp; {` `}
                    <StyledLink to="" 
                      style={{ fontSize:"12px" }}
                      onClick={() => { window.open('/privacy')}} 
                    >
                      Privacy Policy
                    </StyledLink>
                  </Typography>
                
                </Grid>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>

            { SidePanel({ data, totals }) }

          </Grid>

        </Grid>
      </Container>
    </>
  )
}