import { Divider, Grid, Paper, Typography } from '@mui/material'
import { format } from 'date-fns'
import StyledLink from '../../../components/styled-ui/StyledLink'

// Icons
import GuaranteeLock from '../../../assets/icons/guarantee_lock.svg'

// Custom components
import ProfileAvatar from '../../../components/ProfileAvatar'


// Main components
export default function SidePanel({...props}) {
  // Vars
  const { data, totals } = props

  return (
    <>
      <Paper elevation={4} sx={{ display:"none", p:2 }}>
        <Grid container justifyContent="space-around" alignItems="center" spacing={1} sx={{ fontSize: 13 }}>
          <Grid item xs={12}>
            <Typography noWrap variant='h6'>Your Chip In</Typography>
          </Grid>
          <Grid item xs={8}>
            Amount:
          </Grid>
          <Grid item xs={4} sx={{ textAlign:'right' }}>
            ${ totals.amount }
          </Grid>
          <Grid item xs={8}>
            Processing&nbsp;Fees:
          </Grid>
          <Grid item xs={4} sx={{ textAlign:'right' }}>
            ${ totals.fees }
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ mt:1, mb:1 }}></Divider>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6">Total:</Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign:'right' }}>
            <Typography variant="h6">
              ${ totals.total}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={4} sx={{ mt:0, p:2, pt:1 }}>
        <Grid container justifyContent="space-around" alignItems="center" spacing={0} sx={{ mt:1, fontSize:13 }}>
          <Grid item xs={12}>
            <Typography variant="h6">Selected Raise:</Typography>
            <Divider sx={{ mt:1, mb:1 }}></Divider>
          </Grid>
          <Grid item xs={12} sx={{ mb:2 }}>
            <Typography noWrap variant='h6'>{ data?.title }</Typography>
            <Typography noWrap variant='subtitle2'>Created { data?.createdAt && format(new Date(data?.createdAt), 'LLL do, yyyy') }</Typography>
          </Grid>

          <Grid container spacing={1} alignItems="center">
            <Grid item >
              <ProfileAvatar sx={{ mt:0, width:38, height:38, fontSize:16 }} alt={ data?.author?.username || 'unknown' } src={ data?.author?.profileImage } />
            </Grid>

            <Grid item >
              <Typography component="p" variant='subtitle'>{ data?.author?.username || 'unknown' }</Typography>
              <Typography variant='subtitle' sx={{ mb:0 }}>Beneficiary, Organizer</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ mt:1, mb:1 }}></Divider>
            <Typography component="div" variant='caption3' sx={{ mt:2, mb:2 }}>
              The beneficiary will receive the funds directly. Your funds are protected by the Begerz Gurantee. 
            </Typography>
          </Grid>

          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs={2}>
              <img alt="guarantee lock" src={GuaranteeLock}></img>
            </Grid>
            <Grid item xs={10}>
              <Typography variant='subtitle3' >Begerz Guarantee:</Typography>
              <Typography variant='caption3'>
                If something isn't right, we will refund your donation.
              </Typography>

                <StyledLink to="" 
                  style={{ fontSize:"12px" }}
                  onClick={() => { window.open('/terms')}} 
                >
                  Learn More
                </StyledLink>

            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
