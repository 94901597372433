import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import Pagination from '@mui/material/Pagination'

// APIs
import { useApi } from '../../../apis/privateApiCall'


// Main component
export default function Invited(props) {

  // APIs
  const { execute } = useApi()
  
  // Vars
  const dateFormat = 'MM/dd/yyyy'
  const begId = props.begId
  const sendInvitation = props.sendInvitation

  // State
  const [invitations, setInvitations ] = React.useState()
  const [pagination, setPagination ] = React.useState({
    current: 1,
    pages: 0,
    limit: 10,
    records: 0,
    loading: true
  })

  
  // Methods

  // resend the invitation
  const handleResend = (data) => {
    const reqData = {
      userId: data.userId,
      begId: data.begId,
      email: data.email,
      status: 'pending'
    }

    let reqArray = []
    reqArray[data._id] = reqData
    sendInvitation(reqArray)
  }

  // pagination
  const handlePagination = (event, value) => {
    setPagination(prev => ({...prev, current: value}))
  }


  // Effect to load initial data
  React.useEffect(() => {
    // get invitations to this beg
    const fetchInvitations = async (begId) => {
      // set api options
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/begs/${begId}/invitations?page=${pagination.current}&limit=${pagination.limit}&sort=-createdAt`
      }
      // call the api and show an alert
      try { 
        const data = await execute({}, options)
        setInvitations(data)
        setPagination(prev => ({...prev, ...data.pagination}))
      } catch(error) {
        throw error
      } finally {
        setPagination(prev => ({...prev, loading:false}))
      }
    }

    fetchInvitations(begId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current])


  // invitation component
  const Invitation = (props) => {

    const data = props.data

    return (
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={2}><Typography variant='subtitle1'>{ data?.createdAt && format(new Date(data.createdAt), dateFormat) }</Typography></Grid>
          <Grid item xs={5}><Typography variant='subtitle1'>{ data?.user?.username || data.email }</Typography></Grid>
          <Grid item xs={2}><Typography variant='subtitle1'>{ data.status }</Typography></Grid>
          <Grid item xs={3}>
            <Button
              sx={{ padding:'0', paddingLeft:1, paddingRight: 1 }} 
              variant="outlined"
              onClick={() => handleResend(data)}
              >
              Resend Invitation
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      { !pagination.loading && invitations?.results && invitations.results.map((item, index) => {
        return (<Invitation key={index} data={{...item, begId:begId}} />)
      })}

      {/* pagination */}

      <Grid container sx={{ mt:4 }} justifyContent="center">
        <Grid item>
          <Pagination
            disabled={!pagination?.next?.limit > 0}
            color="primary"
            count={pagination?.pages}
            onChange={handlePagination}
          />
        </Grid>
      </Grid>
    </>
  )
}