import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTheme } from '@mui/material/styles'

// Components
import NumberBox from '../../../components/NumberBox'

// Icons
import MoneyBagFull from '../../../assets/icons/moneybag/full.svg'

// Main component
export default function WithdrawalHeader(props) {

  // Vars
  const data = props.data
  const theme = useTheme()
  const borderColor = theme.palette.gray.secondary
  const border = { border:'1px solid', borderColor:borderColor, borderRadius:'4px' }

  // Methods
  const capFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container sx={{ border }} alignItems="center">
            <Grid item xs={7.4} sx={{ padding:1 }}>
              <img  alt="money bag" height="50px" style={{ marginRight:'6px', verticalAlign:'bottom' }} src={MoneyBagFull} />
              <Box sx={{ display:"inline-block"}}>
                <Typography variant="h7">
                  ${ data.amountRaised }
                </Typography>
                <Typography variant="small">
                  raised of a ${ data.goalAmount } goal
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4.6} p={1} sx={{ borderLeft:`1px solid ${borderColor}`}}>
              <Grid container justifyContent="center" spacing={1} alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography textAlign="center" variant='small1'>{ data?.status && capFirstLetter(data.status)}</Typography>
                  <Typography noWrap textAlign="center" variant='small'>
                    { data?.createdAt && format(new Date(data.createdAt), 'LLL d') }&nbsp; - &nbsp;
                    { data?.goalDate && format(new Date(data.goalDate), 'LLL d') }
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <NumberBox variant="small1" sx={{ textAlign:'center' }} number={data?.donors} />
                  <Typography textAlign="center" variant='small'>donors</Typography>
                </Grid>
                <Grid item xs={6}>
                  <NumberBox variant="small1" sx={{ textAlign:'center' }} number={data?.shares} />
                  <Typography textAlign="center" variant='small'>shares</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant='contained'
          onClick={() => {props.setDialog(1)}}
        >
          Withdraw Funds
        </Button>
      </Grid>
    </Grid>
  )
}
