import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// Icons
import Admirable from   '../../../assets/icons/achievements/admirable.svg'
import Brilliant from   '../../../assets/icons/achievements/brilliant.svg'
import Hilarious from  '../../../assets/icons/achievements/hilarious.svg'
import Informative from '../../../assets/icons/achievements/informative.svg'
import Inspiring from   '../../../assets/icons/achievements/inspiring.svg'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'
import PaidIcon from '@mui/icons-material/Paid'
import HelpIcon from '@mui/icons-material/Help'
import InfoIcon from '@mui/icons-material/Info'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

// Main components
export default function MobileDrawer() {
  // Vars
  const theme = useTheme()
  const navigate = useNavigate()

  const explore = [
    {title:'Admirable Raises', link:'/raises/categories/admirable', image:Admirable, alt:'admirable'},
    {title:'Brilliant Raises', link:'/raises/categories/brilliant', image:Brilliant, alt:'brilliant'},
    {title:'Hilarious Raises', link:'/raises/categories/hilarious', image:Hilarious, alt:'hilarious'},
    {title:'Informative Raises', link:'/raises/categories/informative', image:Informative, alt:'informative'},
    {title:'Inspiring Raises', link:'/raises/categories/inspiring', image:Inspiring, alt:'inspiring'},
  ]

  // navigate to menu link
  const handleClick = (link) => {
    navigate(link)
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return
    setDrawerOpen(open)
  }


  // State
  const [drawerOpen, setDrawerOpen] = useState(false)

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={ () => {window.open('https://www.begerz.com/help', '_blank')} }>
            <ListItemIcon><HomeIcon /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={ ()=>{handleClick('/raises/create')} }>
            <ListItemIcon><PaidIcon /></ListItemIcon>
            <ListItemText primary="Start a Raise" />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider />

      <List>
        {
          explore.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={ ()=>{handleClick(item.link)} }>

                { item?.image && 
                  <ListItemIcon>
                      <img alt={item?.alt} height="28px" width="28px" style={{ marginRight:"10px" }} src={item?.image} /> 
                  </ListItemIcon>
                }
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))
        }
      </List>
      
      <Divider />

      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={ ()=>{handleClick('/recent')} }>
          <ListItemIcon><EventAvailableIcon color="primary" /></ListItemIcon>
            <ListItemText primary="Most Recent" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={ ()=>{handleClick('/trending')} }>
            <ListItemIcon><TrendingUpIcon color="primary"/></ListItemIcon>
            <ListItemText primary="Trending" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={ ()=>{handleClick('/success')} }>
            <ListItemIcon><CheckCircleOutlineIcon color="primary"/></ListItemIcon>
            <ListItemText primary="Success Stories" />
          </ListItemButton>
        </ListItem>
      </List>


      <Divider />

      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={ ()=>{handleClick('/about')} }>
          <ListItemIcon><InfoIcon /></ListItemIcon>
            <ListItemText primary="About" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={ ()=>{handleClick('/help')} }>
            <ListItemIcon><HelpIcon /></ListItemIcon>
            <ListItemText primary="Help" />
          </ListItemButton>
        </ListItem>
      </List>

    </Box>
  )


  return (
    <>
      <MenuIcon 
        sx={{ cursor:'pointer', color:theme.palette.primary.main }}
        onClick={toggleDrawer(true)}
      />

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        {list()}
      </Drawer>
    </>
  )
}