import { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Chip, Grid } from "@mui/material"

// Custom components
import StyledLink from "../../../../components/styled-ui/StyledLink"

// Utils
import formatDate from "../../../../utils/formatDate"
import upperFirst from "../../../../utils/upperFirst"

// Apis
import { useApi as privateApiCall } from '../../../../apis/privateApiCall'

// CSS
import './grid.css'
// import currFormat from '../../../../utils/currFormat'


// Main component
export default function CommentsGrid(props) {
  // Vars
  const begId = props?.begId
  const { execute, controller } = privateApiCall()
  const pageOptions = [10,25,50,100]

  // Grid column definitions
  const columns = [
    {
      field:"User",
      width:160,
      sortable: false,
      renderHeader: () => (<span className="columnHeader">{'User'}</span>),
      valueGetter: (params) => {
        return `${params.row.author.username}`
      },
      renderCell: (params) => {
        const link = `/profile/${params.row.author._id}`
        return (
          <StyledLink to={link}> {upperFirst(params.row.author.firstName)} {upperFirst(params.row.author.lastName)} </StyledLink>
        )
      }
    },
    {
      field:"Comment",
      sortable: false,
      width:224,
      renderHeader: () => (<span className="columnHeader">{'Comment'}</span>),
      valueGetter: (params) => {
        return params.row.textDescription
      }
    },
    { 
      field:"Status",
      width:110,
      sortable: false,
      renderHeader: () => (<span className="columnHeader">{'Status'}</span>),
      valueGetter: (params) => {
        if (params.row?.flagged) return "Hidden"
        return "Enabled"
      },
      renderCell: (params) => {
        if (params.row?.flagged) return <Chip label="Hidden" color="default" variant="outlined" />
        return <Chip color="info" label="Enabled" />
      }
    },
    {
      field:"Date",
      sortable: false,
      width:200,
      type: 'date',
      renderHeader: () => (<span className="columnHeader">{'Date'}</span>),
      valueGetter: (params) => {
        return formatDate(params.row.createdAt, 'MMM dd, u ')
      }
    },
    {
      field:"Actions",
      sortable: false,
      width:80,
      renderHeader: () => (<span className="columnHeader">{'Actions'}</span>),
      headerClassName: "columnHeader",
      renderCell: (params) => {
        return (
          <Grid container spacing={1}>
            { 
              (params.row.flagged &&
                <Grid item>
                  <StyledLink to="">{'Clear flags'}</StyledLink>
                </Grid>
              ) ||
              <Grid item>
                <StyledLink to="">{'Hide'}</StyledLink>
              </Grid>
            }
          </Grid>
        )
      }
    }
  ]

  // State
  const [pagination, setPagination] = useState({ current:1, limit:10 })
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
    

  // Methods
 
  const fetchData = async() => {
    try {
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/begs/${begId}/comments?sort=-createdAt&page=${pagination.current}&limit=${pagination.limit}`
      }
      // send call to API
      const response = await execute({}, options)
      setRows(response.results)
      setPagination(prev => ({...prev, ...response.pagination}))
    } catch(error) {
      throw error
    } finally {
      setLoading(false)
    }
  }


  // call api to save new note
  // const createLogEntry = async(flagId,status,content) => {
  //   try {
  //     const options =  {
  //       method: 'post',
  //       baseURL: process.env.REACT_APP_API_SERVER_URL,
  //       url: `/api/v1/admin/flag-logs`
  //     }

  //     // send call to API
  //     await execute({
  //       flagId: flagId,
  //       editorId: localStorage.getItem('userId'),
  //       action: "status",
  //       status: status,
  //       note: content
  //     }, options)

  //     // reload the activity log
  //     await props.refresh()
  //   } catch(error) {}
  // }

  // update pagination, add 1 to a zero-based index
  const updatePagination = (newPage) => {
    newPage = parseInt(newPage) + 1
    setPagination(prev => ({ ...prev, current:newPage }))
  }

  // Load Effect
  useEffect(() => {
    setLoading(true)
    fetchData()

    // clean up
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current, pagination.limit])


  return (
    <DataGrid
      disableMultipleSelection={true}
      sx={{ border:"none", "& .MuiDataGrid-columnHeaders": { backgroundColor: "none" } }}
      autoHeight
      components={{}}
      rows={rows}
      getRowId={(row) => row._id}
      columns={columns}
      disableColumnMenu
      pagination
      pageSize={pagination?.limit}
      rowsPerPageOptions={ pageOptions }
      rowCount={ pagination?.records || 0 }
      paginationMode="server"
      getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
      onPageSizeChange={(pageSize) => ( setPagination(prev => ({ ...prev, ...{limit:pageSize, current:1} }))   )}
      onPageChange={(newPage) => ( updatePagination(newPage) )}
      onSelectionModelChange={(newSelectionModel) => { setSelectionModel(newSelectionModel) }}
      selectionModel={selectionModel}
      loading={loading}
      keepNonExistentRowsSelected
    />
  )
}