import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Paper, Box, Typography, Divider, Grid, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// Custom components
import ShareDialog from '../../../components/dialogs/ShareDialog'

// Icons
import MoneyBagIcon from '../../../assets/icons/moneyBag.svg'

// CSS
import '../css/BegSideBar.css'


// Main component
export default function BegSideBar({data}) {
  // Vars
  const navigate = useNavigate()
  const theme = useTheme()

  // convert amount in cents to dollars and cents
  const currFormat = (number) => {
    number = parseFloat(number).toFixed(0)
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // State
  const [shareOpen, setShareOpen] = useState(false)

  return (
    <Paper elevation={4}>

      { shareOpen && <ShareDialog shareOpen={shareOpen} setShareOpen={setShareOpen} begId={data._id} userId={localStorage.getItem('userId')} />}

      <Box sx={{ padding: '1rem' }}>
        <Grid container mb={2} spacing={1}>
          <Grid item xs={4}>
            <img src={data?.iconPath || MoneyBagIcon} className="moneyBagIcon" alt="goal progress" />
          </Grid>
          <Grid item xs={8}>
            <Typography component="span" variant="h5" sx={{ fontWeight:700, marginBottom:1 }}> ${ currFormat(data?.amountRaised || 0) }</Typography>
            {' '} raised of a ${ currFormat(data?.goalAmount ) || 0 } goal
          </Grid>
        </Grid>

        <Divider />

        <Typography mt={2} mb={2} align='center' component="h6" variant="body1">
          { data?.status === 'active' && <><strong>{ (data?.daysRemaining > -1 && data?.daysRemaining) || 0 }</strong> days left to contribute</> }
          { data?.status !== 'active' && <>This beg has ended</> }

          <Grid container mt={1} pb={1} spacing={3}>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant='outlined'
                sx={{ color:theme.palette.primary.main }}
                onClick={ () => { setShareOpen(true) } }
              >
                Share
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={data.status !== 'active'}
                fullWidth
                variant='outlined'
                sx={{ color:theme.palette.primary.main }}
                onClick={() => { navigate(`/raises/chipin/${data._id}`) }}
              >
                Chip in
              </Button>
            </Grid>
          </Grid>
        </Typography>
    
        <Divider />
      
        <Grid container pt={1} pb={1} spacing={0}>
          <Grid item xs={6}>
            <Typography align='center' variant="h6" sx={{ fontWeight:700 }}>
              {data?.donors || 0}
            </Typography>
            <Typography align='center' variant="body2">
              donors
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography align='center' variant="h6" sx={{ fontWeight:700 }}>
              {data?.shares || 0}
            </Typography>
            <Typography align='center' variant="body2">
              shares
            </Typography>
          </Grid>
        </Grid>  

        <Divider />

        <Typography
          variant="body1"
          align='center'
          pt={2}
          sx={{ fontWeight: 700 }}
          >
            {data?.comments || 0} Comments
          </Typography>
      </Box>
    </Paper>
  )
}