import { useEffect, useState, useRef } from 'react'


// Main function
export const useKarma = (points) => {
  // Vars
  const levels = [199,499,1499,2999,5999,11999,23999,47999]
  const descriptions = ['Level 0', 'Level 1','Level 2','Level 3','Level 4','Total Consciousness','Level 6','Gold','Platinum','Emerald']
  const IconRef = useRef(null)

  // State
  const [loading, setLoading] = useState(true)
  const [karmaLevel, setKarmaLevel] = useState(0)
  const [description, setDescription] = useState('Level 0')

  // Load image dynamically
  useEffect(() => {
    
    // determine the karma level and image based on karma points
    const getKarmaImage = (points) => {
  
      let karmaLevel = 0
  
      levels.every(value => {
        if (points > value && karmaLevel < 8) {
          karmaLevel = karmaLevel + 1
        }
        setKarmaLevel(karmaLevel)
        setDescription(descriptions[karmaLevel])
        return karmaLevel
      })
      return `level_${karmaLevel}.svg`
    }

    // load the image file
    const loadImage = async (name) => {
      try {
        const { default: Image } = await import(`../assets/icons/karma-levels/${name}`)
        IconRef.current = Image
      } catch(error) {
        name = 'level_0'
        const { default: Image } = await import(`../assets/icons/karma-levels/${name}`)
        IconRef.current = Image
      } finally {
        setLoading(false)
      }
    }
    
    const imageName = getKarmaImage(points)
    loadImage(imageName)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[points])


  return {
    loading,
    karmaLevel,
    IconRef,
    points,
    description
  }
}