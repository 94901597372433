import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'
import { Button, Divider, Grid, Paper, Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { format } from 'date-fns'
import Pagination from '@mui/material/Pagination'

// Dialogs
import AccountsDialog from '../components/dialogs/AccountsDialog'

// Icons
import LeftArrow from '../assets/icons/leftArrow.svg'

// Error handling
import WaitOverlay from '../components/WaitOverlay'
import ErrorDialog from '../components/ErrorDialog'
import { Link } from 'react-router-dom'
import DialogHeader from '../components/DialogHeader'

// APIs
import { useApi } from '../apis/privateApiCall'

// Utils
import currFormat from '../utils/currFormat'


// Main component
export default function Payments() {
  // Vars
  const theme = useTheme()
  const userId = localStorage.getItem('userId')
  const dateFormat = 'MM/dd/yyyy'

  // APIs
  const { execute } = useApi()
  
  // State
  const [showAlert, setShowAlert] = React.useState(false)
  const [defaultMethod, setDefaultMethod] = React.useState({})
  // State for dialog box
  const [dialogProps, setDialogProps] = React.useState({open:false})

  const [isLoading, setIsLoading] = React.useState(true)
  const [withdrawals, setWithdrawals] = React.useState(false)
  const [pagination, setPagination ] = React.useState({
    current: 1,
    pages: 0,
    limit: 10,
    records: 0,
    loading: true
  })
  const [hasError, setHasError] = React.useState({
    status: false,
    message: '',
    code: 500
  })
  
  
  // Methods

  // get the default paymethod
  const fetchDefaultAccount = async (userId) => {
    // set api options
    const options =  {
      method: 'get',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/users/${userId}/paymethods?sort=-default&page=1&limit=1`
    }
    // call the api and show an alert
    try { 
      const data = await execute({}, options)
      setDefaultMethod(data?.results[0])
    } catch(error) {
      throw error
    } 
  }

  const handlePagination = (event, value) => {
    setPagination(prev => ({...prev, current: value}))
  }

  // opend the accounts dialog
  const handleManageAccounts = () => {
    setDialogProps(prev => ({
      ...prev,
      open:true,
      cancelDialog:cancelDialog,
      closeAndSave: closeAndSave,
      })
    )
  }
  
  // reset clipboard state so the alert can be re-displayed
  const handleAlertClose = () => {
    setShowAlert(false)
  }

  // close the dialog and reload the data
  const closeAndSave = async () => {
    setShowAlert(true)
    setDialogProps(prev => ({...prev, open:false}))
    fetchDefaultAccount(userId)
  }

  // close the dialog and reload the data
  const cancelDialog = async () => {
    setDialogProps(prev => ({...prev, open:false}))
  }


  // Components
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  // Effects
  useEffect(() => {
    // get the default paymethod
    const fetchWithdrawals = async (userId) => {
      // set api options
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/users/${userId}/withdrawals?sort=-createdAt&page=${pagination.current}&limit=${pagination.limit}`
      }
      // call the api and show an alert
      try { 
        const data = await execute({}, options)
        setPagination(prev => ({...prev, ...data.pagination}))
        setWithdrawals(data)
      } catch(error) {
        throw error
      } finally {
        setIsLoading(false)
      }
    }

    setIsLoading(true)
    fetchWithdrawals(userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current])


  useEffect(() => {
    fetchDefaultAccount(userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])



  return (
    <Container component="main" maxWidth="md">
      <Paper elevation={4} sx={{ padding:4 }}>

        { isLoading && <WaitOverlay /> }
        { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving begs'} /> }

        <Link to="/myprofile" style={{ display:'inline-block', textDecoration:"none", color:theme.palette.primary.main }}>
          <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> Dashboard
        </Link>

        {/* accounts dialog */}
        { dialogProps?.open && <AccountsDialog {...dialogProps} /> }


        <DialogHeader mt={2} mb={0}>Payments &amp; Withdrawals</DialogHeader>
        <Typography variant='subtitle1'>Edit your accounts and set your default account for receiving payments</Typography>

        <Divider sx={{ mt:2, mb:2 }} />

        <Typography variant='h6'>
          Active Payment Account
        </Typography>

        <Grid container alignItems="center" justifyContent="space-between" spacing={1} sx={{ mt:2 }}>
        { defaultMethod?._id && 
          <>
            <Grid item>
              { defaultMethod.paytype.toUpperCase() } | { defaultMethod.description }
            </Grid>
          </>
          }

          <Grid item>
            <Button
              sx={{  }}
              variant="outlined"
              onClick={handleManageAccounts}
            >
              Manage Accounts
            </Button>
          </Grid>
        </Grid>

        <Divider sx={{ mt:2, mb:2 }} />
        

        {/* list of accounts */}

        <Typography variant='h6'>
          Withdrawals
        </Typography>


        <Grid container spacing={.5} sx={{ mt:3 }}>
          <Grid item mb={1} xs={12}>
            <Grid container>
              <Grid item xs={2}><Typography noWrap variant='caption2'>Date</Typography></Grid>
              <Grid item xs={2}><Typography noWrap variant='caption2'>Beg Title</Typography></Grid>
              <Grid item xs={2}><Typography noWrap variant='caption2'>Raised</Typography></Grid>
              <Grid item xs={2}><Typography noWrap variant='caption2'>Withdrawn</Typography></Grid>
              <Grid item xs={2}><Typography noWrap variant='caption2'>Chipped-In</Typography></Grid>
              <Grid item xs={2}><Typography noWrap variant='caption2'>Donated</Typography></Grid>
            </Grid>
          </Grid>
        </Grid>

        { withdrawals?.results && withdrawals.results.map((item, index) => {
          return (
            <Grid item key={index} xs={12} sx={{ mb:1 }}>
              <Grid container spacing={1}>
                <Grid item xs={2}><Typography variant='subtitle1'>{ item?.createdAt && format(new Date(item?.createdAt), dateFormat) }</Typography></Grid>
                <Grid item xs={2}><Typography noWrap variant='subtitle1'>{ item?.beg[0].title }</Typography></Grid>
                <Grid item xs={2}><Typography variant='subtitle1'>{ item?.amountRaised && currFormat(item?.amountRaised, 2) }</Typography></Grid>
                <Grid item xs={2}><Typography variant='subtitle1'>{ item?.withdrawalAmount && currFormat(item?.withdrawalAmount, 2)}</Typography></Grid>
                <Grid item xs={2}><Typography variant='subtitle1'>{ item?.chipInAmount && currFormat(item?.chipInAmount, 2)}</Typography></Grid>
                <Grid item xs={2}><Typography variant='subtitle1'>{ item?.donationAmount && currFormat(item?.donationAmount, 2)}</Typography></Grid>
              </Grid>
            </Grid>
          )
          })
        }

        <Divider />

        {/* pagination */}

        <Grid container sx={{ mt:4 }} justifyContent="center">
          <Grid item>
            <Pagination
              disabled={!pagination?.next?.limit > 0}
              color="primary"
              count={pagination?.pages}
              onChange={handlePagination}
            />
          </Grid>
        </Grid>


        <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
            Settings have been updated!
          </Alert>
        </Snackbar>        

      </Paper>
    </Container>
  )
}