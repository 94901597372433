import { Grid, Typography } from '@mui/material'

// Icons
import Fees from '../../assets/content-widgets/features/fees.svg'
import Hands from '../../assets/content-widgets/features/hands.svg'
import Security from '../../assets/content-widgets/features/security.svg'


// Main component
export default function Features(props) {
  return (
    <Grid container spacing={6} {...props}>

      {/* low fees */}
      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={12}>
            <img alt="lowfees" src={Fees} height="43px" widht="43px" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" mb={1} fontWeight={700} >
              Low Fees
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Begerz has minimal fees, which means more of your money goes directly to the individual needing it most. We charge a 5.5% processing fee for using our platform, and .5% of that fee will be automatically donated to 1 of 4 charities pre-determined by Begerz. You're helping those in need without even knowing it!
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* safe & secure */}

      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={12}>
            <img alt="lowfees" src={Security} height="43px" widht="43px" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" mb={1} fontWeight={700} >
              Safe &amp; Secure
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Begerz is safe &amp; secure to use, with end to end encryption to secure data across the entire platform. You never have to worry about your information being compromised, with an entire team dedicated to your security at Begerz.
            </Typography>
          </Grid>
        </Grid>
      </Grid>


      {/* pay it forward */}

      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={12}>
            <img alt="lowfees" src={Hands} height="43px" widht="43px" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" mb={1} fontWeight={700} >
              Pay It Forward
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              The Begerz community has collectively donated over $28,000 dollars to charity. Every time you Chip In on Begerz, you're automatically donating, without even thinking about it. That's the best part, helping those in need without all the hassle!
            </Typography>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}
