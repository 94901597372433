import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Button, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material"

// Custom components
import DialogHeader from "../../../components/DialogHeader"

// Icons
import CloseIcon from '@mui/icons-material/Close'
import StyledLink from "../../styled-ui/StyledLink"
// import { useState } from "react"


// Main component
export default function Confirmation(props) {
  // Vars
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const {open, handleClose, handleConfirm, description, setReason, reason} = props

  // Methods
  const handleChange = (event) => {
    setReason(event.target.value)
  }


  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
      fullScreen={fullScreen}
    >

      <DialogContent sx={{ mb:2 }}>
        <DialogHeader
          id="alert-dialog-title"
          sx={{ textAlign:'center', mb:3 }}
        >
          Report Content
          <Typography variant="body2">
            Are you sure you want to report this {description || 'raise'} for <br />violating our {` `} 
            <StyledLink to=""
              onClick={() => {window.open('/terms', '_blank')}}
            >
              community guidelines</StyledLink>?
          </Typography>
        </DialogHeader>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>


        <DialogContentText sx={{ mt:4, mb:4 }}>
          <FormControl fullWidth>
            <InputLabel id="reason-select-label">Reason for reporting</InputLabel>
            <Select
              labelId="reason-label"
              id="reason-simple-select"
              value={reason}
              label="Reason for reporting"
              onChange={handleChange}
            >
              <MenuItem value="offensive">Offensive</MenuItem>
              <MenuItem value="prohibited">Prohibited</MenuItem>
              <MenuItem value="misleading">Misleading</MenuItem>
              <MenuItem value="violent">Violent</MenuItem>
              <MenuItem value="inappropriate">Inappropriate</MenuItem>
              <MenuItem value="political">Political</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
        </DialogContentText>

        <Divider />

        <Box sx={{ textAlign:'center' }}>
          <Button variant="contained" sx={{mt:3, mb:0, mr:1, fontWeight: 700}}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={{ mt:3, mb:0, fontWeight:700 }}
            onClick={handleConfirm}
          >
            Report Content
          </Button>
        </Box>

      </DialogContent>
    </Dialog>
  )
}