import { Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

// Icons
import MoreIcon from '../../assets/icons/more.svg'


// Main component
export default function Title(props) {
  // Vars
  const link = props?.link
  const data = props?.data

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="">

        {/* title */}
        <Grid item xs={10}>
          <Link to={ link } style={{ textDecoration:'none' }}>
            <Typography
              variant="subtitle1"
              noWrap
              sx={{ fontWeight:600, mb:0, minHeight:'22px' }}>
              { `${data?.username}` || 'Anonymous' }
            </Typography>
          </Link>
        </Grid>


        {/* profile link */}

        <Grid item xs={2}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Link to={ link }>
                  <img alt="more" style={{ cursor:'pointer' }} src={MoreIcon} />
                </Link>
              </Grid>
            </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}