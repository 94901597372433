import { Button, Grid } from '@mui/material'

// Custom components
import SuccessStory from '../../../components/success-stories/SuccessStory'
import SectionDivider from '../../../components/styled-ui/SectionDivider'
import More from '../../../components/pagination/More'


// Main component
export default function Successes(props) {
  // Vars
  const pagination = props.pagination
  const setPagination = props.setPagination
  const begs = props.begs


  return (
    <>
      <Grid container spacing={2} sx={{ mb:0 }}>
        { !pagination.loading && begs?.map((item, index) => {
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <SuccessStory data={item} />
            </Grid>
            )
          }) 
        }
      </Grid>          

      {/* pagination */}
      <Grid container sx={{ mt:0, mb:3 }}>
        <More
          pagination={pagination}
          setPagination={setPagination}
          divider={<SectionDivider />}
        >
          <SectionDivider>
            <Button variant='outlined' sx={{ mt:-4, backgroundColor:"white", "&:hover": { backgroundColor: "white !important" } }}>
              View More
            </Button>
          </SectionDivider>
        </More>
      </Grid>
    </>
  )
}