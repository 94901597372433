import { useState } from 'react'
import { Button, Grid } from '@mui/material'

// Dialogs
import ShareDialog from '../../../components/dialogs/ShareDialog'
import InviteDialog from '../../../components/dialogs/InviteDialog'
import EndRaise from '../dialogs/EndRaise'

// Apis
import { useApi } from '../../../apis/privateApiCall'


// Main component
export default function ShareBeg(props) {
  // Vars
  const data = props.data
  const setDialog = props.setDialog
  const { execute } = useApi()

  // State
  const [shareOpen, setShareOpen] = useState(false)
  const [inviteOpen, setInviteOpen] = useState(false)
  const [endOpen, setEndOpen] = useState(false)

  // Methods

  const updateRaise = async () => {
    // call API to update status
    const options =  {
      method: 'patch',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/begs/${data._id}`
    }
    const payload = { status: 'ended' }
    try {
      // set data and pagination
      await execute(payload, options)
    } 
    catch(error) {} 
    finally {
      props.refresh()
    }
  }


  const handleEndRaise = () => {
    setEndOpen(true)
  }

  // end the raise by setting the status and refreshing the state
  const endRaise = () => {
    setEndOpen(false)
    updateRaise()
  }


  return (
    <>
      { endOpen && 
        <EndRaise
          open={endOpen}
          setOpen={setEndOpen}
          endRaise={endRaise}
          begId={data._id} 
        /> 
      }

      { shareOpen &&  
        <ShareDialog
          shareText={"Check out my raise on Begerz!"}
          shareOpen={true}
          setShareOpen={setShareOpen}
          begId={data._id} 
        /> 
      }

      { inviteOpen &&  
        <InviteDialog
          helpText={"We recommend inviting at least 3-5 freinds.<br />Sharing on a social network can raise up to 5x more!"}
          shareText={"Check out this raise I just created on Begerz!"}
          shareOpen={true}
          setShareOpen={setInviteOpen}
          begId={data._id} 
        />       
      }

      <Grid item xs={12} sm={12} md={2.5}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>

            { data?.publishType !== "private" && 
              <Button
                onClick={() => {setShareOpen(true)}}
                fullWidth
                variant='outlined'
              >
                Share
              </Button>
            }

            { data?.publishType === "private" && 
              <Button
                onClick={() => {setInviteOpen(true)}}
                fullWidth
                variant='outlined'
              >
                Invite
              </Button>
            }
          </Grid>

          <Grid item xs={12}>
            { (props.data.amountRaised <= 0 && 
              <Button
                sx={{ textDecoration:'none' }}
                onClick={() => {handleEndRaise()}}
                fullWidth
                variant='text'
              >
                End Raise
              </Button>
            ) || (
              <Button
                sx={{ textDecoration:'none' }}
                onClick={() => {setDialog(1)}}
                fullWidth
                variant='text'
              >
                End &amp; Withdraw
              </Button>
              )
            }
          </Grid>

        </Grid>
      </Grid>
    </>
  )
}