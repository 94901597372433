import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Grid, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'

// Icons
import Logo from          './icons/logoWhiteText.svg'
import TwitterIcon from   './icons/twitter.svg'
import InstagramIcon from './icons/instagram.svg'
import FacebookIcon from  './icons/facebook.svg'

// Get context
import { AuthContext } from '../../context/Auth'

// Create Items
const Item = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  textDecoration: 'none',
  fontSize: '14px',
  lineHeight: '14px',
  padding: theme.spacing(0),
  textAlign: 'left',
  color: "#fff",
  fontWeight: 600
}))

const SmallerItem = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  textDecoration: 'none',
  fontSize: '10px',
  lineHeight: '10px',
  padding: theme.spacing(0),
  textAlign: 'left',
  color: "#fff",
  fontWeight: 400
}))


// Main component
export default function Footer() {
  // Context
  const { 
    handleSignIn,
    loggedIn,
    handleSignOut } = useContext(AuthContext)

  // Events
  const handleSignInClick = (event) => {
    event.preventDefault()
    handleSignIn()
  }

  // Components
  const Links = () => {
    return (
      <Grid container spacing={1} sx={{ paddingBottom:'24px' }}>
        <Grid item xs={4} >
          <Stack spacing={2} >
            <Item component={Link} to={'/trending'}>Trending</Item>
            <Item component={Link} to={'/raises/categories/brilliant'}>Brilliant</Item>
            <Item component={Link} to={'/raises/categories/hilarious'}>Hilarious</Item>
            <Item component={Link} to={'/raises/categories/admirable'}>Admirable</Item>
          </Stack>
        </Grid>
        <Grid item xs={4} >
          <Stack spacing={2}>
            <Item component={Link} to="/help" >Help</Item>
            <Item component={Link} to={'/about'}>About</Item>
          </Stack>
        </Grid>
        <Grid item xs={4} >
          <Stack spacing={2}>
            
            { (loggedIn && <Item onClick={handleSignOut} component={Link} to={''}>Sign Out</Item>) || <Item onClick={handleSignInClick} component={Link} to={''}>Sign In</Item>  }

            
            
            
            <Item component={Link} to={'/signup'}>Join</Item>
          </Stack>
        </Grid>
      </Grid>      
    )
  }

  const SocialIcons = () => {
    return (
      <Grid container justifyContent="flex-end" sx={{ paddingBottom:'24px' }}>
        <Grid item >
          <Stack direction="row" spacing={2.6}>
            <Item>
              <a target="_blank" rel="noreferrer" href="https://twitter.com/Begerz_official"> <img src={TwitterIcon} alt="Twitter" /> </a>
            </Item>
            <Item>
              <a target="_blank" rel="noreferrer" href="https://www.instagram.com/begerz_official/"> <img src={InstagramIcon} alt="Instagram" /> </a>
            </Item>
            <Item>
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/BegerzOfficial"> <img src={FacebookIcon} alt="Facebook" /> </a>
            </Item>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  const BottomLinks = () => {
    return (
      <Grid container>
        <Grid item xs={6}>
          <SmallerItem sx={{ marginRight:'24px' }} component={Link} to={'/terms'}>Terms &amp; Conditions</SmallerItem>
          <SmallerItem component={Link} to={'/privacy'}>Privacy Policy</SmallerItem>
        </Grid>
        <Grid item xs={6}>
          <SmallerItem sx={{ textAlign:'right' }}>{'© '}{new Date().getFullYear()}{' '}Begerz </SmallerItem>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className="footer" >
      <Grid container spacing={1} 
        sx={{ 
          paddingTop:'40px', 
          paddingBottom:'20px', 
          width:'100%', 
          maxWidth:'1020px', 
          marginLeft:'auto', 
          marginRight:'auto',
        }} 
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={4}  >
              <img src={Logo} alt="Logo" />
              <Typography variant='body2' sx={{ color: '#ffffff', marginBottom:'24px' }}>
                Begerz is the platform that harnesses <br />your charm, energy, and imagination<br />and lets you ask for support in style.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={7} md={6} lg={4}>
              <Links />
            </Grid>

            <Grid item xs={12} sm={5} md={12} lg={4}>
              <SocialIcons />
            </Grid>

            <Grid item xs={12}>
              <BottomLinks />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}