import { useKarma } from '../KarmaFlower'


// Main component
export default function Karma({points}) {
  // get karmaimage
  const karmaImage = useKarma(points).IconRef.current

  return (
    <>
      { karmaImage && <img height="auto" width="64" src={karmaImage} alt="karma flower" style={{ position:"relative", top:"-24px", marginBottom:"-36px" }} /> }
    </>
  )
}