import { useEffect, useState } from 'react'
import { Box, Container } from '@mui/system'
import { Paper, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

// Page components
import { columns } from './columns'
import CustomToolbar from './CustomToolbar'

// Apis
import { useApi as privateApiCall } from '../../../apis/privateApiCall'

// CSS
import './grid.css'


// Main component
export default function FlaggedContent() {
  // Vars
  const { execute, controller } = privateApiCall()
  const pageOptions = [10,25,50,100]

  // State
  const [pagination, setPagination] = useState({ current:1, limit:10 })
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
    

  // Methods

  // update pagination, add 1 to a zero-based index
  const updatePagination = (newPage) => {
    newPage = parseInt(newPage) + 1
    setPagination(prev => ({ ...prev, current:newPage }))
  }

  // Load Effect
  useEffect(() => {
    const fetchData = async() => {
      try {
        //const page = parseInt(pagination?.current) + 1
        const options =  {
          method: 'get',
          baseURL: process.env.REACT_APP_API_SERVER_URL,
          url: `/api/v1/content-flags?sort=-createdAt&page=${pagination.current}&limit=${pagination.limit}`
        }
        // send call to API
        const response = await execute({}, options)
        setRows(response.results)
        setPagination(prev => ({...prev, ...response.pagination}))
      } catch(error) {
        throw error
      } finally {
        setLoading(false)
      }
    }
    setLoading(true)
    fetchData()
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current, pagination.limit])


  return (
    <Container component="main" maxWidth="lg">
      <Typography variant="h4" sx={{ color:'white', textAlign:'left', mb:4 }}>
        Flagged Content
      </Typography>

      <Paper elevation={0}>
        <Box sx={{ display:'flex', p:2 }} >
          <DataGrid
            disableMultipleSelection={true}
            sx={{ border:"none", "& .MuiDataGrid-columnHeaders": { backgroundColor: "none" } }}
            autoHeight
            components={{ Toolbar: CustomToolbar }}
            rows={rows}
            getRowId={(row) => row._id}
            columns={columns}
            disableColumnMenu
            pagination
            pageSize={pagination.limit}
            rowsPerPageOptions={ pageOptions }
            rowCount={ pagination?.records || 0 }
            paginationMode="server"
            getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd' }
            onPageSizeChange={(pageSize) => ( setPagination(prev => ({ ...prev, ...{limit:pageSize, current:1} }))   )}
            onPageChange={(newPage) => ( updatePagination(newPage) )}
            onSelectionModelChange={(newSelectionModel) => { setSelectionModel(newSelectionModel) }}
            selectionModel={selectionModel}
            loading={loading}
            keepNonExistentRowsSelected
          />
        </Box>
      </Paper>
    </Container>
  )
}