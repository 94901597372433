import { useEffect, useState } from 'react'
import { Box, Container } from '@mui/system'
import { Paper, Typography } from '@mui/material'

// Custom components
import CustomDataGrid from '../../../components/data-grid'

// Page components
import { columns } from './columns'

// Apis
import { useApi as privateApiCall } from '../../../apis/privateApiCall'

// CSS
import '../../../css/Admin.css'


// Main component
export default function FlaggedContent() {
  // Vars
  const { execute, controller } = privateApiCall()

  // State  
  const [pagination, setPagination] = useState({ current:1, limit:10 })
  const [filter, setFilter] = useState("")
  const [rows, setRows] = useState([])
  // const [loading, setLoading] = useState(false)
    
  // Datagrid props
  const gridProps = {
    columns: columns,
    pageOptions: [10,25,50,100],
    rows: rows,
    pagination: pagination,
    setPagination: setPagination,
    setFilter: setFilter   
  }


  // Effects
  useEffect(() => {
    const fetchData = async(filter) => {
      // create the endpoint with filter and sort options      
      if (filter) filter = `${filter}&`
      let url = `/api/v1/admin/begs?${filter}sort=-createdAt&page=${pagination.current}&limit=${pagination.limit}`

      try {
        const options =  {
          method: 'get',
          baseURL: process.env.REACT_APP_API_SERVER_URL,
          url: url
        }
        // send call to API
        const response = await execute({}, options)
        setRows(response.results)
        setPagination(prev => ({...prev, ...response.pagination}))
      } catch(error) {
        throw error
      } finally {
        // setLoading(false)
      }
    }
    // setLoading(true)
    fetchData(filter)
    
    // run abort controller to clean up
    return controller

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current, pagination.limit, filter])


  return (
    <Container component="main" maxWidth="lg">
      <Typography variant="h4" className="page-title">
        {`Admin > Raises`}
      </Typography>

      {/* data grid */}
      <Paper elevation={0}>
        <Box sx={{ display:'flex', p:2 }} >
          <CustomDataGrid {...gridProps} />
        </Box>
      </Paper>
    </Container>
  )
}