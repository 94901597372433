import { Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

// Icons
import RightArrow from '../../assets/icons/rightArrow.svg'
import { Typography } from '@mui/material'


// Main component
export default function ViewAllLink(props) {
  // Vars
  const theme = useTheme()  
  const color = theme.palette.primary.main
  const textStyle = { 
    display:'inline-block',
    float:'right',
    textDecoration:'none',
    color:color,
    cursor: 'pointer'
  }
  const imageStyle = { marginLeft:'6px', verticalAlign:'top' }

  return (
    <Link to={props.to} {...props} style={textStyle}>
      <Typography 
        noWrap
        variant={props?.variant || 'subtitle4'}
        color={color}
      >
        { props.children || `View All` }
        <img style={imageStyle} alt="right arrow" src={RightArrow} /> 
      </Typography>
    </Link>
  )
}