import { Grid } from '@mui/material'
import React from 'react'
import MenuLink from '../components/MenuLink'

// Components
import SignInLink from '../components/SignInLink'
import StartBegButton from '../components/StartBegButton'

// Main component
export default function SignedOutItems() {
  return (
    <>
      <Grid item xs={3} sx={{ display: { xs:'none', md:'flex'} }}>
        { MenuLink('Help','/help')}
      </Grid>
      <Grid item xs={3} >
        <SignInLink />
      </Grid>
      <Grid item xs={6} 
        sx={{ textAlign:'right' }}
      >
        <StartBegButton />
      </Grid>
    </>
  )
}
