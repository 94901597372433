import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { customTheme } from "./Theme";
import { isMobile } from "react-device-detect";

// CSS
import "../src/css/App.css";

// Custom components
import NavMenu from "./components/menu/index";
import Header from "./components/header/Header";
import PublicHeader from "./components/header/PublicHeader";
import Footer from "./components/footer/Footer";
import RouteChange from "./components/header/RouteChange";
import ProtectedRoute from "./components/header/ProtectedRoute";
import ScrollToTop from "./components/header/ScrollToTop";
import ErrorHeader from "./components/ErrorHeader";
import MobileApp from "./components/dialogs/MobileApp";

// Pages
import Home from "./pages/Home";
import Trending from "./pages/Trending";
import Success from "./pages/Success";
import Recent from "./pages/Recent";
import TopCategories from "./pages/TopCategories";
import ResetPassword from "./pages/reset-password/ResetPassword";
import Search from "./pages/search/Search";
import SignUp from "./pages/sign-up/SignUp";
import BegCategory from "./pages/BegCategory";
import BegDetails from "./pages/beg-details";
import ChipIn from "./pages/chip-in/index";
import EditBeg from "./pages/edit-beg/EditBeg";
import NewBeg from "./pages/create-beg/CreateBeg";
import ForgotPassword from "./pages/ForgotPassword";
import About from "./pages/about/About";
import Profile from "./pages/Profile";
import MyProfile from "./pages/MyProfile";
import EditProfile from "./pages/edit-profile/EditProfile";
import Help from "./pages/help";
import Karma from "./pages/Karma";
import Payments from "./pages/Payments";
import BlockedUsers from "./pages/BlockedUsers";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

// Error pages
import NotFound from "./pages/404";
import NoAccess from "./pages/403";
import BadLink from "./pages/410";

// Admin pages
import AdminWithdrawal from "./pages/admin/withdrawals/index";
import AdminWithdrawalDetails from "./pages/admin/withdrawal-details/index";
import FlaggedContent from "./pages/admin/flagged-content/index";
import FlaggedItem from "./pages/admin/flagged-item/index";
import AdminSettings from "./pages/admin/settings/index";
import Begz from "./pages/admin/begz/index";
import BegzDetails from "./pages/admin/beg-details/index";
import { HelmetProvider } from "react-helmet-async";

// Main component
export default function App() {

  console.log(console.log('%c staging social-auth!!', 'font-weight: bold; font-size: 50px;color: red;'));
  // State
  const [mobileOpen, setMobileOpen] = React.useState(isMobile);

  // Methods

  // Close mobile app dialog
  const closeDialog = () => {
    setMobileOpen(false);
  };

  // Render private pages protected via login
  const Private = (Component) => {
    return (
      <>
        <Header />
        <ProtectedRoute component={Component} />
      </>
    );
  };

  // Render private pages protected via login
  const PrivatePublicHeader = (Component) => {
    return (
      <>
        <PublicHeader />
        <ProtectedRoute component={Component} />
      </>
    );
  };

  // Render admin pages protected via login and rights
  const Admin = (Component) => {
    return (
      <>
        <Header />
        <ProtectedRoute component={Component} rights={"admin"} />
      </>
    );
  };

  // Render public page
  const Public = (Component) => {
    return (
      <>
        <PublicHeader />
        <Component />
      </>
    );
  };

  // Render an error page
  const Error = (Component) => {
    return (
      <>
        <ErrorHeader />
        <Component />
      </>
    );
  };

  return (
    <HelmetProvider>
      <Router>
        {/* close signin dialog and other clean up if user navigates away */}

        <RouteChange />

        {/* automatically scroll pages to the top*/}

        <ScrollToTop />

        {mobileOpen && (
          <MobileApp dialogOpen={mobileOpen} close={closeDialog} />
        )}
        <Routes>
          <Route
            path="/"
            element={
              <ThemeProvider theme={customTheme}>
                <CssBaseline />
                <div className="content">
                  <NavMenu />
                  <Outlet />
                </div>
                <Footer />
              </ThemeProvider>
            }
          >
            {/* public routes */}

            <Route path="about" element={Public(About)} />
            <Route path="home" element={Public(Home)} />
            <Route path="recent" element={Public(Recent)} />
            <Route path="success" element={Public(Success)} />
            <Route path="trending" element={Public(Trending)} />
            <Route path="topcategories" element={Public(TopCategories)} />
            <Route path="help" element={Public(Help)} />
            <Route path="privacy" element={Public(Privacy)} />
            <Route path="signup" element={Public(SignUp)} />
            <Route path="terms" element={Public(Terms)} />
            <Route path="forgotpassword" element={Public(ForgotPassword)} />
            <Route path="search" element={Public(Search)} />
            <Route
              path="resetpassword/:tokenId"
              element={Public(ResetPassword)}
            />
            <Route path="raises/details/:begId" element={Public(BegDetails)} />
            <Route
              path="raises/categories/:category"
              element={Public(BegCategory)}
            />
            <Route path="profile/:userId" element={Public(Profile)} />
            <Route path="/" element={Public(Home)} />

            {/* protected routes */}

            <Route path="karma" element={Private(Karma)} />
            <Route path="payments" element={Private(Payments)} />
            <Route path="blockedList" element={Private(BlockedUsers)} />
            <Route path="myprofile" element={Private(MyProfile)} />
            <Route path="myprofile/edit" element={Private(EditProfile)} />
            <Route
              path="raises/chipin/:begId"
              element={PrivatePublicHeader(ChipIn)}
            />
            <Route
              path="raises/chipin/:begId/complete"
              element={PrivatePublicHeader(ChipIn)}
            />
            <Route path="raises/create" element={PrivatePublicHeader(NewBeg)} />
            <Route path="raises/edit/:begId" element={Private(EditBeg)} />

            {/* admin protected routes */}

            <Route path="admin/withdrawals" element={Admin(AdminWithdrawal)} />
            <Route
              path="admin/withdrawals/details/:withdrawalId"
              element={Admin(AdminWithdrawalDetails)}
            />
            <Route
              path="admin/flagged-content"
              element={Admin(FlaggedContent)}
            />
            <Route
              path="admin/flagged-content/item/:flagId"
              element={Admin(FlaggedItem)}
            />
            <Route path="admin/settings" element={Admin(AdminSettings)} />
            <Route path="admin/begz" element={Admin(Begz)} />
            <Route
              path="admin/begz/details/:begId"
              element={Admin(BegzDetails)}
            />

            {/* error pages */}

            <Route path="404" element={Error(NotFound)} />
            <Route path="403" element={Error(NoAccess)} />
            <Route path="410" element={Error(BadLink)} />
            <Route path="*" element={Error(NotFound)} />
          </Route>
        </Routes>
      </Router>
    </HelmetProvider>
  );
}
