import React, { useState } from 'react'
import * as axios from 'axios'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

// Dialogs
import ErrorDialog from './ErrorDialog'
import WaitOverlay from './WaitOverlay'

// Apis
import { useApi as useSignedUrl } from '../apis/getSignedUrl'

// Styles
const Input = styled('input')({ display: 'none' })


// Main Component
export default function UploadVideo(props) {
  // Vars
  let obUrl

  // APIs
  const execSignedUrl = useSignedUrl().execute
  
  // State
  const [isLoading, setIsLoading] = useState(false)
  const [videoError, setVideoError] = useState()
  const [showError, setShowError] = useState()
  const [fileObj, setFileObj] = useState()

  // ref to hold results
  const fileAttributes = React.useRef()


  // Methods
  
  // show video errors
  const handleError = (message) => {
    setVideoError(message)
    setShowError(true)
  }


  // get secure S3 upload link
  const getSecureLink = async () => {
    try {
      const result = await execSignedUrl()
      const linkAttribs = {
        uuid: result?.uuid,
        url: result?.url
      }

      // update the file attributes
      fileAttributes.current = {...fileAttributes.current, ...linkAttribs}

      return linkAttribs
    } catch(error) {
      throw error
    }
  }


  // save the video directly to the S3 bucket
  const putFile = async (link) => {
    await axios.put(link.url, fileObj, { headers: {'Content-Type': 'multipart/form-data'} }).then((response) => {
      return true
    }).catch(function(error) {
      handleError(`Error uploading file.`)
      return false
    })
  }    


  // check the duration pf the video
  const getVideoDuration = (event) => {
    const buffer = 2
    const maxDuration = parseInt(process.env.REACT_APP_MAX_VIDEO_DURATION) + buffer
    const videoDuration = Math.round(event.currentTarget.duration)
    URL.revokeObjectURL(obUrl)

    if (videoDuration > maxDuration) {
      handleError(`The video is too long. Maximum video length is ${process.env.REACT_APP_MAX_VIDEO_DURATION} seconds. This video is ${videoDuration} seconds.`)
      return false
    }
    return true
  }


  // open the upload dialog. sets fileObj and fileAttributes
  const handleUpload = (event) => {
    // get the file
    const fileInput = document.querySelector('#filename')
    const file = fileInput.files[0]

    // upload window was closed
    if (!file) return false
    
    // check size of file
    if (file.size > process.env.REACT_APP_MAX_VIDEO_SIZE) {
      handleError(`The video file is too large. Maximum size is ${process.env.REACT_APP_MAX_VIDEO_SIZE / 1000000} Megabytes.`)
      return false
    }
    // Use event to calc file size
    obUrl = URL.createObjectURL(file);
    document.getElementById('audio').setAttribute('src', obUrl)
    
    // Save upload file information
    setFileObj(file)
    fileAttributes.current = {...fileAttributes.current, localFileName:file.name, fileSize:file.size}
  }


  // execute the processes
  const executeProcesses = async (event) => {

    setIsLoading(true)
    
    // check video duration
    if (!getVideoDuration(event)) {
      setIsLoading(false)
      return false
    }

    let linkAttribs = {}

    // get the secure link and save update the fileAttriutes
    try {
      // get link
      linkAttribs = await getSecureLink()

      // upload the file to S3
      await putFile(linkAttribs)

      // execute the caller's success callback
      props.success(fileAttributes.current)

    } catch (error) {
      handleError(`Error creating upload process.`)
      return false
    } finally {
      setIsLoading(false)
    }
  }


  return (
    <>
      <audio id="audio"
        onError={(event) => { handleError("This file is invalid or has a format issue. Please check your video or try another file.") }}
        onCanPlayThrough={(event) => {executeProcesses(event)}}
      />

      { isLoading && <WaitOverlay />}
      { !!showError && <ErrorDialog message={videoError} setError={() => {setShowError(false)}} errorCode={'none'} title={'Video file error'} /> }

      <Box component="form" noValidate>
        <label htmlFor="filename">
          <Input
            value=""
            accept="video/*"
            name="filename"
            id="filename"
            multiple type="file" 
            onChange={(event) => {handleUpload(event)}}
          />

          { props.button }

        </label>
      </Box>
    </>
  )
}