import { useState, useContext, useEffect } from "react";
import { Divider, Grid, IconButton, Menu, Tooltip } from "@mui/material";

// Custom components
import ProfileAvatar from "../../ProfileAvatar";

// Page components
import AdminItems from "../menu-items/AdminItems";
import MenuLink from "../components/MenuLink";
import MenuItemLink from "../components/MenuItemLink";
import StartBegButton from "../components/StartBegButton";
// import Notifications from "../components/Notifications";

// Context
import { AuthContext } from "../../../context/Auth";
// import { useApi } from "../../../apis/privateApiCall";
// import WaitOverlay from "../../WaitOverlay";

// Main component
export default function SignedInItems() {
  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuRights, setMenuRights] = useState({
    customer: true,
    admin: false,
  });
  // const [notifications, setNotifications] = useState([]);

  // Vars
  const open = Boolean(anchorEl);
  const username = localStorage.getItem("username");
  // const { execute, isLoading } = useApi();
  // const { accessToken } = useContext(AuthContext);

  // Context
  const { handleSignOut, navProfileImage } = useContext(AuthContext);

  // Methods
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Effect to handle special menu rights
  useEffect(() => {
    const rights = localStorage.getItem("rights");
    if (rights?.includes("admin")) {
      setMenuRights((prev) => ({ ...prev, admin: true }));
    }
  }, [username]);

  // notification apiCall
  // const fetchData = async () => {
  //   try {
  //     const options = {
  //       method: "get",
  //       baseURL: process.env.REACT_APP_API_SERVER_URL,
  //       url: "api/v1/notification",
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     };
  //     const res = await execute({}, options);
  //     // setNotifications(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   // fetchData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {/* start a beg */}
      {/* {isLoading && <WaitOverlay />} */}

      <Grid
        item
        xs
        sx={{ textAlign: "left", display: { xs: "none", md: "flex" } }}
      >
        <StartBegButton />
        {MenuLink("Help", "/help")}
        {/* <Notifications fetchData={fetchData} notifications={notifications} /> */}
      </Grid>

      {/* about link */}

      {/* <Grid item xs sx={{ display: { xs:'none', md:'flex'} }} >
        { MenuLink('Help','/help')}
       
      </Grid> */}

      {/* account menu */}

      <Grid item xs sx={{ textAlign: "right", display: "block" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, mr: 1, p: 0 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <ProfileAvatar
              src={navProfileImage || localStorage.getItem("profileImage")}
              alt={username}
              sx={{ width: 37.33, height: 37.33, fontSize: 18 }}
            />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {/* admin menu items */}

          {menuRights?.admin && <AdminItems />}

          {/* customer menu items */}

          <MenuItemLink to="/myprofile">My Profile</MenuItemLink>
          <MenuItemLink to="/karma">
            My Chip-ins &amp; Karma Points
          </MenuItemLink>
          <MenuItemLink to="/payments">Payments &amp; Withdrawls</MenuItemLink>
          <MenuItemLink to="/blockedList">Blocked Users</MenuItemLink>
          <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
          <MenuItemLink to="/help">Help</MenuItemLink>
          <MenuItemLink to="/" onClick={handleSignOut}>
            Logout
          </MenuItemLink>
        </Menu>
      </Grid>
    </>
  );
}
