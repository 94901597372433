import React from 'react'
import WaitOverlay from '../components/WaitOverlay'
import ErrorDialog from '../components/ErrorDialog'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Paper, Typography } from '@mui/material'

// Custom components
import BegPagination from '../components/raises/BegPagination'
import SuccessPagination from '../components/success-stories/SuccessPagination'
import FeaturedSuccess from '../components/featured-success'
import FoundersChoice from '../components/founders-choice'
// import PageTitle from '../components/styled-ui/PageTitle'
import SectionHeaderContainer from '../components/styled-ui/SectionHeaderContainer'
import SectionDivider from '../components/styled-ui/SectionDivider'

// Bottom content widgets
// import Adverts from '../components/advertisement'
import BottomCards from '../components/BottomCards'
import AccessPageLower from '../components/AccessPageLower'


// Main component
export default function Home() {
  // Vars
  const delayMs = 20

  // State
  const [waitOpen, setWaitOpen] = React.useState(true)
  const [canLoad, setCanLoad] = React.useState({ trending: true})
  const [hasError, setHasError] = React.useState({
    status: false,
    message: '',
    code: 500
  })

  
  // Methods
  const delay = (time) => { return new Promise(resolve => setTimeout(resolve, time)) }
  
  // handle load order of data sections
  const handleLoaded = (index) => {
    switch (index) {
      case 'trending':
        delay(delayMs).then( () => { setCanLoad( prev => ( {...prev, active:true} )) })
        break
      case 'active':
        delay(delayMs).then( () => { setCanLoad( prev => ( {...prev, success:true} )) })
        break
      case 'success':
        delay(delayMs).then( () => { setCanLoad( prev => ( {...prev, recent:true} )) })
        break
      default:
        setWaitOpen(false)
    }
  }


  return (
    <>
      { waitOpen && <WaitOverlay />}
      { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving raises'} /> }

      <Container component="main" maxWidth="md">

        <Typography
          lineHeight="1.2"
          fontFamily="Hey Comic"
          fontSize="54px"
          textAlign="left"
          color="white"
          mb={6}
          mt={8}
        >
          Where Begerz<br />can be Choozers
        </Typography>

        <Paper elevation={0} >
          <Box sx={{ display:'flex', flexDirection:'column', alignItems:'left', padding:{ xs:1, sm:3} }} >

            {/* trending raises */}
            <SectionHeaderContainer mb={2} to="/trending">Trending</SectionHeaderContainer>
            { canLoad?.trending && <BegPagination doneLoading={handleLoaded} type={'trending'} url={`/api/v1/begs/trending?sort=proximity`} pageSize={3} /> }

            {/* active raises - top categories */}
            <SectionHeaderContainer mb={2} to="/topcategories" mt={2}>Active Raises - Top Categories</SectionHeaderContainer>
            { canLoad?.active && <BegPagination doneLoading={handleLoaded} type={'active'} url={`/api/v1/begs/top-categories?sort=-createdAt`} pageSize={3} /> }

            <SectionDivider />

            {/* success stories */}
            <SectionHeaderContainer mb={2} to="/success">Begerz Success Stories</SectionHeaderContainer>
            { canLoad?.success && <SuccessPagination doneLoading={handleLoaded} type={'success'} url={`/api/v1/success?sort=-createdAt`} pageSize={3} /> }

            <SectionDivider />

            {/* featured success stories */}
            <FeaturedSuccess />

            {/* ads */}
            {/*<Adverts style={{ marginBottom:'34px'}} />*/}

            {/* founders choice */}
            <FoundersChoice />

            <div style={{ marginTop:'2em' }} />

            {/* most recent */}
            <SectionHeaderContainer mb={2} to="/recent">Most Recent</SectionHeaderContainer>
            { canLoad?.recent && <BegPagination doneLoading={handleLoaded} type={'recent'} url={`/api/v1/begs/active?sort=-createdAt`} pageSize={3} /> }

            <SectionDivider />

            <AccessPageLower />

          </Box>
        </Paper>
      </Container>

      <BottomCards />
    </>
  )
}