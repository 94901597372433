import React from 'react'
import { Button, Grid, Dialog, DialogContent, Divider, Typography, Box, IconButton } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

// Components
import DialogHeader from '../../../components/DialogHeader'

// Utils
import currFormat from '../../../utils/currFormat.js'

// Icons
import checkMark from '../../../assets/largeCheck.svg'


// Main component
export default function StepThree(props) {
  // Vars
  const setDialog = props.setDialog
  const activeDialog = props.activeDialog
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      maxWidth="sm"
      open={activeDialog === 3}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent sx={{ mb: 2 }}>

        <Box sx={{ textAlign:'left' }}>
          <DialogHeader sx={{ textAlign:'left', mb:3 }}>
            Withdrawal Successful
          </DialogHeader>
        </Box>        

        <IconButton
          onClick={ () => { setDialog(2) }}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Divider sx={{ mb:2 }}></Divider>

        {/* info section */}

        <Grid container sx={{ backgroundColor:theme.palette.gray.light }} p={1} alignItems="center">
          <Grid item xs={1.5} >
            <img alt='chekmark' height="54px" src={checkMark}></img>
          </Grid>
          <Grid item xs={10.5}>
            <Typography
              textAlign="center"
              variant='bodyText'
              sx={{ fontWeight:550, paddingRight:1 }}
            >
              Transfer in progress.<br />Your funds should arrive in 5-7 days.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={3} mb={2} alignItems="center">
          <Grid item xs={6}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Typography variant='h6'>
                  Total Withdrawl
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* total withdrawl */}

          <Grid item xs={6}>
            <Grid container justifyContent="space-between" alignItems="flex-end">
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  sx={{ textAlign:"right", fontWeight:700 }}
                  >
                  ${ currFormat( props.totalWithdrawal, 2 ) }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt:1, mb:1, fontWeight: 700 }}
              onClick={ () => { setDialog(4) } }
            >
              Close
            </Button>
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  )
}