import Box from '@mui/material/Box'

// Main component
export default function Header() {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '234px',
        left: 0,
        right: 0,
        top: 80,
        zIndex: '-1',
        background: 'linear-gradient(291.54deg, #E42D84CC 12.18%, #FA288CCC 35.5%, #F84768CC 64.85%, #FA6A5BCC 89.38%)'
      }}
    >
    </Box>
  )
}