import { Grid } from '@mui/material'

// Page components
import SectionHeader from './SectionHeader'
import ViewAllLink from './ViewAllLink'


// Main component
export default function SectionHeaderContainer(props) {
  return (
    <Grid container justifyContent="space-between" {...props}>
      <Grid item>
        <SectionHeader>{props.children}</SectionHeader>
      </Grid>
      {
        props?.to &&
        <Grid item>
          <ViewAllLink to={props.to} />
        </Grid>
      }
    </Grid>
  )
}

