import Achievements from '../Achievements'
import { Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// Page components
import Title from './Title'
import ImageCarousel from '../ImageCarousel'
import NumberBox from '../NumberBox'
import ProfileAvatar from '../ProfileAvatar'

// Icons
import MoneyBag from    '../../assets/icons/moneybag/half.svg'
import VideoCamera from '../../assets/icons/videoCamera.svg'

// CSS
import '../../css/Beg.css'

// Utils
import currFormat from '../../utils/currFormat'


// Main component
export default function Beg({data}) {
  // Vars
  const navigate = useNavigate()
  const begLink = data.clickLink || `/raises/details/${data._id}`
  let items = []
  
  // look for empty video list
  if (data.videos.length === 0 ) {
    items.push({ src: null})
  }

  data.videos.map((item, i) => {
    return items.push({ src: item?.thumbLink})
  })

  // format the goal and raised amounts
  const renderAmounts = (data) => {
    const raised = currFormat(data.amountRaised)
    if (data.status === 'active') {
      const goal = currFormat(data.goalAmount)
      return `$${raised} of $${goal}`
    } else return `$ ${raised}`
  }
  
  const handleProfileClick = (id) => {
    if (id) navigate(`/profile/${id}`)
  }  

  return (
    <>
      {/* header */}
      <Grid container columnSpacing={1} sx={{ mb:.8, pl:"2px", pr:"2px"}}>

        {/* profile */}
        <Grid item xs={2}>
          <ProfileAvatar
            src={data.author?.profileImage}
            alt={data.author?.username}
            sx={{ width:37.33, height:37.33, fontSize:18, borderRadius:"8px", cursor:"pointer" }} 
            onClick={() => { handleProfileClick(data?.author._id) }}
          />
        </Grid>

        <Grid item xs={10}>
          <Grid container justifyContent="flex-end">

            {/* beg titlebar */}
            <Title data={data} begLink={begLink} />

            {/* username */}
            <Grid item xs={12} mt={-.5}>
              <Typography noWrap variant='subtitle2'>
                by { data.author?.username }
              </Typography>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

    
      {/* body */}
      <Grid container className="rounded-corners-borders" mb={2} sx={{ position:'relative' }} >

        {/* image carousel */}
        <Grid row="true" item xs={12}>
          { ImageCarousel(items, begLink) }
        </Grid>

        {/* bottom row background */}
        <Grid container justifyContent="center" alignItems="center" className="beg-footer">

          {/* money bag */}
          <Grid item xs={1.2}>
            <img alt="money bag" height="22px" width="19px" src={data?.iconPath || MoneyBag} style={{ float:"right", marginRight:"4px" }} />
          </Grid>

          <Grid item xs={4.8} >
            <Typography variant="caption2">
              { renderAmounts(data) }
            </Typography>
          </Grid>

          <Grid item xs={3} sx={{ marginTop:"3px"}}>
            { Achievements(data) }
          </Grid>

          <Grid item xs={1.8}>
            <img alt="video camera" src={VideoCamera} style={{ float:"right", paddingTop:'2px' }}/>
          </Grid>
          <Grid item xs={1.2}>
            <NumberBox variant="caption2" sx={{ textAlign:'left' }} number={ data.views || 0 } />
          </Grid>

        </Grid>
      </Grid>
    </>
  )
}