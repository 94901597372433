import { Divider, Typography } from '@mui/material'

// Page components
import MenuItemLink from '../components/MenuItemLink'


// Main component
export default function AdminItems(props) {
  return (
    <>
      <Typography variant="h6" ml={1.5} mb={1}>Admin:</Typography>

      <MenuItemLink onClick={props?.clickFunction} to="/admin/begz">Raises</MenuItemLink>
      <MenuItemLink onClick={props?.clickFunction} to="/admin/withdrawals">Withdrawals &amp; Payouts</MenuItemLink>
      <MenuItemLink onClick={props?.clickFunction} to="/admin/flagged-content">Flagged Content</MenuItemLink>
      <MenuItemLink onClick={props?.clickFunction} to="/admin/settings">Settings</MenuItemLink>

      <Divider sx={{ mt:1, mb:1 }} />
    </>
  )
}
