import React from 'react'
import { useTheme } from '@mui/material'
import { Link } from 'react-router-dom'

// Icons
import LeftArrow from '../../assets/icons/leftArrow.svg'

// Main component
export default function BackLink({ goPreviousPage }) {
  // Vars
  const theme = useTheme()

  return (
    <Link to="" 
      onClick={goPreviousPage}
      style={{ padding:'14px', paddingBottom:"0px", display:'inline-block', textDecoration:"none", color:theme.palette.primary.main }}>
      <img style={{ marginRight:'6px', verticalAlign:'middle' }} alt="left arrow" src={LeftArrow} /> Back
    </Link>
  )
}