import * as React from 'react'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import { useNavigate } from "react-router-dom"
import { ClickAwayListener, IconButton, Input } from '@mui/material'

// Icons
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'


// Main component
export default function NavSearch() {
  
  // Vars
  const navigate = useNavigate()

  // State
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [placement, setPlacement] = React.useState()
  const [value, setValue] = React.useState("")

  // Methods
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  }

  // close the search when user clicks away
  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  // clear the input
  const handleClearClick = () => {
    setValue("")
  }

  // perform the search
  const handleSubmit = (event) => {
    // stop normal form submission    
    event.preventDefault()
    // url encode the value
    if (value !== "") {
      const encoded = encodeURIComponent(value)
      navigate(`/search?terms=${encoded}`)
    }
  }

  return (
    <Box sx={{ width: 500 }}>

      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              { /* <form noValidate onSubmit={handleSubmit}> */ }
              <form noValidate onSubmit={handleSubmit}>

                <ClickAwayListener onClickAway={handleClose}>
                  <Input
                    autoFocus
                    placeholder='Search'
                    disableUnderline={true}
                    sx={{ pl:1, pr:1, fontSize:'14px'}}
                    onChange={(newValue) => {setValue(newValue.target.value)} }
                    value={value}
                    endAdornment={
                      <IconButton 
                        sx={{visibility: value? "visible": "hidden"}} 
                        onClick={handleClearClick}>
                        <ClearIcon/>
                      </IconButton>
                    }
                  />
                </ClickAwayListener>

              </form>
            </Paper>
          </Fade>
        )}
      </Popper>
      
      <IconButton 
        size="large"
        aria-label="search"
        color="primary"
        onClick={handleClick('right-start')}
      >
        <SearchIcon />
      </IconButton>
    </Box>
  )
}