import React from 'react'
import { Button, Grid, Typography } from '@mui/material'


// Main component
export default function ShareStory(props) {
  return (
    <Grid item xs={12} sm={12} md={2.5}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12}>
          <Typography textAlign="center" variant='h6'>Share your <br />Success Story!</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign="center" variant='small'>Submit a success story to be featured in Begerz community!</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={() => {props.setTab('4')}}
            fullWidth
            variant='outlined'
          >
            Share My Story
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}