import { Divider, Grid, Typography } from '@mui/material'
import React from 'react'

// Icons
import GuaranteeLock from '../../../assets/icons/guarantee_lock.svg'


// Main component
export default function SignInHeader() {
  return (
    <>
      <Divider />
      <Grid container sx={{ mt:2, mb:2 }} alignItems="center" justifyContent="flex-start">
        <Grid item xs={1.5}>
          <img alt="guarantee lock" src={GuaranteeLock}></img>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="subtitle2">
            This is a private beg and can only be seen by the owner or an invited community member.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}