import Button from '@mui/material/Button'
import { Container, Typography, Dialog, DialogContent, IconButton } from '@mui/material'
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// Icons
import CloseIcon from '@mui/icons-material/Close'
import dissatisfied from '../../../assets/icons/dissatisfied.svg'


// Main component
export default function NeedGuardian({ setDialogOpen, open }) {
  // Vars
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Methods
  const closeDialog = () => {
    setDialogOpen(false)
  }

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      fullScreen={fullScreen}
      fullWidth
    >
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 24,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Typography
          component="h1"
          variant="h7"
          color="gray.main"
          align='center'
          sx={{ marginBottom: 2}}
        >
          Uh oh!
        </Typography>

        <Typography
          component="h4"
          variant="subtitle1"
          color="gray.main"
          align='center'
          sx={{ marginBottom: 2}}
        >
          {"Looks like you need a parent or guardian to help you sign up."}
        </Typography>

        <Container align="center">
          <img src={dissatisfied} alt="Dissatisfied" />
        </Container>

        <Button
          component={Link}
          to="/trending"
          fullWidth
          variant="outlined"
          sx={{mt: 4, mb: 1, fontWeight: 700}}
        >
          View Trending Begs
        </Button>

        <Button
          component={Link}
          to="/home"
          fullWidth
          variant="outlined"
          sx={{mt: 2, mb: 1, fontWeight: 700}}
        >
          Go To the Home Page
        </Button>
      </DialogContent>
    </Dialog>
  )
}