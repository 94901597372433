import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Divider } from '@mui/material'
import { styled } from '@mui/material/styles'

// Custom components
import ErrorDialog from '../../../components/ErrorDialog'

// Page components
import Disclaimer from '../components/Disclaimer'

// Context
import { BegContext } from '../CreateBeg'

// Styles
const Input = styled('input')({
  display: 'none'
})


// Main component
export default function StepTwo() {
  // Vars
  let obUrl
  
  // State
  const [videoError, setVideoError] = useState()
  const [showError, setShowError] = useState()

  // Contexts
  const { 
    inputs,
    setInputs,
    setFileObj,
    goNextPage,
  } = useContext(BegContext)

  // Components
  const NextButton = () => {
    return (
      <>
      <Divider sx={{ mt: 2, mb: 2 }}> or </Divider>
      <Button
          type="button"
          fullWidth
          component="span"
          variant="outlined"
          onClick={goNextPage}
        >
          Next Step
        </Button>
      </>
    )
  }

  
  // Methods

  // error handler
  const handleError = (message) => {
    setVideoError(message)
    setInputs({...inputs, ...{localFileName: null, fileSize: 0} })
    setShowError(true)
  }

  // use html component to get video information
  const getVideoDuration = (event) => {
    const buffer = 2
    const maxDuration = parseInt(process.env.REACT_APP_MAX_VIDEO_DURATION) + buffer
    const videoDuration = Math.round(event.currentTarget.duration)
    URL.revokeObjectURL(obUrl)
    if (videoDuration > maxDuration) {
      handleError(`The video is too long. Maximum video length is ${process.env.REACT_APP_MAX_VIDEO_DURATION} seconds. This video is ${videoDuration} seconds.`)
      return false
    }
    goNextPage()
  }

  const handleUpload = (event) => {
    // Get the file
    const fileInput = document.querySelector('#filename')
    const file = fileInput.files[0]

    // Upload window was closed
    if (!file) {
      return false
    }
    if (file.size > process.env.REACT_APP_MAX_VIDEO_SIZE) {
      handleError(`The video file is too large. Maximum size is ${process.env.REACT_APP_MAX_VIDEO_SIZE / 1000000} Megabytes.`)
      return false
    }
    // Use event to calc file size
    obUrl = URL.createObjectURL(file);
    document.getElementById('audio').setAttribute('src', obUrl)
    
    // Save upload file information
    setFileObj(file)
    setInputs({...inputs, ...{localFileName: file.name, fileSize: file.size} })
  }

  
  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{ pb: 4 }}
    >

      <audio id="audio"
        onError={(event) => { handleError("This file is invalid or has a format issue. Please check your video or try another file.") }}
        onCanPlayThrough={(event) => {getVideoDuration(event)}}
      />

      { !!showError && <ErrorDialog message={videoError} setError={() => {setShowError(false)}} errorCode={'none'} title={'Video file error'} /> }

      <Typography variant="h2">Add a video</Typography>
      <Typography marginBottom={2} variant="subtitle1" >
        A great video helps convince people to chip in!
      </Typography>
        
      <Box
        component="form"
        noValidate sx={{ mt: 4, mb: 2 }}
      >
        <label htmlFor="filename">
          <Input
            value=""
            accept="video/*"
            name="filename"
            id="filename"
            multiple type="file" 
            onChange={(event) => {handleUpload(event)}}
          />

          <Button fullWidth component="span" variant="contained">
            Upload Video
          </Button>

          { !!inputs.localFileName && !videoError && <NextButton />}
          
        </label>
        
      </Box>
      <Disclaimer />
    </Container>
  )
}