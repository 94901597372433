import React, { useState } from 'react'
import { Button, Container, Dialog, DialogContent, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// Custom components
import DialogHeader from '../../components/DialogHeader'

// Icons
import largeCheck from '../../assets/largeCheck.svg'

// Context
import { AuthContext } from '../../context/Auth'


// Main component
export default function ResetCompleted() {
  // Vars
  const navigate = useNavigate()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  // Context
  const { handleSignIn } = React.useContext(AuthContext)

  // Use state
  const [dialogOpen, setDialogOpen] = useState(true)
  

  return (
    <Dialog
      maxWidth="xs"
      open={dialogOpen}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent>
        
        <DialogHeader sx={{ textAlign:'center' }}>
          You're all set!
          <Typography variant="body2">
            Your password has successfully been reset!
          </Typography>
        </DialogHeader>

        <Container align="center">
          <img src={largeCheck} alt="Checked" />
        </Container>

        <Button
          fullWidth
          variant="outlined"
          sx={{mt: 4, mb: 2, fontWeight: 700}}
          onClick={() => {
            setDialogOpen(false)
            handleSignIn()
          }}
        >
          Return to Sign In
        </Button>

        <Button
          fullWidth
          variant="outlined"
          sx={{mt: 2, mb: 2, fontWeight: 700}}
          onClick={() => {
            navigate('/trending')
          }}
        >
          View Trending Begs
        </Button>
        
        <Button
          fullWidth
          variant="outlined"
          sx={{mt: 2, mb: 4, fontWeight: 700}}
          onClick = {() => {
            navigate('/home')
          }}
        >
          Go to Home Page
        </Button>
      </DialogContent>
    </Dialog>
  )
}