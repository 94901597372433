import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'


// Main component
export default function Stats(props) {
  // Vars
  const data = props.data

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>

          <Grid item xs>
            <Paper elevation={1} sx={{ p:1 }}>
              <Typography component="div" variant="subtitle3" mb={.5}>
                Admirable
              </Typography>
              <Typography noWrap component="div" variant="h6">
                { data?.achievements.admirable }
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs>
            <Paper elevation={1} sx={{ p:1 }}>
              <Typography component="div" variant="subtitle3" mb={.5}>
                Hilarious
              </Typography>
              <Typography noWrap component="div" variant="h6">
                { data?.achievements.hilarious }
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs>
            <Paper elevation={1} sx={{ p:1 }}>
              <Typography component="div" variant="subtitle3" mb={.5}>
                Brilliant
              </Typography>
              <Typography noWrap component="div" variant="h6">
                { data?.achievements.brilliant }
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs>
            <Paper elevation={1} sx={{ p:1 }}>
              <Typography component="div" variant="subtitle3" mb={.5}>
                Informative
              </Typography>
              <Typography noWrap component="div" variant="h6">
                { data?.achievements.informative }
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs>
            <Paper elevation={1} sx={{ p:1 }}>
              <Typography component="div" variant="subtitle3" mb={.5}>
                Inspiring
              </Typography>
              <Typography noWrap component="div" variant="h6">
                { data?.achievements.inspiring }
              </Typography>
            </Paper>
          </Grid>

        </Grid>
      </Grid>


      {/* chippers / shares */}
      
      <Grid item xs={12}>
        <Grid container spacing={2}>

          <Grid item xs>
            <Paper elevation={1} sx={{ p:1 }}>
              <Typography component="div" variant="subtitle3" mb={.5}>
                Chippers
              </Typography>
              <Typography noWrap component="div" variant="h6">
                { data?.donors }
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs>
            <Paper elevation={1} sx={{ p:1 }}>
              <Typography component="div" variant="subtitle3" mb={.5}>
                Shares
              </Typography>
              <Typography noWrap component="div" variant="h6">
                { data?.shares }
              </Typography>
            </Paper>
          </Grid>

        </Grid>
      </Grid>
    </>
  )
}
