import Carousel from 'react-material-ui-carousel'
import MissingPhoto from '../assets/placeholder.png'
import { useNavigate } from "react-router-dom"

// CSS
import '../css/ImageCarousel.css'


// Carousel item
const Item = (props) => {
  const navigate = useNavigate()

  // add missing image on error
  const addDefaultSrc = (event) => {
    event.target.removeAttribute('onError')
    event.target.src = MissingPhoto
  }

  // go to beg on image click
  const handleImageclick = () => {
    navigate(props.begLink, { replace: false })
  }

  return (
    <img
      className='carousel-image'
      alt="video thumbnail"
      src={props?.item?.src || MissingPhoto}
      style={{ cursor:'pointer' }}
      onError={addDefaultSrc}
      onClick={handleImageclick}
    />
  )
}

// Carousel component 
export default function ImageCarousel(items, begLink) {
  return (
    <Carousel
      autoPlay={false}
      IndicatorIcon={ <div className='carousel-indicator-icon'></div> }
      indicatorContainerProps={{ 
        style: { 
          marginTop: '-20px' 
        } 
      }}          
      indicatorIconButtonProps={{
        style: {
          bottom: 50,
          zIndex: 1,
          marginLeft:'2px', 
          marginRight:'2px', 
          height:'10px',
          width:'10px'              
        }
      }}  
      activeIndicatorIconButtonProps={{
        style: { backgroundColor:'white' }
      }}                
    >
      { items && items.map((item, i) => {
        return <Item key={i} item={item} begLink={begLink} />
      })}
    </Carousel>
  )
}