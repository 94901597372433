import { useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// Custom components
import SignInForm from './SignInForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import EmailSentForm from './EmailSentForm'
import BoxSignUpLink from '../BoxSignUpLink'

// Icons
import CloseIcon from '@mui/icons-material/Close'


// Main component
export default function SignInDialogs({closeFunction}) {
  // Vars
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  // State
  const [activeDialog, setActiveDialog] = useState('signIn')
  
  // Methods
  const closeSignIn = (loginStatus) => {
    closeFunction(loginStatus)
  }

  const setDialog = (dialog) => {
    setActiveDialog(dialog)
  }

  const switchDialogs = () => {
    if (activeDialog === 'forgotPassword') return <ForgotPasswordForm setDialog={setDialog} />
    if (activeDialog === 'emailSent') return <EmailSentForm setDialog={setDialog} />
    return <SignInForm closeFunction={closeSignIn} setDialog={setDialog} />
  }

  return (
    <Dialog
      maxWidth="sm"
      open={true}
      fullScreen={fullScreen}
      fullWidth
    >
      <IconButton
        aria-label="close"
        onClick={closeSignIn}
        sx={{
          position: 'absolute',
          right: 8,
          top: 24,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>

        { switchDialogs() }

      </DialogContent>
      <BoxSignUpLink closeFunction={closeFunction}/> 
    </Dialog>
  )
}