import { Grid, Paper, Typography } from '@mui/material'

// Page components
import SideNote from './SideNote'

// Utils
import formatDate from '../../../utils/formatDate'


// Main component
export default function ActivityLog(props) {
  // Vars
  const formattedDate = formatDate(props.createdAt, 'MMM dd, u ') + " at " + formatDate(props.createdAt, 'h:mm a')
  const data = props.data
  const withdrawalId = props.withdrawalId


  return (
    <>
      <Grid container spacing={1}>

        <Grid item xs={12}>
          <Paper elevation={1} sx={{ p:1 }}>
            <Typography component="div" variant="subtitle3" fontWeight="600">
              User requested withdrawal
            </Typography>
            <Typography component="div" variant="small">
              { formattedDate }
            </Typography>
          </Paper>
        </Grid>

        { data?.results && data?.results.map((item, index) => {
          return ( 
            <Grid key={index} item xs={12}>
              <Paper elevation={1} sx={{ p:1 }}>
                <Typography component="div" variant="subtitle3" fontWeight="550">
                  { item.description }
                </Typography>
                <Typography component="div" variant="subtitle3" fontWeight="600">
                  { item.note }
                </Typography>
                <Typography component="div" variant="small">
                  { formatDate(item.createdAt, 'MMM dd, u ') + " at " + formatDate(item.createdAt, 'h:mm a') }
                </Typography>
              </Paper>
            </Grid>
          )
        })}

      </Grid>
      <SideNote refresh={props.refresh} withdrawalId={withdrawalId} />
    </>
  )
}
