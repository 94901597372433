// Custom components
import StyledLink from "../../../components/styled-ui/StyledLink"

// Utils
import formatDate from "../../../utils/formatDate"
import upperFirst from "../../../utils/upperFirst"


// Main export
export const columns = [
  {
    field:"Timestamp",
    sortable: false,
    width:200,
    type: 'date',
    renderHeader: () => (<span className="columnHeader">{'Timestamp'}</span>),
    valueGetter: (params) => {
      const baseDate = params.row?.updatedAt || params.row.createdAt
      return formatDate(baseDate, 'MMM dd, u ') + " at " + formatDate(baseDate, 'h:mm a')
    }
  },
  {
    field:"Content",
    width: 100,
    sortable: false,
    renderHeader: () => (<span className="columnHeader">{'Content'}</span>),
    valueGetter: (params) => {
      return ( upperFirst(params.row.itemType))
    }
  },
  {
    field:"Flagged By",
    width:160,
    sortable: false,
    renderHeader: () => (<span className="columnHeader">{'Flagged By'}</span>),
    headerClassName: "columnHeader",
    valueGetter: (params) => {
      return  `${upperFirst(params.row.flaggedBy.firstName)} ${upperFirst(params.row.flaggedBy.lastName)}`
    },
    renderCell: (params) => {
      const link = `/profile/${params.row.flaggerId}`
      return (
        <StyledLink to={link}> {upperFirst(params.row.flaggedBy.firstName)} {upperFirst(params.row.flaggedBy.lastName)} </StyledLink>
      )
    }
  },
  {
    field:"Beg Title",
    width: 180,
    sortable: false,
    renderHeader: () => (<span className="columnHeader">{'Beg Title'}</span>),
    valueGetter: (params) => {
      return (params.row.beg.title)
    }
  },
  { 
    field:"Reason",
    width:140,
    sortable: false,
    headerClassName: "columnHeader",
    renderHeader: () => (<span className="columnHeader">{'Reason'}</span>),
    valueGetter: (params) => {
      return (upperFirst(params.row.reason))
    } 
  },
  {
    field:"Active",
    width:80,
    sortable: false,
    renderHeader: () => (<span className="columnHeader">{'Active'}</span>),
    headerClassName: "columnHeader",
    valueGetter: (params) => {
      return params.row.activeFlags
    }
  },
  {
    field:"Inactive",
    sortable: false,
    width:80,
    renderHeader: () => (<span className="columnHeader">{'Inactive'}</span>),
    valueGetter: (params) => {
      return params.row.inactiveFlags
    }
  },
  {
    field:"Status",
    sortable: false,
    width:100,
    renderHeader: () => (<span className="columnHeader">{'Status'}</span>),
    valueGetter: (params) => {
      let flagged = 'Enabled'
      if (params.row.beg?.flagged === true) {
        flagged = 'Hidden'
      }
      return upperFirst(flagged)
    }
  },
  {
    field:"Action",
    sortable: false,
    width:70,
    renderHeader: () => (<span className="columnHeader">{'Action'}</span>),
    headerClassName: "columnHeader",
    renderCell: (params) => {
      const link = `/admin/flagged-content/item/${params.row._id}`
      return (
        <StyledLink to={link} > { 'Edit' } </StyledLink>
      )
    }
  }
]