import { useContext, useEffect, useRef, useState } from 'react'
import * as axios from 'axios'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { RadioGroup, FormControl, FormControlLabel, FormHelperText, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

// Custom components
import WaitOverlay from   '../../../components/WaitOverlay'
import ShareDialog from   '../../../components/dialogs/ShareDialog'
import InviteDialog from  '../../../components/dialogs/InviteDialog'
import ErrorDialog from   '../../../components/ErrorDialog'
import BegerzRadio from   '../../../components/styled-ui/BegerzRadio'

// Editor componnets
import { convertToRaw, EditorState } from "draft-js"
import draftToHtmlPuri from "draftjs-to-html"
import { Editor } from 'react-draft-wysiwyg'

// Page components
import Disclaimer from '../components/Disclaimer'

// Apis
import { useApi as useSignedUrl } from '../../../apis/getSignedUrl'
import { useApi as useCreateBeg } from '../../../apis/createBeg'

// Context
import { BegContext } from '../CreateBeg'

// CSS
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import '../../../css/CreateBeg.css'

// Component
export default function StepThree() {
  // Contexts
  const { 
    inputs,
    setInputs,
    fileObj
  } = useContext(BegContext)

  // Vars
  const navigate = useNavigate()

  // APIs
  const execSignedUrl = useSignedUrl().execute
  const execCreateBeg = useCreateBeg().execute

  // State
  const [editorState, setEditorState] = useState(inputs.description && EditorState.createWithContent(inputs.description))
  const [errors, setErrorState] = useState({})
  const [showError, setShowError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [begId, setBegId] = useState(false)

  // Vars
  const EditorRef = useRef();
  const minEditorLength = process.env.REACT_APP_MIN_BEG_DESCRIPTION_LENGTH
  const maxEditorLength = process.env.REACT_APP_MAX_BEG_DESCRIPTION_LENGTH
  const toolbarOptions = { options: ['inline', 'list', 'textAlign'],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline']
    }}
  
  // Focus the editor
  useEffect(() => {
    EditorRef?.current.focus()
    setErrorState({})
  },[])
  
  // Events
  const handleRadioChange = (event) => {
    setInputs({...inputs, [event.target.name]: event.target.value})
  }

  // Check for editor requirement errors
  const checkEditorErrors = (contentState) => {
    setErrorState({})
    if (!contentState || !contentState.hasText() ) {
      setErrorState({
        description: {
          error: true,
          message: 'Please tell your story'
        }
      })
      return false
    }

    // Check content length
    const length = contentState?.getPlainText().length || 0
    if (length < minEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Your story should be at least ${minEditorLength} characters`
        }
      })
      return false
    }
    if (length > maxEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Your story cannot exceed ${maxEditorLength} characters`
        }
      })
      return false
    }
    return true
  }

  const closeShareDialog = () => {
    navigate(`/raises/details/${begId}`)
  }

  // Get the S3 link
  const getSecureLink = async () => {
    try {
      const result = await execSignedUrl()
      const uuid = result?.uuid
      const url = result?.url
      return { uuid, url}
    } catch(error) {
      throw error
    }
  }

  // Save the video directly to the s3 bucket
  const putFile = async (link) => {
    await axios.put(link.url, fileObj, { headers: {'Content-Type': 'multipart/form-data'} }).then((response) => {
      return true
    }).catch(function(error) {
      throw error
    })
  }    

  // ----------------------------------------------------------------
  
  // Call Beg API
  const saveBeg = async (fileId, fileName, thumbnail, textDescription, htmlDescription) => {
    // Setup the data
    const data = {...inputs}
    delete data.description
    data['goalDate'] = format(new Date(data.goalDate), 'yyyy-MM-dd')
    data['fileId'] = fileId
    data['thumbLink'] = thumbnail
    data['videoLink'] = fileName
    data['textDescription'] = textDescription
    data['htmlDescription'] = htmlDescription
    data['status'] = 'active'
    data['userId'] = localStorage.getItem('userId')
    
    // Call the API
    try {
      const result = await execCreateBeg(data)
      return result
    } catch(error) {
      throw error
    }
  }

  // Submit form
  const handleCompleteBeg = async () => {
    if (errors.description) {
      return false
    }
    // Get the upload link
    setIsLoading(true)
    try {
      // Get a secure S3 upload link from the API server
      const link = await getSecureLink()
      await putFile(link)

      // Set the data to be saved
      const thumbnail = process.env.REACT_APP_S3URL + `thumbs/${link.uuid}-00001.png`
      const fileName = process.env.REACT_APP_S3URL + `${link.uuid}.mp4` 
      const contentState = editorState.getCurrentContent()
      const textDescription = contentState?.getPlainText()
      const htmlDescription = draftToHtmlPuri( convertToRaw(editorState.getCurrentContent()) )
      
      // Save the Beg via API
      const response = await saveBeg(link.uuid, fileName, thumbnail, textDescription, htmlDescription)
      setBegId(response._id)
    } catch(error) {
        setShowError(error?.response?.status || 'The server encountered an error sending your request. Please try again later.')
    } finally {
      setIsLoading(false)      
    }
  }

  // Editor functions
  const handleBeforeInput = () => {
    const contentState = editorState.getCurrentContent()
    const length = contentState?.getPlainText().length || 0
    if (length > maxEditorLength) return 'handled'
  }

  const handlePastedText = (pastedText) => {
    const contentState = editorState.getCurrentContent()
    const length = (contentState?.getPlainText().length || 0) + pastedText.length
    if (length > maxEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Cannot paste. Your story cannot exceed ${maxEditorLength} characters`
        }
      })
      return 'handled'
    }
  }

  const handleEditorChange = (editorState) => {
    setEditorState(editorState)
    const contentState = editorState.getCurrentContent()
    checkEditorErrors(contentState)
    setInputs({...inputs, 'description': contentState})
  }


  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{ pb: 4 }}
    >
      <Typography variant="h2">Tell your story</Typography>
      <Typography marginBottom={3} variant="subtitle1" >
          Explain who you are and why you are creating this raise.
      </Typography>

      { !!showError && <ErrorDialog message={showError} setError={() => {setShowError(false)}} errorCode={'none'} title={'Error saving your Beg'} /> }
      { isLoading && <WaitOverlay />}

      { (begId && inputs.publishType !== 'unlisted') && 
        <ShareDialog
          buttonText={"Close and View Raise"}
          title={"Your raise is ready!"}
          shareText={"Check out this raise I just created on Begerz!"}
          shareOpen={true}
          setShareOpen={closeShareDialog}
          begId={begId}
        /> 
      }

      { (begId && inputs.publishType === 'unlisted') && 
        <InviteDialog
          title={"Your raise is ready!"}
          helpText={<>"We recommend inviting at least 3-5 freinds.<br />Sharing on a social network can raise up to 5x more!"</>}
          secondaryButtonText={"Close and View Raise"}
          shareText={"Check out this raise I just created on Begerz!"}
          shareOpen={true}
          setShareOpen={closeShareDialog}
          begId={begId}
        /> 
      }

      <Editor
        id="description"
        name="description"
        wrapperClassName="wrapper"
        editorClassName="editor"
        toolbar={toolbarOptions}
        hashtag={{
          separator: ' ',
          trigger: '#'
        }}
        onEditorStateChange={handleEditorChange}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        editorState={editorState}
        editorRef={(ref) => (EditorRef.current = ref)}
      />

      <FormHelperText 
        error = {!!errors.description}
      >
        {(errors.description && errors.description?.message) || 'Tell your story in 8 - 1000 characters'}
      </FormHelperText>

      <div className="radio-button-frame">
        <FormControl>
          <RadioGroup
            name="publishType"
            id="publishType"
            value={inputs.publishType || 'unlisted'}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="private"
              control={<BegerzRadio />}
              label="Private" 
            />
            <div className="radio-button-description">Only you and people you choose can watch your video</div>

            <FormControlLabel
              value="unlisted"
              control={<BegerzRadio />}
              label="Unlisted"
            />
            <div className="radio-button-description">Anyone with the video link can watch your video</div>

            <FormControlLabel
              value="public"
              control={<BegerzRadio />}
              label="Public"
            />
            <div className="radio-button-description">Everyone can watch your video</div>
          </RadioGroup>
        </FormControl>
      </div>

      <Button
        onClick={handleCompleteBeg}  
        type="button"
          fullWidth
          sx={{ mt: 4, mb: 1 }}
          variant="contained"
      >
        Complete Your Raise!
      </Button>

      <Disclaimer />
    </Container>
  )
}