import { Typography } from '@mui/material'
import React from 'react'
import NumberBox from './NumberBox'

// Icons
import Admirable from '../assets/icons/achievements/admirable.svg'
import Brilliant from '../assets/icons/achievements/brilliant.svg'
import Hillarious from '../assets/icons/achievements/hillarious.svg'
import Informative from '../assets/icons/achievements/informative.svg'
import Inspiring from '../assets/icons/achievements/inspiring.svg'

export default function Achievements(data) {
  const achievements = [
    { name: 'Admirable', src: Admirable, field: 'admirable' },
    { name: 'Hillarious', src: Hillarious, field: 'hillarious' },
    { name: 'Informative', src: Informative, field: 'informative' },
    { name: 'Brilliant', src: Brilliant, field: 'brilliant' },
    { name: 'Inspiring', src: Inspiring, field: 'inspiring' }
  ] 

  // Return an empty component if there are no achievements
  if (data.totalAchievements === 0) {
    return <></>
  }

  // Render a single cirular achievement icon
  const AchievementCircle = (name, source, count) => {
    if (count > 0) {
      return (
        <img alt={name} src={source} style={{ float:'right', marginLeft:'-7px', borderRadius:'50%', height:'12.5px', width:'12.5px' }} />
      )
    }
  }

  return (
    <>
      <Typography variant="caption2" sx={{ float:'right', marginLeft:'4px'  }}>
        <NumberBox variant="caption2" sx={{ textAlign:'left' }} number={data.totalAchievements} />
      </Typography>

      <div style={{ display:'inline-block', float:'right' }}>
        { achievements.map((item, index) => {
          return (
            <span key={index} >
              { AchievementCircle(item.name, item.src, data.achievements[item.field] ?? 0) }
            </span>
          )}
        )}
      </div>    
    </>
  )
}