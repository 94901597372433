import React, { useEffect, useRef, useState } from 'react'
import Typography from '@mui/material/Typography'
import { convertToRaw, EditorState, ContentState } from "draft-js"
import { Editor } from 'react-draft-wysiwyg'
import draftToHtmlPuri from "draftjs-to-html"
import htmlToDraft from 'html-to-draftjs'
import { FormHelperText, Button, Grid } from '@mui/material'
import { useTheme } from '@emotion/react'

// Custom components
import ErrorDialog from '../../../components/ErrorDialog'
import Player from '../../../components/player/Player'
import ThumbnailImage from '../../../components/ThumbnailImage'
import UploadVideo from '../../../components/UploadVideo'

// Dialogs
import DeleteVideo from '../../../components/dialogs/DeleteVideo'
import VideoWorking from '../../../components/dialogs/VideoWorking'

// APIs
import { useApi } from '../../../apis/privateApiCall'

// CSS
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import '../../../css/CreateBeg.css'


// Main component
export default function SuccessTab(props) {
  // Vars
  const begId = props.data._id
  const refresh = props.refresh
  const successId = props.data.success?._id
  const theme = useTheme()
  const inactiveColor = theme.palette.gray.light

  // APIs
  const { execute } = useApi()

  // State
  const [errors, setErrorState] = useState({})
  const [showError, setShowError] = useState(false)


  // eslint-disable-next-line
  const [data, setData] = useState(props.data?.success)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [workingDialogOpen, setWorkingDialogOpen] = React.useState(false)
  const [streams, setStreams] = React.useState([])
  const [inputs, setInputs] = useState({localFileName: '' })

  // set the opacity to 1 for the selected video
  const inactiveStyle = { objectFit:'cover', objectPosition:'center', boxShadow:`0 0 0`, cursor:"pointer", border:`1px solid ${inactiveColor}`, borderBottom:`2px solid ${inactiveColor}`, borderRadius: '4px' }

  // Convert HTML story to draft format
  const blocksFromHtml = htmlToDraft(data?.htmlDescription || "")
  const { contentBlocks, entityMap } = blocksFromHtml
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))


  // Vars for editor
  const EditorRef = useRef()
  const minEditorLength = process.env.REACT_APP_MIN_BEG_DESCRIPTION_LENGTH
  const maxEditorLength = process.env.REACT_APP_MAX_BEG_DESCRIPTION_LENGTH
  const toolbarOptions = { options: ['inline', 'list', 'textAlign'],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'underline']
    }}
  
  // Focus the editor
  useEffect(() => {
    EditorRef?.current.focus()
    setErrorState({})
  },[])
  
  
  // Effect to set video streams
  useEffect(() => {
    const createStreamData = ()   => {
      let items = []
      if (data?.videoLink) {
        items[0] = {
          createdAt : data.createdAt,
          thumbLink : data.thumbLink, 
          videoLink : data.videoLink,
          localFileName : data.localFileName
        }
        setStreams(items)
      }
    }
    createStreamData()
  },[data])


  // Video methods
  const closeVideoWorking = async () => {
    // refresh the display
    setWorkingDialogOpen(false)
    await refresh(begId)
  }


  // upload success function
  const uploadSucceeded = async (file) => {
    // determine if the success record exists 
    let method = "post"
    let url = `/api/v1/success`
    
    // existing record    
    if (successId) {
      method = "patch"
      url = `/api/v1/success/${successId}`
    }
    
    // update the success ercord
    try {
      const options =  {
        method: method,
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: url
      }

      // add data for the request
      const data = {
        videoLink: process.env.REACT_APP_S3URL + `${file.uuid}.mp4`,
        thumbLink: process.env.REACT_APP_S3URL + `thumbs/${file.uuid}-00001.png`,
        localFileName: file.localFileName
      }

      // call the api
      await execute(data, options)
      
      // refresh the display
      setWorkingDialogOpen(true)
    } catch (error) {}
  }
  
  // Check for editor requirement errors
  const checkEditorErrors = (contentState) => {
    setErrorState({})
    if (!contentState || !contentState.hasText() ) {
      setErrorState({
        description: {
          error: true,
          message: 'Share your success'
        }
      })
      return false
    }

    // Check content length
    const length = contentState?.getPlainText().length || 0
    if (length < minEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Your story should be at least ${minEditorLength} characters`
        }
      })
      return false
    }
    if (length > maxEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Your story cannot exceed ${maxEditorLength} characters`
        }
      })
      return false
    }
    return true
  }

  // ----------------------------------------------------------------
 
  // Editor functions
  const handleBeforeInput = () => {
    const contentState = editorState.getCurrentContent()
    const length = contentState?.getPlainText().length || 0
    if (length > maxEditorLength) return 'handled'
  }

  const handlePastedText = (pastedText) => {
    const contentState = editorState.getCurrentContent()
    const length = (contentState?.getPlainText().length || 0) + pastedText.length
    if (length > maxEditorLength) {
      setErrorState({
        description: {
          error: true,
          message: `Cannot paste. Your story cannot exceed ${maxEditorLength} characters`
        }
      })
      return 'handled'
    }
  }

  const handleEditorChange = (editorState) => {
    setEditorState(editorState)
    const contentState = editorState.getCurrentContent()
    checkEditorErrors(contentState)
    setInputs({...inputs, 'description': contentState})
  }


  // Submit form
  const saveBeg = async () => {
    // check for errors in the editor
    if (errors.description) {
      return false
    }

    // call api
    const update = async () => {

      // determine if the success record exists 
      let method = "post"
      let url = `/api/v1/success`
      
      // existing record    
      if (successId) {
        method = "patch"
        url = `/api/v1/success/${successId}`
      }
      
      // update the success ercord
      try {
        const options =  {
          method: method,
          baseURL: process.env.REACT_APP_API_SERVER_URL,
          url: url
        }

        // Set the data to be saved
        const contentState = editorState.getCurrentContent()
        const data = {
          textDescription: contentState?.getPlainText(),
          htmlDescription: draftToHtmlPuri( convertToRaw(editorState.getCurrentContent()) )
        }

        // call the api
        await execute(data, options)
        await refresh(begId)
      } 
      catch (error) {}
    }

    update()
  }

  // click event for delete button
  const handleDeleteVideo = () => {
    setDialogOpen(true)
  }

  // cancel delete, close dialog
  const cancelDeleteVideo = () => {
    setDialogOpen(false)
  }

  // dialog contine, delete video
  const deleteVideo = async () => {
    setDialogOpen(false)
    try {
      const options =  {
        method: 'patch',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `/api/v1/success/${successId}`
      }
      const data =  {
        thumbLink : "", 
        videoLink : "",
        localFileName : ""
      }

      // call api to remove the video    
      await execute(data, options)
      
      // clear the list of video streams
      await refresh(begId)
      setStreams([])        
    } catch (error) {}
  }


  return (
    <>
      { workingDialogOpen && <VideoWorking dialogOpen={workingDialogOpen} close={closeVideoWorking} />}
      { dialogOpen && <DeleteVideo cancel={cancelDeleteVideo} delete={deleteVideo} dialogOpen={dialogOpen} />}
      { !!showError && <ErrorDialog message={showError} setError={() => {setShowError(false)}} errorCode={'none'} title={'Error saving your Beg'} /> }

      <Typography mb={1} variant="h6">Share your experience and say thanks</Typography>

      <Grid container mt={1} spacing={1} alignItems="baseline">

        {/* editor */}

        <Grid item xs={12}>
          <Editor
            id="description"
            name="description"
            wrapperClassName="wrapper"
            editorClassName="editorSuccess"
            toolbar={toolbarOptions}
            hashtag={{
              separator: ' ',
              trigger: '#'
            }}
            onEditorStateChange={handleEditorChange}
            handleBeforeInput={handleBeforeInput}
            handlePastedText={handlePastedText}
            editorState={editorState}
            editorRef={(ref) => (EditorRef.current = ref)}
          />
          <FormHelperText error={ !!errors.description }>
            {(errors.description && errors.description?.message) || 'Tell your story in 8 - 1000 characters'}
          </FormHelperText>
        </Grid>

        {/* video section */}

        <Grid item mt={3} xs={12}>
          <Typography variant='h6'>Video</Typography>
          <Typography variant='subtitle1'>The cover video will display as the main preview image for your Success Story.</Typography>

          <Grid container spacing={2} alignContent="center" justifyContent="space-between">

            {/* video player */}

            <Grid item xs={12} md={5}>
              { <Player controls playsinline streams={ streams } /> }
            </Grid>

            {/* video add delete */}

            <Grid item xs={12} md={4}>
              <Grid container spacing={1} sx={{ mt:.5, pt:1, pb:1, backgroundColor:theme.palette.gray.light }}>
                <Grid item xs={12} sx={{ textAlign:"center" }}>

                  {/* add video */}

                  <UploadVideo data={data} success={uploadSucceeded} button={
                    <Button
                      component="span"
                      variant="outlined"
                    >
                      { (streams.length === 0 && <>+ Add Video </>) || <>Replace Video</> }

                    </Button>}
                  />

                </Grid>
                <Grid item xs={12} sx={{ textAlign:"center"}}>
                  <Button
                    variant="outlined"
                    onClick={() => {handleDeleteVideo()}}
                    disabled={ !data?.videoLink }
                  > 
                    Delete Video
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/* thumbnail */}

            <Grid item xs={12} md={3}>
              <ThumbnailImage height="140px" width="140px" src={data?.thumbLink} style={ inactiveStyle } />
            </Grid>
          </Grid>

        </Grid>
      </Grid>

      <Button
        onClick={ () => {saveBeg()} }
        sx={{ marginTop:4 }}
        fullWidth
        variant="contained"
      >
        Save Your Success Story
      </Button>
    </>
  )
}