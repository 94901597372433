/**
 * props to pass:
 * url={`/api/v1/begs/users${profile?._id}/?status=active`} title={'Active Begs'}
 * pageSize={100}
 * doneLoading={ function to coordinate loading processes from parent }
 * props?.pagination to have pagination data passed back
 */
import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'

// Custom components
import Beg from './Beg'
import More from '../pagination/More'
import SectionHeaderContainer from '../styled-ui/SectionHeaderContainer'
import Adverts from '../advertisement'

// Apis
import { useApi as privateApiCall } from '../../apis/privateApiCall'


// Main Component
export default function BegPagination(props) {
  // Vars
  const title = props?.title
  const url = props?.url
  const pageSize = props?.pageSize || 12
  const advertIndex = props?.advertIndex || 12
  const type = props?.type
  const doneLoading = props?.doneLoading
  const defaultPagination = {current:1, pages:0, limit:pageSize, records:0, loading:true}

  // APIs
  const { execute, controller } = privateApiCall()
  
  // State
  const [begs, setBegs] = useState([])
  const [pagination, setPagination ] = useState(defaultPagination)


  // Effects

  // refresh the data on API URL change
  useEffect(() => {
    setBegs([])
    setPagination(defaultPagination)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[url])


  // effect to load data
  useEffect(() => {
    const fetchData = async () => {
      const options =  {
        method: 'get',
        baseURL: process.env.REACT_APP_API_SERVER_URL,
        url: `${url}&page=${pagination.current}&limit=${pagination.limit}`
      }
      try {
        // set data and pagination
        const response = await execute({}, options)
        setBegs(prev => (prev.concat(response.results)) )
        setPagination(prev => ({...prev, ...response.pagination}))
        // send pagination back on props
        if (props?.pagination) props.pagination(response.pagination)
      } 
      catch(error) {} 
      finally {
        setPagination(prev => ({...prev, loading:false}))
        doneLoading(type)     
      }
    }
    fetchData()
    return controller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination.current, url])


  return (
    <>
      { props?.title && <SectionHeaderContainer mb={2} to={ props?.toLink || "" }>{ title } ({ pagination?.records })</SectionHeaderContainer> }

      <Grid container spacing={2} mb={2}>
        { !pagination.loading && begs.map((item, index) => {
            // set link to edit if required
            if (props?.editLink) item.editLink = `/raises/edit/${item._id}`

            // display an advert every advertIndex records
            let showAdvert = false
            if ((index + 1) % advertIndex === 0 && props.adverts === true) showAdvert = true

            return (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={6} md={4}>
                  <Beg data={item} />
                </Grid>

                { (showAdvert && props?.adverts) && <Grid item xs={12} mt={1} mb={1}> <Adverts /> </Grid> }        
              </React.Fragment>
            )

          })
        }
      </Grid>

      {/* pagination */}
      { props?.paginationType === 'more' && 
        <Grid container sx={{ mt:0 }}>
          <More
            pagination={pagination}
            setPagination={setPagination}
            divider={props?.divider}
          >
            {props.children}
          </More>
        </Grid>
      }
    </>
  )
}