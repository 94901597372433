/**
 * close signin dialog if user hits browser navigation
 */
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Get context from App
import { AuthContext } from '../../context/Auth'


// Main component
export default function RouteChange() {
  // Vars
  const history = useNavigate()
  const { signInOpen, handleCloseSignin } = useContext(AuthContext)  

  // Effects
  useEffect(() => {
    if (signInOpen) handleCloseSignin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])
}