import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

// Main component
export default function StartBegButton() {
  return (
    <Link style={{textDecoration: "none"}} to="/raises/create">
      <Button 
        variant="contained"
        disableRipple
        sx={{ pl:3, pr:3}}
      >
        Start a Raise
      </Button>
    </Link>
  )
}