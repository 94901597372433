import React, { useState } from 'react'
import { Divider, Grid, Snackbar, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'

// Components
import Invited from '../components/Invited'

// Errors
import ErrorDialog from '../../../components/ErrorDialog'

// APIs
import { useApi } from '../../../apis/privateApiCall'

// Components
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})


// Main component
export default function BegCompleted(props) {
  
  // APIs
  const { execute } = useApi()
  
  // Vars
  const begId = props.data._id

  // Use state

  // number of email address fields
  const [showAlert, setShowAlert] = useState(false)

  // loading state
  const [hasError, setHasError] = React.useState({
    status: false,
    message: '',
    code: 500
  })


  // Methods

  // reset state so the alert can be re-displayed
  const handleAlertClose = () => {
    setShowAlert(false)
  }

  // use the api to send each invitation
  const sendInvitations = async (invites) => {
    let index = 0
    const recordCount = Object.keys(invites).length

    // set api options
    const options =  {
      method: 'post',
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/invitations`
    }

      // iterate and send the invitations
    try {
      Object.keys(invites).forEach( async (key) => {

        // create data object to post
        const data = invites[key]
        const reqData = {
          userId: data.userId,
          begId: data.begId,
          email: data.email,
          status: 'pending'
        }
        // add invitee if user an existing follower
        if (data?.inviteeId) reqData['inviteeId'] = data?.inviteeId

        // call the api and show an alert
        await execute(reqData, options)
        index ++
        if (index === recordCount) {
          setShowAlert(true)
        }
      })
    } catch {} 
  }

 
  return (
    <>
      { hasError.status && <ErrorDialog message={hasError.message} setError={setHasError} errorCode={hasError.code} title={'Error retrieving begs'} /> }

      {/* existing invitations */}

      <Typography mt={0} mb={1} variant="h6">
        Invitations you've sent
      </Typography>

      <Divider sx={{ mb:2 }} />

      <Grid container spacing={.5}>
        <Grid item mb={1} xs={12}>
          <Grid container>
            <Grid item xs={2}><Typography variant='caption2'>Date Invited</Typography></Grid>
            <Grid item xs={5}><Typography variant='caption2'>Name</Typography></Grid>
            <Grid item xs={2}><Typography variant='caption2'>Status</Typography></Grid>
            <Grid item xs={3}><Typography variant='caption2'>Resend</Typography></Grid>
          </Grid>
        </Grid>

        { <Invited sendInvitation={sendInvitations} begId={begId} /> }

      </Grid>

      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={() => {setShowAlert(false)}}>
        <Alert onClose={handleAlertClose} severity="success" sx={{ width: '100%' }}>
          { `Invitations have been sent!`}
        </Alert>
      </Snackbar>        
    </>
  )
}