import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Grid, Dialog, DialogContent, Divider, Box, IconButton, TextField, Typography, Snackbar, Alert } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// Custom components
import DialogHeader from '../DialogHeader'

// Icons
import CloseIcon from '@mui/icons-material/Close'

// APIs
import { useApi } from '../../apis/privateApiCall'


// Main component
export default function DepositSettingDialog(props) {
  // Vars
  const data = props.data
  const theme = useTheme()
  const saveRecord = useApi().execute
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  let initAccountNumber
  if (props.action === "add") initAccountNumber = data?.accountNumber

  // Validation
  const { 
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    mode: 'submit',
    defaultValues: {
      description: data.description,
      accountNumber: initAccountNumber,
      routeNumber: data.routeNumber
    }
  })

  // State
  const [showAlert, setShowAlert] = useState(false)


  // Methods
  
  // validate and submit the form
  const onSubmit = async (inputs) => {
    // Vars
    let options = {}

    // post or patch the record depending on the requested action
    switch (props.action) {
      case 'add':
        options =  {
          method: 'post',
          baseURL: process.env.REACT_APP_API_SERVER_URL,
          url: `/api/v1/paymethods`
        }
        // call the api and show an alert
        try { 
          await saveRecord({
            ...inputs,  
            userId:localStorage.getItem('userId'),
            paytype:'ach'
            }, options
          )
          props.closeAndSave()      
        } catch(error) {
          setShowAlert(true)
        }
      break

      default:
        options =  {
          method: 'patch',
          baseURL: process.env.REACT_APP_API_SERVER_URL,
          url: `/api/v1/paymethods/${data._id}`
        }
        // call the api and show an alert
        try { 
          await saveRecord(inputs, options)
          props.closeAndSave()      
        } catch(error) {
          setShowAlert(true)
        }
    }
  } 

  // reset clipboard state so the alert can be re-displayed
  const handleAlertClose = () => {
    setShowAlert(false)
  }

  return (
    <Dialog
      maxWidth="sm"
      open={props.open}
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogContent sx={{ mb: 2 }}>

        <Box sx={{ textAlign:'left' }}>
          <DialogHeader sx={{ textAlign:'left', mb:1 }}>
            {props?.title || <>Edit Payment Account</>}
          </DialogHeader>
        </Box>        
        <Typography
          variant='subtitle1'
          sx={{ mb:2 }}
        >
          {props?.subTitle || <>Edit your account for receiving payments</>}
        </Typography>

        <IconButton
          onClick={props.cancelDialog}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Divider sx={{ mb:4 }}></Divider>
    

        <form noValidate onSubmit={handleSubmit(onSubmit)}>

          <Grid container spacing={2} alignItems="baseline">
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                required
                autoComplete="description"
                fullWidth
                error={!!errors.description || null}
                helperText={errors.description?.message || <br />}
                {...register('description', {
                    required: "Description is required",
                    maxLength: { value: 48, message: 'Description must be 48 characters or less' },
                    pattern: {
                      value : /^[ A-Za-z0-9_@./#&+-]*$/,
                      message: 'Description must be alpha numeric characters only'
                    }
                  }
                )}
              />          
            </Grid>

            { props?.action === "add" && 
              <Grid item xs={12}>
                <TextField
                  name="accountNumber"
                  label="Account Number"
                  required
                  autoComplete="accountNumber"
                  fullWidth
                  error={!!errors.accountNumber || null}
                  helperText={errors.accountNumber?.message || <br />}
                  {...register('accountNumber', { 
                    required: "Account is required",
                    minLength: { value: 2, message: "At least 2 digits" },
                    maxLength: { value: 30, message: "30 digits or less" },
                    pattern: {
                      value : /^[0-9 ]+$/,
                      message: 'Account must be numeric characters only'
                    }
                  })}
                />
              </Grid>
            }

            { props?.action === "edit" && 
              <Grid item xs={12}>
                <TextField
                  name="accountNumber"
                  label="Account Number"
                  autoComplete="accountNumber"
                  disabled
                  placeholder="XXXXXXXXXX"
                  fullWidth
                  error={!!errors.accountNumber || null}
                  helperText={errors.accountNumber?.message || <br />}
                  {...register('accountNumber', { 
                    minLength: { value: 2, message: "At least 2 digits" },
                    maxLength: { value: 30, message: "30 digits or less" },
                    pattern: {
                      value : /^[0-9]+$/,
                      message: 'Account must be numeric characters only'
                    }
                  })}
                />
              </Grid>
            }


            <Grid item xs={12}>
              <TextField
                name="routeNumber"
                label="Routing Number"
                required
                autoComplete="routeNumber"
                fullWidth
                error={!!errors.routeNumber || null}
                helperText={errors.routeNumber?.message || <br />}
                {...register('routeNumber', { 
                  required: "Routing is required",
                  minLength: { value: 9, message: "Must be 9 digits" },
                  maxLength: { value: 9, message: "Must be 9 digits" },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Numbers only'
                  }
                })}
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt:3, mb:0 }} />


          <Grid container spacing={2} mt={0}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ mt:1, mb:1, fontWeight: 700 }}
                onClick={props.cancelDialog}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt:1, mb:1, fontWeight: 700 }}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </form>

        <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
            Error saving account
          </Alert>
        </Snackbar>

      </DialogContent>
    </Dialog>
  )
}