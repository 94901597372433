import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import { Button, Grid, Paper, Snackbar } from "@mui/material";
// import Profile from "../components/profile/Profile";

// Icons
import LeftArrow from "../assets/icons/leftArrow.svg";

// Error handling
import WaitOverlay from "../components/WaitOverlay";
import ErrorDialog from "../components/ErrorDialog";
import { Link, useNavigate } from "react-router-dom";
import DialogHeader from "../components/DialogHeader";

// APIs
import { useApi } from "../apis/privateApiCall";
import ProfileAvatar from "../components/ProfileAvatar";

// Main component
export default function BlockedUsers() {
  // Vars
  const theme = useTheme();
  // const userId = localStorage.getItem('userId')

  // APIs
  const { execute } = useApi();

  // State
  const [blockedUser, setBlockedUser] = useState([]);
  // State for dialog box
  const [snackState, setSnackState] = useState({
    open: false,
    vertical: "",
    horizontal: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // const [pagination, setPagination ] = useState({
  //   current: 1,
  //   pages: 0,
  //   limit: 10,
  //   records: 0,
  //   loading: true
  // })
  const [hasError, setHasError] = React.useState({
    status: false,
    message: "",
    code: 500,
  });

  // Methods
  const fetchDefaultAccount = async () => {
    // set api options
    const options = {
      method: "get",
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/users/blockedList`,
    };
    try {
      setIsLoading(true);
      const data = await execute({}, options);
      setBlockedUser(data);
      setIsLoading(false);
    } catch (error) {
      throw error;
    }
  };

  // Effects
  // useEffect(() => {
  //   // get the default paymethod
  //   const fetchWithdrawals = async (userId) => {
  //     // set api options
  //     const options =  {
  //       method: 'get',
  //       baseURL: process.env.REACT_APP_API_SERVER_URL,
  //       url: `/api/v1/users/blockedList`
  //     }
  //     // call the api and show an alert
  //     try {
  //       const data = await execute({}, options)
  //       setPagination(prev => ({...prev, ...data.pagination}))
  //       setWithdrawals(data)
  //     } catch(error) {
  //       throw error
  //     } finally {
  //       setIsLoading(false)
  //     }
  //   }

  //   setIsLoading(true)
  //   fetchWithdrawals(userId)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[pagination.current])

  useEffect(() => {
    fetchDefaultAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // methods
  const navigate = useNavigate();
  const unblockUser = async (id) => {
    const options = {
      method: "post",
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      url: `/api/v1/users/${id}/unblock`,
    };
    try {
      setIsLoading(true);
      const response = await execute({}, options);
      await fetchDefaultAccount();
      setSnackState((state) => ({
        ...state,
        open: true,
        horizontal: "right",
        vertical: "top",
        message: response?.message,
      }));
    } catch (error) {
      throw error;
    }
  };

  const handleProfileClick = (id) => {
    if (id) navigate(`/profile/${id}`);
  };

  return (
    <Container component="main" maxWidth="md">
      <Paper elevation={4} sx={{ padding: 4 }}>
        {isLoading && <WaitOverlay />}
        {snackState && (
          <Snackbar
            anchorOrigin={{
              vertical: snackState.vertical,
              horizontal: snackState.horizontal,
            }}
            open={snackState.open}
            onClose={() => {
              setSnackState((state) => ({ ...state, open: false }));
            }}
            message={snackState.message}
            key={snackState.vertical + snackState.horizontal}
          />
        )}
        {hasError.status && (
          <ErrorDialog
            message={hasError.message}
            setError={setHasError}
            errorCode={hasError.code}
            title={"Error retrieving begs"}
          />
        )}

        <Link
          to="/myprofile"
          style={{
            display: "inline-block",
            textDecoration: "none",
            color: theme.palette.primary.main,
          }}
        >
          <img
            style={{ marginRight: "6px", verticalAlign: "middle" }}
            alt="left arrow"
            src={LeftArrow}
          />{" "}
          Dashboard
        </Link>

        <DialogHeader mt={2} mb={0}>
          Blocked Users
        </DialogHeader>
        <Typography variant="subtitle1">your blocked users list</Typography>

        {/* list of blocked user */}
        <Grid
          container
          spacing={2}
          sx={{ mt: "20px", ml: "0", width: "100%", gap: "20px" }}
        >
          {blockedUser &&
            blockedUser.map(({ blockId, userDetails }) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={blockId}
                  sx={{
                    backgroundColor: "#fff",
                    color: "rgba(0, 0, 0, 0.87)",
                    // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    borderRadius: "4px",
                    boxShadow:
                      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
                    mt: "0px",
                    mb: "0px",
                    padding: "14px 5px",
                    pr: "16px",
                    textAlign: "center",
                  }}
                >
                  <Paper
                    className="rounded-corners-gradient-borders"
                    sx={{
                      height: "auto",
                      margin: "10px auto",
                      maxWidth: "min-content",
                      width: "auto",
                      borderRadius: "24px",
                    }}
                  >
                    <ProfileAvatar
                      src={
                        userDetails?.currentImage || userDetails?.profileImage
                      }
                      alt={userDetails?.username}
                      sx={{
                        width: 96,
                        height: 96,
                        fontSize: 46,
                        margin: "2px",
                        cursor: "pointer",
                      }}
                      style={{ borderRadius: "22px" }}
                      onClick={() => {
                        handleProfileClick(userDetails._id);
                      }}
                    ></ProfileAvatar>
                  </Paper>
                  <Typography variant="subtitle1">
                    name :{" "}
                    {userDetails?.firstName + " " + userDetails?.lastName}
                  </Typography>
                  <Typography variant="subtitle1">
                    username : {userDetails?.username}
                  </Typography>

                  <Button
                    variant="contained"
                    sx={{ mt: "10px", mb: "10px" }}
                    onClick={unblockUser.bind(null, userDetails._id)}
                  >
                    Unblock this user
                  </Button>
                </Grid>
              );
            })}
        </Grid>

        {/* pagination */}

        {/* <Grid container sx={{ mt:4 }} justifyContent="center">
          <Grid item>
            <Pagination
              disabled={!pagination?.next?.limit > 0}
              color="primary"
              count={pagination?.pages}
              onChange={handlePagination}
            />
          </Grid>
        </Grid> */}
      </Paper>
    </Container>
  );
}
