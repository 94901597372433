import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import BoxSignUpLink from '../components/BoxSignUpLink'
import { Paper } from '@mui/material'

export default function Privacy() {

  return (
    <Container component="main" maxWidth="md">

      <Paper elevation={4}>
        <Box
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            padding: 6,
          }}
        >
          <Typography
            component="h1"
            variant="h7"
            color="gray.main"
            sx={{ mb: 3 }}
          >
            Begerz.com Privacy Policy
          </Typography>

          <div style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
          }}>
          
<p>This Privacy Policy discloses the privacy practices for the Begerz.com website, the Begerz mobile app and the various related services accessible through them (collectively referred to as the &ldquo;Service&rdquo;). By using the Service, you are consenting to the practices described in this Privacy Policy Notice. Please note, we are committed to protecting your privacy online. Please read the information below to learn the following regarding your use of this site:</p>
<p>1. What information do we collect from you?</p>
<p>2. Where do we collect information from you and how do we use it?</p>
<p>3. With whom do we share your information?</p>
<p>4. How can you update, manage or delete your Personally Identifiable Information?</p>
<p>5. What are your choices regarding collection, use and distribution of your information?</p>
<p>6. What security precautions are in place to protect against the loss, misuse or alteration of your information?</p>
<p>7. Do we use &ldquo;cookies&rdquo;?</p>
<p>8. What should you know about privacy policies and data collection at any third-party sites accessible from our site?</p>
<p>9. What else should you know about your privacy online?</p>
<p>We reserve the right to change this Privacy Policy at any time. Such changes, modifications, additions or deletions shall be effective immediately upon notice thereof, which may be given by any means including but not limited to issuing an e-mail to the e-mail address provided by users and posting the revised Policy on the site.</p>
<p>You acknowledge and agree that it is your responsibility to maintain a valid e-mail address as a registered user, review this site and this Policy periodically and be aware of any modifications. Your continued use of the site after such modifications will constitute your:</p>
<p>(a) acknowledgment of the modified Policy; and</p>
<p>(b) agreement to abide and be bound by the modified Policy.</p>
<p>1. What information do we collect from you?</p>
<p>In order to better provide you with our services, we collect different kinds of information from or about our users, including Personally Identifiable Information and Non-Personally Identifiable Information. Our primary goal in collecting information from you is to provide you with a smooth, efficient, and customized experience while using our site. Personally Identifiable Information: This refers to information that lets us know the specifics of who you are. When you engage in certain activities on this site, such as registering for a user account, sending us feedback or making a purchase or contribution, we may ask you to provide certain information about yourself. This information includes personal identifying information. In addition, when you create an account, you will be asked to provide Personally Identifiable Information, including your email address. Non-Personally Identifiable Information: This refers to information that does not by itself identify a specific individual. We gather certain information about you based upon your use of the site. This information is compiled and analyzed on an aggregated basis. This information may include the site&rsquo;s Uniform Resource Locator (&ldquo;URL&rdquo;) that you just came from, which URL you go to next, what browser you are using, and your Internet Protocol (&ldquo;IP&rdquo;) address.</p>
<p>We also collect content and information that you (or others) provide when you (or they) share or upload photos and/or videos. We collect information about the people and groups you are connected to and how you interact with them. When you use our Services for purchases or financial transactions, we collect information about the purchase or transaction. This includes payment information such as your credit or debit card number and other card information, account authentication information, as well as billing, shipping and contract details.</p>
<p>2. Where do we collect information from you and how do we use it?</p>
<p>Begerz is designed to create an engaging customized experience for the people we serve and the information we gather is used to help us accomplish this goal. We do not collect any Personally Identifiable Information about you unless you voluntarily provide it to us. You provide certain Personally Identifiable Information to us when you:</p>
<p>(a) Register for a user account;</p>
<p>(b) Leave feedback and/or comments;&nbsp;</p>
<p>(c) Make a purchase or contribution;</p>
<p>(d) sign up for special offers from us or selected third parties.</p>
<p>In addition, we may also collect, or our third-party ad server and/or content server may collect, certain Non-Personally Identifiable Information. We use your IP address to diagnose problems with our servers, software, to administer our site and to gather demographic information. We will primarily use your Personally Identifiable Information to provide our services to you, as required by our agreements with you. We will also use Personally Identifiable Information to enhance the operation of our site, fill orders, authorize payment, improve our marketing and promotional efforts, statistically analyze site use, improve our product and service offerings, and customize our site&rsquo;s content, layout, and services.&nbsp;</p>
<p>We employ other companies to perform functions on our behalf.&nbsp; One example is the payments processor we use. These third-party service providers have access to personal information needed to perform their functions but may not use it for other purposes. As the business continues to develop, we may buy or sell other businesses or services and customer information is a generally transferred business asset but remains subject to the pre-existing Privacy Notice in effect unless the customer consents otherwise.&nbsp;</p>
<p>We may use Personally Identifiable Information to deliver information to you and to contact you regarding administrative notices. We may also use Personally Identifiable Information to troubleshoot problems and enforce our agreements with you. We may use the information we have to improve our systems and provide relevant ads for other third-party services. We also may use the information we have to verify accounts and activity and promote safety and security.</p>
<p>3. With whom do we share your information?</p>
<p>Except as provided in this Privacy Policy, we do not sell or share your Personally Identifiable Information with third parties for marketing purposes and only share your information as described herein. We share your Personally Identifiable Information with:</p>
<p>(a) our server providers and processing agents;</p>
<p>(b) service providers under contract who help with parts of our business operations such as fraud prevention, bill collection, marketing and technology services;</p>
<p>(c) financial institutions with which we partner;&nbsp;</p>
<p>(d) people you choose to share and communicate with;</p>
<p>(e) people that see the content you choose to share and communicate with;&nbsp;</p>
<p>(f) other third parties with your consent or direction to do so. In addition, we may share some of your Personally Identifying Information with the person or company that you are paying or that is paying you through bergerz.com; and/or</p>
<p>(g) new ownership, if control or ownership of our Services or assets change.</p>
<p>Without limiting the above, occasionally we may be required by law enforcement or judicial authorities to provide Personally Identifiable Information to the appropriate governmental authorities. We will disclose Personally Identifiable Information upon receipt of a court order, subpoena, or to cooperate with a law enforcement investigation. We fully cooperate with law enforcement agencies in identifying those who use our services for illegal activities. We reserve the right to report to law enforcement agencies any activities that we in good faith believe to be unlawful. We may also provide Non-Personally Identifiable Information about our users&rsquo; traffic patterns, and related site information to third party advertisers.</p>
<p>4. How can you update or correct your Personally Identifiable Information?</p>
<p>We believe you should have the ability to access and edit the Personally Identifiable Information that you have provided to us. You may change any of your Personally Identifiable Information in your account online at any time by logging on to your account and editing your information. We encourage you to promptly update your Personally Identifiable Information if it changes. You may ask to have the information on your account deleted or removed; however, because we keep track of past transactions, you cannot delete information associated with past transactions on this site. In addition, it may be impossible to completely delete your information without some residual information because of backups.</p>
<p>5. What are your choices regarding collection, use, and distribution of your information?</p>
<p>Without limiting paragraph 3 above, we may, from time to time, send you e-mail regarding our services. In addition, we and our partners may occasionally send you direct mail (including e-mail) about products and services that we feel may be of interest to you. You may unsubscribe from such e-mail messages. You also have choices with respect to cookies, as described below. By modifying your browser preferences, you have the choice to accept all cookies, to be notified when a cookie is set, or to reject all cookies. If you choose to reject all cookies some parts of our site may not work properly in your case.</p>
<p>Our Services are used to connect and share information with others. When you choose to upload photos and/or video, you agree to share and communicate using our Services. In some cases, people you share and communicate with may download or re-share content with others on or off our Services. In addition, other people may use our Services to share content about you with the audience they choose meaning they may share a photo you posted, mention you or share information that you shared.&nbsp;</p>
<p>We may also transfer information to third parties who support our business for various purposes including providing technical services, analyzing our Services, measuring effectiveness of ads and services, providing customer service, facilitating payments or conducting research. Any third-party partner must adhere to the confidentiality that is consistent with this Data Policy.</p>
<p>6. What security precautions are in place to protect against the loss, misuse, or alteration of your information?</p>
<p>At our site you can be assured that your Personally Identifiable Information is secure, consistent with current industry standards. The importance of security for all Personally Identifiable Information associated with our users is of utmost concern to us. Your Personally Identifiable Information is protected in several ways. Access by you to your Personally Identifiable Information is available through a password and unique customer ID selected by you. This password is encrypted. We recommend that you do not divulge your password to anyone. In addition, your Personally Identifiable Information resides on a secure server that only selected Begerz personnel and contractors have access to via password. We encrypt your Personally Identifiable Information and thereby prevent unauthorized parties from viewing such information when it is transmitted to us.</p>
<p>In addition, we follow the Payment Card Industry Data Security Standard (PCI DSS) whenever handling credit card data. We also maintain physical, electronic and procedural safeguards when it comes to storage, collection and disclosure of any/all Personally Identifiable Information.</p>
<p>7. Do we use &ldquo;cookies&rdquo;?</p>
<p>When you use our site we will store cookies on your computer, phone or other device, in order to facilitate and customize your use of our site. A cookie is a small data text file, which a website stores on your computer&rsquo;s hard drive (if your Web browser permits), or other device, that can later be retrieved to identify you to us. The cookies make your use of the site easier, make the site run more smoothly, helps us maintain a secure site, and enables the functionality that helps us provide additional services and features. You are always free to decline our cookies if your browser permits, but some parts of our site may not work properly in that case.</p>
<p>8. What should you know about privacy policies and data collection at any third-party sites accessible from our site?</p>
<p>Except as otherwise discussed in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. Other sites accessible through our site have their own privacy policies and data collection, use and disclosure practices. Please consult each site&rsquo;s privacy policy.</p>
<p>9. What else should you know about your privacy online?</p>
<p>You must be at least 13 years old (or otherwise reached the age of majority in your jurisdiction, whichever is older) to have our permission to use this site. Our policy is that we do not knowingly collect, use or disclose Personally Identifiable Information about visitors that are under 13 years of age. If you are a parent or guardian and you are aware that your child has provided Us with Personal Data, please contact Us. If we become aware that we have collected Personal Data from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from Our servers.&nbsp;&nbsp;</p>
<p>You should also be aware that when Personally Identifiable Information is voluntarily disclosed (i.e., your name, address, etc.) on any profile page, discussion forum, message board or other public areas of the site, that information, along with any information disclosed in your communication, can be viewed, collected and used by third parties and may result in unsolicited messages from third parties. Such activities are beyond our control and this Policy does not apply to such information. Any submissions to public areas on this site are accepted with the understanding that they are accessible to third parties.</p>
<p>10.&nbsp; Company Contact Information.</p>
<p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us by sending us an email to support@begerz.com. This Privacy Policy is effective as of May 20, 2022.</p>
<p>11. CCPA Privacy</p>
<p>This privacy notice section is for California residents and supplements the information contained in this Privacy Policy and it applies solely to visitors and users who reside in the State of California.</p>
<ol>
<li><span ><strong>Categories of Personal Information Collected</strong></span></li>
</ol>
<p><span >We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer or Device. The following is a list of categories of personal information which we may collect from California residents within the last twelve (12) months.</span></p>
<p><span >Please note that the categories and examples provided in the list below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact collected by us. For example, certain categories of personal information would only be collected if you provided such personal information directly to us.</span></p>
<ul>
<li><span ><strong>Category A: Identifiers.</strong></span></li>
</ul>
<p><span >Examples: A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, driver's license number, passport number, or other similar identifiers.</span></p>
<p><span >Collected: Yes.</span></p>
<ul>
<li><span ><strong>Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code &sect; 1798.80(e)).</strong></span></li>
</ul>
<p><span >Examples: A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</span></p>
<p><span >Collected: Yes.</span></p>
<ul>
<li><span ><strong>Category C: Protected classification characteristics under California or federal law.</strong></span></li>
</ul>
<p><span >Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</span></p>
<p><span >Collected: Yes, if you upload content displaying such information.</span></p>
<ul>
<li><span ><strong>Category D: Commercial information.</strong></span></li>
</ul>
<p><span >Examples: Records and history of products or services purchased or considered.</span></p>
<p><span >Collected: Yes.</span></p>
<ul>
<li><span ><strong>Category E: Biometric information.</strong></span></li>
</ul>
<p><span >Examples: Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</span></p>
<p><span >Collected: Yes, if you upload content displaying such information.</span></p>
<ul>
<li><span ><strong>Category F: Internet or other similar network activity.</strong></span></li>
</ul>
<p><span >Examples: Interaction with our Service or advertisement.</span></p>
<p><span >Collected: Yes.</span></p>
<ul>
<li><span ><strong>Category G: Geolocation data.</strong></span></li>
</ul>
<p><span >Examples: Approximate physical location.</span></p>
<p><span >Collected: Yes.</span></p>
<ul>
<li><span ><strong>Category H: Sensory data.</strong></span></li>
</ul>
<p><span >Examples: Audio, electronic, visual, thermal, olfactory, or similar information.</span></p>
<p><span >Collected: Yes.</span></p>
<ul>
<li><span ><strong>Category I: Professional or employment-related information.</strong></span></li>
</ul>
<p><span >Examples: Current or past job history or performance evaluations.</span></p>
<p><span >Collected: No.</span></p>
<ul>
<li><span ><strong>Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</strong></span></li>
</ul>
<p><span >Examples: Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</span></p>
<p><span >Collected: No.</span></p>
<ul>
<li><span ><strong>Category K: Inferences drawn from other personal information.</strong></span></li>
</ul>
<p><span >Examples: Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</span></p>
<p><span >Collected: Yes.</span></p>
<p><span >Under CCPA, personal information does not include:</span></p>
<ul>
<li><span >Publicly available information from government records</span></li>
<li><span >Deidentified or aggregated consumer information</span></li>
<li><span >Information excluded from the CCPA's scope, such as:</span></li>
<ul>
<li><span >Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data</span></li>
<li><span >Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994</span></li>
</ul>
</ul>
<ol>
<li><span ><strong>Sources of Personal Information</strong></span></li>
</ol>
<p><span >We obtain the categories of personal information listed above from the following categories of sources:</span></p>
<ul>
<li><span ><strong>Directly from You</strong></span><span >. For example, from the forms you complete in connection with our Services, preferences you express or provide through our Services, or from your purchases or other transactions on our Service.</span></li>
<li><span ><strong>Indirectly from You</strong></span><span >. For example, from observing your activity on our Service.</span></li>
<li><span ><strong>Automatically from You</strong></span><span >. For example, through cookies we or our Service Providers set on your Device as you navigate through our Service.</span></li>
<li><span ><strong>From Service Providers</strong></span><span >. For example, third-party vendors to monitor and analyze the use of our Service, third-party vendors for payment processing, or other third-party vendors that we use to provide the Service to you.</span></li>
</ul>
<ol>
<li><span ><strong>Use of Personal Information for Business Purposes or Commercial Purposes</strong></span></li>
</ol>
<p><span >We may use or disclose personal information we collect for "business purposes" or "commercial purposes" (as defined under the CCPA), which may include the following examples:</span></p>
<ul>
<li><span >To operate our Service and provide you with our Service.</span></li>
<li><span >To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our Service.</span></li>
<li><span >To fulfill or meet the reason you provided the information. For example, if you share your contact information to ask a question about our Service, we will use that personal information to respond to your inquiry. If you provide your personal information to purchase a product or service or to set up an account, we will use that information to process your payment and facilitate delivery and to allow you to use the Service.</span></li>
<li><span >To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</span></li>
<li><span >As described to you when collecting your personal information or as otherwise set forth in the CCPA.</span></li>
<li><span >For internal administrative and auditing purposes.</span></li>
<li><span >To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity, including, when necessary, to prosecute those responsible for such activities.</span></li>
</ul>
<p><span >Please note that the examples provided above are illustrative and not intended to be exhaustive. For more details on how we use this information, please refer to the "Use of Your Personal Data" section.</span></p>
<p><span >If we decide to collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes we will update this Privacy Policy.</span></p>
<ol>
<li><span ><strong>Disclosure of Personal Information for Business Purposes or Commercial Purposes</strong></span></li>
</ol>
<p><span >We may use or disclose and may have used or disclosed the following categories of personal information for business or commercial purposes:</span></p>
<ul>
<li><span >Category A: Identifiers</span></li>
<li><span >Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code &sect; 1798.80(e))</span></li>
<li><span >Category C: Protected classification characteristics under California or federal law</span></li>
<li><span >Category D: Commercial information</span></li>
<li><span >Category E:&nbsp; Biometric information</span></li>
<li><span >Category F: Internet or other similar network activity</span></li>
<li><span >Category G: Geolocation data</span></li>
<li><span >Category H: Sensory data</span></li>
</ul>
<p><span >Please note that the categories listed above are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact disclosed.</span></p>
<p><span >When we disclose personal information for a business purpose or a commercial purpose, where possible, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.</span></p>
<ol>
<li><span ><strong>Sale of Personal Information</strong></span></li>
</ol>
<p><span >As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a consumer's personal information by the business to a third party for valuable consideration. This means that we may have received some kind of benefit in return for sharing personal information, but not necessarily a monetary benefit.</span></p>
<p><span >Please note that the categories listed below are those defined in the CCPA. This does not mean that all examples of that category of personal information were in fact sold.</span></p>
<p><span >We may sell and may have sold the following categories of personal information:</span></p>
<ul>
<li><span >Category A: Identifiers</span></li>
<li><span >Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code &sect; 1798.80(e))</span></li>
<li><span >Category C: Protected classification characteristics under California or federal law</span></li>
<li><span >Category D: Commercial information</span></li>
<li><span >Category E:&nbsp; Biometric information</span></li>
<li><span >Category F: Internet or other similar network activity</span></li>
<li><span >Category G: Geolocation data</span></li>
<li><span >Category H: Sensory data</span></li>
</ul>
<ol>
<li><span ><strong>Sharing of Personal Information</strong></span></li>
</ol>
<p><span >We may share your personal information identified in the above categories with the following categories of third parties:</span></p>
<ul>
<li><span >Service Providers</span></li>
<li><span >Payment processors</span></li>
<li><span >Our affiliates</span></li>
<li><span >Our business partners</span></li>
<li><span >Third party vendors to whom you or your agents authorize us to disclose your personal information in connection with products or services we provide to you</span></li>
<li><span >Other visitors to our site or app.</span></li>
</ul>
<ol>
<li><span ><strong>Sale of Personal Information of Minors Under 16 Years of Age</strong></span></li>
</ol>
<p><span >We do not knowingly collect personal information from minors under the age of 16 through our Service, although certain third party websites that we link to may do so. These third-party websites have their own terms of use and privacy policies and we encourage parents and legal guardians to monitor their children's Internet usage and instruct their children to never provide information on other websites without their permission.</span></p>
<p><span >We do not sell the personal information of Consumers we actually know are less than 16 years of age, unless we receive affirmative authorization (the "right to opt-in") from either the Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales at any time. To exercise the right to opt-out, you (or your authorized representative) may submit a request to us by contacting us.</span></p>
<p><span >If you have reason to believe that a child under the age of 13 (or 16) has provided us with personal information, please contact us with sufficient detail to enable us to delete that information.</span></p>
<ol>
<li><span ><strong>Your Rights under the CCPA</strong></span></li>
</ol>
<p><span >The CCPA provides California residents with specific rights regarding their personal information. If you are a resident of California, you have the following rights:</span></p>
<ul>
<li><span ><strong>The right to notice.</strong></span><span >&nbsp;You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.</span></li>
<li><span ><strong>The right to request.</strong></span><span >&nbsp;Under CCPA, you have the right to request that we disclose information to you about Our collection, use, sale, disclosure for business purposes and share of personal information. Once we receive and confirm your request, we will disclose to you:</span></li>
<ul>
<li><span >The categories of personal information we collected about you</span></li>
<li><span >The categories of sources for the personal information we collected about you</span></li>
<li><span >Our business or commercial purpose for collecting or selling that personal information</span></li>
<li><span >The categories of third parties with whom we share that personal information</span></li>
<li><span >The specific pieces of personal information we collected about you</span></li>
<li><span >If we sold your personal information or disclosed your personal information for a business purpose, we will disclose to you:</span></li>
<ul>
<li><span >The categories of personal information categories sold</span></li>
<li><span >The categories of personal information categories disclosed</span></li>
</ul>
</ul>
<li><span ><strong>The right to say no to the sale of Personal Data (opt-out).</strong></span><span >&nbsp;You have the right to direct us to not sell your personal information. To submit an opt-out request please contact us.</span></li>
<li><span ><strong>The right to delete Personal Data.</strong></span><span >&nbsp;You have the right to request the deletion of your Personal Data, subject to certain exceptions. Once we receive and confirm your request, we will delete (and direct Our Service Providers to delete) your personal information from our records, unless an exception applies. We may deny your deletion request if retaining the information is necessary for Us or Our Service Providers to:</span></li>
<ul>
<li><span >Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</span></li>
<li><span >Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</span></li>
<li><span >Debug products to identify and repair errors that impair existing intended functionality.</span></li>
<li><span >Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</span></li>
<li><span >Comply with the California Electronic Communications Privacy Act (Cal. Penal Code &sect; 1546 et. seq.).</span></li>
<li><span >Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.</span></li>
<li><span >Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with Us.</span></li>
<li><span >Comply with a legal obligation.</span></li>
<li><span >Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</span></li>
</ul>
<li><span ><strong>The right not to be discriminated against.</strong></span><span>&nbsp;You have the right not to be discriminated against for exercising any of your consumer's rights, including by:</span></li>
<ul>
<li><span >Denying goods or services to you</span></li>
<li><span >Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties</span></li>
<li><span >Providing a different level or quality of goods or services to you</span></li>
<li><span >Suggesting that you will receive a different price or rate for goods or services or a different level or quality of goods or services</span></li>
</ul>
</ul>
<ol>
<li><span ><strong>Exercising Your CCPA Data Protection Rights</strong></span></li>
</ol>
<p><span >In order to exercise any of your rights under the CCPA, and if you are a California resident, you can contact Us by </span>sending us an email to support@begerz.com.</p>
<p><span >Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable request related to your personal information.</span></p>
<p><span >Your request to Us must:</span></p>
<ul>
<li><span >Provide sufficient information that allows Us to reasonably verify you are the person about whom we collected personal information or an authorized representative</span></li>
<li><span >Describe your request with sufficient detail that allows Us to properly understand, evaluate, and respond to it</span></li>
</ul>
<p><span >We cannot respond to your request or provide you with the required information if we cannot:</span></p>
<ul>
<li><span >Verify your identity or authority to make the request</span></li>
<li><span >And confirm that the personal information relates to you</span></li>
</ul>
<p><span >We will disclose and deliver the required information free of charge within 45 days of receiving your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary and with prior notice.</span></p>
<p><span >Any disclosures we provide will only cover the 12-month period preceding the verifiable request's receipt.</span></p>
<p><span >For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</span></p>
<ol>
<li><span ><strong>Do Not Sell My Personal Information</strong></span></li>
</ol>
<p><span >You have the right to opt-out of the sale of your personal information. Once we receive and confirm a verifiable consumer request from you, we will stop selling your personal information. To exercise your right to opt-out, please contact Us.</span></p>
<p><span >The Service Providers we partner with (for example, our analytics or advertising partners) may use technology on the Service that sells personal information as defined by the CCPA law. If you wish to opt out of the use of your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below.</span></p>
<p><span >Please note that any opt out is specific to the browser you use. You may need to opt out on every browser that you use.</span></p>
<p><span >Website</span></p>
<p><span >You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:</span></p>
<ul>
<li><span >The NAI's opt-out platform:&nbsp;</span><a href="http://www.networkadvertising.org/choices/" target="_blank" rel="noreferrer"><span >http://www.networkadvertising.org/choices/</span></a></li>
<li><span >The EDAA's opt-out platform&nbsp;</span><a href="http://www.youronlinechoices.com/" target="_blank" rel="noreferrer"><span >http://www.youronlinechoices.com/</span></a></li>
<li><span >The DAA's opt-out platform:&nbsp;</span><a href="http://optout.aboutads.info/?c=2&amp;lang=EN" target="_blank" rel="noreferrer"><span >http://optout.aboutads.info/?c=2&amp;lang=EN</span></a></li>
</ul>
<p><span >The opt out will place a cookie on your computer that is unique to the browser you use to opt out. If you change browsers or delete the cookies saved by your browser, you will need to opt out again.</span></p>
<p><span >Mobile Devices</span></p>
<p><span >Your mobile device may give you the ability to opt out of the use of information about the apps you use in order to serve you ads that are targeted to your interests:</span></p>
<ul>
<li><span >"Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices</span></li>
<li><span >"Limit Ad Tracking" on iOS devices</span></li>
</ul>
<p><span >You can also stop the collection of location information from your mobile device by changing the preferences on your mobile device.</span></p>
<ol>
<li><span ><strong>"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)</strong></span></li>
</ol>
<p><span >Our Service does not respond to Do Not Track signals.</span></p>
<p><span >However, some third party websites do keep track of your browsing activities. If you are visiting such websites, you can set your preferences in your web browser to inform websites that you do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of your web browser.</span></p>
<ol>
<li><span ><strong>Your California Privacy Rights (California's Shine the Light law)</strong></span></li>
</ol>
<p><span >Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes.</span></p>
<p><span >If you'd like to request more information under the California Shine the Light law, and if you are a California resident, you can contact Us using the contact information provided below.</span></p>
<ol>
<li><span ><strong>California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)</strong></span></li>
</ol>
<p><span >California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.</span></p>
<p><span >To request removal of such data, and if you are a California resident, you can contact Us using the contact information provided below, and include the email address associated with your account.</span></p>
<p><span >Be aware that your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.</span></p>


          </div>
        </Box>
        <BoxSignUpLink />
      </Paper>
    </Container>
  )
}