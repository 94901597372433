import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box, Button, Divider, IconButton, Typography } from "@mui/material"

// Custom components
import DialogHeader from "../../../components/DialogHeader"

// Icons
import CloseIcon from '@mui/icons-material/Close'


// Main component
export default function Delete({ open, handleClose, handleDelete }) {
  // Vars
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullScreen={fullScreen}
    >

      <DialogContent sx={{ mb: 2 }}>
        <DialogHeader
          id="alert-dialog-title"
          sx={{ textAlign:'center', mb:3 }}
        >
          Delete Your Account
          <Typography variant="body2">
            Are you sure you want to delete your account?
          </Typography>
        </DialogHeader>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 24,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContentText sx={{ mb:2 }}>
          Once you delete your account, you will no longer be able to login and all of your raises will be ended.
        </DialogContentText>

        <Divider />

        <Box sx={{ textAlign:'center' }}>
          <Button variant="contained" sx={{mt:3, mb:0, mr:1, fontWeight: 700}}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button variant="outlined" sx={{mt:3, mb:0, fontWeight: 700}}
            onClick={handleDelete}
          >
            Delete Account
          </Button>
        </Box>

      </DialogContent>
    </Dialog>
  )
}