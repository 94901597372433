import { Grid, Paper, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

// Page components
import Title from './Title'
import Karma from './Karma'

// Custom components
import ProfileAvatar from '../ProfileAvatar'
import NumberBox from '../NumberBox'

// CSS
import '../../css/Beg.css'
import '../../css/ImageCarousel.css'

// Utils
import currFormat from '../../utils/currFormat'


// Main component
export default function Profile({data}) {
  // Vars
  const theme = useTheme()
  const bcolor = theme.palette.gray.light
  const navigate = useNavigate()

  // Methods
  const handleProfileClick = (id) => {
    if (id) navigate(`/profile/${id}`)
  }

  return (
    <>
      {/* header */}
      <Grid container spacing={1} sx={{ mb:.8, p:"2px" }}>

        <Title data={data} link={`/profile/${data._id}`} />

        <Grid item xs={12} mt={1}>

            <Paper elevation={3} sx={{ m:"2px", mt:0, mb:0, p:"2px", pt:"14px"}}>

              {/* top row */}
              <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item sx={{ textAlign:"center", pl:1 }}>
                    <NumberBox fontWeight="700" number={data?.followers} />
                    <Typography noWrap variant="subtitle2">Followers</Typography>
                  </Grid>

                  {/* profile image */}
                  <Grid item>
                    <Paper className="rounded-corners-gradient-borders" sx={{ height:"auto", width:"auto", borderRadius:"24px" }}>
                      <ProfileAvatar 
                        src={ data?.currentImage || data?.profileImage } alt={ data?.username } 
                        sx={{ width:96, height:96, fontSize:46, margin:"2px", cursor:"pointer" }}
                        style={{ borderRadius:'22px'}}
                        onClick={() => { handleProfileClick(data._id)} }
                      >
                      </ProfileAvatar>
                    </Paper>
                  </Grid>

                  <Grid item sx={{ textAlign:"center", pr:1 }}>
                    <NumberBox fontWeight="700" number={data?.following} />
                    <Typography noWrap variant="subtitle2">Following</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* middle row */}
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  
                  <Grid item xs={4} sx={{ textAlign:"center" }}>
                    {/* <ToggleContentFlag /> */}
                  </Grid>

                  {/* karma flower - middel section */}
                  <Grid item xs={12} sx={{ textAlign:"center" }}>
                  
                    <Grid container>
                      <Grid item xs={12}>
                        <Karma points={data?.karma || 0} />
                      </Grid>
                      <Grid item xs={12}>
                        <NumberBox fontWeight={700} number={ currFormat(data?.karma || 0 ) } />
                        <Typography noWrap variant="subtitle2">Karma Earned</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* empty right side */}
                  <Grid item xs={4} sx={{ textAlign:"center" }}>
                  </Grid>
                </Grid>
              </Grid>


              {/* bottom row - stats */}
              <Grid item xs={12} mt={1.5}>
                <Grid container justifyContent="space-between" sx={{ padding:"4px", borderTop:`1px solid ${bcolor}` }}>
                  <Grid item xs={3} sx={{ textAlign:"center", borderRight:`1px solid ${bcolor}` }}>
                      <NumberBox fontWeight="700" number={data?.begCount || 0} />
                    <Typography noWrap variant="small1"> Raises </Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign:"center", borderRight:`1px solid ${bcolor}` }}>
                    <NumberBox fontWeight={700} number={ `$${currFormat(data?.totalRaised || 0)}`} />
                    <Typography noWrap variant="small1"> Raised </Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign:"center", borderRight:`1px solid ${bcolor}` }}>
                    <NumberBox fontWeight={700} number={data?.chipins || 0} />
                    <Typography noWrap variant="small1"> Chip-Ins </Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ textAlign:"center" }}>
                    <NumberBox fontWeight={700} number={ `$${currFormat( (data?.totalChippedIn + data.totalDonated) || 0)}` } />
                    <Typography noWrap variant="small1"> Donated </Typography>
                  </Grid>
                </Grid>
              </Grid>

            </Paper>
 
        </Grid>
      </Grid>
    </>
  )
}