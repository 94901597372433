import { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'

// Custom DataGrid components
import CustomToolbar from './CustomToolbar'

// CSS
import './css/Grid.css'


// Main component
export default function CustomDataGrid(props) {
  // Vars
  const {
    columns,
    pageOptions,
    rows,
    pagination,
    setPagination,
    setFilter,
    filter    
  } = props

  // State  
  const [filterButtonEl, setFilterButtonEl] = useState(null)
  const [selectionModel, setSelectionModel] = useState([])

  // row click event
  const handleRowClick = (params, event) => {
    console.log(event.target)
  }
 
  // find and return the column definition
  const getColumn = (obj, searchField, searchVal) => {
    for (var i=0; i < obj.length; i++) {
      if (obj[i][searchField] === searchVal) {
        return (obj[i])
      }
    }
  }

  // generate the json api query string from the filters
  const handleFilterChange = (filters) => {
    let string = ""

    // parse each filter to build the query string
    filters.items.forEach(item => {
      // get the column object
      const column = getColumn(columns, 'field', item.columnField)
      const value = item.value
      
      // replace friendly display column name with field name for use in the query
      const field = column?.searchField || item.columnField

      console.log(item.operatorValue)

      // set action to perform using the operator
      switch (item.operatorValue) {
        case 'equals':
        case 'is':
          if (!value) return
          string = `${field}=${value}`
          break

        case 'not':
          if (!value) return
          string = `${field}!=${value}`
          break

        case 'contains':
          if (!value) return
          string = `${field}=/${value}/i`
          break

        case 'startsWith':
          if (!value) return
          string = `${field}=/^${value}/i`
          break

        case 'endsWith':
          if (!value) return
          string = `${field}=/${value}$/i`
          break

        case 'isEmpty':
          string = `!${field}`
          break

        case 'isNotEmpty':
          string = `${field}`
          break

        case 'isAnyOf':
          if (!value) return
          string = `${field}=${value}`
          console.log(string)
          break

        case 'onOrAfter':
          if (!value) return
          string = `${field}>=${value}`
          break

        case 'after':
          if (!value) return
          string = `${field}>${value}`
          break
              
        case 'before':
          if (!value) return
          string = `${field}<${value}`
          break

        case 'onOrBefore':
          if (!value) return
          string = `${field}<=${value}`
          break
            
        default:
          string = ""
          break
      }
    })

    // update the api query
    if (string !== filter) setFilter(string)
  }

  // sort option change event
  const handleSortChange = (props) => {
    console.log(props)
  }
  
  // update pagination by incrementing the zero-based page index
  const updatePagination = (newPage) => {
    newPage = parseInt(newPage) + 1
    setPagination(prev => ({ ...prev, current:newPage }))
  }


  return (
    <DataGrid
      disableSelectionOnClick
      disableMultipleSelection={true}
      autoHeight
      components={{ Toolbar:CustomToolbar }}
      componentsProps={{
        panel: {
          anchorEl: filterButtonEl,
          placement: "bottom-end"
        },
        toolbar: { setFilterButtonEl }
      }}
      rows={rows}
      getRowId={(row) => row._id}
      columns={columns}
      headerHeight={56}
      pagination
      paginationMode="server"
      pageSize={pagination.limit}
      rowsPerPageOptions={ pageOptions }
      rowCount={ pagination?.records || 0 }
      onRowClick={(params, event) => ( handleRowClick(params, event) )}
      getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even' }
      onPageSizeChange={(pageSize) => ( setPagination(prev => ({ ...prev, ...{limit:pageSize, current:1} }))   )}
      onPageChange={(newPage) => ( updatePagination(newPage) )}
      onSelectionModelChange={(newSelectionModel) => { setSelectionModel(newSelectionModel) }}
      selectionModel={selectionModel}
      filterMode='server'
      onFilterModelChange={handleFilterChange}
      sortingMode="server"
      onSortModelChange={handleSortChange}
      loading={props?.loading}
      keepNonExistentRowsSelected
      {...props.DataGridProps}
    />
  )
}