import React, { useState } from "react";
import { useParams } from "react-router-dom";
import WaitOverlay from "../components/WaitOverlay";
import ErrorDialog from "../components/ErrorDialog";
import Container from "@mui/material/Container";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";

// apis
import { useApi as blockUserApi } from "../apis/privateApiCall";

// Custom components
import BegPagination from "../components/raises/BegPagination";
import SuccessPagination from "../components/success-stories/SuccessPagination";
import BottomCards from "../components/BottomCards";
import Header from "../components/profile/Header";
import SectionDivider from "../components/styled-ui/SectionDivider";
import SectionHeaderContainer from "../components/styled-ui/SectionHeaderContainer";
import AccessPageLower from "../components/AccessPageLower";
import { useTheme } from "@emotion/react";
import DialogHeader from "../components/DialogHeader";

// Main Component
export default function Profile() {
  // Vars
  const { userId } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // State
  const [waitOpen, setWaitOpen] = React.useState(true);
  const [canLoad, setCanLoad] = React.useState({ active: true });
  const [hasError, setHasError] = React.useState({
    status: false,
    message: "",
    code: 500,
  });
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [blockUser, setBlockUser] = useState("");
  const [snackState, setSnackState] = useState({
    open: false,
    vertical: "",
    horizontal: "",
    message: "",
  });
  // apis
  const { execute } = blockUserApi();

  // Methods
  const openBlockModal = (user) => {
    setIsBlockModalOpen(true);
    setBlockUser(user);
  };
  const closeBlockModal = () => setIsBlockModalOpen(false);

  const confirmBlockUnblockUser = async (endUrl = "block") => {
    setWaitOpen(true);
    const response = await execute(
      {},
      {
        method: "post",
        url: `/api/v1/users/${userId}/${endUrl}`,
      }
    );
    endUrl === "block" && closeBlockModal();
    setSnackState((state) => ({
      ...state,
      open: true,
      horizontal: "right",
      vertical: "top",
      message: response.message,
    }));
    setWaitOpen(false);
  };

  // handle load order of data sections
  const handleLoaded = (index) => {
    switch (index) {
      case "active":
        setCanLoad((prev) => ({ ...prev, success: true }));
        break;
      default:
        setWaitOpen(false);
    }
  };

  // properties for active raises
  const activeProps = {
    doneLoading: handleLoaded,
    type: "active",
    url: `/api/v1/begs/users/${userId}?status=active&publishType=public&sort=-createdAt`,
    pageSize: 12,
    paginationType: "more",
    adverts: false,
    divider: (
      <>
        <SectionDivider />
      </>
    ),
  };

  // properties for success stories
  const successProps = {
    doneLoading: handleLoaded,
    type: "success",
    url: `/api/v1/success/users/${userId}?sort=-createdAt`,
    pageSize: 12,
    paginationType: "more",
    adverts: false,
    divider: (
      <>
        <SectionDivider />
      </>
    ),
  };
  return (
    <>
      {waitOpen && <WaitOverlay />}
      {hasError.status && (
        <ErrorDialog
          message={hasError.message}
          setError={setHasError}
          errorCode={hasError.code}
          title={"Error retrieving begs"}
        />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: snackState.vertical,
          horizontal: snackState.horizontal,
        }}
        open={snackState.open}
        onClose={() => {
          setSnackState((state) => ({ ...state, open: false }));
        }}
        message={snackState.message}
        key={snackState.vertical + snackState.horizontal}
      />

      {isBlockModalOpen && (
        <Dialog maxWidth="sm" open={true} fullScreen={fullScreen} fullWidth>
          <IconButton
            aria-label="close"
            onClick={closeBlockModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 24,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              padding: "20px",
            }}
          >
            <DialogHeader>Block User</DialogHeader>
            <Typography>
              Are you sure do you want to block {blockUser} ?
            </Typography>
            <Grid
              sx={{
                display: "flex",
                gap: "10px",
                marginTop: "10px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => confirmBlockUnblockUser()}
              >
                Continue
              </Button>
              <Button variant="outlined" onClick={closeBlockModal}>
                Cancel
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      )}

      <Container component="main" maxWidth="md">
        {!waitOpen && (
          <Header
            showBlock
            blockClickHandler={openBlockModal}
            unblockUser={confirmBlockUnblockUser}
            data={userId}
          />
        )}

        <div style={{ marginTop: "2em" }} />

        {/* active begs */}
        <SectionHeaderContainer mb={2} to="">
          Active Raises
        </SectionHeaderContainer>
        {canLoad?.active && (
          <BegPagination {...activeProps}>
            <SectionDivider>
              <Button
                variant="outlined"
                sx={{
                  mt: -4,
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "white !important" },
                }}
              >
                View More
              </Button>
            </SectionDivider>
          </BegPagination>
        )}

        {/* success stories */}
        <SectionHeaderContainer mb={2} to="">
          Success Stories
        </SectionHeaderContainer>
        {canLoad?.success && (
          <SuccessPagination {...successProps}>
            <SectionDivider>
              <Button
                variant="outlined"
                sx={{
                  mt: -4,
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "white !important" },
                }}
              >
                View More
              </Button>
            </SectionDivider>
          </SuccessPagination>
        )}

        <AccessPageLower />
      </Container>

      <BottomCards />
    </>
  );
}
